import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import { Observable, Subscriber } from 'rxjs';
import { Archivo } from '@models/archivo.model';
import { take } from 'rxjs/operators';
import { ErrorService } from './errors.service';
import { UiService } from './ui.service';
import { Respuesta } from '@root/models/respuesta.model';


@Injectable()
export class ConexionService {
	message: string;

	constructor(
		private http: HttpClient,
		private errorS: ErrorService,
		private ui: UiService,
		private g: GlobalService,
	) {
		console.log('conexionService: online');
	}

	local() {
		this.http.get('http://localhost/dashboard/').subscribe(
			(res) => {
				console.log('RESPUESTA LOCAL:', res)
			},
			(err) => {
				console.log('RESPUESTA LOCAL:', err)

			}
		)
	}

	/**
	   * ECOMMERCE - devuelve la URL STRING con el token concatenado para bajar la imagen del server
	 *
	   * @param nombreImagen : string
	   * @param tipo : string
	   */
	getImgStore(nombreImagen: string, tipo: 'perfiles' | 'arts' | 'logos'): string {
		if (!tipo) { return '' }
		nombreImagen = nombreImagen || 'no-img'
		if (nombreImagen === '-') nombreImagen = 'no-img';

		let URL = ''
		if (nombreImagen === 'no-img') {
			URL = `assets/img/no-img.png`;
		} else {
			if (nombreImagen.indexOf('http://') !== -1 || nombreImagen.indexOf('https://') !== -1) return nombreImagen;
			let authToken = this.g.getDato('EcomEmpToken') || 'xxx';
			URL = `${environment.apiURL}ecommerce/img/${tipo}/${nombreImagen}?authToken=${authToken}`;
		}
		return URL;

	}
	/**
	   * devuelve la URL STRING con el token concatenado para bajar la imagen del server
	   * @param nombreImagen : string
	   * @param tipo : string
	   */
	getImg(nombreImagen: string, tipo: 'perfiles' | 'arts' | 'logos'): string {
		if (!tipo) { return '' }
		nombreImagen = nombreImagen || 'no-img'
		if (nombreImagen === '-') nombreImagen = 'no-img';
		let URL = ''
		if (nombreImagen === 'no-img') {
			URL = `assets/img/no-img.png`;
		} else {
			if (nombreImagen.indexOf('http://') !== -1 || nombreImagen.indexOf('https://') !== -1) return nombreImagen;
			let authToken = this.g.getDato('authToken') || 'xxx';
			URL = `${environment.apiURL}img/${tipo}/${nombreImagen}?authToken=${authToken}`;
		}
		return URL;

	}

	/**
	 * devuelve la URL via promesa con el token concatenado para bajar la imagen del server
	 * @param nombreImagen : string
	 * @param tipo : string
	 */
	sGetImg(nombreImagen: string, tipo: string): Promise<string> {
		return new Promise((res, rej) => {
			if (!tipo) { res(''); return; }
			nombreImagen = nombreImagen || 'no-img'

			if (nombreImagen === '-') nombreImagen = 'no-img'
			let URL = ''
			if (nombreImagen === 'no-img') {
				URL = `assets/img/no-img.png`;
			} else {
				if (nombreImagen.indexOf('http://') !== -1 || nombreImagen.indexOf('https://') !== -1) res(nombreImagen);
				let authToken = this.g.getDato('authToken') || 'xxx';
				URL = `${environment.apiURL}img/${tipo}/${nombreImagen}?authToken=${authToken}`;
			}
			console.log(URL)
			res(URL);
		})

	}
	sPostUpload(archivo?: Archivo, id: string = '-'): Observable<any> {
		return new Observable((suscriptor: Subscriber<any>) => {
			if (!archivo) {
				suscriptor.error('no hay archivo para subir');
			}

			let authToken = this.g.getDato('authToken') || 'xxx';
			const URL = environment.apiURL + 'upload' + '/';
			let CABECERAS = new HttpHeaders({ 'authToken': authToken });
			let form = new FormData();
			form.append('archivo', archivo.archivo);
			form.append('pass', archivo.pass);
			this.http.post(URL + archivo.tipo + '/' + id, form, { headers: CABECERAS })
				.subscribe(
					res => {
						suscriptor.next(res);
						suscriptor.complete();

					},
					err => {
						this.ui.loading(false);
						suscriptor.error(err)
					}
				)
		})
	}

	post(datos: Object, servicio: string, muestraError = false, tipo: 'form-data' | 'json' = 'json'): Observable<any> {

		return new Observable((suscriptor: Subscriber<any>) => {

			let authToken = this.g.getDato('authToken') || 'xxxx';
			const URL = environment.apiURL + servicio + '/';

			let CABECERAS = new HttpHeaders({ 'Content-Type': 'application/json', 'authToken': authToken });
			if (tipo === 'form-data') CABECERAS = new HttpHeaders({ 'authToken': authToken });

			if (servicio !== 'login') {
				if (!environment.production) console.log('ver si revisamos la sesion en este punto...');
			}

			this.http.post(URL, datos, { headers: CABECERAS }).subscribe((data: any) => {

				if (!data.ok) {
					this.errorS.manejarError(data, muestraError, URL);
					suscriptor.complete()
					return;
				}
				suscriptor.next(data);
				suscriptor.complete()
			},
				err => {
					this.ui.loading(false);
					this.errorS.manejarError(err, muestraError, URL);
					suscriptor.error(err);
					return
				}
			)
		})


	}
	postP(datos: Object, servicio: string, muestraError = false, tipo: 'form-data' | 'json' = 'json'): Promise<any> {

		return new Promise((ok, er) => {
			let authToken = this.g.getDato('authToken') || 'xxxx';
			const URL = environment.apiURL + servicio + '/';

			let CABECERAS = new HttpHeaders({ 'Content-Type': 'application/json', 'authToken': authToken });
			if (tipo === 'form-data') CABECERAS = new HttpHeaders({ 'authToken': authToken });

			if (servicio !== 'login') {
				console.log('TODO: CSATP');
			}
			this.http.post(URL, datos, { headers: CABECERAS })
				.subscribe(
					(data: any) => {
						if (!data.ok) {
							this.ui.loading(false);
							this.errorS.manejarError(data, muestraError, URL);
							er(data.error)
							return;
						}
						ok(data)
					},
					err => {
						this.ui.loading(false);
						this.errorS.manejarError(err, muestraError, URL);
						er(err);
						return;
					}
				)
		})
	}
	get(servicio: string, muestraError = false): Observable<any> {
		return new Observable((suscriptor: Subscriber<any>) => {

			let authToken = this.g.getDato('authToken') || 'xxx';

			const URL = environment.apiURL + servicio + '/';

			let headers = new HttpHeaders({ 'authToken': authToken });
			this.http.get(URL, { headers })
				.subscribe(
					(data: any) => {
						if (!data.ok) {
							this.ui.loading(false);
							this.errorS.manejarError(data, muestraError, URL);
							suscriptor.complete()
							return;
						}
						suscriptor.next(data);
						suscriptor.complete();
					},
					err => {
						this.ui.loading(false);
						this.errorS.manejarError(err, muestraError, URL);
						suscriptor.error(err);
						return
					}
				);
		})
	}
	getP(servicio: string, muestraError = false): Promise<any> {
		return new Promise((ok, er) => {
			let authToken = this.g.getDato('authToken') || 'xxx';
			let EcomFcpToken = this.g.getDato('EcomFcpToken') || 'xxxx';
			let EcomEmpToken = this.g.getDato('EcomEmpToken') || 'xxxx';

			const URL = environment.apiURL + servicio + '/';

			let headers = new HttpHeaders({ 'authToken': authToken });

			this.http.get(URL, { headers })
				.subscribe(
					(data: any) => {
						if (!data.ok) {
							this.ui.loading(false);
							this.errorS.manejarError(data, muestraError, URL);
							er(data)
							return;
						}
						ok(data);
					},
					err => {
						this.ui.loading(false);
						this.errorS.manejarError(err, muestraError, URL);
						er(err);
						return
					}
				);
		})
	}
	getArchivo(servicio: string): Observable<any> {
		return new Observable((suscriptor: Subscriber<any>) => {
			let authToken = this.g.getDato('authToken');

			const URL = environment.apiURL + servicio + '/';
			// const URL = 'data_testing/' + servicio + '.json' ;
			let headers = new HttpHeaders({ 'authToken': authToken, 'Content-Type': 'application/json' });
			this.http.get(URL, { responseType: 'blob', headers })
				.subscribe(
					data => {
						suscriptor.next(data);
						suscriptor.complete();
					},
					err => {
						this.ui.loading(false);
						err.error.message = err.error.message ? err.error.message : ' No se pudo descargar el archivo. ';
						suscriptor.error(err);
						console.log('Error al hacer GET: ', err);
					}
				);
		})
	}

	/**
	 * Permite mandar un POST con FormData, en el form data pueden ir archivos y valores con clave
	 *  Enviar un objeto asi:
	 *  let form = new FormData();
	 *	form.append('archivo', archivoBlob);
	 *	form.append('dato', unString);
	 *
	 * @param formData : FormData con los datos a enviar
	 * @param servicio : Ruta de la API
	 */
	postFormData(formData: FormData, servicio: string): Observable<any> {

		return new Observable((suscriptor: Subscriber<any>) => {


			let authToken = this.g.getDato('authToken') || 'xxx';
			const URL = environment.apiURL + servicio + '/';
			let CABECERAS = new HttpHeaders({ 'authToken': authToken });

			this.http.post(URL, formData, { headers: CABECERAS })
				.pipe(take(1))
				.subscribe(
					res => {
						suscriptor.next(res);
						suscriptor.complete();

					},
					err => suscriptor.error(err)
				)
		})
	}

}


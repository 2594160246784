export const tipoFigura = [
	{
		clave: '01',
		descripcion: 'Operador'
	},
	{
		clave: '02',
		descripcion: 'Propietario'
	},
	{
		clave: '03',
		descripcion: 'Arrendador'
	},
	{
		clave: '04',
		descripcion: 'Notificado'
	},
]

export const tipoPermisoSCT = [
	{
		clave: 'TPAF01',
		descripcion: 'Autotransporte Federal de carga general.'
	},
	{
		clave: 'TPAF02',
		descripcion: 'Transporte privado de carga.'
	},
	{
		clave: 'TPAF03',
		descripcion: 'Autotransporte Federal de Carga Especializada de materiales y residuos peligrosos.'
	},
	{
		clave: 'TPAF04',
		descripcion: 'Transporte de automóviles sin rodar en vehículo tipo góndola.'
	},
	{
		clave: 'TPAF05',
		descripcion: 'Transporte de carga de gran peso y/o volumen de hasta 90 toneladas.'
	},
	{
		clave: 'TPAF06',
		descripcion: 'Transporte de carga especializada de gran peso y/o volumen de más 90 toneladas.'
	},
	{
		clave: 'TPAF07',
		descripcion: 'Transporte Privado de materiales y residuos peligrosos.'
	},
	{
		clave: 'TPAF08',
		descripcion: 'Autotransporte internacional de carga de largo recorrido.'
	},
	{
		clave: 'TPAF09',
		descripcion: 'Autotransporte internacional de carga especializada de materiales y residuos peligrosos de largo recorrido.'
	},
	{
		clave: 'TPAF10',
		descripcion: 'Autotransporte Federal de Carga General cuyo ámbito de aplicación comprende la franja fronteriza con Estados Unidos.'
	},
	{
		clave: 'TPAF11',
		descripcion: 'Autotransporte Federal de Carga Especializada cuyo ámbito de aplicación comprende la franja fronteriza con Estados Unidos.'
	},
	{
		clave: 'TPAF12',
		descripcion: 'Servicio auxiliar de arrastre en las vías generales de comunicación.'
	},
	{
		clave: 'TPAF13',
		descripcion: 'Servicio auxiliar de servicios de arrastre, arrastre y salvamento, y depósito de vehículos en las vías generales de comunicación.'
	},
	{
		clave: 'TPAF14',
		descripcion: 'Servicio de paquetería y mensajería en las vías generales de comunicación.'
	},
	{
		clave: 'TPAF15',
		descripcion: 'Transporte especial para el tránsito de grúas industriales con peso máximo de 90 toneladas.'
	},
	{
		clave: 'TPAF16',
		descripcion: 'Servicio federal para empresas arrendadoras servicio público federal.'
	},
	{
		clave: 'TPAF17',
		descripcion: 'Empresas trasladistas de vehículos nuevos.'
	},
	{
		clave: 'TPAF18',
		descripcion: 'Empresas fabricantes o distribuidoras de vehículos nuevos.'
	},
	{
		clave: 'TPAF19',
		descripcion: 'Autorización expresa para circular en los caminos y puentes de jurisdicción federal con configuraciones de tractocamión doblemente articulado.'
	},
	{
		clave: 'TPAF20',
		descripcion: 'Autotransporte Federal de Carga Especializada de fondos y valores.'
	},
	{
		clave: 'TPTM01',
		descripcion: 'Permiso temporal para navegación de cabotaje'
	},
	{
		clave: 'TPTA01',
		descripcion: 'Concesión y/o autorización para el servicio regular nacional y/o internacional para empresas mexicanas'
	},
	{
		clave: 'TPTA02',
		descripcion: 'Permiso para el servicio aéreo regular de empresas extranjeras'
	},
	{
		clave: 'TPTA03',
		descripcion: 'Permiso para el servicio nacional e internacional no regular de fletamento'
	},
	{
		clave: 'TPTA04',
		descripcion: 'Permiso para el servicio nacional e internacional no regular de taxi aéreo'
	},
	{
		clave: 'TPXX00',
		descripcion: 'Permiso no contemplado en el catálogo.'
	},
]

export const configuracionAutotransporte = [
	{
		clave: 'C2',
		descripcion: 'Camión Unitario (2 llantas en el eje delantero y 4 llantas en el eje trasero)',
	},
	{
		clave: 'C3',
		descripcion: 'Camión Unitario (2 llantas en el eje delantero y 6 o 8 llantas en los dos ejes traseros)',
	},
	{
		clave: 'C2R2',
		descripcion: 'Camión-Remolque (6 llantas en el camión y 8 llantas en remolque)',
	},
	{
		clave: 'C3R2',
		descripcion: 'Camión-Remolque (10 llantas en el camión y 8 llantas en remolque)',
	},
	{
		clave: 'C2R3',
		descripcion: 'Camión-Remolque (6 llantas en el camión y 12 llantas en remolque)',
	},
	{
		clave: 'C3R3',
		descripcion: 'Camión-Remolque (10 llantas en el camión y 12 llantas en remolque)',
	},
	{
		clave: 'T2S1',
		descripcion: 'Tractocamión Articulado (6 llantas en el tractocamión, 4 llantas en el semirremolque)',
	},
	{
		clave: 'T2S2',
		descripcion: 'Tractocamión Articulado (6 llantas en el tractocamión, 8 llantas en el semirremolque)',
	},
	{
		clave: 'T2S3',
		descripcion: 'Tractocamión Articulado (6 llantas en el tractocamión, 12 llantas en el semirremolque)',
	},
	{
		clave: 'T3S1',
		descripcion: 'Tractocamión Articulado (10 llantas en el tractocamión, 4 llantas en el semirremolque)',
	},
	{
		clave: 'T3S2',
		descripcion: 'Tractocamión Articulado (10 llantas en el tractocamión, 8 llantas en el semirremolque)',
	},
	{
		clave: 'T3S3',
		descripcion: 'Tractocamión Articulado (10 llantas en el tractocamión, 12 llantas en el semirremolque)',
	},
	{
		clave: 'T2S1R2',
		descripcion: 'Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 4 llantas en el semirremolque y 8 llantas en el remolque)',
	},
	{
		clave: 'T2S2R2',
		descripcion: 'Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 8 llantas en el semirremolque y 8 llantas en el remolque)',
	},
	{
		clave: 'T2S1R3',
		descripcion: 'Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 4 llantas en el semirremolque y 12 llantas en el remolque)',
	},
	{
		clave: 'T3S1R2',
		descripcion: 'Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 4 llantas en el semirremolque y 8 llantas en el remolque)',
	},
	{
		clave: 'T3S1R3',
		descripcion: 'Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 4 llantas en el semirremolque y 12 llantas en el remolque)',
	},
	{
		clave: 'T3S2R2',
		descripcion: 'Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 8 llantas en el remolque)',
	},
	{
		clave: 'T3S2R3',
		descripcion: 'Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 12 llantas en el remolque)',
	},
	{
		clave: 'T3S2R4',
		descripcion: 'Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 16 llantas en el remolque)',
	},
	{
		clave: 'T2S2S2',
		descripcion: 'Tractocamión Semirremolque-Semirremolque (6 llantas en el tractocamión, 8 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)',
	},
	{
		clave: 'T3S2S2',
		descripcion: 'Tractocamión Semirremolque-Semirremolque (10 llantas en el tractocamión, 8 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)',
	},
	{
		clave: 'T3S3S2',
		descripcion: 'Tractocamión Semirremolque-Semirremolque (10 llantas en el tractocamión, 12 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)',
	},
	{
		clave: 'OTROEV',
		descripcion: 'Especializado de Voluminoso',
	},
	{
		clave: 'OTROEGP',
		descripcion: 'Especializado de Gran Peso',
	},
	{
		clave: 'OTROSG',
		descripcion: 'Servicio de Grúas',
	},
]
export const subTipoRemolque = [
	{
		clave: 'CTR001',
		descripcion: 'Caballete',
	},
	{
		clave: 'CTR002',
		descripcion: 'Caja',
	},
	{
		clave: 'CTR003',
		descripcion: 'Caja Abierta',
	},
	{
		clave: 'CTR004',
		descripcion: 'Caja Cerrada',
	},
	{
		clave: 'CTR005',
		descripcion: 'Caja De Recolección Con Cargador Frontal',
	},
	{
		clave: 'CTR006',
		descripcion: 'Caja Refrigerada',
	},
	{
		clave: 'CTR007',
		descripcion: 'Caja Seca',
	},
	{
		clave: 'CTR008',
		descripcion: 'Caja Transferencia',
	},
	{
		clave: 'CTR009',
		descripcion: 'Cama Baja o Cuello Ganso',
	},
	{
		clave: 'CTR010',
		descripcion: 'Chasis Portacontenedor',
	},
	{
		clave: 'CTR011',
		descripcion: 'Convencional De Chasis',
	},
	{
		clave: 'CTR012',
		descripcion: 'Equipo Especial',
	},
	{
		clave: 'CTR013',
		descripcion: 'Estacas',
	},
	{
		clave: 'CTR014',
		descripcion: 'Góndola Madrina',
	},
	{
		clave: 'CTR015',
		descripcion: 'Grúa Industrial',
	},
	{
		clave: 'CTR016',
		descripcion: 'Grúa ',
	},
	{
		clave: 'CTR017',
		descripcion: 'Integral',
	},
	{
		clave: 'CTR018',
		descripcion: 'Jaula',
	},
	{
		clave: 'CTR019',
		descripcion: 'Media Redila',
	},
	{
		clave: 'CTR020',
		descripcion: 'Pallet o Celdillas',
	},
	{
		clave: 'CTR021',
		descripcion: 'Plataforma',
	},
	{
		clave: 'CTR022',
		descripcion: 'Plataforma Con Grúa',
	},
	{
		clave: 'CTR023',
		descripcion: 'Plataforma Encortinada',
	},
	{
		clave: 'CTR024',
		descripcion: 'Redilas',
	},
	{
		clave: 'CTR025',
		descripcion: 'Refrigerador',
	},
	{
		clave: 'CTR026',
		descripcion: 'Revolvedora',
	},
	{
		clave: 'CTR027',
		descripcion: 'Semicaja',
	},
	{
		clave: 'CTR028',
		descripcion: 'Tanque',
	},
	{
		clave: 'CTR029',
		descripcion: 'Tolva',
	},
	{
		clave: 'CTR030',
		descripcion: 'Tractor',
	},
	{
		clave: 'CTR031',
		descripcion: 'Volteo',
	},
	{
		clave: 'CTR032',
		descripcion: 'Volteo Desmontable',
	},

]

export const tipoEmbalaje = [
	{ clave: '1A1', descripcion: 'Bidones (Tambores) de Acero 1 de tapa no desmontable' },
	{ clave: '1A2', descripcion: 'Bidones (Tambores) de Acero 1 de tapa desmontable' },
	{ clave: '1B1', descripcion: 'Bidones (Tambores) de Aluminio de tapa no desmontable' },
	{ clave: '1B2', descripcion: 'Bidones (Tambores) de Aluminio de tapa desmontable' },
	{ clave: '1D', descripcion: 'Bidones (Tambores) de Madera contrachapada' },
	{ clave: '1G', descripcion: 'Bidones (Tambores) de Cartón' },
	{ clave: '1H1', descripcion: 'Bidones (Tambores) de Plástico de tapa no desmontable' },
	{ clave: '1H2', descripcion: 'Bidones (Tambores) de Plástico de tapa desmontable' },
	{ clave: '1N1', descripcion: 'Bidones (Tambores) de Metal que no sea acero ni aluminio de tapa no desmontable' },
	{ clave: '1N2', descripcion: 'Bidones (Tambores) de Metal que no sea acero ni aluminio de tapa desmontable' },
	{ clave: '3A1', descripcion: 'Jerricanes (Porrones) de Acero de tapa no desmontable' },
	{ clave: '3A2', descripcion: 'Jerricanes (Porrones) de Acero de tapa desmontable' },
	{ clave: '3B1', descripcion: 'Jerricanes (Porrones) de Aluminio de tapa no desmontable' },
	{ clave: '3B2', descripcion: 'Jerricanes (Porrones) de Aluminio de tapa desmontable' },
	{ clave: '3H1', descripcion: 'Jerricanes (Porrones) de Plástico de tapa no desmontable' },
	{ clave: '3H2', descripcion: 'Jerricanes (Porrones) de Plástico de tapa desmontable' },
	{ clave: '4A', descripcion: 'Cajas de Acero' },
	{ clave: '4B', descripcion: 'Cajas de Aluminio' },
	{ clave: '4C1', descripcion: 'Cajas de Madera natural ordinaria' },
	{ clave: '4C2', descripcion: 'Cajas de Madera natural de paredes a prueba de polvos (estancas a los pulverulentos)' },
	{ clave: '4D', descripcion: 'Cajas de Madera contrachapada' },
	{ clave: '4F', descripcion: 'Cajas de Madera reconstituida' },
	{ clave: '4G', descripcion: 'Cajas de Cartón' },
	{ clave: '4H1', descripcion: 'Cajas de Plástico Expandido' },
	{ clave: '4H2', descripcion: 'Cajas de Plástico Rígido' },
	{ clave: '5H1', descripcion: 'Sacos (Bolsas) de Tejido de plástico sin forro ni revestimientos interiores' },
	{ clave: '5H2', descripcion: 'Sacos (Bolsas) de Tejido de plástico a prueba de polvos (estancos a los pulverulentos)' },
	{ clave: '5H3', descripcion: 'Sacos (Bolsas) de Tejido de plástico resistente al agua' },
	{ clave: '5H4', descripcion: 'Sacos (Bolsas) de Película de plástico' },
	{ clave: '5L1', descripcion: 'Sacos (Bolsas) de Tela sin forro ni revestimientos interiores' },
	{ clave: '5L2', descripcion: 'Sacos (Bolsas) de Tela a prueba de polvos (estancos a los pulverulentos)' },
	{ clave: '5L3', descripcion: 'Sacos (Bolsas) de Tela resistentes al agua' },
	{ clave: '5M1', descripcion: 'Sacos (Bolsas) de Papel de varias hojas' },
	{ clave: '5M2', descripcion: 'Sacos (Bolsas) de Papel de varias hojas, resistentes al agua' },
	{ clave: '6HA1', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con bidón (tambor) de acero' },
	{ clave: '6HA2', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con una jaula o caja de acero' },
	{ clave: '6HB1', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con un bidón (tambor) exterior de aluminio' },
	{ clave: '6HB2', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con una jaula o caja de aluminio' },
	{ clave: '6HC', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con una caja de madera' },
	{ clave: '6HD1', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con un bidón (tambor) de madera contrachapada' },
	{ clave: '6HD2', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con una caja de madera contrachapada' },
	{ clave: '6HG1', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con un bidón (tambor) de cartón' },
	{ clave: '6HG2', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con una caja de cartón' },
	{ clave: '6HH1', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con un bidón (tambor) de plástico' },
	{ clave: '6HH2', descripcion: 'Envases y embalajes compuestos de Recipiente de plástico, con caja de plástico rígido' },
	{ clave: '6PA1', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con un bidón (tambor) de acero' },
	{ clave: '6PA2', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con una jaula o una caja de acero' },
	{ clave: '6PB1', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con un bidón (tambor) exterior de aluminio' },
	{ clave: '6PB2', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con una jaula o una caja de aluminio' },
	{ clave: '6PC', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con una caja de madera' },
	{ clave: '6PD1', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con bidón (tambor) de madera contrachapada' },
	{ clave: '6PD2', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con canasta de mimbre' },
	{ clave: '6PG1', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con un bidón (tambor) de cartón' },
	{ clave: '6PG2', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con una caja de cartón' },
	{ clave: '6PH1', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con un envase y embalaje de plástico expandido' },
	{ clave: '6PH2', descripcion: 'Envases y embalajes compuestos de Recipiente de vidrio, porcelana o de gres, con un envase y embalaje de plástico rígido' },
	{ clave: '7H1', descripcion: 'Bultos de Plástico' },
	{ clave: '7L1', descripcion: 'Bultos de Tela' },
	{ clave: 'Z01', descripcion: 'No aplica' },
]



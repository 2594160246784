import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
@Component({
  selector: 'app-mensaje-default',
  templateUrl: './mensaje-default.component.html',
  styleUrls: ['./mensaje-default.component.css']
})
export class MensajeDefaultComponent implements OnInit {
  titulo = 'Titulo';
  mensaje = 'Mensaje del dialogo...';

  constructor(public dialogo: MatDialogRef<MensajeDefaultComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                // aca en el constructor se recibe la informacion que viene del padre en 'data'
                this.titulo = data.titulo ? data.titulo : this.titulo;
                this.mensaje = data.mensaje ? data.mensaje : this.mensaje;
    }

  ok() {
    //El usuario hizo click en ok
    this.dialogo.close('ok');

  }
  cancelar() {
    //El usuario hizo click en cancelar
    this.dialogo.close('cancelar');
  }
  ngOnInit() {

  }

}
@Component({
  selector: 'app-mensaje-default',
  templateUrl: './mensaje-default.component.html',
  styleUrls: ['./mensaje-default.component.css']
})
export class MensajeDefaultComponentX extends MensajeDefaultComponent { }

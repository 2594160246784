import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { DatosService } from '@core/datos.service';
import { GlobalService } from 'src/app/core/global.service';
import { ConexionService } from '@core/conexion.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { UiService } from 'src/app/core/ui.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from '../../../../auth/auth.service';
import { Contacto, ContactoEmail, ContactoTel } from '@root/models/contacto.model';
import Swal from 'sweetalert2';
import { Fcp } from '@root/models/fcps.model';

@Component({
	selector: 'app-crm-contacto-edita',
	templateUrl: './crm-contacto-edita.component.html',
	styleUrls: ['./crm-contacto-edita.component.scss']
})
export class CrmContactoEditaComponent implements OnInit, OnDestroy {
	@ViewChild('f') formulario: NgForm;

	isModal: boolean = false;
	suscripciones: Subscription[] = [];

	contacto: Contacto = new Contacto();
	empresa: Fcp = new Fcp();

	cargando: boolean;


	constructor(
		private g: GlobalService,
		public ds: DatosService,
		private con: ConexionService,
		private snackBar: MatSnackBar,
		public ui: UiService,
		public auth: AuthService,
		private route: ActivatedRoute,
		public modal: MatDialogRef<CrmContactoEditaComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		if (data.modal) {
			this.isModal = true;
			this.contacto = new Contacto(JSON.parse(JSON.stringify(data.contacto)))
		}

	}
	agregarEmail() {
		this.contacto.email.push(new ContactoEmail('', 'Trabajo'))
	}
	borrarEmail(i: number) {
		this.contacto.email.splice(i, 1)
	}
	agregarTelefono() {
		this.contacto.tel.push(new ContactoTel('', 'Trabajo'))

	}
	borrarTelefono(i: number) {
		this.contacto.tel.splice(i, 1)
	}
	async guardar() {
		if (!this.contacto.nom) {
			Swal.fire('', 'Escriba el nombre del contacto.', 'info')
			return
		}
		if (!this.contacto.idEmp && this.empresa._id) this.contacto.idEmp = this.empresa._id

		try {
			this.ui.loading(true);
			let res = await this.con.postP(this.contacto, 'crm/contacto', true)
			this.ui.loading(false);

			if (res.ok && res.data._id) {
				this.contacto = new Contacto(res.data)
				if (this.isModal) {
					this.modal.close(this.contacto);
					return;
				}
			} else {
				Swal.fire('', 'Problema al Guardar el Contacto.', 'error')
				return;
			}
		} catch (error) {
			console.log(error);
		}

	}
	async eliminar(contacto: Contacto) {

		contacto = this.contacto;

		let res = await this.g.SweetMsg('Eliminar', '¿Desea eliminar el registro?', 'question')
		if (!res.value) return

		contacto.baja = 1;
		res = await this.con.postP(contacto, `crm/contacto`)
		if (this.isModal) this.modal.close(true) // Manda true para que sea positivo pero no lleva dato, asi se sabe que se borro

		this.snackBar.open('¡ Contacto eliminado correctamente !', 'DESHACER', { duration: 5000 }).onAction().subscribe(() => {

			contacto.baja = 0;
			this.con.post(contacto, `crm/contacto`)
				.subscribe(res => {
					this.snackBar.open('Accion deshecha', null, { duration: 3000 })
				})
		})


	}
	nuevo(){
		this.contacto = new Contacto();
	}
	cancelar() {
		if (this.isModal) this.modal.close()
	}

	ngOnInit() {
		this.route.data.pipe(take(1)).subscribe((data: any) => {

			if (!this.isModal) this.ui.paginaActual = data.titulo || '';
		})

	}

	ngOnDestroy() {
		this.suscripciones.forEach(suscripcion => {
			suscripcion.unsubscribe();
		});
	}

}

import { createReducer, on } from '@ngrx/store';
import * as fromAuth from './auth.actions';
import { Usu } from '@models/usu.model';

const usuLocal = JSON.parse(window.localStorage.getItem('usu'));
const estadoInicial: Usu = usuLocal ? usuLocal : null;

export const usuReducer = createReducer(
  estadoInicial,
  on(fromAuth.crearUsuario, (state, action) => {
    const nvoUsu = new Usu();
    Object.assign(nvoUsu, action.obj);
    window.localStorage.setItem('authToken', nvoUsu.authToken);
    window.localStorage.setItem('usu', JSON.stringify(nvoUsu));
    return nvoUsu;
  }),
  on(fromAuth.cambiarUsuario, (state, action) => {
    const nvoUsu = new Usu();
    Object.assign(nvoUsu, action.obj);
    window.localStorage.setItem('usu', JSON.stringify(nvoUsu));
    return nvoUsu;
  }),
  on(fromAuth.cambiarPropiedad, (state, action) => {
    const nvoUsu = { ...state };
    nvoUsu[action.propiedad] = action.valor;
    window.localStorage.setItem('usu', JSON.stringify(nvoUsu));
    return nvoUsu;
  }),
  on(fromAuth.cerrarSesion, (state) => {
    let nvoUsu = null;
    const mensaje = window.localStorage.getItem('mensajeSesion');

    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      // evita que desloguee al usuario del ecommerce
      if (key.indexOf('Ecom') === -1) {
        localStorage.removeItem(key);
      }
    }

    if (mensaje) {
      window.localStorage.setItem('mensajeSesion', mensaje);
    }

    return nvoUsu;
  })
);

import { Directive, EventEmitter, ElementRef, HostListener, Input, Output, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appUploadFileButton]'
})
export class UploadFileButtonDirective implements AfterViewInit {

	@Input('inputUpload')inputUpload: string;
	@Input('inputLabel')inputLabel: string;

  	constructor(private elem: ElementRef) { 	}
	
	@HostListener('click', ['$event'])
	public onClick(event: any){
		
		let elementos = this.elem.nativeElement.children
		let cantidadElementos = this.elem.nativeElement.children.length;
		
		if(!elementos) {console.error('appUploadFileButton: No se encontraron elementos dentro de la directiva.'); return};
		if(!cantidadElementos) {console.error('appUploadFileButton: La cantidad de elementos es incorrecta.'); return};
		if(cantidadElementos===0) {console.error('appUploadFileButton: La cantidad de elementos es 0.'); return};

		for (let i = 0; i < cantidadElementos; i++ ) {
			if(elementos[i].type === 'file' &&  elementos[i].getAttribute(this.inputUpload) === 'inputUpload'){
				elementos[i].click();
			}
		}
 
		
	}
	@HostListener('change', ['$event'])
	public onChange(event: any){
		
		let elementos = this.elem.nativeElement.children
		let cantidadElementos = this.elem.nativeElement.children.length;

		if (!event.target) {console.error('appUploadFileButton: No se encontro el Target del elemento, no se ha detectado cambio.'); return};

		if ( event.target.getAttribute(this.inputUpload) === 'inputUpload' ) {
		
			console.log('encontre!!', event.target.files[0]);
		
			for (let i = 0; i < cantidadElementos; i++ ) {
				if( elementos[i].tagName === 'MAT-FORM-FIELD' && elementos[i].getAttribute(this.inputLabel) === 'inputLabel' ) {
					elementos[i].getElementsByClassName('mat-input-element')[0].value = event.target.files[0].name
				}
			}
		}
		
	}

	ngAfterViewInit(){
		
		let elementos = this.elem.nativeElement.children
		let cantidadElementos = this.elem.nativeElement.children.length;

		if (!elementos) {console.error('appUploadFileButton: No se encontraron elementos dentro de la directiva.'); return};
		if (!cantidadElementos) {console.error('appUploadFileButton: La cantidad de elementos es incorrecta.'); return};
		if (cantidadElementos===0) {console.error('appUploadFileButton: La cantidad de elementos es 0.'); return};

		for (let i = 0; i < cantidadElementos; i++ ) {
			if(elementos[i].type === 'file' &&  elementos[i].getAttribute(this.inputUpload) === 'inputUpload'){
				elementos[i].style.display = "none";
			}
		}

	}
}

import { FondoTipo } from './interfaces';


export class Fondo {


	_id?: string = '';
	idUsu?: string = '';
	fondo: string = '';
	fondoTipo: FondoTipo = null;

	constructor(obj: any = {}) {
		if (obj._id) { this._id = obj._id.toString() || ''; }
		this.idUsu = obj.idUsu || this.idUsu;
		this.fondo = obj.fondo || this.fondo;
		this.fondoTipo = obj.fondoTipo || this.fondoTipo;

		if (!this._id) delete this._id
	}
	static predeterminado(){
		return new Fondo({
			_id: '01',
			idUsu: '',
			fondo: 'Efectivo',
			fondoTipo: { idFon: 50, codigoForPag: 1, idMdl: 0, codigo: '01', fon: 'Efectivo', idMon: 'MXN', baja: 0 }
		})
	}
}

import { Component, OnInit, OnDestroy, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { DatosService } from '@core/datos.service';
import { GlobalService } from 'src/app/core/global.service';
import { Fcp, RecordatorioPago } from '@models/fcps.model';
import { ConexionService } from '@core/conexion.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { UiService } from 'src/app/core/ui.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { AuthService } from '../../../../auth/auth.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-contactos-edita',
  templateUrl: './contactos-edita.component.html',
  styleUrls: ['./contactos-edita.component.scss'],

})
export class ContactosEditaComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('f') formulario: NgForm;

  isModal: boolean = false;
  suscripciones: Subscription[] = [];
  fcp: Fcp;

  cargando: boolean;

  regiones: Object[] = [];
  paises: Object[] = [];
  regsFiss: Object[] = [];
  fons: Object[] = [];

  termino: number
  tipoRFC: string = 'normal'

  idMdl = 2;
  recordatorio: RecordatorioPago = { unidad: 'dias', cantidad: 5 }

  constructor(
    public g: GlobalService,
    public ds: DatosService,
    private con: ConexionService,
    private snackBar: MatSnackBar,
    public ui: UiService,
    public auth: AuthService,
    private route: ActivatedRoute,
    //CUANDO SE ABRE COMO DIALOGO
    public modal: MatDialogRef<ContactosEditaComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data.modal) {
      this.isModal = true;
      this.idMdl = data.idMdl;
      this.fcp = new Fcp(data.fcp)
      this.fcp.setValoresPreferenciaUsuario(this.auth.usu)

    }

  }

  rfcTypeChanged() {
    let rfc = ''
    switch (this.tipoRFC) {
      case 'publico_general':
        rfc = 'XAXX010101000'
        break;
      case 'extranjero':
        rfc = 'XEXX010101000'
        break;
      case 'normal':
      default:
        break;
    }
    this.fcp.rfc = rfc
  }

  elimiarRecordatorio(i) {
    this.fcp.recordatoriosPagos.splice(i, 1)
  }
  agregarRecordatorio() {
    if (this.fcp.recordatoriosPagos.length >= 2) {
      Swal.fire('', `Por el momento solo puede agregar 2 recordatorios por cliente.`)
      return;
    }
    if (this.recordatorio.cantidad <= 0) {
      Swal.fire('', `La cantidad de ${this.recordatorio.unidad} debe ser mayor a cero.`)
      return;
    }

    this.fcp.recordatoriosPagos.push({ ...this.recordatorio })
  }

  guardar() {

    this.cargando = true

    if (!this.g.revisaFormulario(this.formulario)) {
      this.g.SweetMsg('¡Ups!', 'Parece que hay algunos errores, complete los campos marcados en rojo.', 'warning');
      this.cargando = false
      return;
    }

    this.fcp.rfc = this.fcp.rfc ? this.fcp.rfc : 'XAXX010101000';
    this.fcp.idMdl = this.idMdl || 3;

    this.ui.loading(true);
    this.con.postP(this.fcp, 'fcps', true).then(res => {


      this.ui.loading(false);
      let nvoFcp = new Fcp(res.data)

      if (this.isModal) {
        this.cargando = false
        this.modal.close(nvoFcp);
        return;
      }
      this.snackBar.open('¡Cliente guardado!', 'OK', { duration: 2000 })
      this.fcp = new Fcp();
      this.fcp.setValoresPreferenciaUsuario(this.auth.usu)
    })
  }

  eliminar(fcp: Fcp) {

    fcp = this.fcp;

    this.g.SweetMsg('Eliminar', '¿Desea eliminar el registro?', 'question')
      .then((res) => {
        if (res.value) {

          fcp.baja = 1;
          this.con.post(fcp, `fcps`)
            .subscribe(res => {
              if (this.isModal) this.modal.close(true)
              this.snackBar.open('¡ Cliente eliminado correctamente !', 'DESHACER', { duration: 5000 }).onAction()
                .subscribe(() => {

                  fcp.baja = 0;
                  this.con.post(fcp, `fcps`)
                    .subscribe(res => {
                      this.snackBar.open('Accion deshecha', null, { duration: 3000 })
                    })
                })
            })
        }
      })
      .catch(err => {
        this.g.muestraError(err, 'Hubo un error, intente mas tarde');
      })
  }

  cancelar() {
    if (this.isModal) this.modal.close(true)
  }

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe((data: any) => {

      if (!this.isModal) this.ui.paginaActual = data.titulo || '';
      if (!this.isModal) this.idMdl = data.idMdl || 2;
    })

  }
  ngAfterViewInit() {
    
    switch (this.fcp.rfc) {
      case 'XAXX010101000':
        this.tipoRFC = 'publico_general'
        break;
      case 'XEXX010101000':
        this.tipoRFC = 'extranjero'
        break;
      default:
        this.tipoRFC = 'normal'
        break;
    }
  }

  ngOnDestroy() {
    this.suscripciones.forEach(suscripcion => {
      suscripcion.unsubscribe();
    });
  }


}

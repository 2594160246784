import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { RegistroComponent } from './auth/login/registro/registro.component';
import { ClaveComponent } from './auth/login/clave/clave.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { TycComponent } from './varios/tyc/tyc.component';
import { PrivacyComponent } from './varios/privacy/privacy.component';
import { ConfirmacionEmailComponent } from './auth/confirmacion-email/confirmacion-email.component';
import { AsistenteInicialComponent } from './componentes/asistente-inicial/asistente-inicial.component';
import { ActualizarPlanComponent } from './componentes/actualizar-plan/actualizar-plan.component';
import { Resolver } from './resolver';
import { PasarelaStripeComponentX } from './componentes/pasarela-stripe/pasarela-stripe.component';
import { InicioComponent } from './componentes/inicio/inicio.component';
import { PreloadService } from '@core/preload.service';


export const rutas = [
	//PRIVADAS
	{
		path: 'inicio',
		component: InicioComponent,
		loadChildren: () => import('./componentes/principal.module').then(m => m.PrincipalModule),
		canActivate: [AuthGuard],
		data: { preload: true }
	},
	//PUBLICAS
	{ path: '', component: LoginComponent },
	{ path: 'login', component: LoginComponent },
	{ path: 'asistente-inicial', canActivate: [AuthGuard], component: AsistenteInicialComponent, data: { titulo: 'Asistente' } },
	{ path: 'actualizar-plan', canActivate: [AuthGuard], component: ActualizarPlanComponent, resolve: { datosCargados: Resolver }, data: { titulo: 'Actualizar mi Plan' } },
	{ path: 'pasarela-stripe', canActivate: [], component: PasarelaStripeComponentX, resolve: { datosCargados: Resolver }, data: { titulo: 'Pago con Tarjeta' } },
	{ path: 'clave', component: ClaveComponent },
	{ path: 'registro', component: RegistroComponent },
	{ path: 'confirmacion-email', component: ConfirmacionEmailComponent },
	{ path: 'tyc', component: TycComponent },
	{ path: 'privacy', component: PrivacyComponent },
	{ path: 'r/:referido', component: RegistroComponent },
	//NO MATCH
	{ path: '**', redirectTo: 'login' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(rutas, { useHash: true, preloadingStrategy: PreloadService})
	],
	exports: [
		RouterModule
	]
})
export class AppRoutesModule { }

import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * recibe el evento, en el busca el nombre del input, lo selecciona y selecciona todo el texto de ese input
 * el input debe de ser de tipo text
 * Ejemplo: <input type="text" seleccionarTexto>ng serve 
 */
@Directive({
  selector: '[seleccionarTexto]'
})
export class SeleccionarTextoDirective {

	constructor(
			private el: ElementRef
	) {}

	@HostListener( 'focus', ['$event'] ) onFocus(e:FocusEvent) {
		try{

			let elemento = this.el.nativeElement;

			if(!elemento){
				console.error('seleccionarTexto no pudo encontrar el elemento.');
				return;
			}
			
			elemento.focus();
			elemento.setSelectionRange(0, elemento.value.length);
			
		} catch(e) {
			console.error('Parece seleccionarTexto fallo...');
			return;
		}
	}
}

import { Injectable } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { UiService } from '../ui.service';

import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import {  cambiarUsuario } from '@root/auth/auth.actions';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ErrorService } from '../errors.service';
import { Fcv } from '@models/fcvs.model';
import { Fpr } from '@models/fprs.model';
import { femexType } from '@models/emps.model';
import { cambiarEmpresa } from '@root/models/emps.actions';
import moment from 'moment';import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { CartaPorteDataComponent } from '@root/componentes/carta-porte-data/carta-porte-data.component';
import { first } from 'rxjs/operators';
import { TrackService } from '../track.service';
import { TimbraCfdiComponent } from '@root/componentes/timbra-cfdi/timbra-cfdi.component';


@Injectable({
	providedIn: 'root'
})
export class MxService {
	tipoQR = 'canvas';
	valorQR = '';
	constructor(
		private auth: AuthService,
		private trk: TrackService,
		private ui: UiService,
		private store: Store<AppState>,
		private http: HttpClient,
		private errorS: ErrorService,
		private dialog: MatDialog,
		private router: Router,

	) { }

	verificaFemex(idMdl = 70): boolean {

		if (idMdl >= 80) return false; //SI ES UNA COMPRA

		if (idMdl === 70.1) return false; //SI ES UN TICKET NO TIMBRA

		let femex = this.auth.emp.femex;
		switch (femex) {
			case femexType.activa: return true;
			case femexType.prueba: return true;
			case femexType.inactiva:

				let tiempoNoMolestar: any = window.localStorage.getItem('femexNoMolestar')
				if (tiempoNoMolestar) {

					tiempoNoMolestar = Number(tiempoNoMolestar);
					let tiempoActual = new Date().getTime();
					if (tiempoActual < tiempoNoMolestar) return false;

				}

				Swal.fire(<any>{
					title: `Facturación Electrónica`,
					html: `
        <p>
          Parece que no esta habilitada la facturación electrónica. <br>¿La habilitamos?
        </p>
        <button id="monCot-boton-aceptar" class="btn btn-primary mt-4 btn-lg mb-2"> Si, Habilitala </button><br>
        <button id="monCot-boton-cancelar" class="btn btn-secondary mt-4 btn-lg"> No necesito timbrar </button>
        <button id="monCot-boton-despues" class="btn btn-danger btn-lg"> Después </button>
        `,
					type: 'question',
					showConfirmButton: false,
					didOpen: () => {
						let monCotBotonAceptar = document.querySelector('#monCot-boton-aceptar')
						let monCotBotonDespues = document.querySelector('#monCot-boton-despues')
						let monCotBotonCancelar = document.querySelector('#monCot-boton-cancelar')

						monCotBotonAceptar.addEventListener('click', () => {

							this.ui.loading(true);
							this.postP({}, 'emps/habilitar-femex', true).then((res) => {
								this.ui.loading(false);
								if (res.data === '1') {
									Swal.fire('¡Bien!', 'La facturación electrónica ha sido habilitada, si necesitas, podrás timbrar los comprobantes que hayas emitido hasta el momento.', 'success');
									this.auth.emp.femex = femexType.activa;
									this.store.dispatch(cambiarEmpresa({emp:this.auth.emp}))
									this.auth.usu.emps[0] = this.auth.emp;
									this.store.dispatch(cambiarUsuario({obj: this.auth.usu}))
								} else {
									Swal.fire('Algo salió mal', 'Hubo algún problema realizando la solicitud, puede enviarnos un correo a contacto@oadmin.app.', 'warning');
								}

							})
							Swal.close()
						})
						monCotBotonDespues.addEventListener('click', () => {
							let tiempo = moment().add(2, 'hour').toDate().getTime().toString();
							window.localStorage.setItem('femexNoMolestar', tiempo)
							Swal.close()
						})
						monCotBotonCancelar.addEventListener('click', () => {

							this.auth.emp.femex = femexType.rechazada;
							this.store.dispatch(cambiarEmpresa({emp: this.auth.emp}))
							this.auth.usu.emps[0].femex = femexType.rechazada;
							this.store.dispatch(cambiarUsuario({obj: this.auth.usu}))

							this.ui.loading(true);
							this.postP(this.auth.emp, 'emps', true).then((res) => {
								this.ui.loading(false);

								Swal.fire('Facturación Electrónica', 'No se te volverá a recordar, de igual forma siempre podrás habilitarla desde "Configuración" > "Mi Empresa" en la sección de "Datos Fiscales".', 'warning');

							})

							Swal.close()
						})


					}
				})
				return false;

			case femexType.pendiente:
				Swal.fire('Facturación Electrónica', 'Esta cuenta se encuentra en proceso de verificación para que puedas timbrar tus comprobantes, considera que es un proceso manual, contáctanos por cualquier duda a contacto@oadmin.app.', 'success');
				return false;
			case femexType.deshabilitada:
			case femexType.rechazada:
				return false;
			default: return false;
		}
	}

	postP(datos: Object, servicio: string, muestraError = false): Promise<any> {

		return new Promise((ok, er) => {
			let authToken = window.localStorage.getItem('authToken') || 'xxxx';
			const URL = environment.apiURL + servicio + '/';

			let CABECERAS = new HttpHeaders({ 'Content-Type': 'application/json', 'authToken': authToken });

			if (servicio !== 'login') {
				console.log('ver si revisamos la sesion en este punto');
			}
			this.http.post(URL, datos, { headers: CABECERAS })
				.subscribe(
					(data: any) => {
						if (!data.ok) {
							this.errorS.manejarError(data, muestraError);
							er(data.error)
							return;
						}
						ok(data)
					},
					err => {
						this.ui.loading(false);
						this.errorS.manejarError(err, muestraError);
						er(err);
						return;
					}
				)
		})
	}
	/**
	   *
	   * Genera el codigo QR en base a un objecto Fcv,
	   *
	   * @param valorId valor del id, por ejemplo 123 o D098
	   * @param nombreId nombre del ID, por ejemplo 'id' o 'codigo'
	   * @param arreglo arreglo donde etan todos los elementos
	   * @param objGral Objeto padre del elemento a modificar P.Ej. : this.fcv
	   * @param propGral String del elemento a modificar P .Ej. : 'metPag'
	   */
	generaQR(fcv: Fcv | Fpr) {
		return new Promise((ok, rej) => {

			this.generaStrCBB(
				fcv.uuid,
				fcv.emp.rfc,
				fcv.fcp.rfc,
				fcv.total,
				fcv.nroSerCsdEmi
			).then((strQR) => {
				this.valorQR = strQR as string;
				let veces = 0;
				var checkExist = setInterval(() => {
					let canvas: HTMLCanvasElement = document.querySelector("canvas");

					veces++;
					if (canvas) {
						let imageData = canvas.toDataURL("image/jpeg").toString();
						ok(imageData);
						clearInterval(checkExist);
					}
					if (veces === 200) {
						clearInterval(checkExist);
						//TODO: reportar error a DB;
						console.log('()Error: despues de 2 segundos no se pudo obtener el canvas.');
						rej('Error: despues de 2 segundos no se pudo obtener el canvas.')
					}
				}, 10);

			})
		})
	}
	generaStrCBB(
		uuid: string = '1PRUEBAS-1234-1234-1234-PRUEBAS12345',
		rfcEmi: string = 'FGH000000S55',
		rfcRec: string = 'ASD000000S55',
		total: number = 1.00,
		selloDigitalEmisor: string = '0000000000'
	) {
		return new Promise((res, rej) => {

			let strQR = 'https://verificacfdi.facturaelectronica.sat.gob.mx/default.aspx';
			strQR += `&id=${uuid}`
			strQR += `&re=${rfcEmi}`
			strQR += `&rr=${rfcRec}`
			strQR += `&rr=${total}`
			strQR += `&rr=${(selloDigitalEmisor || '000000000000').slice(0, 7)}`
			res(strQR);
		})
	}

	openCartaPorteData(fcv: Fcv): Promise<Fcv | null> {

		let ancho = '70%';
		let altura = '95%';
		history.pushState({}, 'oAdmin', '/#' + this.router.url);

		if (screen.width <= 992) {
			ancho = '100%';
			altura = '100%';
		}
		const dialogo = this.dialog.open(CartaPorteDataComponent, {
			width: ancho,
			maxWidth: ancho,
			height: altura,
			closeOnNavigation: true,
			disableClose: true,
			panelClass: 'dialogo-popup',
			data: { fcv }
		})

		// Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
		return new Promise((res, rej) => {
			//A esa referencia nos podemos suscribir para esperar respuesta del usuario.
			dialogo.afterClosed()
				.subscribe(res, rej);
		});
	}
	openTimbrarCfdiData(fcv: Fcv): Promise<Fcv | null> {

		let ancho = '70%';
		let altura = '95%';
		history.pushState({}, 'oAdmin', '/#' + this.router.url);

		if (screen.width <= 992) {
			ancho = '100%';
			altura = '100%';
		}
		const dialogo = this.dialog.open(TimbraCfdiComponent, {
			width: ancho,
			maxWidth: ancho,
			height: altura,
			closeOnNavigation: true,
			disableClose: true,
			panelClass: 'dialogo-popup',
			data: { fcv }
		})

		// Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
		return new Promise((res, rej) => {
			//A esa referencia nos podemos suscribir para esperar respuesta del usuario.
			dialogo.afterClosed()
				.subscribe(res, rej);
		});
	}

	getFcvFromStore = (): Promise<Fcv> => {
		return new Promise((res, rej) => {
			try {

				this.store.select('fcv')
					.pipe(first())
					.subscribe((fcv: Fcv) => {
						res(fcv)
					})

			} catch (error) {
				rej(error)
			}
		})

	}

	async save(): Promise<void> {

		// We Should be able to save from this service...


		// const fcv = await this.getFcvFromStore()

		// if (this.auth.suc._id && this.auth.emp.multisucursal) fcv.sector = this.auth.suc.sector
		// fcv.idSuc = this.auth.suc && !fcv._id ? this.auth.suc._id : '1';




		// fcv.emp = this.auth.emp;

		// fcv.idEst = fcv.idMdl >= 80 ? 11 : fcv.idEst;

		// // fcv.codigoCategoria = this.categoria.codigo;

		// if (fcv.idTik !== '') {

		// }
		// this.trk.trackAccion('accion', 'Guarda ' + this.g.mdl(fcv.idMdl).nombre);

		// this.ui.loading(true);
		// this.postP(fcv, 'fcvs', true).then(res => {
		// 	this.ui.loading(false);

		// 	if (!res.ok) {
		// 		this.snackBar.open('ERROR! No se pudo guardar la factura.', 'OK')
		// 		fcv = new Fcv({ idMdl: this.idMdl, emp: this.emp });
		// 		this.editaContacto = true;
		// 		return;
		// 	}

		// 	if (timbrar) {
		// 		fcv._id = res.data;
		// 		this.guardarFcvStore();
		// 		this.timbrar(false);
		// 		if (this.auth.emp.preferencias.imprimeTicketAlFacturar) this.imprimir();
		// 		return;
		// 	}

		// 	this.snackBar.open('Comprobante Guardado', 'OK', 3000)

		// 	this.g.scrollTop();

		// 	if (this.mx.verificaFemex(this.idMdl)) {
		// 		// pregunta facturacion electroncia
		// 		this.g.SweetMsg(
		// 			'Facturación Electrónica',
		// 			'¿Desea timbrar el comprobante?.',
		// 			'question',
		// 			{ textoOk: 'Si', textoCancela: 'No' }
		// 		)
		// 			.then(ok => {
		// 				if (this.auth.emp.preferencias.imprimeTicketAlFacturar) this.imprimir();
		// 				if (ok.value) {
		// 					fcv._id = res.data
		// 					fcv.numero = res.message
		// 					this.guardarFcvStore()
		// 					this.timbrar(false)
		// 				} else {
		// 					fcv = new Fcv()
		// 					fcv.emp = this.emp
		// 					this.editaContacto = true
		// 					if (this.ui.isMobile) this.router.navigate(['/inicio/' + this.g.mdl(this.idMdl).ruta + '-busqueda']);
		// 					this.nuevo();
		// 					if (this.tabla) this.tabla.renderRows();
		// 				}
		// 			})
		// 	} else {
		// 		if (this.auth.emp.preferencias.imprimeTicketAlFacturar) this.imprimir();
		// 		if (this.ui.isMobile) this.router.navigate(['/inicio/' + this.g.mdl(this.idMdl).ruta + '-busqueda']);
		// 		this.fcv = new Fcv({ idMdl: this.idMdl, emp: this.emp });
		// 		this.editaContacto = true;
		// 		this.nuevo();
		// 		if (this.tabla) this.tabla.renderRows();
		// 	}

	}
}

import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';import { PdfFactura } from 'src/app/core/pdf-factura.service';
import { PdfRecibo } from '@core/pdf-recibo.service';
import { Subscription } from 'rxjs';
import { UiService } from '@core/ui.service';
import { PdfPedido } from '@core/pdf-pedido.service';

@Component({
	selector: 'app-muestra-pdf',
	templateUrl: './muestra-pdf.component.html',
	styleUrls: ['./muestra-pdf.component.css']
})
export class MuestraPdfComponent implements OnInit, OnDestroy {
	suscripciones: Subscription[] = []
	@ViewChild('miVisor', { static: true }) miVisor: ElementRef;
	formato: string = '';
	miPdf: any = '';
	demo: boolean = false;
	blobQR: string = '';

	constructor(
		private _PdfFactura: PdfFactura,
		private _PdfPedido: PdfPedido,
		private _PdfRecibo: PdfRecibo,
		public dialogo: MatDialogRef<MuestraPdfComponent>,
		private ui: UiService,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.formato = data.formato ? data.formato : this.formato;
		this.demo = data.demo ? data.demo : this.demo;
		this.blobQR = data.blobQR ? data.blobQR : this.blobQR;
	}

	cerrar() {
		this.dialogo.close(true)
	}

	ngOnInit() {

		this.formato = this.formato.replace('1', 'factura').replace('2', 'recibo')

		switch (this.formato) {
			case 'factura':
				this._PdfFactura.generarPDF(this.miVisor.nativeElement, this.demo, this.blobQR)
					.then(pdf => {
						this.miVisor.nativeElement.src = pdf;
						if (this.ui.isMobile) {
							window.open(pdf, '_blank');
							this.dialogo.close(true);
						}
					});

				break;
			case 'recibo':
				this._PdfRecibo.generarPDF(this.miVisor.nativeElement, this.demo, this.blobQR)
					.then(pdf => {
						this.miVisor.nativeElement.src = pdf;
						if (this.ui.isMobile) {
							window.open(pdf, '_blank');
							this.dialogo.close(true);
						}
					})

				break;
			case 'pedido':
				this._PdfPedido.generarPDF(this.miVisor.nativeElement, this.demo, this.blobQR)
					.then(pdf => {
						this.miVisor.nativeElement.src = pdf;
						if (this.ui.isMobile) {
							window.open(pdf, '_blank');
							this.dialogo.close(true);
						}
					});

				break;
			default:
				console.log('No se encontro formato de PDF... ');
				break;
		}

	}

	ngOnDestroy(): void {
		this.miVisor.nativeElement.src = ''
		this.suscripciones.forEach(s => {
			s.unsubscribe();
		});
	}
}
@Component({
    selector: 'app-muestra-pdf',
    templateUrl: './muestra-pdf.component.html',
    styleUrls: ['./muestra-pdf.component.css']
})
export class MuestraPdfComponentX extends MuestraPdfComponent { }

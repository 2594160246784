import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { SidebarService } from '../../layout/sidebar-pro/sidebar.service';
import { UiService } from '@core/ui.service';
import { DatosService } from '@core/datos.service';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ConexionService } from '@core/conexion.service';
import { AuthService } from '@auth/auth.service';
import {  cambiarUsuario } from '@root/auth/auth.actions';
import { cerrarSesion } from '../../auth/auth.actions';
import { Subscription } from 'rxjs';

// declare const jQuery: any;
// declare const $: any;
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit, OnDestroy {
  suscripciones: Subscription[] = [];
  title = 'angular-pro-sidebar';
  cantidadVerificar = 0
  mensaje = '';
  sideBarState = false;
  constructor(
    public sb: SidebarService,
    public ds: DatosService,
    public ui: UiService,
    private store: Store<AppState>,
    private con: ConexionService,
    private auth: AuthService,
  ) {

  }

  swRight() {
    if (this.ui.isMobile) this.sb.setSidebarState(false);
  }
  swLeft() {
    if (this.ui.isMobile) this.sb.setSidebarState(true);
  }

  toggleSidebar() {
    this.sb.setSidebarState(!this.sb.getSidebarState());
  }
  toggleBackgroundImage() {
    this.sb.hasBackgroundImage = !this.sb.hasBackgroundImage;
  }
  getSideBarState() {
    return this.sb.getSidebarState();
  }


  cargarDatos() {
    let ini = new Date().getTime();

    this.ds.buscarDatos().then(() => {
      let fin = (new Date().getTime()) - ini;
      console.log('CARGA DE DATA: ', fin);
    })
  }
  revisarUsuarioVerificado(primeraVez = 0) {

    if (this.cantidadVerificar > 3) this.auth.cerrarSesion('Ha excedido el limite de intentos.');

    this.cantidadVerificar++

    Swal.fire(<any>{
      title: `¡ Hola ${this.auth.usu.nom + ' ' || ''}!`,
      html: this.mensaje || 'Parece que aún no has verificado tu email, te hemos enviado un mail con un enlace para para que lo verifiques.',
      type: 'info',
      showCancelButton: true,
      showCloseButton: true,
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya lo verifiqué',
      cancelButtonText: 'Envíamelo de nuevo'
    }).then((res: SweetAlertResult) => {

      if (String(res.dismiss) === 'cancel') {

        this.ui.loading(true);
        this.con.getP(`usus-mail-confirmacion/${this.auth.usu._id}`).then(() => {
          this.mensaje = 'Lo hemos vuelto a enviar así confirmas tu correo, recuerda revisar el correo no deseado y el spam, si el problema sigue, escríbenos a contacto@oadmin.app'

          this.ui.loading(false);
          this.revisarUsuarioVerificado();

        }).catch(() => {

          this.ui.loading(false);
          this.auth.cerrarSesion('Error al enviar mail, avise a contacto@oadmin.app');

          return;

        })

      }
      if (String(res.dismiss) === 'close') {
        this.store.dispatch(cerrarSesion())
      }

      if (res.value === true) {

        this.ui.loading(true);


        this.con.getP(`usus/${this.auth.usu._id}`).then((res: any) => {
          this.ui.loading(false);
          if (res.data.verificado) {
            this.auth.usu.verificado = true;
            this.store.dispatch(cambiarUsuario({obj: this.auth.usu}));
            this.cargarDatos();
            return;
          }
          this.revisarUsuarioVerificado();

        }).catch(() => {
          this.ui.loading(false);
          this.auth.cerrarSesion('Error al enviar mail, avise a contacto@oadmin.app');
          return;
        })

      }



    })
  }

  ngOnInit() {

    // this.ui.loadingLogin(false)

    this.suscripciones.push(
      this.store.select('ui').subscribe(ui=>{
        this.sideBarState = ui.isSideBar.open;
      })
    )

    if (!this.auth.usu.verificado && this.auth.usu.cantidadSesion > 5) {
      this.revisarUsuarioVerificado()
    } else {
      this.cargarDatos();
    }

    if (window['fcWidget']) {

      window['fcWidget'].setExternalId(this.auth.usu._id);
      window['fcWidget'].user.setFirstName(this.auth.usu.nom);
      window['fcWidget'].user.setLastName(this.auth.usu.ape);
      window['fcWidget'].user.setEmail(this.auth.usu.usu);
      console.log('usuario identificado');
    }
  }

  ngOnDestroy() {
    this.suscripciones.forEach(s=>s.unsubscribe());
  }




}

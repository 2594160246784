import { createAction, props } from '@ngrx/store';

export const cambiarPipeline = createAction(
  '[CRM] Cambiar Pipeline',
  props<{ pipeline: object }>()
);

export const cambiarPropiedadPipeline = createAction(
  '[CRM] Cambiar Propiedad Pipeline',
  props<{ prop: object }>()
);

export type accionesValidas = ReturnType<typeof cambiarPipeline> | ReturnType<typeof cambiarPropiedadPipeline>;

import { Pais, Region, RegFis } from './interfaces';
import { environment } from 'src/environments/environment';
export class WebHooksShopify {
	'orders/create': string
	constructor(obj: any = {}) {
		this['orders/create'] = obj['orders/create'] || this['orders/create']
	}
}

export class IntegracionData {

	tienda: string = '';
	clientId: string = '';
	clientSecret: string = '';
	webhooks: WebHooksShopify = new WebHooksShopify;

	constructor(obj: any = {}) {
		this.tienda = obj.tienda || this.tienda;
		this.webhooks = obj.webhooks || this.webhooks;
		this.clientId = obj.clientId || this.clientId;
		this.clientSecret = obj.clientSecret || this.clientSecret;
	}
}

export class Integraciones {
	shopify: IntegracionData = new IntegracionData();
	mercadoLibre: IntegracionData = new IntegracionData();
	woocommerce: IntegracionData = new IntegracionData();
	amazon: IntegracionData = new IntegracionData();

	constructor(obj: any = {}) {
		this.shopify = new IntegracionData(obj.shopify) || this.shopify
		this.mercadoLibre = new IntegracionData(obj.mercadoLibre) || this.mercadoLibre
		this.woocommerce = new IntegracionData(obj.woocommerce) || this.woocommerce
		this.amazon = new IntegracionData(obj.amazon) || this.amazon
	}
}
export enum femexType {
	inactiva,     // 0
	activa,		    // 1
	rechazada,    // 2
	pendiente,    // 3
	prueba,		    // 4
	deshabilitada	// 5
}
export interface RedesSociales {
	whatsapp: string,
	instagram: string,
	facebook: string,
	twitter: string
}
export interface EcommerceConfig {
	nombre: string,
	fondo: number,
	colorHeader: string
}
export interface CuentaBanco {
	banco: string,
	titular: string,
	cuenta: string,
	clabe: string
}

export class Emp {

	public _id: string = '';
	public idUsu: string = ''
	public idStore: string = ''
	public tokenTienda: string = ''
	public emp: string = ''
	public rso: string = ''
	public rfc: string = ''
	public regFis: RegFis = environment.pdt.regFis
	public timbres: number = 0
	public femex: femexType = femexType.inactiva
	public certificado: string = ''
	public serieCertificado: string = ''
	public llave: string = ''
	public keyPass: string = ''
	public logo: string = ''
	public logoURL: string = ''
	public tel1: string = ''
	public tel2: string = ''
	public cuentasBancos: CuentaBanco[] = []
	public obsMail: string = ''
	public multisucursal: boolean = false
	public email: string = ''
	public formatoPDF: number = 1
	public numeroPago: number = 0
	public numeroCotizacion: number = 0
	public numeroPedido: number = 0
	public numeroFactura: number = 0
	public numeroTicket: number = 0
	public numeroCredito: number = 0
	public numeroDebito: number = 0
	public numeroAjusteInventario: number = 0
	public numeroPaseInventario: number = 0
	public numeroOrdenProduccion: number = 0
	public numeroPaseFondos: number = 0
	public numero: number = 0
	public fisDom: string = ''
	public fisDomNro: string = ''
	public fisDomInt: string = ''
	public fisDomCodPos: string = ''
	public fisDomCol: string = ''
	public fisDomCiu: string = ''
	public fisDomMun: string = ''
	public tokenPayPal: string = ''
	public tokenMP: string = ''
	public redesSociales: RedesSociales = {
		whatsapp: '',
		instagram: '',
		facebook: '',
		twitter: ''
	}
	integraciones: Integraciones = new Integraciones()
	public ecommerce: EcommerceConfig = {
		nombre: '',
		fondo: 1,
		colorHeader: '#004dda'
	}
	public preferencias: {
		imprimeTicketAlFacturar?: boolean,
		actualizaCostosAlComprar?: boolean
	} = {
			imprimeTicketAlFacturar: false,
			actualizaCostosAlComprar: false
		}
	public fisDomRegion: Region = environment.pdt.region
	public fisDomPais: Pais = environment.pdt.pais
	public baja: string = '0'

	constructor(obj: any = {}) {
		this._id = obj._id ? obj._id : this._id;
		this.idUsu = obj.idUsu ? obj.idUsu : this.idUsu;
		this.idStore = obj.idStore ? obj.idStore : this.idStore;
		this.tokenTienda = obj.tokenTienda ? obj.tokenTienda : this.tokenTienda;
		this.emp = obj.emp ? obj.emp : this.emp;
		this.rso = obj.rso ? obj.rso : this.rso;
		this.rfc = obj.rfc ? obj.rfc : this.rfc;
		this.regFis = obj.regFis ? obj.regFis : this.regFis;
		this.timbres = obj.timbres ? obj.timbres : this.timbres;
		this.femex = obj.femex ? obj.femex : this.femex;
		this.certificado = obj.certificado ? obj.certificado : this.certificado;
		this.serieCertificado = obj.serieCertificado ? obj.serieCertificado : this.serieCertificado;
		this.llave = obj.llave ? obj.llave : this.llave;
		this.keyPass = obj.keyPass ? obj.keyPass : this.keyPass;
		this.logo = obj.logo ? obj.logo : this.logo;
		this.logoURL = obj.logoURL ? obj.logoURL : this.logoURL;
		this.tel1 = obj.tel1 ? obj.tel1 : this.tel1;
		this.tel2 = obj.tel2 ? obj.tel2 : this.tel2;
		this.cuentasBancos = obj.cuentasBancos ? obj.cuentasBancos : this.cuentasBancos;
		this.obsMail = obj.obsMail ? obj.obsMail : this.obsMail;
		this.multisucursal = obj.multisucursal || this.multisucursal;
		this.email = obj.email ? obj.email : this.email;
		this.formatoPDF = obj.formatoPDF ? obj.formatoPDF : this.formatoPDF;
		this.numeroPago = obj.numeroPago ? obj.numeroPago : this.numeroPago;
		this.numeroCotizacion = obj.numeroCotizacion ? obj.numeroCotizacion : this.numeroCotizacion;
		this.numeroPedido = obj.numeroPedido ? obj.numeroPedido : this.numeroPedido;
		this.numeroFactura = obj.numeroFactura ? obj.numeroFactura : this.numeroFactura;
		this.numeroTicket = obj.numeroTicket ? obj.numeroTicket : this.numeroTicket;
		this.numeroCredito = obj.numeroCredito ? obj.numeroCredito : this.numeroCredito;
		this.numeroDebito = obj.numeroDebito ? obj.numeroDebito : this.numeroDebito;
		this.numeroAjusteInventario = obj.numeroAjusteInventario ? obj.numeroAjusteInventario : this.numeroAjusteInventario;
		this.numeroPaseInventario = obj.numeroPaseInventario ? obj.numeroPaseInventario : this.numeroPaseInventario;
		this.numeroOrdenProduccion = obj.numeroOrdenProduccion ? obj.numeroOrdenProduccion : this.numeroOrdenProduccion;
		this.numeroPaseFondos = obj.numeroPaseFondos ? obj.numeroPaseFondos : this.numeroPaseFondos;
		this.fisDom = obj.fisDom ? obj.fisDom : this.fisDom;
		this.fisDomNro = obj.fisDomNro ? obj.fisDomNro : this.fisDomNro;
		this.fisDomInt = obj.fisDomInt ? obj.fisDomInt : this.fisDomInt;
		this.fisDomCodPos = obj.fisDomCodPos ? obj.fisDomCodPos : this.fisDomCodPos;
		this.fisDomCol = obj.fisDomCol ? obj.fisDomCol : this.fisDomCol;
		this.fisDomCiu = obj.fisDomCiu ? obj.fisDomCiu : this.fisDomCiu;
		this.fisDomMun = obj.fisDomMun ? obj.fisDomMun : this.fisDomMun;
		this.fisDomRegion = obj.fisDomRegion ? obj.fisDomRegion : this.fisDomRegion;
		this.fisDomPais = obj.fisDomPais ? obj.fisDomPais : this.fisDomPais;
		this.tokenPayPal = obj.tokenPayPal ? obj.tokenPayPal : this.tokenPayPal;
		this.tokenMP = obj.tokenMP ? obj.tokenMP : this.tokenMP;
		this.redesSociales = obj.redesSociales ? obj.redesSociales : this.redesSociales;
		this.integraciones = obj.integraciones ? new Integraciones(obj.integraciones) : this.integraciones;
		this.ecommerce = obj.ecommerce ? obj.ecommerce : this.ecommerce;
		this.preferencias = obj.preferencias ? obj.preferencias : this.preferencias;
		this.baja = obj.baja ? obj.baja : this.baja;
	}

	public getDomicilioFiscal() {

		let domicilio = this.fisDom + ' No. ' + this.fisDomNro;
		// if (this.fisDomInt) {
		// 	domicilio += ' Int. ' + this.fisDomInt;
		// }
		domicilio = 'Ver que onda con el metodo';
		return domicilio;
	}
	public getLocalidadFiscal() {

		let localidad = this.fisDomCiu + ', ' + this.fisDomMun;
		// let pais = this.fisDomPais.name.charAt(0).toUpperCase() + this.fisDomPais.name.toLowerCase().slice(1)
		// localidad += ', ' + pais
		localidad = 'Ver que onda con el metodo';
		return localidad;

	}
}

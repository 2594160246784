// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-card.movil {
  margin: 0 !important;
  padding: 4px !important;
}

.contenedor {
  height: auto !important;
  min-height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.contenedor.bg-1 {
  background: url('login_fondo.39c709cc9dca2fb9.jpg') no-repeat center center fixed !important;
  background-size: cover !important;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/login/clave/clave.component.scss"],"names":[],"mappings":"AAAA;EACC,oBAAA;EACA,uBAAA;AACD;;AACA;EACC,uBAAA;EACA,2BAAA;EACA,oBAAA;EACA,qBAAA;AAED;;AACA;EACI,4FAAA;EAIH,iCAAA;AAED","sourcesContent":["mat-card.movil{\n\tmargin:0!important;\n\tpadding: 4px!important;\n}\n.contenedor {\n\theight: auto  !important; \n\tmin-height: 100% !important;\n\tmargin: 0 !important;\n\tpadding: 0  !important;\n}\n\n.contenedor.bg-1 {\n    background: url(\"../../../../assets/login/images/auth/login_fondo.jpg\") no-repeat center center fixed !important;\n\t-webkit-background-size: cover!important;\n\t-moz-background-size: cover!important;\n\t-o-background-size: cover!important;\n\tbackground-size: cover!important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

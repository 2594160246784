import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DatosService } from '@core/datos.service';
import { GlobalService } from '@core/global.service';
import { UiService } from '@core/ui.service';

@Component({
  selector: 'app-hide-show',
  templateUrl: './hide-show.component.html',
  styleUrls: ['./hide-show.component.scss']
})
export class HideShowComponent implements OnInit {

  @Output() buscar: EventEmitter<any>;
  @Output() genExcel: EventEmitter<any>;

  @Output() filtrosAbiertoChange: EventEmitter<string>;
  @Input() filtrosAbierto = false;
  @Input() activaExcel = false;


  constructor(
    public ui: UiService,
		public g: GlobalService,
		public ds: DatosService
  ) {
    this.filtrosAbiertoChange = new EventEmitter();
    this.buscar = new EventEmitter();
    this.genExcel = new EventEmitter();
  }

  toggleFiltros() {

    this.filtrosAbierto = this.filtrosAbierto ? false : true;

  }
  ok(){
    this.buscar.emit();
    this.toggleFiltros();
  }

  emitGenExcel(){
    this.genExcel.emit(true);
  }



  ngOnInit() { }

}

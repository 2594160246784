import { Injectable } from '@angular/core';
import { ConexionService } from './conexion.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { Emp } from '@models/emps.model';
import { Fcv, FcvDet } from '@models/fcvs.model';
import { NumerosFormatoPipe } from '@pipes/numerosFormato.pipe';
import { CerosPipe } from '@pipes/ceros.pipe';
import { getMonedaByMonId, numeroALetras } from 'src/assets/js/numerosALetras'
import { take } from 'rxjs/operators';
import { Fcp } from '@models/fcps.model';
import moment from 'moment';import { GlobalService } from './global.service';
import * as jsPDF from 'jspdf'

@Injectable({
	providedIn: 'root'
})
export class PdfPedido {
	numerosFormato = new NumerosFormatoPipe
	ceros = new CerosPipe

	imagenesCargadas: number = 0
	imagenesPendientes: number = 0
	emp: Emp;
	fcv: Fcv = new Fcv();
	tamLinea = 5;
	linea = 5;

	enters = 0

	Y = 0;
	X = 0;
	constructor(
		private con: ConexionService,
		private store: Store<AppState>,
		private g: GlobalService,
	) { }

	/**
	 * La funcion genera un PDF de Venta  con los datos del Venta desde el store, o bien usando datos hardcodeados de prueba.
	 *
	 * @param contenedor : Elemento canvas tipo elementRF (usando notacion Viewchild # ) >>> <iframe fxFlex="100" type="application/pdf" #miVisor class="miVisor"></iframe>
	 * @param demo : Si se va a usar (default true) o no (false) los datos de prueba y no un comprobante real del store
	 * @param blobQR : QR en formato blob string
	 * @param guardar : Si se va a devolver el bloburi (default) o se va a guardar
	 */
	generarPDF(contenedor?: any, demo: boolean = true, blobQR: string = '', salida: 'GUARDAR' | 'VISTA' | 'BLOB' = 'VISTA'): Promise<any> {


		return new Promise((ok, er) => {



			new Promise((res, rej) => {

				this.store
					.pipe(take(1))
					.subscribe(store => {

						if (!demo) { this.fcv = store.fcv; }
						this.emp = this.fcv?.idEst !== 10 ? this.fcv?.emp : store.emp;
						//PRE-CARGA DE IMAGENES
						let imagenes = {}
						let cantidadImagenes = 3
						this.con.sGetImg(this.emp.logo, 'logos')
							.then(logoUrl => {
								// logo
								let codigoQR = this.imagenObjetoHTML(blobQR);
								codigoQR.onload = () => {
									imagenes = { ...imagenes, codigoQR };
									if (Object.keys(imagenes).length === cantidadImagenes) { res(imagenes); }
								}
								// logo
								let logo = this.imagenObjetoHTML(logoUrl);
								logo.onload = () => {
									imagenes = { ...imagenes, logo };
									if (Object.keys(imagenes).length === cantidadImagenes) { res(imagenes); }
								}
								// formato
								let formatoUrl = 'assets/formatosPDF/f1-gris-rojo.jpg';
								formatoUrl = `assets/formatosPDF/${Number(this.emp.formatoPDF) || 1}f.jpg`;
								let formato = this.imagenObjetoHTML(formatoUrl);
								formato.onload = () => {
									imagenes = { ...imagenes, formato };
									if (Object.keys(imagenes).length === cantidadImagenes) { res(imagenes); }
								}
							})
					});

			})
				.then((imagenes: any) => {
					// SEDECLARAN IMAGENES QUE SE VAN A USAR
					let formato = imagenes.formato;
					let logo = imagenes.logo;
					let codigoQR = imagenes.codigoQR;
					// CONFIGURACION DE PAGINA ------------------------------------------------------.
					let gris = [65, 65, 67];
					let blanco = [255, 255, 255];
					let blanco_gris = [235, 235, 235];
					let separador = [240, 240, 240];
					let configuracioPagina = {
						orientation: 'portrait',
						unit: 'mm',
						format: 'a4',
						compressPdf: true,
					}
					let doc = new jsPDF(configuracioPagina)
					let hojaAncho = 210;
					let hojaAlto = 297;
					// ------------------------------------------------------------------------------.
					// FORMATO ----------------------------------------------------------------------.

					let ratio = (<any>formato).width / (<any>formato).height
					let ancho = 210;
					let alto = 89;
					doc.addImage(formato, '', 0, 0, ancho, alto)
					// ------------------------------------------------------------------------------.
					// LOGO -------------------------------------------------------------------------.
					// let logo = this.imagenObjetoHTML(<string>imgUrl)
					ancho = (<any>logo).width;
					alto = (<any>logo).height;
					ratio = (<any>logo).width / (<any>logo).height

					if (alto >= ancho) {
						alto = 37;
						ancho = alto * ratio;
					} else {
						ancho = 39;
						alto = ancho / ratio;
					}
					doc.addImage(logo, '', 2, 4, ancho, alto);

					//NOTA: Las imagenes tardan en cargar, entonces al final 	se espera que carguen todas
					// ------------------------------------------------------------------------------.

					// TIPO DE COMPROBANTE ----------------------------------------------------------.
					this.X = 204
					this.Y = 9
					doc.setFontSize(14); this.tamLinea = 5;
					doc.setTextColor(...blanco_gris);
					doc.setFont('Helvetica', 'bold'); doc.text(this.g.mdl(this.fcv.idMdl).nombre.toUpperCase() || 'PEDIDO', this.X, this.Y, { align: 'right' });
					// ------------------------------------------------------------------------------.

					// DATOS CABECERA ---------------------------------------------------------------.
					doc.setFontSize(10); this.tamLinea = 5;
					doc.setTextColor(...blanco_gris);
					doc.setFont('Helvetica', '');
					this.X = 180
					this.Y = 17
					doc.setFont('Helvetica', 'bold'); doc.text('Folio:', this.X, this.Y, { align: 'right' });
					doc.setFont('Helvetica', ''); doc.text(`${this.ceros.transform(this.fcv.numero, 10) || '0000000001'}`, this.X + 2, this.Y);
					doc.setFont('Helvetica', 'bold'); doc.text('Emisión:', this.X, this.sigLinea(), { align: 'right' });
					doc.setFont('Helvetica', ''); doc.text(`${moment(this.fcv.fecEmi).format('DD-MM-YYYY') || '01-01-2000'}`, this.X + 2, this.Y);
					if (this.fcv.idMdl === 74 || this.fcv.idMdl === 84) {
						doc.setFont('Helvetica', 'bold'); doc.text('Entrega:', this.X, this.sigLinea(), { align: 'right' });
						doc.setFont('Helvetica', ''); doc.text(`${moment(this.fcv.fecEnt).format('DD-MM-YYYY') || '01-01-2000'}`, this.X + 2, this.Y);
					}

					// DATOS EMISOR -----------------------------------------------------------------.
					doc.setFontSize(9); this.tamLinea = 4;
					doc.setTextColor(...blanco_gris);
					doc.setFont('Helvetica', '');
					this.X = 42
					this.Y = 14
					doc.setFont('Helvetica', 'bold'); doc.text(this.emp.emp, this.X, this.Y)
					doc.setFont('Helvetica', 'bold'); doc.text('Razón Social:', this.X, this.sigLinea());
					doc.setFont('Helvetica', ''); doc.text(this.emp.rso, this.X + 22, this.Y);
					doc.setFont('Helvetica', 'bold'); doc.text('Domicilio:', this.X, this.sigLinea());
					doc.setFont('Helvetica', ''); doc.text(`${this.emp.fisDom} No. ${this.emp.fisDomNro} Int. ${this.emp.fisDomNro},  Col. ${this.emp.fisDomCol}`, this.X + 18, this.Y);
					doc.text(`${this.emp.fisDomMun}, ${this.emp.fisDomRegion.region}, ${this.emp.fisDomPais.name}`, this.X, this.sigLinea());
					doc.setFont('Helvetica', 'bold'); doc.text('RFC:', this.X, this.sigLinea());
					doc.setFont('Helvetica', ''); doc.text(this.emp.rfc, this.X + 8, this.Y);
					doc.text(this.emp.regFis.regFis, this.X, this.sigLinea());
					// ------------------------------------------------------------------------------.
					// DATOS RECEPTOR ---------------------------------------------------------------.
					doc.setFontSize(9); this.tamLinea = 4;
					doc.setTextColor(...gris);
					doc.setFont('Helvetica', '');
					this.X = 5
					this.Y = 52
					doc.setFont('Helvetica', 'bold'); doc.text(`${!demo ? this.fcv.fcp.fcp : 'Empresa De Prueba'}`, this.X, this.Y)
					doc.setFont('Helvetica', 'bold'); doc.text('Razón Social:', this.X, this.sigLinea());
					doc.setFont('Helvetica', ''); doc.text(`${!demo ? this.fcv.fcp.rso : 'Empresa Prueba SRL'}`, this.X + 22, this.Y);
					doc.setFont('Helvetica', 'bold'); doc.text('Domicilio:', this.X, this.sigLinea());
					doc.setFont('Helvetica', ''); doc.text(`${!demo ? Fcp.getDomicilioFiscal(this.fcv.fcp) : 'Calle con un nombre ext. 670 int D'}`, this.X + 18, this.Y);
					doc.text(`${!demo ? Fcp.getLocalidadFiscal(this.fcv.fcp) : 'Colonia Barrio, Localidad, Partido, Estado, Pais'}`, this.X, this.sigLinea());
					doc.setFont('Helvetica', 'bold'); doc.text('RFC:', this.X, this.sigLinea());
					doc.setFont('Helvetica', ''); doc.text(`${!demo ? this.fcv.fcp.rfc : 'ASDF880623MF1'}`, this.X + 8, this.Y);
					doc.text(`${!demo ? this.fcv.fcp.fcp : 'Regimen de Incorporacion Fiscal'}`, this.X, this.sigLinea());
					// ------------------------------------------------------------------------------.

					// ----------------------------------- ARTICULOS --------------------------------.
					// ------------------------------------------------------------------------------.
					doc.setFontSize(9); this.tamLinea = 4;
					doc.setTextColor(...gris);
					doc.setFont('Helvetica', '');
					this.Y = 95

					this.fcv.fcvsDets.forEach((fd: FcvDet) => {
						doc.setFontSize(9); this.tamLinea = 4;

						if ((this.Y + 50) > 280) {
							doc.addPage();
							this.Y = 20;
						}
						this.X = 8
						doc.text(fd.art.art, this.X, this.Y); //CODIGO - PRODUCTO
						if (fd.descripcion) {// SI TIENE DESCRIPCION LA PONE ABAJO
							doc.setFontSize(8);
							doc.text(this.insertaEnter(fd.descripcion, 70), this.X, this.Y + 4.5);
							doc.setFontSize(9);
						}
						this.X = 112
						doc.text(this.numerosFormato.transform(fd.cantidad, 2), this.X, this.Y, { align: 'right' }); // CANTIDAD
						this.X = 135
						doc.text(this.numerosFormato.transform(this.fcv.fcp.ivaIncluido ? fd.precioManual : fd.precioOriginal, 2), this.X, this.Y, { align: 'right' }); // PRECIO UNITARIO
						this.X = 160
						doc.text(this.numerosFormato.transform(fd.descuentoP, 2), this.X, this.Y, { align: 'right' }); // DESCUENTO
						this.X = 187
						if (this.fcv.fcp.ivaIncluido) {
							doc.text(this.numerosFormato.transform(fd.descuentoP ? (FcvDet.getTotalWithTaxes(fd) - fd.descuentoI) : FcvDet.getTotalWithTaxes(fd), 2), this.X, this.Y, { align: 'right' }); // IMPORTE
						} else {
							doc.text(this.numerosFormato.transform(fd.descuentoP ? (fd.importe - fd.descuentoI) : fd.importe, 2), this.X, this.Y, { align: 'right' }); // IMPORTE
						}
						doc.setDrawColor(...separador);
						if (fd.descripcion && this.enters > 1) {
							let aumento = Number((this.enters * 3.2)) + 4
							doc.line(6, this.Y + aumento, 200, this.Y + aumento) //LINEA SEPARADORA
							this.Y += aumento + 6;
						} else {
							doc.setDrawColor(...separador);
							doc.line(6, this.Y + 4, 200, this.Y + 4) //LINEA SEPARADORA
							this.Y += 10;
						}
					})

					// ------------------------------------------------------------------------------.
					// ------------------------------------------------------------------------------.

					// TOTALES ----------------------------------------------------------------------.
					doc.setFontSize(9); this.tamLinea = 4;
					doc.setTextColor(...gris);
					doc.setFont('Helvetica', '');

					this.Y += 4
					doc.setFontSize(11); this.tamLinea = 6;
					if (this.fcv.descuentoTotalP) {
						doc.setFont('Helvetica', 'bold'); doc.text('Sub-Total', 145, this.sigLinea(), { align: 'right' });
						doc.setFont('Helvetica', 'bold'); doc.text(`$ ${this.numerosFormato.transform(this.fcv.subTotal, 2)}`, 187, this.Y, { align: 'right' });
						doc.setFont('Helvetica', 'bold'); doc.text('Descuento', 145, this.sigLinea(), { align: 'right' });
						doc.setFont('Helvetica', ''); doc.text(`${this.numerosFormato.transform(this.fcv.descuentoTotalP, 2)}%`, 187, this.Y, { align: 'right' });
						doc.setFont('Helvetica', 'bold'); doc.text('Total', 145, this.sigLinea(), { align: 'right' });
						doc.setFont('Helvetica', ''); doc.text(`$${this.numerosFormato.transform(this.fcv.baseTotal, 2)}`, 187, this.Y, { align: 'right' });
					} else {
						doc.setFont('Helvetica', 'bold'); doc.text('Total', 145, this.sigLinea(), { align: 'right' });
						doc.setFont('Helvetica', 'bold'); doc.text(`$ ${this.numerosFormato.transform(this.fcv.fcp.ivaIncluido ? this.fcv.total :this.fcv.subTotal , 2)}`, 187, this.Y, { align: 'right' });
					}
					// ------------------------------------------------------------------------------.
					// TOTAL LETRA + MONEDA ---------------------------------------------------------.
					doc.setFontSize(9); this.tamLinea = 4;
					doc.setTextColor(...gris);
					doc.setFont('Helvetica', '');

					this.X = 8;
					this.Y = 280;
					if (this.fcv.idMdl === 75) {
						if (this.fcv.fcp.ivaIncluido) {
							doc.setFont('Helvetica', 'bold'); doc.text(' * Los precios aquí expresados incluyen impuestos.', this.X, this.Y - 8);
						} else {
							doc.setFont('Helvetica', 'bold'); doc.text(' * Los precios aquí expresados no incluyen impuestos.', this.X, this.Y - 8);
						}
					}
					doc.setFont('Helvetica', 'bold'); doc.text('TOTAL LETRA: ', this.X, this.Y);
					doc.setFont('Helvetica', ''); doc.text(numeroALetras(this.fcv.subTotal, getMonedaByMonId(this.fcv.idMon), true), this.X + 25, this.Y);
					doc.setFont('Helvetica', 'bold'); doc.text('MONEDA: ', this.X, this.sigLinea());
					doc.setFont('Helvetica', ''); doc.text(`${this.fcv ? this.fcv.idMon : 'MXN'} [Cot. ${this.fcv ? this.numerosFormato.transform(this.fcv.monCot, 2) : '1.00'}]`, this.X + 25, this.Y);
					// ------------------------------------------------------------------------------.
					// CANTIDAD DE PAGINAS Y LEYENDA DE FINAL DE PAGINA------------------------------.
					let cantidadPaginas = doc.internal.getNumberOfPages();
					if (cantidadPaginas > 0) {
						for (let i = 1; i <= cantidadPaginas; i++) {
							doc.setPage(i)
							doc.setFontSize(8);
							this.X = 180;
							this.Y = 292;
							doc.setFont('Helvetica', ''); doc.text(`Página ${i} de ${cantidadPaginas}`, this.X, this.Y);
						}
					}
					// -----------------------------------------------------------------------------.

					let dato = ''

					switch (salida) {
						case 'GUARDAR':
							doc.save('Factura-' + new Date().getDate() + '.pdf')
							ok(false);
							break;
						case 'VISTA':
							dato = doc.output('bloburi');
							ok(dato);
							break;
						case 'BLOB':
							dato = doc.output('blob');
							ok(dato);
							break;
						default:
							dato = 'Salida no valida';
							break;
					}
					console.log('ok ok ok ')





				})
				.catch(err => {
					console.log('Aca ta el error: ', err);

					er(err);
				})
		})

	}

	imagenObjetoHTML(rutaImg: string): HTMLElement {
		let obj = document.createElement('img')
		obj.src = rutaImg;
		return obj;
	}
	sigLinea(lineas?: number): number {
		lineas = lineas ? lineas : 1;
		this.Y += (this.tamLinea * lineas)
		return this.Y;
	}
	insertaEnter(texto: string, cadaCuantosCaracteres: number): string {

		if (texto.length < cadaCuantosCaracteres) return texto



		this.enters = (texto.match(/\n/g) || []).length + 1; //CUENTA LOS ENTERS QUE YA TENIA..
		let textoConEnters = [];

		let posicion = 0
		let i = 0
		for (; i < texto.length; i++) {
			let caracter = texto[i]
			textoConEnters.push(caracter)
			if (caracter === '\n') posicion = 0;
			if (posicion === cadaCuantosCaracteres) {
				textoConEnters.push('\n');
				this.enters++;
				posicion = 0
			}
			posicion++
		}
		return textoConEnters.join('');

	}
}

import { Pais, Region, RegFis } from './interfaces';
import { environment } from 'src/environments/environment';
import { Fcp } from './fcps.model';
import moment from 'moment';import { Art } from './arts.model';


export class Filtros {

	fechaDesde?: Date;
	fechaHasta?: Date;
	fcp?: Fcp;
	art?: Art;
	componentes?: any = {}

	constructor(obj: Filtros = {}) {
		this.fechaDesde = obj.fechaDesde || moment().add(-1, 'month').toDate()
		this.fechaHasta = obj.fechaHasta || new Date()
		this.fcp = obj.fcp || new Fcp()
		this.art = obj.art || new Art()
		this.componentes = obj.componentes || this.componentes
	}
}

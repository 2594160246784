import { Directive, ElementRef, HostListener, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalService } from '@core/global.service';


/**
 * El select quedaria:
 * <select class="form-control"
 *		[(ngModel)]="usoCfdiSeleccionado" name="usoCfdi_Seleccionado" #usoCfdi_Seleccionado="ngModel"
 *		selectD
 *		[selectArray]="usosCfdis"
 *		selectId="codigo"
 *		[(ngModelDatos)]="fcv.usoCfdi"
 *		(selectFunction)="guardarFcvStore()"
 *		required>
 *
 * 	Donde
 *  - selectD:   Es el selector de la directiva
 *  - ngModel:   Es el valor codigo o ID que se cambia normalmente con el select
 *  - [(ngModelDatos)]="fcv.usoCfdi":   Es la variable donde se va a almacenar el objeto como debe de venir de la base de datos
 *  - (selectFunction)="guardarFcvStore()":   Es la funcion a ejectura cuando se haga un cambio
 *	- selectId:   Es el string que indica el ID o codigo con el cual el select se basa para seleccionar la opcion (propiedad value del option)
 *  - [selectArray]="usosCfdis":    Es el array con todos los objetos u opciones
 *  - selectIdPredeterminado:   No esta probado pero en caso de que no venga nada deberia de seleccionar esa opcion ...
 */
@Directive({
  selector: '[selectD]'
})
export class SelectDirective implements OnChanges {

	arrayCargado = false;
	huboCambioInterno = false;
	@Input() selectArray:[];
	@Output() selectArrayChange = new EventEmitter();;

	@Input() selectId:string = '';
	@Output() selectFunction = new EventEmitter();;

	@Input() selectIdPredeterminado:'';

	@Input() ngModelDatos;
	@Output() ngModelDatosChange = new EventEmitter();

	@Input() ngModel;
	// @Output() ngModelChange = new EventEmitter();

	@HostListener('ngModelChange', ['$event']) onChange(model:any) {

		// if( typeof(model) === 'string' || typeof(model) === 'undefined' || !model) return;
		let encontrado = false;

		this.selectArray.forEach( e => {
			//busca que sea igual al id o si no hay id que el model sea igual al elemento (para casos de arreglos de numbers o strigns)
			if( e[this.selectId] === model ) {
				this.ngModelDatosChange.emit(e)
				encontrado = true
			}
			if(!this.selectId && String(e) === String(model) ) {
				this.ngModel = e;
				encontrado = true
			}
		});

		if ( !encontrado ) {
			if ( this.selectIdPredeterminado ) {

				this.selectArray.forEach(e => {
					if( e[this.selectId] === model ) {
						this.ngModelDatosChange.emit(e)
					}
				});

			} else {
				this.ngModelDatosChange.emit('')
			}
		}
		//con timeout para esperar un ciclo de angular
		setTimeout(() => {
			this.selectFunction.emit();
		}, 50);
	}


	constructor (
		private el: ElementRef,
		private g: GlobalService
	) {
		// setTimeout(() => {
		// 	this.onChange(this.ngModel);
		// }, 500);
	}

	ngOnChanges(changes: SimpleChanges): void {

		if (!this.ngModel) {
			this.ngModel = ''
			this.el.nativeElement.value = ''
		}
	}


}


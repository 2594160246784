import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { axCollContentModule } from './components/axCollContent/axCollContent.module';
import { axAutocompleteModule } from './components/ax-autocomplete/ax-autocomplete.module';
import { autoCompletarDirective } from './components/autocompletar.directive';
import { FocusScrollDirective } from './components/focus-scroll.directive';
import { FormatoNumeroDirective } from './components/formato-numero.directive';
import { NoFocusDirective } from './components/no-focus.directive';
import { SeleccionarTextoDirective } from './components/seleccionar-texto.directive';
import { SelectDirective } from './components/select.directive';
import { UploadFileButtonDirective } from './components/upload-file-button.directive';
import { FileSelectModule } from './components/file-select/file-select.module';
import { HideShowComponent } from './components/hide-show/hide-show.component';
import { MaterialModule } from '../material.module';
import { FrappeChartsDirective } from './components/frappe-charts/frappe-charts.directive';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { EmailDirective } from '../validadores/email.directive';
// CONFIGURACION FORMATOS MATERIAL
// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'DD - MM - YYYY',
//   },
//   display: {
//     dateInput: 'DD - MM - YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'DD - MM - YYYY',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };

@NgModule({
  declarations: [
    autoCompletarDirective,
    FocusScrollDirective,
    FormatoNumeroDirective,
    NoFocusDirective,
	EmailDirective,
    SeleccionarTextoDirective,
    SelectDirective,
    UploadFileButtonDirective,
    HideShowComponent,
    FrappeChartsDirective,


  ],
  imports: [
    CommonModule,
    axCollContentModule,
    axAutocompleteModule,
    FileSelectModule,
    MaterialModule,
    // MatMomentDateModule,

  ],
  exports: [
    axCollContentModule,
    axAutocompleteModule,
    FileSelectModule,
    autoCompletarDirective,
    FocusScrollDirective,
    FormatoNumeroDirective,
    NoFocusDirective,
    SeleccionarTextoDirective,
    SelectDirective,
    UploadFileButtonDirective,
    HideShowComponent,
	EmailDirective,
    FrappeChartsDirective,

  ]
})
export class DirectivasModule { }

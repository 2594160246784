import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthService } from '@root/auth/auth.service';
import { DatosService } from '@root/core/datos.service';
import { MxService } from '@root/core/facturacion/mx.service';
import { GlobalService } from '@root/core/global.service';
import { UiService } from '@root/core/ui.service';
import { CantidadTransporta, CartaPorteV2, FiguraTransporte, Mercancia, Mercancias, Remolque, Ubicacion } from '@root/models/cartaPorteDataV2.model';
import { Fcv } from '@root/models/fcvs.model';
import moment from 'moment';import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { c_Exportacion, c_MetodoPago } from '../timbra-cfdi/cfdi4-catalogos';
import { configuracionAutotransporte, subTipoRemolque, tipoEmbalaje, tipoFigura, tipoPermisoSCT } from './carta-porte.catalogo';



@Component({
	selector: 'app-carta-porte-data',
	templateUrl: './carta-porte-data.component.html',
	styleUrls: ['./carta-porte-data.component.scss']
})
export class CartaPorteDataComponent implements OnInit {

	cargando = false
	carta: CartaPorteV2 = new CartaPorteV2()
	origen: Ubicacion = null
	destinos: Ubicacion[] = []
	fcv: Fcv = null
	environment = environment
	catTipoFigura = tipoFigura
	catTipoPermisoSCT = tipoPermisoSCT
	catConfiguracionAutotransporte = configuracionAutotransporte
	catsubTipoRemolque = subTipoRemolque
	catTipoEmbalaje = tipoEmbalaje
	catalogoExportacion = c_Exportacion
	catalogoMetodoPago = c_MetodoPago

	constructor(
		public g: GlobalService,
		public ui: UiService,
		public ds: DatosService,
		private modal: MatDialogRef<CartaPorteDataComponent>,
		@Inject(MAT_DIALOG_DATA) data: { fcv: Fcv },
		private auth: AuthService,
	) {
		this.fcv = data.fcv

		this.origen = new Ubicacion('Origen', this.carta.TranspInternac, this.auth.emp.rfc)
		this.addDestino()
		this.addFigura()


		const LSorigen = localStorage.getItem('cp:origen')
		if (LSorigen && LSorigen.length > 10) {
			const origenJSON: Ubicacion = JSON.parse(LSorigen)
			const origen: Ubicacion = new Ubicacion('Origen', this.carta.TranspInternac, this.auth.emp.rfc)
			origen.Domicilio.Calle = origenJSON.Domicilio.Calle
			origen.Domicilio.NumeroExterior = origenJSON.Domicilio.NumeroExterior
			origen.Domicilio.Colonia = origenJSON.Domicilio.Colonia
			origen.Domicilio.Localidad = origenJSON.Domicilio.Localidad
			origen.Domicilio.Municipio = origenJSON.Domicilio.Municipio
			origen.Domicilio.Estado = origenJSON.Domicilio.Estado
			origen.Domicilio.Pais = origenJSON.Domicilio.Pais
			origen.Domicilio.CodigoPostal = origenJSON.Domicilio.CodigoPostal
			this.origen = origen
		}

		if (this.fcv.cartaPorte) {
			try {
				this.carta = new CartaPorteV2(this.fcv.cartaPorte)
				this.origen = this.carta.getOrigen()
				this.destinos = this.carta.getDestinos()
				if (!this.destinos.length) this.addDestino()
			} catch (e) {
				console.log(e)
			}
		}

	}

	handleMaterilaPeligrosoChange = (mercancia: Mercancia) => {
		if (!mercancia.isMaterialPeligroso) {
			mercancia.MaterialPeligroso = 'No'
			mercancia.CveMaterialPeligroso = ''
		}
	}

	addDetalleMercancia = (index: number) => {
		this.carta.Mercancias.Mercancia[index].CantidadTransporta.push(new CantidadTransporta())
	}
	removeDetalleMercancia = (indexMercancia: number, indexDetalle: number) => {
		if (this.carta.Mercancias.Mercancia[indexMercancia].CantidadTransporta.length === 1) {
			Swal.fire({ title: '¡Ups!', icon: 'warning', text: 'Debe de dejar por lo menos un detalle.' })
			return;
		}
		this.carta.Mercancias.Mercancia[indexMercancia].CantidadTransporta.splice(indexDetalle, 1)
	}
	removeMercancia = (i: number) => {
		if (this.carta.Mercancias.Mercancia.length === 1) return
		this.carta.Mercancias.Mercancia.splice(i, 1)

	}
	addMercancia = () => {
		this.carta.Mercancias.Mercancia.push(new Mercancia())
	}

	removeDestino = (i: number) => {
		const destino = this.destinos[i]
		if (this.destinos.length == 1) return
		this.destinos.splice(i, 1)

		//looks for the detalle that has the ID and clears it
		this.carta.Mercancias.Mercancia = this.carta.Mercancias.Mercancia.map(m => {
			if (m.CantidadTransporta.length) {
				m.CantidadTransporta = m.CantidadTransporta.map(md => {
					if (md.IDDestino === destino.IDUbicacion) md.IDDestino = '';
					return md
				})
			}
			return m
		})
	}

	addDestino = () => {

		this.destinos.push(new Ubicacion('Destino', this.carta.TranspInternac, this.fcv.fcp.rfc, this.destinos))
	}
	addFigura = () => {
		this.carta.FiguraTransporte.push(new FiguraTransporte())
	}
	removeFigura = (i: number) => {
		if (this.carta.FiguraTransporte.length == 1) return
		this.carta.FiguraTransporte.splice(i, 1)
	}
	addRemolque = () => {
		if (this.carta.Mercancias.Autotransporte.Remolques.length === 2) {
			Swal.fire({ title: '¡Ups!', icon: 'warning', text: 'No puede agregar más de dos remolques.' })
			return;
		}
		this.carta.Mercancias.Autotransporte.Remolques.push(new Remolque())
	}
	removeRemolque = (i: number) => {
		if (this.carta.Mercancias.Autotransporte.Remolques.length == 0) return
		this.carta.Mercancias.Autotransporte.Remolques.splice(i, 1)
	}

	test = () => {
		this.origen = new Ubicacion('Origen', 'No', this.auth.emp.rfc)
		this.origen.Domicilio.Calle = 'Av. Torremolinos'
		this.origen.Domicilio.NumeroExterior = '301'
		this.origen.Domicilio.Colonia = '0099'
		this.origen.Domicilio.Localidad = '03'
		this.origen.Domicilio.Municipio = '039'
		this.origen.Domicilio.Estado = 'JAL'
		this.origen.Domicilio.Pais = 'MEX'
		this.origen.Domicilio.CodigoPostal = '44298'
		this.origen.FechaHoraSalidaLlegada = moment().add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')


		const destino: Ubicacion = new Ubicacion('Destino', 'No', this.fcv.fcp.rfc)

		destino.Domicilio.Calle = 'Av. Los Ganzos'
		destino.Domicilio.NumeroExterior = '204'
		destino.Domicilio.Colonia = '0725'
		destino.Domicilio.Localidad = '10'
		destino.Domicilio.Municipio = '120'
		destino.Domicilio.Estado = 'JAL'
		destino.Domicilio.Pais = 'MEX'
		destino.Domicilio.CodigoPostal = '45130'
		destino.RFCRemitenteDestinatario = 'OASA880623MF1'
		destino.FechaHoraSalidaLlegada = moment().add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
		destino.DistanciaRecorrida = 10
		this.destinos = [destino]

		const destino2: Ubicacion = new Ubicacion('Destino', 'No', this.fcv.fcp.rfc)

		destino2.Domicilio.Calle = 'Av. Los Dos Ganzos'
		destino2.Domicilio.NumeroExterior = '333'
		destino2.Domicilio.Colonia = '0725'
		destino2.Domicilio.Localidad = '10'
		destino2.Domicilio.Municipio = '120'
		destino2.Domicilio.Estado = 'JAL'
		destino2.Domicilio.Pais = 'MEX'
		destino2.Domicilio.CodigoPostal = '45130'
		destino2.RFCRemitenteDestinatario = 'OASA880623MF1'
		destino2.FechaHoraSalidaLlegada = moment().add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
		destino2.DistanciaRecorrida = 20
		this.destinos.push(destino2)

		this.carta.Mercancias.Autotransporte.IdentificacionVehicular = {
			ConfigVehicular: "C2R2",
			PlacaVM: "JKV1452",
			AnioModeloVM: '2020',
			PesoBrutoVehicular: 1000

		}

		this.carta.Mercancias.Autotransporte.Remolques = []
		this.carta.Mercancias.Autotransporte.Remolques.push(new Remolque('JKV4657', 'CTR003'))
		this.carta.Mercancias.Autotransporte.Remolques.push(new Remolque('RFG6879', 'CTR003'))
		this.carta.Mercancias.Autotransporte.PermSCT = "TPAF01"
		this.carta.Mercancias.Autotransporte.NumPermisoSCT = "NumPermisoSCT"


		this.carta.Mercancias.Autotransporte.Seguros.AseguraMedAmbiente = "TIA SEGUROS AMBIENTALES"
		this.carta.Mercancias.Autotransporte.Seguros.PolizaMedAmbiente= "45789856AX"
		this.carta.Mercancias.Autotransporte.Seguros.AseguraCarga = "TIA SEGUROS"
		this.carta.Mercancias.Autotransporte.Seguros.AseguraRespCivil = "TIA SEGUROS"
		this.carta.Mercancias.Autotransporte.Seguros.PolizaCarga = "123654"
		this.carta.Mercancias.Autotransporte.Seguros.PolizaRespCivil = "123465"
		this.carta.Mercancias.Autotransporte.Seguros.PrimaSeguro = "250000"

		this.carta.Mercancias.Mercancia[0] = {
			BienesTransp: "52101500",
			Cantidad: 1.0,
			isMaterialPeligroso: false,
			MaterialPeligroso: 'No',
			CantidadTransporta: [
				{
					Cantidad: 1.0,
					IDDestino: this.destinos[0].IDUbicacion,
					IDOrigen: "OR000001"
				}
			],
			PesoEnKg: 111.10,
			ClaveUnidad: "MTR",
			Descripcion: "ALFOMBRA NO TEJIDA",
		}
		this.carta.Mercancias.Mercancia[1] = {
			BienesTransp: "11121801",
			Cantidad: 3.0,
			isMaterialPeligroso: true,
			MaterialPeligroso: 'Sí',
			CveMaterialPeligroso: 'M0001',
			Embalaje: '1A1',
			CantidadTransporta: [
				{
					Cantidad: 1.0,
					IDDestino: this.destinos[0].IDUbicacion,
					IDOrigen: "OR000001"
				},
				{
					Cantidad: 2.0,
					IDDestino: this.destinos[1].IDUbicacion,
					IDOrigen: "OR000001"
				}
			],
			PesoEnKg: 222.20,
			ClaveUnidad: "MTR",
			Descripcion: "Cáñamo",
		}
		this.carta.FiguraTransporte = []
		const tiposfigura: FiguraTransporte = {
			TipoFigura: "01",
			RFCFigura: "OASA880623MF1",
			NumLicencia: "C12345678",
			NombreFigura: "Javier Hernandez"
		}

		this.carta.FiguraTransporte.push(tiposfigura)

	}

	cerrar = () => {
		this.modal.close({fiscal: null, cartaPorte:null})
	}

	datosInvalidos = () => {
		const fiscal = this.fcv.fiscal
		if (!fiscal.metodoPago?.codigo) {
			Swal.fire('¡Ups!', 'Seleccione un Método de Pago.', 'warning');
			return true;
		}
		if (!fiscal.formaPago?.fondoTipo?.codigo) {
			Swal.fire('¡Ups!', 'Seleccione la Forma de Pago.', 'warning');
			return true;
		}

		if (fiscal.formaPago.fondoTipo.codigo === '99' && fiscal.metodoPago.codigo === 'PUE') {
			Swal.fire('¡Ups!', 'Si la forma de pago es "Por definir", el Método de Pago no puede ser "PUE".', 'warning');
			return true;
		}
		if (!fiscal.usoCfdi?.codigo) {
			Swal.fire('¡Ups!', 'Seleccione la Uso del CFDi.', 'warning');
			return true;
		}
		if (!this.carta.Mercancias.Autotransporte.IdentificacionVehicular.ConfigVehicular) {
			Swal.fire({ title: 'Ups!', text: 'Debe de seleccionar la configuración vehicular.', icon: 'error' })
			return true
		}
		if (!this.carta.Mercancias.Autotransporte.NumPermisoSCT) {
			Swal.fire({ title: 'Ups!', text: 'Debe de indicar el número de permiso SCT.', icon: 'error' })
			return true
		}
		if (!this.carta.Mercancias.Autotransporte.PermSCT) {
			Swal.fire({ title: 'Ups!', text: 'Debe de seleccionar el tipo de permiso SCT.', icon: 'error' })
			return true
		}
		if (!this.carta.Mercancias.Autotransporte.PermSCT) {
			Swal.fire({ title: 'Ups!', text: 'Debe de seleccionar el tipo de permiso SCT.', icon: 'error' })
			return true
		}
		let materialPeligrosoOk = true
		this.carta.Mercancias.Mercancia.forEach(m => {
			if (m.MaterialPeligroso === 'Sí' && (!m.CveMaterialPeligroso || !m.Embalaje)) materialPeligrosoOk = false
		})

		if (!materialPeligrosoOk) {
			Swal.fire({ title: 'Ups!', text: 'Si selecciona "Sí" para algún material peligroso, debe indicar la clave según el catálogo "c_MaterialPeligroso" y el tipo de embalaje según "c_TipoEmbalaje".', icon: 'error' })
			return true
		}

		return false
	}
	aceptar = () => {

		if (this.datosInvalidos()) return;

		localStorage.setItem('cp:origen', JSON.stringify(this.carta.getOrigen()))
		/* MERCANCIAS */
		this.carta.Mercancias.NumTotalMercancias = this.carta.Mercancias.Mercancia.length
		this.carta.Mercancias.PesoBrutoTotal = this.carta.Mercancias.Mercancia.reduce((p, c) => p + Number(c.PesoEnKg), 0)
		this.carta.Mercancias.PesoBrutoTotal = Number(this.carta.Mercancias.PesoBrutoTotal.toFixed(3))
		this.carta.Mercancias.UnidadPeso = 'KGM'
		this.carta.Mercancias.Mercancia = this.carta.Mercancias.Mercancia


		/* UBICACIONES */
		this.carta.Ubicaciones = [];
		let TotalDistRec = 0
		this.carta.Ubicaciones.push(this.origen)
		this.carta.Ubicaciones.push(... this.destinos)
		this.carta.Ubicaciones.forEach(ubi => {
			if (ubi.TipoUbicacion === 'Destino') {
				TotalDistRec += Number(ubi.DistanciaRecorrida)
			}
		})
		this.carta.TotalDistRec = TotalDistRec.toString()
		this.fcv.cartaPorte = this.carta


		this.modal.close(this.fcv)

	}

	ngOnInit(): void {

	}

}

import { Imp, UniMed, ProSrv } from './interfaces';
import { Rubro } from './rubros.model';
import { CurvaAtributos } from './atributo.model';

export interface ArtDetalle {
	cantidad: number,
	art: Art
}

export class Art {

	_id: string = '';
	idUsu: string = '';
	art: string = '';
	codigo: string = '';
	codigoAlterno: string = '';
	codigoBarras: string = '';
	rubro: Rubro = null;
	subrubro: Rubro = null;
	curvaAtributos: CurvaAtributos = null;
	imagen: string = 'no-img';
	imagen2: string = 'no-img';
	imagen3: string = 'no-img';
	imagenURL: string = '';
	imagenURL2: string = '';
	imagenURL3: string = '';
	editable: boolean = false;
	inventario: boolean = false;
	costo: number = 0;
	cantidadMinima: number = 0;
	stockMinimo: number = 0;
	stockMaximo: number = 0;
	precio: number = 0;
	precio2: number = 0;
	impuestos: Impuesto[] = []
	baja: number = 0;
	proSrv: '';
	uniMed: UniMed = { codigo: '', uniMed: '' }
	detalle: ArtDetalle[] = [];
	// DATOS DE ECOMMERCE -----------------------------------------.
	descripcion: '';
	precioEcommerce: number = 0;
	ecommerce: boolean = false // Aparece en el ecommerce
	// ------------------------------------------------------------.
	// DATOS DE PLATAFORMAS ---------------------------------------.
	idShopify: string = '';
	idMercadoLibre: string = '';
	idWoocommerce: string = '';
	idEbay: string = '';
	idAmazon: string = '';
	// ------------------------------------------------------------.
	idMdl: number = 7;

	constructor(obj: any = {}) {

		this._id = obj._id ? obj._id : this._id
		this.art = obj.art || this.art
		this.idUsu = obj.idUsu || this.idUsu
		this.idMdl = obj.idMdl ? obj.idMdl : this.idMdl
		this.codigo = obj.codigo ? obj.codigo : this.codigo
		this.codigoAlterno = obj.codigoAlterno ? obj.codigoAlterno : this.codigoAlterno
		this.codigoBarras = obj.codigoBarras ? obj.codigoBarras : this.codigoBarras
		this.rubro = obj.rubro || this.rubro
		this.subrubro = obj.subrubro || this.subrubro
		this.curvaAtributos = obj.curvaAtributos || this.curvaAtributos
		this.imagen = obj.imagen ? obj.imagen : this.imagen
		this.imagen2 = obj.imagen2 ? obj.imagen2 : this.imagen2
		this.imagen3 = obj.imagen3 ? obj.imagen3 : this.imagen3
		this.imagenURL = obj.imagenURL ? obj.imagenURL : this.imagenURL
		this.imagenURL2 = obj.imagenURL2 ? obj.imagenURL2 : this.imagenURL2
		this.imagenURL3 = obj.imagenURL3 ? obj.imagenURL3 : this.imagenURL3
		this.editable = obj.editable ? obj.editable : this.editable
		this.inventario = obj.inventario ? obj.inventario : this.inventario
		this.costo = obj.costo ? obj.costo : this.costo
		this.stockMinimo = obj.stockMinimo ? obj.stockMinimo : this.stockMinimo
		this.stockMaximo = obj.stockMaximo ? obj.stockMaximo : this.stockMaximo
		this.cantidadMinima = obj.cantidadMinima ? obj.cantidadMinima : this.cantidadMinima
		this.precio = obj.precio ? obj.precio : this.precio
		this.precio2 = obj.precio2 ? obj.precio2 : this.precio2
		// this.impTasa = obj.impTasa ? obj.impTasa : this.impTasa
		// this.imp = obj.imp ? obj.imp : this.imp
		this.impuestos = obj.impuestos && obj.impuestos.length ? obj.impuestos : this.impuestos
		this.baja = obj.baja ? obj.baja : this.baja
		this.proSrv = obj.proSrv ? obj.proSrv : this.proSrv
		this.uniMed = obj.uniMed ? obj.uniMed : this.uniMed
		this.detalle = obj.detalle && obj.detalle.length ? obj.detalle : this.detalle

		// DATOS DE ECOMMERCE -----------------------------------------.
		this.descripcion = obj.descripcion || this.descripcion
		this.precioEcommerce = obj.precioEcommerce || this.precioEcommerce
		this.ecommerce = obj.ecommerce || this.ecommerce
		// ------------------------------------------------------------.

		// DATOS DE PLATAFORMAS ---------------------------------------.
		this.idShopify = obj.idShopify || this.idShopify;
		this.idMercadoLibre = obj.idMercadoLibre || this.idMercadoLibre;
		this.idWoocommerce = obj.idWoocommerce || this.idWoocommerce;
		this.idEbay = obj.idEbay || this.idEbay;
		this.idAmazon = obj.idAmazon || this.idAmazon;
		// ------------------------------------------------------------.

	}

}

export class Impuesto {
	tipo: 'RET' | 'IMP' = 'IMP';
	imp: Imp = { codigo: '', imp: '' };
	tasa: number = 0.00;
	total: number = 0.00;
	constructor(tipo?: 'RET' | 'IMP', imp?: Imp, tasa?: number, total?:number) {
		this.tipo = tipo || this.tipo
		this.imp = imp || this.imp
		this.tasa = tasa || this.tasa
		this.total = total || this.total
	}
}

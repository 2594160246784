import { Emp } from './emps.model';
import { Preferencias, Planes } from './interfaces';
import { environment } from '../../environments/environment';
import { Fondo } from './fondo.model';

export enum acceso {
	denegado,   	// 0
	permitido,	// 1
	soloLectura  	// 2
}
export enum usuRoles { USER_ROLE, ADMIN_ROLE, MASTER_ROLE };
export type estadoInvitacion = 'NONE' | 'PENDIENTE';
export interface usuRutas { ruta: string, acceso: acceso, role: usuRoles }

export type pantallaInicio = 	'dashboard-sin-datos' |
								'pos' |
								'dashboard-completo';

export interface configUsu {
	pantallaInicio: pantallaInicio
}
export interface referido {
	idUsu: string,
	codigoReferido: string
}
export class Usu {
	_id?: string;
	idUsu: string;
	idSuc: string = '1';
	nom: string;
	ape: string;
	emps: Emp[];
	usu: string;
	pass: string;
	rutas: usuRutas[] = [ ];
	img: string = '';
	tel: string = '';
	email: string = '';
	codigoReferido: string = '' //CODIGO PARA QUE ESTE USER REFIERA
	referidoPor: referido = { idUsu: '', codigoReferido: '' };
	role: usuRoles;
	plan: Planes;
	planFecha: number | Date = 0;
	preferencias: Preferencias = {
		pais: { code: 'mx', name: 'México' },
		region: { region: 'Ciudad de México', country: 'mx' },
		fon: new Fondo(),
		regFis: { codigo: '601', regFis: "General de Ley Personas Morales" }
	};

	// femex: 0 | 1 | 3
	metodo: string;
	config: configUsu = {
		pantallaInicio: 'dashboard-completo'
	};
	verificado: boolean = false;
	verificadoFecha: Date = new Date(0);
	altaFecha: Date = new Date(0);
	invitacionToken: string = '';
	invitacionEstado: estadoInvitacion = 'NONE';
	testing: boolean = false;
	baja: number = 0;
	authToken: string = '';
	cantidadSesion: number = 0

	constructor(obj: any = {}) {
		this._id = obj._id ? obj._id : '';
		this.idUsu = obj.idUsu ? obj.idUsu : '';
		this.idSuc = obj.idSuc ? obj.idSuc : '1';
		this.nom = obj.nom ? obj.nom : '';
		this.ape = obj.ape ? obj.ape : '';
		this.emps = obj.emps ? obj.emps : [new Emp()]
		this.usu = obj.usu ? obj.usu : '';
		this.pass = obj.pass ? obj.pass : '';
		this.rutas = obj.rutas ? obj.rutas : [];
		this.img = obj.img ? obj.img : '';
		this.verificado = obj.verificado ? obj.verificado : false;
		this.verificadoFecha = obj.verificadoFecha ? obj.verificadoFecha : new Date(0);
		this.altaFecha = obj.altaFecha ? obj.altaFecha : new Date();
		this.tel = obj.tel ? obj.tel : '';
		this.email = obj.email ? obj.email : '';
		this.codigoReferido = obj.codigoReferido || this.codigoReferido;
		this.referidoPor = obj.referidoPor || this.referidoPor;
		this.role = obj.role ? obj.role : usuRoles.USER_ROLE;
		this.plan = obj.plan ? obj.plan : '';
		this.planFecha = obj.planFecha ? obj.planFecha : 0;
		this.metodo = obj.metodo ? obj.metodo : '';
		this.config = obj.config || this.config;
		this.preferencias = obj.preferencias ? obj.preferencias : this.preferencias;
		this.cantidadSesion = obj.cantidadSesion ? obj.cantidadSesion : 0;
		this.testing = obj.testing || this.testing;
		this.baja = obj.baja ? obj.baja : 0;
		this.authToken = obj.authToken ? obj.authToken : '';
		this.invitacionToken = obj.invitacionToken || '';
		this.invitacionEstado = obj.invitacionEstado || 'NONE';

	}

}

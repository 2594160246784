import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { toggleSidebar } from '../../layout/ui.actions';
import { UiService } from '@core/ui.service';
import { ConexionService } from '@core/conexion.service';
import { WebsocketsService } from '@core/websockets.service';
import { MenuSystem } from '@models/menu.model';

@Injectable({
	providedIn: 'root'
})
export class SidebarService implements OnDestroy {
	toggled = true;
	_hasBackgroundImage = true;
	menus: MenuSystem[] = [];

	constructor(
		private store: Store<AppState>,
		private ui: UiService,
		private con: ConexionService,
		private wSockets: WebsocketsService
	) {
		console.log('Sidebar service: online')
		this.setSidebarState(this.ui.isMobile);
	}

	init() {
		this.menus = []
		this.con.getP('system/menus', true).then(res => {

			this.menus = res.data

			this.wSockets.listen('menus').subscribe((value) => {
        const menus = value as MenuSystem[]
				this.menus = menus
			})

		})
	}

	toggle() {
		this.setSidebarState(!this.toggled);
	}

	getSidebarState() {
		return this.toggled;
	}

	setSidebarState(state: boolean) {
		this.toggled = state;
		//PARA QUE TENGA SENTIDO SE NIEGA STATE, POR ALGUNA RAZON EL SIDEBAR ESTA ABIERTO EN FALSE Y CERRADO EN TRUE, EN EL APPSTATE LO GUARDAMOS AL REVES
		this.store.dispatch(toggleSidebar({estado: !state}));
	}

	get hasBackgroundImage() {
		return this._hasBackgroundImage;
	}

	set hasBackgroundImage(hasBackgroundImage) {
		this._hasBackgroundImage = hasBackgroundImage;
	}


	ngOnDestroy() {
		console.log('Sidebar Service: offline')
	}
}

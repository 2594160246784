import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppState } from '../app.reducer';
import { Store } from '@ngrx/store';
import { cerrarSesion } from '@root/auth/auth.actions';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';
import { SoporteService } from './soporte.service';
import { take } from 'rxjs/operators';
import { Usu } from '../models/usu.model';
import { TrackService } from './track.service';

@Injectable()
export class ErrorService {

	constructor(
		private g: GlobalService,
		private http: HttpClient,
		private store: Store<AppState>,
		private router: Router,
		private _soporte: SoporteService,
		private trk: TrackService,

	) { }

	manejarError(error: any, muestraMensaje = false, url: string = 'sin URL') {


		if (error.status === 401) {
			this.g.setDato('mensajeSesion', 'Su sesión ha expirado.');
			console.log('mensajeSesion', 'Su sesión ha expirado.')
			this.store.dispatch(cerrarSesion())
			return;
		}

		if (error.status === 402) {
			this.manejarError402(error)
			console.log(error)
			return;
		}

		let mensaje = 'Parece que no tiene conexión a internet, verifíquelo y vuelva a intentarlo.'

		if (error.message) mensaje = error.message;
		if (error.error.message) mensaje = error.error.message;

		if (muestraMensaje) this.g.SweetMsg('¡Ups!', mensaje, 'error');


		// log - solo si es un error 400
		if (error && error.status > 299 || !error.status) {
			this.enviarError(error, mensaje, url);
			if (environment.production) Sentry.captureException(error);
		}

		console.log('Manejando error: ', error)
	}

	/**
	 * Maneja error de suscripcion
	 */
	manejarError402(error) {
		this.trk.trackAccion('accion', 'Aviso Termino Mes Gratis');

		if (error.error.data === 'primer-mes-termino') {
			Swal.fire(<any>{
				title: 'Suscripción GRATIS',
				html: `
        <p>Ya paso un mes desde que te registraste. Actualmente estas usando nuestro <b>Plan Total</b> de forma gratuita.
        </p>
        <p>
        Debes cambiarte a nuestro <b>Plan Starter GRATIS</b> o seleccionar alguno de los planes pagos con <b>mayores ventajas</b>.
        </p>
        <button id="actualizar-boton" class="btn btn-primary mt-4 btn-lg">
        Actualizar Suscripción
        </button>
        <button id="seguir-boton" class="btn btn-secondary mt-4 btn-lg">
        Tengo un Problema
        </button>
        `,
				type: 'warning',
				showConfirmButton: false,
				didOpen: () => {
					let actualizarBoton = document.querySelector('#actualizar-boton')

					actualizarBoton.addEventListener('click', () => {
						Swal.close()
						this.router.navigate(['actualizar-plan']);
					})
					let soporteBoton = document.querySelector('#seguir-boton')

					soporteBoton.addEventListener('click', () => {
						Swal.close();
						this._soporte.SolicitarSoporte();
					})
				}
			})

		} else {
			Swal.fire(<any>{
				title: 'Suscripción Vencida',
				html: `
        <p>Parece que su suscripción ya no es válida, actualízala desde tu perfil en la sección de suscripción. Podrás usar el sistema pero no podrás ver cierta inforamción.</p>
        <p><b>${error.error.message}</b></p>
        <button id="actualizar-boton" class="btn btn-primary mt-4 btn-lg">
        Actualizar Suscripción
        </button>
        <button id="seguir-boton" class="btn btn-secondary mt-4 btn-lg">
        OK
        </button>
        `,
				type: 'warning',
				showConfirmButton: false,
				didOpen: () => {
					let actualizarBoton = document.querySelector('#actualizar-boton')

					actualizarBoton.addEventListener('click', () => {

						this.router.navigate(['actualizar-plan']);

						Swal.close()
					})
					let okBoton = document.querySelector('#seguir-boton')

					okBoton.addEventListener('click', () => {

						Swal.close();
					})


				}
			})
			if (environment.production) {
				this.store.select('usu').pipe(take(1)).subscribe((usu: Usu) => {
					if (usu && usu.usu)
						Sentry.captureException('Suscripcion Vencida - ' + usu.usu);
				})
			}
		}
	}

	enviarError(error: any, data: string, url: string) {
		if (typeof error !== 'string' && typeof error !== 'number') error = JSON.stringify(error);
		let authToken = this.g.getDato('authToken') || 'xxxx';
		let cabeceras = new HttpHeaders({ 'Content-Type': 'application/json', 'authToken': authToken });
		let stack = new Error().stack

		this.http.post(environment.apiURL + 'system/errores', { error, data, url, stack }, { headers: cabeceras }).subscribe(
			() => {
				console.log('Error registrado correctamente...');
			},
			(err) => {
				console.error(err)
				console.log('Problema al enviar error...');
			})
	}
}


import { createReducer, on } from '@ngrx/store';
import * as fromFprs from './fprs.actions';
import { Fpr } from './fprs.model';

const fprLocal = JSON.parse(window.localStorage.getItem('fpr'));
const initialState: Fpr | null = fprLocal ? Object.assign(new Fpr(), fprLocal) : null;

export const fprsReducer = createReducer(
  initialState,
  on(fromFprs.cambiarPago, (state, action) => {
    const nvoFpr = Object.assign(new Fpr(), action.fpr);
    window.localStorage.setItem('fpr', JSON.stringify(nvoFpr));
    return nvoFpr;
  })
);

import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy, Output, EventEmitter, forwardRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';


@Component({
  selector: 'axAutocomplete',
  templateUrl: './ax-autocomplete.component.html',
  styleUrls: ['./ax-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => axAutocompleteComponent),
      multi: true
    }
  ]
})
export class axAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  suscripciones: Subscription[] = []
  @ViewChild('acInput') acInput: ElementRef
  @ViewChild('acInputMobile') acInputMobile: ElementRef
  @ViewChild('matAC') matAC: MatAutocomplete

  @Input() public sinDatos = 'No hay datos cargados';

  @Input() public show2 = '';
  @Input() public show = '';
  @Input() public showSeparator = ' - ';
  @Input() public searchBy: string | string[] = '';

  @Input() public name = (Math.random() * 1000).toFixed(0); //ngModel must have a name in the form
  @Input() public placeholder = 'Buscar'
  @Input() public outline: boolean = false
  appearance: string

  @Input() public disabled = false;

  @Input() public isMobile = false;
  @Input() public dataArray: any[] = [];

  @Input() public addNewText: string = 'AGREGAR NUEVO';
  @Input() public new: boolean = false;
  @Input() public notFound: any = null; //In case of nothing was selected or error what does it should return (default: null)

  @Output() public valueChanges = new EventEmitter();
  @Output() public selectNew = new EventEmitter();

  selectedOption
  selectedObject
  showValue: string = '';
  showValueTemp: string = '';
  showChevronDown: boolean = false;

  acFullscreen: boolean = false
  classParent: string[] = []

  constructor(
    private el: ElementRef
  ) { }

  // ControlValueAccessor -------------------------------------------------.
  writeValue(value: any) {
    this.selectedOption = value || '';
    this.selectedObject = value || this.notFound;
    this.changeShowValue(value);
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() { }

  // ----------------------------------------------------------------------.
  /**
   * loops through parents to remove classes and styles, looking to remove all z-index so the fullScreen
   * is rendered correctly no matter of the DOM configuration
   * @param action
   */
  controlZindex(action: 'remove' | 'restore') {

    let parent = this.el.nativeElement.parentNode

    if (parent) {

      let exit = false
      let i = 0
      while (!exit) {
        if (i === 2) exit = true //MAX 3 LEVELS
        if (parent) {
          if (action === 'remove') {
            this.classParent.push(parent.classList ? parent.classList.value : '');
            parent.classList = '';
          } else {
            if (parent.classList) parent.classList.value = this.classParent[i];
          }

        }

        if (parent.parentNode) {
          parent = parent.parentNode
        } else {
          exit = true;
        }
        i++;
      }
    }
  }

  cleanShowValue() {
    if (this.showValue === '') {
      this.fullscreen(false)
      this.clean()
    }

    this.showValue = '';
  }

  clean() {
    this.propagateChange(this.notFound);
    this.selectedObject = this.notFound;
    this.showValue = '';
  }

  fullscreen(fullscreen) {

    this.acFullscreen = fullscreen;

    if (!this.acFullscreen) {

      if (!this.showValue && this.selectedObject) this.showValue = this.showValueTemp;
      this.controlZindex('restore');
      // document.body.scrollTop = 0; // For Safari
      // document.documentElement.scrollTop = 0; // For Chrome, Fire

    } else {

      this.showValueTemp = this.showValue
      this.showValue = '';
      setTimeout(() => {
        this.acInputMobile.nativeElement.focus()
        this.controlZindex('remove');
      }, 0);

    }

  }

  changeShowValue(option) {
    if (!option) return
    this.valueChanges.emit();

    // validation
    if (this.show) {
      if (option[this.show] === undefined || option[this.show] === null) {
        console.error(`[ax-automcomplete]\n\n  The show [${this.show}] property doesn't exist in the object.`);
      }
    }
    if (this.show2) {
      if (option[this.show2] === undefined || option[this.show2] === null) {
        console.error(`[ax-automcomplete]\n\n  The show2 [${this.show2}] property doesn't exist in the object.`);
      }
    }


    if (this.show2) {
      this.showValue = `${option[this.show2]}${this.showSeparator}${option[this.show]}`;
      if (!option[this.show2] && !option[this.show]) this.showValue = '';
    } else {
      this.showValue = option[this.show];
      if (!option[this.show]) this.showValue = '';
    }

  }

  emitSelectNew() {
    this.selectNew.emit()
  }
  selectOption(option: any) {
    if (option.option) option = option.option.value

    if (option === '~~NEW-ITEM-FUNCTION~~') {
      setTimeout(() => {
        this.clean()
      }, 1)
      this.emitSelectNew()
      return;
    }

    this.propagateChange(option)
    this.selectedObject = option
    setTimeout(() => {

      this.changeShowValue(option)
      if (this.isMobile) this.fullscreen(false)
    }, 0);
  }

  closeMatAC() {
    if (typeof (this.selectedObject) === 'undefined' || !this.selectedObject) {
      this.clean()
    }
  }


  acFocus(e) {
    this.showChevronDown = true;

  }

  acBlur(e) {
    this.showChevronDown = false;
    if (!this.matAC.isOpen) {

      if (!this.selectedObject) {
        this.clean()
      }

    }
  }

  acKeyup(e: KeyboardEvent) {

    if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 17) {

      if (typeof (this.selectedObject) === 'undefined' || !this.selectedObject) return;


      this.propagateChange(this.notFound)
      this.selectedObject = this.notFound
      this.showValue = ''
    }

    // checks for letters and numbers
    var char = String.fromCharCode(e.keyCode);
    if (/[a-zA-Z0-9-_., ]/.test(char)) {
      this.propagateChange(this.notFound)
      this.selectedObject = this.notFound
      return;
    }

    // checks for arrow Keys
    if (e.keyCode >= 37 && e.keyCode <= 40) return;

    // cheks for enter
    if (e.keyCode === 13) return;

    // cheks for tab
    if (e.keyCode === 9) return;

    this.propagateChange(this.notFound)
    this.selectedObject = this.notFound
  }

  ngOnInit() {

    this.appearance = this.outline ? 'outline' : ''

    if (!this.show) {
      console.error(`[ax-automcomplete]\n\n  The autocomplete must have a show attribute with the name of the property you want to show in search and as selected value: \n
      like:\n
      <ax-autocomplete show="a-string"></ax-autocomplete>.`);
      return;
    }

    // CREATES ARRAY OF DATA TO SEARCH BY,
    // IF searchBy is empty it creates an empty array and adds the show and show2 properties
    if (typeof (this.searchBy) === 'string') this.searchBy = this.searchBy.length > 0 ? [this.searchBy] : [];
    if (typeof (this.show) === 'string' && !this.searchBy.includes(this.show)) this.searchBy.push(this.show)
    if (typeof (this.show2) === 'string' && !this.searchBy.includes(this.show2)) this.searchBy.push(this.show2)

    //ORDERS SHOW AND SHOW2 AS THE TWO LAST ELEMENTS OF THE SEARCHBY ARRAY, ALWAYS SHOW GOES FIRST AND SHOW2 LAST
    let showIndex = this.searchBy.indexOf(this.show)
    if (showIndex >= 0) {
      this.searchBy.splice(showIndex, 1);
      this.searchBy.push(this.show);
    }
    let show2Index = this.searchBy.indexOf(this.show2)
    if (show2Index >= 0) {
      this.searchBy.splice(show2Index, 1);
      this.searchBy.push(this.show2);
    }

  }

  ngAfterViewInit() {



  }

  ngOnDestroy() {
    this.suscripciones.forEach(s => s.unsubscribe());
  }
}





import { Injectable, ApplicationRef, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { TrackService } from './track.service';
import { first } from 'rxjs/operators';

@Injectable(
  { providedIn: 'root' }
)
export class SwUpdateService implements OnDestroy {
  suscripciones: Subscription[] = []
  constructor(
    appRef: ApplicationRef,
    private updates: SwUpdate,
    private trk: TrackService

  ) {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const cada2horas$ = interval(6 * 60 * 60 * 1000);
    const cada2horasOnceAppIsStable$ = concat(appIsStable$, cada2horas$);
    cada2horasOnceAppIsStable$.subscribe(() => updates.checkForUpdate());
  }

  updateSW() {
    this.suscripciones.push(this.updates.available.subscribe(evento => {

      Swal.fire('Nueva Versión', 'Hay una nueva versión de la App, se va a descargar, disculpe las molestias.', 'success').then(() => {
        window.location.reload();
        this.trk.trackAccion( 'accion', 'UPD SW' );
        // const snack = this.snackbar.open('¡Hay una nueva versión!', 'ACTUALIZAR', { duration: 15000 });
        // snack
        //   .onAction()
        //   .subscribe(() => {
        //     window.location.reload();
        //   });
      })


    }))

    // this.suscripciones.push(this.updates.activated.subscribe(evento => {

    //   Swal.fire('Nueva Versión Lista', 'La nueva versión esta lista, vamos a reiniciar la App, disculpe las molestias.', 'success').then(() => {
    //     this.trk.trackAccion( 'accion', 'UPD version' );
    //     window.location.reload();
    //   })

    // }))
  }

  ngOnDestroy(){
    this.suscripciones.forEach(s => s.unsubscribe() );
  }

}

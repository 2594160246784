import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { ConexionService } from '@root/core/conexion.service';
import { DatosService } from '@root/core/datos.service';
import { GlobalService } from '@root/core/global.service';
import { MensajesService } from '@root/core/mensajes.service';
import { UiService } from '@root/core/ui.service';
import { Contacto, ContactoEmail, ContactoTel } from '@root/models/contacto.model';
import { Fcp } from '@root/models/fcps.model';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { cambiarPipeline } from '../../models/pipeline.acciones';
import { Pipeline } from '../../models/pipeline.model';
import { Trato } from '../../models/trato.model';

@Component({
	selector: 'app-trato-pop',
	templateUrl: './trato-pop.component.html',
	styleUrls: ['./trato-pop.component.scss']
})
export class TratoPopComponent implements OnInit, OnDestroy {

	subs: Subscription[] = []
	pipe: Pipeline = new Pipeline()
	trato: Trato = new Trato()

	empresaLimpia: Fcp = new Fcp()
	empresa: Fcp = new Fcp()
	empresas: Fcp[] = []
	contactos: Contacto[] = []
	contactoNuevo: Contacto = new Contacto();
	contacto: Contacto = new Contacto();
	nuevoContacto = false

	constructor(
		public ui: UiService,
		public ds: DatosService,
		private con: ConexionService,
		private g: GlobalService,
		private m: MensajesService,
		private _store: Store<AppState>,
		public modal: MatDialogRef<TratoPopComponent>,
		@Inject(MAT_DIALOG_DATA) data: any,
	) {
		if (data.modal) this.trato = new Trato(data.trato)
		this.empresas = ds.fcps.filter((f) => f.rso + f.fcp !== '' && f.idMdl === 2)
		this.contactos = ds.contactos
		this.subs.push(
			this._store.select('pipeline').subscribe(p => {
				if (p) this.pipe = new Pipeline(p)
			}),
			this.ds._fcpsObs.subscribe(fcps => {
				this.empresas = ds.fcps.filter((f) => f.rso + f.fcp !== '' && f.idMdl === 2)

			}),
			this.ds._contactosObs.subscribe(contactos => {
				this.contactos = ds.contactos
			})
		)
	}

	nuevaEmpresa() {

		this.m.nuevoContacto(2, this.empresa).then((value) => {
      const empresa = value as Fcp;
			if (!empresa && empresa._id) {
				this.empresa = new Fcp(empresa);
			}
		})
	}

	async eliminar() {

		let res = await this.g.SweetMsg('Eliminar', '¿Desea borrar el trato?', 'question')
		if (!res.value) return;

		try {
			let res = await this.con.postP(this.trato, 'crm/trato-eliminar', true)
			if (res.ok) {
				this.pipe.fases[this.trato.indexFase].tratos.splice(this.trato.index, 1)
				this._store.dispatch(cambiarPipeline({pipeline: new Pipeline(this.pipe)}));
				this.modal.close();
			}

		} catch (error) {
			console.log(error);
		}
	}

	async guardar() {
		try {
			let guardaContacto = await this.contactoGuardar()
			if(!guardaContacto) return;
			this.trato.idContactos.push(this.contacto._id)
			this.trato.idEmp = this.empresa._id
			this.trato.idPipe = this.pipe._id

			let res = await this.con.postP(this.trato, 'crm/trato-guardar', true)
			if (res.ok) {
				this.trato = new Trato(res.data);
				this.pipe.fases[this.trato.indexFase].tratos.push(this.trato);
				this._store.dispatch(cambiarPipeline({ pipeline: new Pipeline(this.pipe)}));
				this.modal.close();
			}

		} catch (error) {
			console.log(error);
		}
	}
	cambioEmpresa() {
		if (!this.empresa || !this.empresa._id || this.trato.titulo) return

		this.trato.titulo = 'Trato con ' + (this.empresa.fcp || this.empresa.rso)
	}

	agregarEmail() {
		this.contacto.email.push(new ContactoEmail('', 'Trabajo'))
	}
	borrarEmail(i: number) {
		this.contacto.email.splice(i, 1)
	}
	agregarTelefono() {
		this.contacto.tel.push(new ContactoTel('', 'Trabajo'))

	}
	borrarTelefono(i: number) {
		this.contacto.tel.splice(i, 1)
	}
	async contactoGuardar() {
		if (!this.contacto.nom) {
			Swal.fire('', 'Escriba el nombre del contacto.', 'info')
			return false
		}
		if (!this.contacto.idEmp && this.empresa._id) this.contacto.idEmp = this.empresa._id

		try {

			let res = await this.con.postP(this.contacto, 'crm/contacto', true)

			if (res.ok && res.data._id) {
				this.contacto = new Contacto(res.data)
				this.nuevoContacto = false
				return true;
			} else {
				Swal.fire('', 'Problema al Guardar el Contacto.', 'error')
				return false;
			}
		} catch (error) {
			console.log(error);
			return false;
		}

	}
	contactoCancelar() {
		this.contacto = new Contacto()
		this.nuevoContacto = false
	}

	cerrar() {
		this.modal.close()
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		this.subs.forEach(s => s.unsubscribe())
	}
}

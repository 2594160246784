import { Injectable } from '@angular/core';

import { Pipeline } from './models/pipeline.model';
import { Trato } from './models/trato.model';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UiService } from '@root/core/ui.service';
import { TratoPopComponent } from './components/trato-pop/trato-pop.component';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { ConexionService } from '@root/core/conexion.service';
import { cambiarPipeline} from './models/pipeline.acciones';
import { CrmContactoEditaComponent } from './components/crm-contacto/crm-contacto-edita.component';
import { Contacto } from '@root/models/contacto.model';
import { CrmDetalle } from './models/crm-detalle.model';
import { EditaTextoComponent } from './components/edita-texto/edita-texto.component';


@Injectable({
	providedIn: 'root'
})
export class CrmService {

	pipe: Pipeline = new Pipeline()
	trato: Trato = new Trato()
	crmDetalles: CrmDetalle[] = []
	filtros: {
		status: string
	} = {
		status: ''
	}
	constructor(
		private con: ConexionService,
		private store: Store<AppState>,
		private sb: MatSnackBar,
		private router: Router,
		private dialog: MatDialog,
		private ui: UiService,

	) {
		console.log('CRM: Service started');
		this.store.select('pipeline').subscribe((pipe) => {
			this.pipe = new Pipeline(pipe)
			if (!this.pipe.fases || !this.pipe.fases.length || !this.pipe._id) return;
			this.pipe.refrescarIndicesTratos()
			this.con.postP(pipe, 'crm/pipeline-guardar')
		})
		this.store.select('trato').subscribe((trato) => {
			this.trato = new Trato(trato)
			if (!this.trato || !this.trato._id) return;
			this.con.postP(trato, 'crm/trato-guardar')
			this.pipe.refrescarIndiceTrato(this.trato)
			this.pipe.fases[this.trato.indexFase].tratos[this.trato.index] = this.trato;
			this.store.dispatch(cambiarPipeline({pipeline: this.pipe}))
		})
	}

	editarNombrePipeline() {

		let ancho = '25%';
		let alto = '20%';
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		if (this.ui.isMobile) {
			ancho = '100%';
			alto = '100%';
		}
		const dialogo = this.dialog.open(EditaTextoComponent, {
			width: ancho,
			maxWidth: ancho,
			height: alto,
			panelClass: 'dialogo-popup',
			closeOnNavigation: true,
			disableClose: true,
			data: { modal: true, leyenda: 'Escriba el nombre del pipeline', placeholder: 'Nombre del pipeline' }
		})


		dialogo.afterClosed().subscribe((result: any) => {
			if (result) {
				this.pipe.nombre = result
				this.store.dispatch(cambiarPipeline({pipeline: this.pipe}));
			}
		});
	}
	editarFase(i: number) {

		let ancho = '20%';
		let alto = '20%';
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		if (this.ui.isMobile) {
			ancho = '100%';
			alto = '100%';
		}
		const dialogo = this.dialog.open(EditaTextoComponent, {

			width: ancho,
			maxWidth: ancho,
			height: alto,
			panelClass: 'dialogo-popup',
			closeOnNavigation: true,
			disableClose: true,
			data: { modal: true, leyenda: 'Escriba el nombre de la Fase', placeholder: 'Nombre de la Fase' }
		})


		dialogo.afterClosed().subscribe((result: any) => {
			if (result) {
				this.pipe.fases[i].fase = result
				this.store.dispatch(cambiarPipeline({pipeline: this.pipe}));
			}
		});
	}
	tratoNuevo(tratoOindex: Trato | number = new Trato()) {
		let trato = new Trato()
		if (typeof tratoOindex === 'number') {
			trato.indexFase = tratoOindex
			trato.idPipe = this.pipe._id
		} else {
			trato = new Trato(tratoOindex)
		}
		let ancho = '60%';
		let alto = '90%';
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		if (this.ui.isMobile) {
			ancho = '100%';
			alto = '100%';
		}
		const dialogo = this.dialog.open(TratoPopComponent, {
			width: ancho,
			maxWidth: ancho,
			height: alto,
			panelClass: 'dialogo-popup',
			closeOnNavigation: true,
			disableClose: true,
			data: { modal: true, trato }
		})

		return new Promise((res, rej) => {
			dialogo.afterClosed().subscribe((result: any) => {
				res(result);
			});
		});
	}
	/**
	 * 	Si se le pasa un contacto deja editarlo, si no deja crear uno nuevo.
	 * @param idMdl
	 * @param contacto
	 */
	contactoEdita(contacto = new Contacto()): Promise<Contacto> {
		let ancho = '60%';
		let alto = '90%';
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		if (this.ui.isMobile) {
			ancho = '100%';
			alto = '100%';
		}
		const dialogo = this.dialog.open(CrmContactoEditaComponent, {
			width: ancho,
			maxWidth: ancho,
			height: alto,
			panelClass: 'dialogo-popup',
			closeOnNavigation: true,
			disableClose: true,
			data: { modal: true, contacto }
		})

		// Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
		return new Promise((res, rej) => {
			//A esa referencia nos podemos suscribir para esperar respuesta del usuario.
			dialogo.afterClosed().subscribe((result: any) => {
				res(result);
			});
		});
	}
	tratoDetalle(trato: Trato) {

		this.router.navigate(['inicio/crm/trato/', { id: trato._id }])
	}
	drop(event: CdkDragDrop<Trato[]>) {
		if (event.previousContainer === event.container) {

			if (event.previousIndex === event.currentIndex) return

			moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		} else {
			transferArrayItem(event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);
		}
		this.pipe.refrescarIndicesTratos();
		this.store.dispatch(cambiarPipeline({pipeline: this.pipe}))
		this.sb.open('Elemento cambiado', 'OK', { duration: 1000 })
	}
}

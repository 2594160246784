import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ConexionService } from '@core/conexion.service';
import { Fcp } from '@models/fcps.model';
import { GlobalService } from '@core/global.service';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { UiService } from '@core/ui.service';
import { DatosService } from 'src/app/core/datos.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { MensajesService } from '@core/mensajes.service';
import { AuthGuard } from '../../../../auth/auth.guard';
import { AuthService } from '@root/auth/auth.service';


@Component({
	selector: 'app-contactos-bus',
	templateUrl: './contactos-bus.component.html',
	styleUrls: ['./contactos-bus.component.css']
})
export class ContactosBusComponent implements OnInit, OnDestroy, AfterViewInit {
	suscripciones: Subscription[] = [];

	@ViewChild(MatPaginator) paginator: MatPaginator;

	busGral: string = '';
	@Input() mdl: string;
	fcps: Fcp[] = [];
	fcpsPage: Fcp[] = [];
	idMdl = 3;
	anulados:boolean = false;

	displayedColumns: string[] = ['codigo', 'nombre', 'fcp', 'email', 'estado'];

	constructor(
		public ui: UiService,
		public auth: AuthService,
		public ds: DatosService,
		private route: ActivatedRoute,
		private m: MensajesService,
		private ag: AuthGuard,
		private g: GlobalService,
	) {
		if (this.ui.isMobile) this.displayedColumns = ['mobile']

	}

	editor(fcp?: Fcp) {
		let contacto = this.g.mdl(this.idMdl).ruta.toLowerCase();
		if (!this.ag.canActivate(null, null, `/inicio/contactos/${contacto}`)) return
		this.m.nuevoContacto(this.idMdl, fcp).then((value) => {
      const fcpRef = value as Fcp;
			if (!fcpRef) return;
			if (fcpRef['_id']) {

			} else {
				if (fcpRef) return;
			}
		})
	}

	iterator(page?) {

		if (page === 0) this.paginator.firstPage();

		let currentPage = this.paginator.pageIndex
		let pageSize = this.paginator.pageSize
		const end = (currentPage + 1) * pageSize;
		const start = currentPage * pageSize;
		this.fcpsPage = this.fcps.slice(start, end);
	}

	buscar() {
		let contactosFiltrados: Fcp[] = this.ds.fcps.filter(f => f.idMdl === this.idMdl)
		if(!this.anulados) contactosFiltrados = contactosFiltrados.filter(f => f.baja === 0)

		if (this.busGral) {
			contactosFiltrados = contactosFiltrados.filter(p => {
				return p.fcp.toUpperCase().indexOf(this.busGral.toUpperCase()) != -1
					||
					p.codigo.toUpperCase().indexOf(this.busGral.toUpperCase()) != -1
					||
					p.nom.toUpperCase().indexOf(this.busGral.toUpperCase()) != -1
					||
					p.ape.toUpperCase().indexOf(this.busGral.toUpperCase()) != -1
					||
					p.tel.toUpperCase().indexOf(this.busGral.toUpperCase()) != -1
					||
					p.rso.toUpperCase().indexOf(this.busGral.toUpperCase()) != -1
					||
					p.rfc.toUpperCase().indexOf(this.busGral.toUpperCase()) != -1
					||
					p.email.toUpperCase().indexOf(this.busGral.toUpperCase()) != -1
			})
		}
		this.fcps = [];
		this.iterator(0)
		this.fcps = contactosFiltrados;
		this.iterator(0)
	}
	ngAfterViewInit() {
		setTimeout(() => {
			this.buscar();
		}, 0);
	}
	ngOnInit() {

    setTimeout(() => {

			this.suscripciones.push(this.ds._fcpsObs.subscribe(fcps => {
				this.fcps = fcps.filter(f => f.idMdl === this.idMdl)
				this.iterator(0);
			}))
		}, 0);

		this.route.data.pipe(take(1)).subscribe((data: any) => {
			this.idMdl = data.idMdl;
			this.ui.paginaActual = data.titulo || '';
		})
	}

	ngOnDestroy() {
		this.suscripciones.forEach(suscripcion => {
			suscripcion.unsubscribe();
		});
	}

}

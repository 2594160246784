import { Action, createAction, props } from '@ngrx/store';
import { Fcv } from './fcvs.model';

export enum FcvActionTypes  {
	CAMBIAR_FACTURA = '[Fcvs] Cambiar Factura'
}

export const changeFcv = createAction(FcvActionTypes.CAMBIAR_FACTURA, props<{ fcv: Partial<Fcv> | Fcv }>());

// export class CambiarFacturaAction implements Action {
// 	readonly type = CAMBIAR_FACTURA;
// 	constructor( public objConPropiedadesParaAsignar: Object) { }
// }

// export type accionesValidas = 	CambiarFacturaAction | CambiarPropiedadFacturaAction;

import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
	selector: 'app-edita-texto',
	templateUrl: './edita-texto.component.html',
	styleUrls: ['./edita-texto.component.scss']
})
export class EditaTextoComponent implements OnInit {
	dato: string = ''
	placeholder: string = ''
	leyenda: string = ''

	constructor(
		public modal: MatDialogRef<EditaTextoComponent>,
		@Inject(MAT_DIALOG_DATA) data: any,
	) {
		this.placeholder = data.placeholder
		this.leyenda = data.leyenda
	}

	guardar(){
		this.modal.close(this.dato)
	}
	cerrar(){
		this.modal.close()
	}

	ngOnInit(): void {
	}

}

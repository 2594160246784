import { Injectable, OnInit, Pipe } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import * as uiActions from '../layout/ui.actions'
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  isMobile: boolean = false;
  isLoading: boolean = false;
  isLoadingLogin: boolean = false;
  paginaActual: string = '';
  loadingConfig = {
    primaryColour: '#ffffff',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff',
    backdropBorderRadius: '3px'
  }

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute
  ) {
    this.store.select('ui').subscribe((ui) => {
      this.isMobile = ui.isMobile;
	  localStorage.setItem('isMobile', String(this.isMobile))
      this.isLoading = ui.isLoading;
      this.isLoadingLogin = ui.isLoadingLogin;
    })
  }

  loading(isLoading) {
    if (isLoading) {
      //si va a cargar lo escrolea al top
      setTimeout(() => {
        document.body.scrollTop = 0; // Safari
        document.documentElement.scrollTop = 0; // Chrome, Firefox, IE and Opera
      }, 300);
      this.store.dispatch(uiActions.activarLoading());
    } else {
      this.store.dispatch(uiActions.desactivarLoading());
    }
  }
  loadingLogin(isLoading) {
    if (isLoading) {
      this.store.dispatch(uiActions.activarLoadingLogin());
    } else {
      this.store.dispatch(uiActions.desactivarLoadingLogin());
    }
  }

}

import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator } from '@angular/forms';

@Directive({
  selector: '[valEmail]',
	providers: [
        {provide: NG_VALIDATORS, useExisting:EmailDirective, multi: true}
    ]
})
export class EmailDirective implements Validator {

  constructor() { }
	validate(el :AbstractControl) {

		let value = el.value;
		let regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		// let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


		if(value && value.indexOf(';') > -1){
			value = value.replace(/ /, '');
			let mailsValidos = true;
			let mails = value.split(';')
			mails.forEach(m=>{
				if ( !regexEmail.test( String(m).toLowerCase() ) ) 	mailsValidos = false
			})
			if ( !mailsValidos ) return { valEmail : true };
		}else{
			if ( !regexEmail.test( String(value).toLowerCase() ) ) {
				return { valEmail : true };
			}
		}

		return null;

	}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

//MATERIAL
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { FlexLayoutModule } from '@angular/flex-layout';
import { axAutocompleteComponent } from './ax-autocomplete.component';
import { MatRippleModule } from '@angular/material/core';
import { axFilter } from './ax-filter.pipe';

@NgModule({
	declarations: [
		axAutocompleteComponent,
		axFilter
	],
	imports: [
		CommonModule,
		FormsModule,
		MatInputModule,
		MatRippleModule,
		MatButtonModule,
		FlexLayoutModule,
		MatAutocompleteModule,

	],
	exports: [
		axAutocompleteComponent
	]
})
export class axAutocompleteModule { }

import moment from "moment"


export class CartaPorteV2 {
	Version: string = '2.0'
	Ubicaciones: Ubicacion[] = []
	Mercancias: Mercancias = new Mercancias()
	FiguraTransporte: FiguraTransporte[] = []
	TranspInternac: 'No' | 'Si' = 'No'
	TotalDistRec: string = '0'
	constructor(cartaPorte?: Partial<CartaPorteV2>) {
		this.Version = cartaPorte?.Version || this.Version
		this.Ubicaciones = cartaPorte?.Ubicaciones || this.Ubicaciones
		this.Mercancias = cartaPorte?.Mercancias || this.Mercancias
		this.FiguraTransporte = cartaPorte?.FiguraTransporte || this.FiguraTransporte
		this.TranspInternac = cartaPorte?.TranspInternac || this.TranspInternac
		this.TotalDistRec = cartaPorte?.TotalDistRec || this.TotalDistRec
	}

	getOrigen(): Ubicacion {
		const origenes = this.Ubicaciones.filter(u => u.TipoUbicacion === 'Origen')
		if (!origenes?.length) return new Ubicacion('Origen')
		return origenes[0]
	}

	getDestinos(): Ubicacion[] {
		const destinos = this.Ubicaciones
			.filter(u => u.TipoUbicacion === 'Destino')
			.map(u => {
				const d = new Ubicacion('Destino')
				d.setDataFromPartial(u)
				return d
			})
		return destinos
	}
}

export class FiguraTransporte {
	Domicilio?: Domicilio
	TipoFigura: string = ''
	RFCFigura: string = ''
	NumLicencia: string = ''
	NombreFigura: string = ''
}

export class Mercancias {
	Autotransporte: Autotransporte = new Autotransporte()
	Mercancia: Mercancia[] = [new Mercancia()]
	NumTotalMercancias: number = 0
	PesoBrutoTotal: number = 0
	UnidadPeso: string = 'KGM'
}

export class Mercancia {
	BienesTransp: string = ''
	Cantidad: number
	CantidadTransporta: CantidadTransporta[] = [new CantidadTransporta()]
	PesoEnKg: number = 0
	isMaterialPeligroso: boolean = false
	MaterialPeligroso: 'Sí' | 'No' = 'No'
	CveMaterialPeligroso?: string
	Embalaje?: string
	ClaveSTCC?: string = ''
	ClaveUnidad: string = ''
	Descripcion: string = ''
	NumPiezas?: string = ''
}

export class CantidadTransporta {
	Cantidad: number = 0
	Placa?: string = ''
	IDDestino: string = ''
	IDOrigen: string = 'OR000001'
}

export class Autotransporte {
	IdentificacionVehicular: IdentificacionVehicular = new IdentificacionVehicular()
	NumPermisoSCT: string = ''
	PermSCT: string = ''
	Seguros: Seguro = new Seguro()
	Remolques: Remolque[] = []
}

export class Seguro {
	AseguraMedAmbiente: string = ''
	PolizaMedAmbiente: string = ''
	AseguraCarga: string = ''
	AseguraRespCivil: string = ''
	PolizaCarga: string = ''
	PolizaRespCivil: string = ''
	PrimaSeguro: string = ''
}

export class IdentificacionVehicular {
	AnioModeloVM: string = ''
	ConfigVehicular: string = ''
	PlacaVM: string = ''
	PesoBrutoVehicular: number = 0
}

export class Remolque {
	Placa: string
	SubTipoRem: string
	constructor(Placa: string = '', SubTipoRem: string = '') {
		this.Placa = Placa
		this.SubTipoRem = SubTipoRem
	}
}

export class Ubicacion {
	TranspInternac?: 'Si' | 'No' = 'No'
	Domicilio: Domicilio = new Domicilio()
	FechaHoraSalidaLlegada: string = moment().add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
	IDUbicacion: string = ''
	RFCRemitenteDestinatario: string = ''
	TipoUbicacion: 'Origen' | 'Destino'
	DistanciaRecorrida?: number

	constructor(tipo: 'Origen' | 'Destino', TranspInternac: 'No' | 'Si' = 'No', RFCRemitenteDestinatario: string = '', destinos?: Ubicacion[]) {
		this.TipoUbicacion = tipo
		if (tipo === 'Destino') this.DistanciaRecorrida = 0
		this.TranspInternac = TranspInternac
		this.RFCRemitenteDestinatario = RFCRemitenteDestinatario

		let id = (Math.random() * 10 * 6).toFixed(0)
		this.IDUbicacion = 'OR000001'

		if (tipo === 'Destino') {
			this.IDUbicacion = `DE${('000000' + id).slice(-6)}`
			if (destinos) {
				const ids = destinos.map(d => d.IDUbicacion)
				while (ids.includes(this.IDUbicacion)) {
					let id = (Math.random() * 10 * 6).toFixed(0)
					this.IDUbicacion = `DE${('000000' + id).slice(-6)}`
				}
			}
		}
	}

	setDataFromPartial(data: Partial<Ubicacion>) {
		this.TranspInternac = data?.TranspInternac || this.TranspInternac
		this.Domicilio = data?.Domicilio || this.Domicilio
		this.FechaHoraSalidaLlegada = data?.FechaHoraSalidaLlegada || this.FechaHoraSalidaLlegada
		this.IDUbicacion = data?.IDUbicacion || this.IDUbicacion
		this.RFCRemitenteDestinatario = data?.RFCRemitenteDestinatario || this.RFCRemitenteDestinatario
		this.TipoUbicacion = data?.TipoUbicacion || this.TipoUbicacion
		this.DistanciaRecorrida = data?.DistanciaRecorrida || this.DistanciaRecorrida
	}

	getDomicilio() {
		const d = this.Domicilio
		if (!d.CodigoPostal) return `[${this.IDUbicacion}] - Domicilio no ingresado`
		if (!d.Pais) return `[${this.IDUbicacion}] - Domicilio no ingresado`
		if (!d.Estado) return `[${this.IDUbicacion}] - Domicilio no ingresado`
		return `[${this.IDUbicacion}] - CP: ${d.CodigoPostal}, ${d.Estado}, ${d.Pais}`
	}

}

export class Domicilio {
	Calle: string = ''
	NumeroExterior: string = ''
	Colonia: string = ''
	Localidad: string = ''
	Municipio: string = ''
	Estado: string = ''
	Pais: string = ''
	CodigoPostal: string = ''
	Referencia?: string
}

import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array2Order: any[], orderBy: string , order: 'ASC' | 'DESC'): any[] {

	if (!array2Order || array2Order.length <= 1 || !array2Order) return array2Order;

    if (!orderBy || orderBy === '') { return  array2Order} // sort 1d array

	return sortArray(array2Order, orderBy, order);

  }

}

let sortArray = (arregloParaOrdenar: any[], propiedad: string, orden: 'ASC' | 'DESC') => {
		let xOrden = 1;
		if (orden === 'ASC') xOrden = -1;

		let propiedades = []
		if (propiedad.indexOf('.')>-1){
			propiedades = propiedad.split('.')
			switch(propiedades.length){
				case 2:
					return arregloParaOrdenar.sort((a, b) => (a[propiedades[0]][propiedades[1]] < b[propiedades[0]][propiedades[1]]) ? xOrden : xOrden * -1)
				case 3:
					return arregloParaOrdenar.sort((a, b) => (a[propiedades[0]][propiedades[1]][propiedades[2]] < b[propiedades[0]][propiedades[1]][propiedades[2]]) ? xOrden : xOrden * -1)
				default:
					console.error('La funcion de orden de arreglo puede buscar hasta tres propiedades solamente.')
			}
		}

		return arregloParaOrdenar.sort((a, b) => (a[propiedad] < b[propiedad]) ? xOrden : xOrden * -1)

	}

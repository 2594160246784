import { createReducer, on } from '@ngrx/store';
import { Inventario } from './inventario.model';
import * as InventarioActions from './inventario.acciones';

const inventarioLocal = JSON.parse(window.localStorage.getItem('inventario'));
const estadoInicial: Inventario | null = inventarioLocal ? Object.assign(new Inventario(), inventarioLocal) : null;

export const inventariosReducer = createReducer(
  estadoInicial,
  on(InventarioActions.cambiarInventario, (state, action) => {
    const nuevoInventario = Object.assign(new Inventario(), action.objConPropiedadesParaAsignar);
    window.localStorage.setItem('inventario', JSON.stringify(nuevoInventario));
    return nuevoInventario;
  })
);

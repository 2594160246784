import { Component, OnInit } from '@angular/core';
import { UiService } from '@core/ui.service';
import { ConexionService } from '@core/conexion.service';
import { GlobalService } from '@core/global.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-confirmacion-email',
	templateUrl: './confirmacion-email.component.html',
	styleUrls: ['./confirmacion-email.component.css']
})
export class ConfirmacionEmailComponent implements OnInit {

	tipo: 'ecommerce' | 'oadmin'
	confirmado: boolean = false
	mensaje: string = ''

	constructor(
		public ui: UiService,
		private con: ConexionService,
		private g: GlobalService,
		private ruta: ActivatedRoute
	) {
		this.ruta.queryParams.subscribe(params => {
			let at = params['a'];
			let ecom = params['ecom'];

			if (!at && !ecom) {
				this.mensaje = 'No se encontró usuario válido.'
				return
			}
			this.tipo = 'oadmin'
			if(ecom) this.tipo = 'ecommerce'

			this.g.setDato('authToken', at || ecom);
			this.confirmacionEmail()
		});
	}
	confirmacionEmail() {

		this.con.post({tipo: this.tipo}, 'confirmacion-email').subscribe((d) => {
			this.confirmado = true;
		}, (e) => {
			this.confirmado = false;
			this.mensaje = 'Este enlace ya no es válido, le acabamos de enviar uno nuevo a su correo. Por favor utilice el nuevo.'
		})
	}
	ngOnInit() {

	}

}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { Filtros } from '../models/filtros.model';
import * as fromFiltros from '@root/models/filtros.actions';
import { Fcp } from '../models/fcps.model';
import { Art } from '../models/arts.model';

@Injectable({
	providedIn: 'root'
})
export class FiltrosService {
	filtros: Filtros
	constructor(
		private store: Store<AppState>
	) {
		this.store.select('filtros').subscribe(filtros => {
			this.filtros = filtros
		})
	}


	/**
	 * Filtros por componente
	 * @param componente
	 * @param filtros
	 */
	setFiltroComponente(componente: string, filtros: any) {

		this.store.dispatch(fromFiltros.cambiarFiltrosComponente({componente, filtros}))
	}

	/**
	 * Cliente que se esta usando y en todas las pantalla puede o no levantarse, por ejemplo sy estas buscando facturas y pasas a la cuenta corriente deberia de quedarse ese cliente
	 */
	setFcp(fcp: Fcp) {
		this.store.dispatch(fromFiltros.cambiarFcp({fcp}));
	}
	/**
	 * Articulo seleccionado, para que en todas las pantallas se pueda seguir con el mismo articulo, por ejemplo: Inventario >> inventarioDetalle
	 */
	setArt(art: Art) {
		this.store.dispatch(fromFiltros.cambiarArt({art: art}));
	}


}

export class PlanOadmin {
	descripcion: string = ''
	precio: number = 0
	codigo: string = ''
	constructor(obj: any = {}) {
		this.descripcion = obj.descripcion || this.descripcion
		this.precio = obj.precio || this.precio
		this.codigo = obj.codigo || this.codigo
	}

}


export let planesAnual = {
	"plan-starter": {
		descripcion: 'Plan Starter',
		precio: 0 * 11,
		codigo: 'plan-starter-anual'
	},
	"plan-emprendedor": {
		descripcion: 'Plan Emprendedor',
		precio: 357.99 * 11,
		codigo: 'plan-emprendedor-anual'
	},
	"plan-empresa": {
		descripcion: 'Plan Empresa',
		precio: 697.99 * 11,
		codigo: 'plan-empresa-anual'
	},

	"plan-total": {
		descripcion: 'Plan Total',
		precio: 987.99 * 11,
		codigo: 'plan-total-anual'
	},

	"plan-pos": {
		descripcion: 'Plan POS',
		precio: 179.99 * 11,
		codigo: 'plan-pos-anual'
	},

}



export let planesMensual = {
	"plan-starter": {
		descripcion: 'Plan Starter',
		precio: 0,
		codigo: 'plan-starter-mensual'
	},
	"plan-emprendedor": {
		descripcion: 'Plan Emprendedor',
		precio: 357.99,
		codigo: 'plan-emprendedor-mensual'
	},

	"plan-empresa": {
		descripcion: 'Plan Empresa',
		precio: 697.99,
		codigo: 'plan-empresa-mensual'
	},

	"plan-total": {
		descripcion: 'Plan Total',
		precio: 987.99,
		codigo: 'plan-total-mensual'
	},

	"plan-pos": {
		descripcion: 'Plan POS',
		precio: 179.99,
		codigo: 'plan-pos-mensual'
	},

}

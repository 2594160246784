import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthGuard } from '@root/auth/auth.guard';
import { ConexionService } from '@root/core/conexion.service';
import { DatosService } from '@root/core/datos.service';
import { GlobalService } from '@root/core/global.service';
import { MensajesService } from '@root/core/mensajes.service';
import { UiService } from '@root/core/ui.service';
import { Contacto } from '@root/models/contacto.model';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CrmService } from '../../crm.service';

@Component({
	selector: 'app-crm-contacto-bus',
	templateUrl: './crm-contacto-bus.component.html',
	styleUrls: ['./crm-contacto-bus.component.scss']
})
export class CrmContactoBusComponent implements OnInit, OnDestroy {
	sus: Subscription[] = []
	dataSource = new MatTableDataSource([])
	constructor(
		public ui: UiService,
		private route: ActivatedRoute,
		private con: ConexionService,
		private crm: CrmService,
		public g: GlobalService,
		private m: MensajesService,
		private ag: AuthGuard,
		public ds: DatosService,
	) {
		// this.sus.push(
		// 	this.ds._contactosObs.subscribe(c)
		// )
	}

	// async buscar() {
	// 	try {
	// 		let res = await this.con.getP('crm/contactos', true)
	// 		if (res.ok) {
	// 			res.data = res.data.map(con => {
	// 				return new Contacto(con);
	// 			});
	// 			this.dataSource = new MatTableDataSource(res.data)
	// 		}
	// 	} catch (error) {

	// 	}
	// }
	gestion(contacto: Contacto){
		Swal.fire('¡Ups!', 'La gestion de contactos aún no esta habilitada', 'info')
	}

	editarContacto(contacto = new Contacto()) {
		if (!this.ag.canActivate(null, null, `/inicio/crm/contactos-editar`)) return

		this.crm.contactoEdita(contacto);
	}

	ngAfterViewInit() {

	}

	ngOnDestroy(){
		this.sus.forEach(s=>s.unsubscribe())
	}

	ngOnInit() {
		this.route.data.pipe(first()).subscribe((data: any) => {

			this.ui.paginaActual = data.titulo || '';
		})
	}

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { ConexionService } from '@root/core/conexion.service';
import { DatosService } from '@root/core/datos.service';
import { Contacto } from '@root/models/contacto.model';
import { Fcp } from '@root/models/fcps.model';
import { CrmService } from '@root/modulos/crm/crm.service';
import { Pipeline } from '@root/modulos/crm/models/pipeline.model';
import { Trato } from '@root/modulos/crm/models/trato.model';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-trato-card',
	templateUrl: './trato-card.component.html',
	styleUrls: ['./trato-card.component.scss']
})
export class TratoCardComponent implements OnInit, OnDestroy {
	sus: Subscription[] = []
	@Input() trato: Trato
	contacto: Contacto = new Contacto()
	empresa: Fcp = new Fcp()
	pipe: Pipeline = new Pipeline

	constructor(
		public crm: CrmService,
		public con: ConexionService,
		public ds: DatosService,
		public store: Store<AppState>
	) {
		this.sus.push(
			this.store.select('pipeline').subscribe(pipe=>{
				if(pipe._id) this.pipe = new Pipeline(pipe)
			})
		)

	}

	ngOnInit() {

		this.empresa = this.ds.fcps.filter(f=>f._id === this.trato.idEmp || '')[0] || new Fcp()
		this.contacto = this.ds.contactos.filter(c=>c._id === this.trato.idContactos[0] || '')[0] || new Contacto()

	}

	ngOnDestroy(){
		this.sus.forEach(s=>s.unsubscribe())
	}


}

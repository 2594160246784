import { Fase } from "./fase.model"
import { Trato, TratoInfo } from './trato.model'

export class Pipeline {

	_id: string
	idUsu: string
	idUsuPropietario: string
	nombre: string = ''
	tratoInfo: TratoInfo[] = []
	fases: Fase[] = []

	constructor(obj: any = {}) {
		this._id = obj._id || this._id
		this.idUsu = obj.idUsu || this.idUsu
		this.idUsuPropietario = obj.idUsuPropietario || this.idUsuPropietario
		this.nombre = obj.nombre || this.nombre
		this.tratoInfo = obj.tratoInfo || this.tratoInfo
		this.fases = this.fases
		if(obj.fases && obj.fases.length)
			this.fases = obj.fases.map((f:Fase) => new Fase(f))
	}
	/**
	 * Pasa por todos los tratos y le pone el index y faseIndex que va,
	 * esto es cuando se hace drag&drop, refresca el pipeline pero no
	 * esos datos que despues son usados para otras cosas
	 */
	refrescarIndicesTratos(){
		let indicesTratos = []
		let indicesTratosBorrar: {iFase: number, i: number}[] = []
		this.fases.forEach((f,iFase)=>{
			f.tratos.forEach((t,iTrato)=>{
				t.index = iTrato
				t.indexFase = iFase
				if(indicesTratos.includes(t._id)) {
					indicesTratosBorrar.push({iFase: iFase, i: iTrato})
				} else {
					indicesTratos.push(t._id)
				}

			})
		})
		indicesTratosBorrar.forEach(t => {
			console.log('Entra indicesTratosBorrar!')
			this.fases[t.iFase].tratos.splice(t.i, 1)
		});
		if(indicesTratosBorrar.length) this.refrescarIndicesTratos()
	}

	/**
	 * Se le pasa un trato, lo busca, compara si esta en el indice correcto,
	 * si si sale si no lo borra y lo coloca en el index que va
	 * si no lo encuentra no hace nada
	 *
	 * @param trato trato a buscar y re-colocar
	 */
	refrescarIndiceTrato(trato:Trato){
		let indexFaseActual = -1
		let indexTratoActual = -1
		this.fases.forEach((f,iFase)=>{
			f.tratos.forEach((t,iTrato)=>{
				if(trato._id === t._id){
					//lo encontre
					if(trato.indexFase !== iFase){
						//No esta en la fase que el trato dice, guarda donde esta para borrarlo y ponerlo donde va
						indexFaseActual = iFase
						indexTratoActual = iTrato
					}
				}
			})
		})
		if(indexFaseActual != -1){
			//borra
			this.fases[indexFaseActual].tratos.splice(indexTratoActual, 1)
			//agrega
			this.fases[trato.indexFase].tratos.splice(0,0,trato)
		}
		//una vez corregido, escribe el indice que va en el resto de los tratos.
		this.refrescarIndicesTratos()
	}
}

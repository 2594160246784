export type periodoType = 'mensual' | 'anual' | 'unico';

export interface token {
  card: {
    address_city: string,
    address_country: string,
    address_line1: string,
    address_line1_check: string,
    address_line2: string,
    address_state: string,
    address_zip: string,
    address_zip_check: string,
    brand: string,
    country: string,
    cvc_check: string,
    dynamic_last4: string,
    exp_month: number,
    exp_year: number,
    funding: string,
    id: string,
    last4: string,
    metadata: {},
    name: string,
    object: string,
    tokenization_method: string,
  },
  client_ip: string,
  created: number,
  id: string,
  livemode: false,
  object: string,
  type: string,
  used: false,
}
export interface conceptoPago {
  codigo: string,
  descripcion: string,
  cantidad: number,
  precio: number,
  importe: number
}
export interface cardType {
  niceType: string,
  type: string,
  patterns: number[],
  gaps: number[],
  lengths: number[],
  code: {
    name: string,
    size: number
  }
}
export interface comprador {
  idUsu: string,
  nombre: string,
  email: string,
  direccion?: string
}
export interface vendedor {
  idUsu: string,
  nombre: string,
  email: string,
  direccion?: string
  tipo: 'usuario' | 'oadmin';
}
export class PagoCC {

  public comprador: comprador = {
    idUsu: '',
    email: '',
    nombre: ''
  }

  public vendedor: vendedor = {
    idUsu: '',
    email: '',
    nombre: '',
    tipo: 'usuario'
  }

  //DATOS TARJETA
  public numero: string = '';
  public tipo: cardType;
  public mes: string = '';
  public ano: string = '';
  public cvv: string = '';
  public token: token = {
    card: {
      address_city: null,
      address_country: null,
      address_line1: null,
      address_line1_check: null,
      address_line2: null,
      address_state: null,
      address_zip: null,
      address_zip_check: null,
      brand: "Visa",
      country: "US",
      cvc_check: "unchecked",
      dynamic_last4: null,
      exp_month: 6,
      exp_year: 2023,
      funding: "credit",
      id: "",
      last4: "4242",
      metadata: {},
      name: null,
      object: "card",
      tokenization_method: null
    },
    client_ip: "",
    created: 1567110843,
    id: "",
    livemode: false,
    object: "token",
    type: "card",
    used: false
  }
  //DATOS CONECPTO DE PAGO
  public importe: number = 0;
  public periodo: periodoType = 'unico';
  public conceptos: conceptoPago[] = [];


  constructor(obj?) {
    if (!obj) obj = {};
    this.comprador = obj.comprador || this.comprador;
    this.vendedor = obj.vendedor || this.vendedor;
    this.numero = obj.numero || '';
    if (obj.tipo) this.tipo = obj.tipo;
    this.mes = obj.mes || '';
    this.ano = obj.ano || '';
    this.cvv = obj.cvv || '';
    this.importe = obj.importe || 0;
    this.conceptos = obj.conceptos || [];
  }

}

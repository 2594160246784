import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { CrmService } from '@root/modulos/crm/crm.service';
import { Pipeline } from '@root/modulos/crm/models/pipeline.model';
import { Subscription } from 'rxjs';
import { Fase } from '../../../models/fase.model';

@Component({
	selector: 'app-fase-columna',
	templateUrl: './fase-columna.component.html',
	styleUrls: ['./fase-columna.component.scss']
})
export class FaseColumnaComponent implements OnInit, OnDestroy {
	sus: Subscription[] = []
	@Input() fase: Fase
	pipe: Pipeline = new Pipeline

	constructor(
		public store: Store<AppState>,
		public crm: CrmService,
	) {
		this.sus.push(
			this.store.select('pipeline').subscribe(pipe => {
				if (pipe._id) this.pipe = new Pipeline(pipe)
			})
		)

	}

	ngOnInit(): void {
	}
	ngOnDestroy() {
		this.sus.forEach(s => s.unsubscribe())
	}
}

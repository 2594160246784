import { estado } from './interfaces';
import { Art } from '@models/arts.model';
import { Sector } from './sectores.model';
import moment from 'moment';import { Emp } from './emps.model';

export class Inventario {

	_id?: string = '';
	idUsu: string = '';
	idSuc: string = '1';
	idEst: estado = estado.pendiente;
	idMdl: number = 90;
	numero: number = 0;
	emp: Emp = null;
	sector: Sector = Sector.predeterminado();
	sectorDestino: Sector = Sector.predeterminado();
	inventarioDetalle: InventarioDetalle[] = []
	fecEmi: moment.Moment = moment();
	fecApl: moment.Moment = moment();
	obs: string = '';
	baja: number = 0

	constructor(obj: any = {}) {
		this._id = obj._id || this._id
		this.numero = obj.numero || this.numero
		this.idUsu = obj.idUsu || this.idUsu
		this.idSuc = obj.idSuc || this.idSuc
		this.idMdl = obj.idMdl || this.idMdl
		this.idEst = obj.idEst || this.idEst
		this.emp = obj.emp || this.emp
		this.sector = obj.sector || this.sector
		this.sectorDestino = obj.sectorDestino || this.sectorDestino
		this.inventarioDetalle = obj.inventarioDetalle || this.inventarioDetalle
		this.fecEmi = moment(obj.fecEmi) || moment();
		this.fecApl = moment(obj.fecApl) || moment();
		this.obs = obj.obs || this.obs
		this.baja = obj.baja || this.baja
		if (!this._id) delete this._id
	}

}


export class InventarioDetalle {
	art: Art
	atributo1:string = '';
	atributo2:string = '';
	atributo3:string = '';
	atributo4:string = '';
	cantidad: number
	cantidadOriginal: number = 0
	cantidadAjuste: number = 0
	constructor(art: Art = new Art(), cantidad = 0) {
		this.art = art;
		this.cantidad = cantidad;
	}
}

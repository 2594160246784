// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.axCollContent-Container{
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.axCollContent-Fade{
  width: 100%;
  position: relative;
  top: -40px;
  height: 40px;
  position: relative;
  background: rgba(255,255,255,20%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,20%)), color-stop(100%, rgba(255,255,255,1)));
  background: linear-gradient(to bottom, rgba(255,255,255,20%) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
  transition: top 0.2s ease-out;
}

.axCollContent-button{
  position: relative;
  top: -9px;
}
`, "",{"version":3,"sources":["webpack://./src/app/directivas/components/axCollContent/axCollContent.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,iCAAiC;EAEjC,iIAAiI;EAIjI,0FAA0F;EAC1F,oHAAoH;EACpH,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,SAAS;AACX","sourcesContent":[".axCollContent-Container{\n  overflow: hidden;\n  transition: max-height 0.2s ease-out;\n}\n\n.axCollContent-Fade{\n  width: 100%;\n  position: relative;\n  top: -40px;\n  height: 40px;\n  position: relative;\n  background: rgba(255,255,255,20%);\n  background: -moz-linear-gradient(top, rgba(255,255,255,20%) 0%, rgba(255,255,255,1) 100%);\n  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,20%)), color-stop(100%, rgba(255,255,255,1)));\n  background: -webkit-linear-gradient(top, rgba(255,255,255,20%) 0%, rgba(255,255,255,1) 100%);\n  background: -o-linear-gradient(top, rgba(255,255,255,20%) 0%, rgba(255,255,255,1) 100%);\n  background: -ms-linear-gradient(top, rgba(255,255,255,20%) 0%, rgba(255,255,255,1) 100%);\n  background: linear-gradient(to bottom, rgba(255,255,255,20%) 0%, rgba(255,255,255,1) 100%);\n  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );\n  transition: top 0.2s ease-out;\n}\n\n.axCollContent-button{\n  position: relative;\n  top: -9px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

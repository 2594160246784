export class AlertasTipos {
	activo: boolean = false
	bloqueo: boolean = false
	onScreen: boolean = false
	push: boolean = false
	email: boolean = false
	parametro: string = ''
	descripcion: string = ''

	constructor(obj: any = {}) {
		this.activo = obj.activo || this.activo
		this.bloqueo = obj.bloqueo || this.bloqueo
		this.onScreen = obj.onScreen || this.onScreen
		this.push = obj.push || this.push
		this.email = obj.email || this.email
		this.parametro = obj.parametro || this.parametro
		this.descripcion = obj.descripcion || this.descripcion
	}
}

export class AlertasConfig {

	stockMinimo: AlertasTipos = new AlertasTipos({descripcion: 'Avisa cuando se llegue al stock minínimo.'})
	utilidadMinima: AlertasTipos = new AlertasTipos({descripcion: 'Evita agregar productos que no tengan la utilidad mínima ingresada.'})

	constructor(obj: any = {}) {
		this.stockMinimo = obj.stockMinimo || this.stockMinimo
		this.utilidadMinima = obj.utilidadMinima || this.utilidadMinima
	}
}

export class AlertasBloqueos {

	_id: string = '';
	idUsu: string = '';
	idEmp: string = '';
	alertasBloqueos: AlertasConfig = new AlertasConfig();

	constructor(obj: any = {}) {
		if(!obj) return;
		this._id = obj._id || this._id
		this.idUsu = obj.idUsu || this.idUsu
		this.idEmp = obj.idEmp || this.idEmp
		this.alertasBloqueos = obj.alertasBloqueos || this.alertasBloqueos
	}

}

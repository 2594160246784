import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//MATERIAL
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { axCollContentComponent } from './axCollContent.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
	  axCollContentComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    FlexLayoutModule,

  ],
  exports:[
	  axCollContentComponent
  ]
})
export class axCollContentModule { }

import { createReducer, on } from '@ngrx/store';
import * as fcvs from './fcvs.actions';
import { Fcv } from './fcvs.model';

const fcvLocal = JSON.parse(window.localStorage.getItem('fcv'));
let initialState: Fcv;

if (fcvLocal) {
	let xFcv = new Fcv({ ...fcvLocal });
	initialState = xFcv;
} else {
	initialState = new Fcv();
}

export const fcvsReducer = createReducer(
	initialState,
	on(fcvs.changeFcv, (state, { fcv }) => {
		const newFcv = new Fcv({ ...state, ...fcv })
		window.localStorage.setItem('fcv', JSON.stringify(newFcv))
		return newFcv
	})
);




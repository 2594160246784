import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from '@auth/auth.service';
import { GlobalService } from './global.service';
import { Usu } from '../models/usu.model';
import { accionTipoType } from '../models/interfaces';


@Injectable({
  providedIn: 'root'
})
export class TrackService {

  constructor(
    private http: HttpClient,
  ) { }

  trackAccion(accionTipo: accionTipoType, accion: string, data?: any, mdl?: string) {

    let authToken = window.localStorage.getItem('authToken') || '';
    if ( !authToken ) return;
    let url = environment.apiURL + 'tracking/';
    let usu: any = window.localStorage.getItem('usu');
    usu = usu ? JSON.parse(usu) : 'sin usuario';

    let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'authToken': authToken });
    if (usu['usu']) {
      usu = {
        _id: usu._id,
        nom: usu.nom,
        ape: usu.ape,
        usu: usu.usu,
        role: usu.role
      }
    }
    let dataEnvio = {
      mdl,
      accionTipo,
      accion,
      data: {
        usu,
        data
      }
    }

    this.http.post(url, dataEnvio, { headers }).subscribe();

  }


}

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CerosPipe } from './ceros.pipe';
import { FiltroPipe } from './filtro.pipe';
import { NumerosFormatoPipe } from './numerosFormato.pipe';
import { SafePipe } from './safe.pipe';
import { OrderByPipe } from './order.pipe';

@NgModule({
  declarations: [
    CerosPipe,
    FiltroPipe,
    NumerosFormatoPipe,
	SafePipe,
	OrderByPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    CerosPipe,
    FiltroPipe,
    NumerosFormatoPipe,
	SafePipe,
	OrderByPipe
  ],
  providers:[
	  DatePipe
  ]
})
export class PipesModule { }

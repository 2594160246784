import { createAction, props } from '@ngrx/store';

export const activarLoading = createAction('[UI] Activar Loading');
export const desactivarLoading = createAction('[UI] Desactivar Loading');
export const activarLoadingLogin = createAction('[UI] Activar Loading Login');
export const desactivarLoadingLogin = createAction('[UI] Desactivar Loading Login');
export const toggleSidebar = createAction('[UI] Toggle Sidebar', props<{ estado?: boolean }>());
export const pinSidebar = createAction('[UI] Pin Sidebar');

export type AccionesValidas =
  typeof activarLoading |
  typeof desactivarLoading |
  typeof activarLoadingLogin |
  typeof desactivarLoadingLogin |
  typeof toggleSidebar |
  typeof pinSidebar;

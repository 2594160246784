import { createAction, props } from '@ngrx/store';
import { Fcp } from './fcps.model';
import { Art } from './arts.model';

export const cambiarFiltrosComponente = createAction(
  '[Filtros] Cambiando filtros de Componente',
  props<{ componente: string; filtros: object }>()
);

export const cambiarFcp = createAction(
  '[Filtros] Cambiando FCP',
  props<{ fcp: Fcp }>()
);

export const cambiarArt = createAction(
  '[Filtros] Cambiando ART',
  props<{ art: Art }>()
);

export const resetFiltros = createAction('[Filtros] Reset Filtros');

export type AccionesValidas =
  | ReturnType<typeof cambiarFiltrosComponente>
  | ReturnType<typeof cambiarFcp>
  | ReturnType<typeof cambiarArt>
  | ReturnType<typeof resetFiltros>;
export const ResetFiltros = () =>  {
  throw new Error('Function not implemented.');
}


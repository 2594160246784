import { Emp } from './emps.model';
import { environment } from '../../environments/environment';
import { Region, Pais } from './interfaces';
import { Sector } from './sectores.model';

export class Sucursal {


  _id?: string;
  idUsu: string = ''
  idEmp: string = ''
  sector: Sector = Sector.predeterminado();
  codigo: string = ''
  sucursal: string = ''
  email: string = ''
  tel: string = ''
  numeroFactura: number = 0
  numeroTicket: number = 0
  numeroPago: number = 0
  numeroPedido: number = 0
  numeroCredito: number = 0
  numeroDebito: number = 0
  numeroAjusteInventario: number = 0
  numeroPaseInventario: number = 0
  numeroOrdenProduccion: number = 0
  numeroPaseFondos: number = 0
  fisDom: string = '';
  fisDomNro: string = '';
  fisDomInt: string = '';
  fisDomCodPos: string = '';
  fisDomCol: string = '';
  fisDomCiu: string = '';
  fisDomMun: string = '';
  fisDomRegion: Region = environment.pdt.region
  fisDomPais: Pais = environment.pdt.pais
  baja: number = 0

  constructor(obj: any = {}) {
    let central = false
    if (obj === 'CENTRAL') {
      central = true
      obj = {
        _id: '1',
        codigo: 'CEN',
        sucursal: 'CENTRAL',
        sector: null
      }
    }
    if (obj._id || central) { this._id = obj._id.toString() || ''; }
    if (!this._id && !central) delete this._id
    this.idUsu = obj.idUsu || this.idUsu;
    this.idEmp = obj.idEmp || this.idEmp;
    this.sector = obj.sector || this.sector;
    this.codigo = obj.codigo || this.codigo;
    this.sucursal = obj.sucursal || this.sucursal;
    this.email = obj.email || this.email;
    this.tel = obj.tel || this.tel;
    this.numeroPago = obj.numeroPago || this.numeroPago
    this.numeroFactura = obj.numeroFactura || this.numeroFactura
    this.numeroTicket = obj.numeroTicket || this.numeroTicket
    this.numeroPedido = obj.numeroPedido || this.numeroPedido
    this.numeroCredito = obj.numeroCredito || this.numeroCredito
    this.numeroDebito = obj.numeroDebito || this.numeroDebito
    this.numeroAjusteInventario = obj.numeroAjusteInventario || this.numeroAjusteInventario
    this.numeroPaseInventario = obj.numeroPaseInventario || this.numeroPaseInventario
    this.numeroOrdenProduccion = obj.numeroOrdenProduccion || this.numeroOrdenProduccion
    this.numeroPaseFondos = obj.numeroPaseFondos || this.numeroPaseFondos
    this.fisDom = obj.fisDom || this.fisDom
    this.fisDomNro = obj.fisDomNro || this.fisDomNro
    this.fisDomInt = obj.fisDomInt || this.fisDomInt
    this.fisDomCodPos = obj.fisDomCodPos || this.fisDomCodPos
    this.fisDomCol = obj.fisDomCol || this.fisDomCol
    this.fisDomCiu = obj.fisDomCiu || this.fisDomCiu
    this.fisDomMun = obj.fisDomMun || this.fisDomMun
    this.fisDomRegion = obj.fisDomRegion || this.fisDomRegion
    this.fisDomPais = obj.fisDomPais || this.fisDomPais
    this.baja = obj.baja || this.baja;
  }
}

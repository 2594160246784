import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthService } from '@root/auth/auth.service';
import { DatosService } from '@root/core/datos.service';
import { GlobalService } from '@root/core/global.service';
import { UiService } from '@root/core/ui.service';
import { Fcv } from '@root/models/fcvs.model';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { c_Exportacion, c_MetodoPago } from './cfdi4-catalogos';

@Component({
	selector: 'app-timbra-cfdi',
	templateUrl: './timbra-cfdi.component.html',
	styleUrls: ['./timbra-cfdi.component.scss']
})
export class TimbraCfdiComponent implements OnInit {

	environment = environment
	catalogoExportacion = c_Exportacion
	catalogoMetodoPago = c_MetodoPago
	fcv: Fcv = null
	constructor(
		public ui: UiService,
		public g: GlobalService,
		public ds: DatosService,
		private modal: MatDialogRef<TimbraCfdiComponent>,
		@Inject(MAT_DIALOG_DATA) data: { fcv: Fcv },
		private auth: AuthService,
	) {
		this.fcv = data.fcv
	}
	valida() {
		const fiscal = this.fcv.fiscal
		if (!fiscal.metodoPago?.codigo) {
			Swal.fire('¡Ups!', 'Seleccione un Método de Pago.', 'warning');
			return false;
		}
		if (!fiscal.formaPago?.fondoTipo?.codigo) {
			Swal.fire('¡Ups!', 'Seleccione la Forma de Pago.', 'warning');
			return false;
		}

		if (fiscal.formaPago.fondoTipo.codigo === '99' && fiscal.metodoPago.codigo === 'PUE') {
			Swal.fire('¡Ups!', 'Si la forma de pago es "Por definir", el Método de Pago no puede ser "PUE".', 'warning');
			return false;
		}
		if (!fiscal.usoCfdi?.codigo) {
			Swal.fire('¡Ups!', 'Seleccione la Uso del CFDi.', 'warning');
			return false;
		}


		return true
	}
	aceptar = () => {
		if (!this.valida()) return;
		this.modal.close(this.fcv)
	}

	cerrar = () => {
		this.modal.close({fiscal: null})
	}

	ngOnInit(): void {
	}

}

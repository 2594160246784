import { NgModule } from '@angular/core';
import { CrmRoutesModule } from './crm.routes';
import { CommonModule } from '@angular/common';
import { PipelineComponent } from './components/pipeline/pipeline.component';
import { PipelinesBusComponent } from './components/pipeline/pipelines-bus.component';
import { CrmComponent } from './components/crm.component';
import { FaseColumnaComponent } from './components/pipeline/fase-columna/fase-columna.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TratoCardComponent } from './components/pipeline/trato-card/trato-card.component';
import { MaterialModule } from '@root/material.module';
import { TratoPopComponent } from './components/trato-pop/trato-pop.component';
import { EditaTextoComponent } from './components/edita-texto/edita-texto.component';
import { FormsModule } from '@angular/forms';
import { DirectivasModule } from '@root/directivas/directivas.module'
import { PipesModule } from '@root/pipes/pipes.module'
import { TratoComponent } from './components/trato/trato.component';
import { TratoLateralComponent } from './components/trato/trato-lateral/trato-lateral.component';
import { TratoPrincipalComponent } from './components/trato/trato-principal/trato-principal.component';
import { CrmService } from './crm.service';
import { CrmContactoBusComponent } from './components/crm-contacto/crm-contacto-bus.component';
import { CrmContactoEditaComponent } from './components/crm-contacto/crm-contacto-edita.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import moment from 'moment';import { DetalleCardComponent } from './components/trato/trato-principal/detalle-card/detalle-card.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CrmTareasComponent } from './componets/crm-tareas/crm-tareas.component';
import { NgxLoadingModule } from 'ngx-loading';
import { NgApexchartsModule } from 'ng-apexcharts';

export function momentAdapterFactory() {
	return adapterFactory(moment);
};

@NgModule({
	declarations: [
		CrmComponent,
		PipelineComponent,
		PipelinesBusComponent,
		FaseColumnaComponent,
		TratoCardComponent,
		TratoPopComponent,
		EditaTextoComponent,
		TratoComponent,
		TratoLateralComponent,
		TratoPrincipalComponent,
		CrmContactoEditaComponent,
		DetalleCardComponent,
		CrmTareasComponent,
		CrmContactoBusComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		FlexLayoutModule,
		MaterialModule,
		CrmRoutesModule,
		DirectivasModule,
		PipesModule,
		NgxLoadingModule,
		NgApexchartsModule,
		NgxMaterialTimepickerModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: momentAdapterFactory,
		}),
	],
	exports: [
		CrmComponent,
		PipelineComponent,
	],
	providers: [
	]
})
export class CrmModule {
	constructor(private crm: CrmService) { }
}

import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { DatosService } from '@root/core/datos.service';
import { GlobalService } from '@root/core/global.service';
import { Usu } from '@root/models/usu.model';
import { CrmDetalle } from '@root/modulos/crm/models/crm-detalle.model';


@Component({
	selector: 'app-detalle-card',
	templateUrl: './detalle-card.component.html',
	styleUrls: ['./detalle-card.component.scss']
})
export class DetalleCardComponent implements OnInit, AfterViewInit {
	@Input('detalle') detalle: CrmDetalle = new CrmDetalle()
	@Output('editar') editarCrmDetalle: EventEmitter<CrmDetalle> = new EventEmitter()
	@Output('eliminar') eliminarCrmDetalle: EventEmitter<CrmDetalle> = new EventEmitter()
	@Output('marcarCompleta') marcarCompleta: EventEmitter<CrmDetalle> = new EventEmitter()
	@Output('marcarImportante') marcarImportante: EventEmitter<CrmDetalle> = new EventEmitter()
	@Output('marcarIncompleta') marcarIncompleta: EventEmitter<CrmDetalle> = new EventEmitter()

	usuarioAsignado = new Usu()

	constructor(
		public g: GlobalService,
		public ds: DatosService
	) {

	}
	importante() {
		this.marcarImportante.emit(this.detalle)
	}
	completa() {
		this.marcarCompleta.emit(this.detalle)
	}
	incompleta() {
		this.marcarIncompleta.emit(this.detalle)
	}
	editar() {
		this.editarCrmDetalle.emit(this.detalle)
	}
	eliminar() {
		this.eliminarCrmDetalle.emit(this.detalle)
	}
	ngAfterViewInit(){
		setTimeout(() => {
			this.usuarioAsignado = this.ds.usus.filter( u => u._id === this.detalle.idUsuAsignado )[0] || new Usu()
		}, 200);
	}
	ngOnInit(): void {

	}

}

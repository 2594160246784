// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.codigoBarra {
  width: 100%;
  height: 100%;
  position: relative;
}

.overlay {
  background-color: rgba(125, 125, 125, 0.8);
  position: absolute;
}

.cuadrado {
  position: absolute;
  border: solid rgb(255, 157, 0) 3px;
  text-align: center;
}

.instrucciones {
  position: absolute;
  color: rgb(153, 153, 153);
  top: 10%;
  width: 100%;
}

.botones {
  color: white;
  position: absolute;
  font-size: 45px;
}
.botones.boton-volver {
  bottom: 10%;
  left: 10%;
}
.botones.boton-volver .volver-texto {
  font-size: 16px;
  font-family: roboto;
  margin-top: 5px;
}
.botones.boton-camara {
  bottom: 10%;
  left: 46%;
}
.botones.boton-flash {
  bottom: 10%;
  right: 10%;
}
.botones:active {
  font-size: 47px;
}

.cargando {
  position: absolute;
  bottom: 50%;
  left: 46%;
  font-size: 45px;
  color: rgb(143, 143, 143);
}
.cargando.hidden {
  display: none;
}

:host zxing-scanner {
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modulos/codigo-barras/components/lector-codigo-barras/lector-codigo-barras.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,kBAAA;AACD;;AACA;EACC,0CAAA;EACA,kBAAA;AAED;;AAAA;EACC,kBAAA;EACA,kCAAA;EACA,kBAAA;AAGD;;AADA;EACC,kBAAA;EACA,yBAAA;EACA,QAAA;EACA,WAAA;AAID;;AAFA;EACC,YAAA;EACA,kBAAA;EACA,eAAA;AAKD;AAHC;EACC,WAAA;EACA,SAAA;AAKF;AAJE;EACC,eAAA;EACA,mBAAA;EACA,eAAA;AAMH;AAFC;EACC,WAAA;EACA,SAAA;AAIF;AAFC;EACC,WAAA;EACA,UAAA;AAIF;AAFC;EACC,eAAA;AAIF;;AADA;EACC,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;EACA,yBAAA;AAID;AAHC;EACC,aAAA;AAKF;;AADA;EACC,YAAA;EACA,WAAA;AAID","sourcesContent":[".codigoBarra {\n\twidth: 100%;\n\theight: 100%;\n\tposition: relative;\n}\n.overlay {\n\tbackground-color: rgba(125, 125, 125, 0.8);\n\tposition: absolute;\n}\n.cuadrado {\n\tposition: absolute;\n\tborder: solid rgb(255, 157, 0) 3px;\n\ttext-align: center;\n}\n.instrucciones {\n\tposition: absolute;\n\tcolor: rgb(153, 153, 153);\n\ttop: 10%;\n\twidth: 100%;\n}\n.botones {\n\tcolor: white;\n\tposition: absolute;\n\tfont-size: 45px;\n\n\t&.boton-volver {\n\t\tbottom: 10%;\n\t\tleft: 10%;\n\t\t.volver-texto {\n\t\t\tfont-size: 16px;\n\t\t\tfont-family: roboto;\n\t\t\tmargin-top: 5px;\n\t\t}\n\t}\n\n\t&.boton-camara {\n\t\tbottom: 10%;\n\t\tleft: 46%;\n\t}\n\t&.boton-flash {\n\t\tbottom: 10%;\n\t\tright: 10%;\n\t}\n\t&:active {\n\t\tfont-size: 47px;\n\t}\n}\n.cargando {\n\tposition: absolute;\n\tbottom: 50%;\n\tleft: 46%;\n\tfont-size: 45px;\n\tcolor: rgb(143, 143, 143);\n\t&.hidden {\n\t\tdisplay: none;\n\t}\n}\n// De la documentacion https://github.com/zxing-js/ngx-scanner/wiki/Advanced-Usage\n:host zxing-scanner {\n\theight: 100%;\n\twidth: 100%;\n\t//   object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

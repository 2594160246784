import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';

import { ContactosRoutesModule } from './contactos.routes';
import { ContactosBusComponent } from './components/contactos-bus/contactos-bus.component';
import { ContactosEditaComponent } from './components/contactos-edita/contactos-edita.component';
import { MaterialModule } from '../../material.module';
import { PipesModule } from '@pipes/pipes.module';
import { DirectivasModule } from '@directivas/directivas.module';
// import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@NgModule({
	declarations: [
		ContactosBusComponent,
		ContactosEditaComponent,
	],
	imports: [
		PipesModule,
		DirectivasModule,
		MaterialModule,
		CommonModule,
		ContactosRoutesModule,
		FlexLayoutModule,
		FormsModule,
	],
	exports: [
		ContactosBusComponent,
		ContactosEditaComponent
	]
})
export class ContactosModule { }

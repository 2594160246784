import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalService } from '@core/global.service';
import { AppState } from '../app.reducer';
import { Router } from '@angular/router';
import { Usu } from '@models/usu.model';
import { cerrarSesion, crearUsuario } from './auth.actions';
import { ConexionService } from '@core/conexion.service';
import { Subscription } from 'rxjs';

import { cambiarEmpresa } from '@root/models/emps.actions';
import { Emp } from '@models/emps.model';
// import * as socialAuth from "angularx-social-login";
import { WebsocketsService } from '@core/websockets.service';
import { UiService } from '@core/ui.service';
import { TrackService } from '../core/track.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { ErrorService } from '../core/errors.service';
import { Sucursal } from '../models/sucursales.model';
import * as fromFiltros from '@models/filtros.actions';
import { cambiarSucursal } from '../models/sucursales.acciones';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { activarLoading, desactivarLoading } from '@root/layout/ui.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  suscripciones: Subscription[] = [];
  usu: Usu = new Usu();
  emp: Emp = new Emp();
  suc: Sucursal = new Sucursal('CENTRAL');
  imgPerfil: string = '';
  primerMesVencido = false


  constructor(
    private g: GlobalService,
    private ui: UiService,
    private store: Store<AppState>,
    private router: Router,
    private trk: TrackService,
    private con: ConexionService,
    private socialAuth: SocialAuthService,
    private wSocket: WebsocketsService,
    private _http: HttpClient,
    private _errorS: ErrorService
  ) { }

  crearCuentaEmail(usu: Usu) {
    this.usu = usu;
    this.usu.usu = this.usu.email

    this.ui.loadingLogin(true)
    this.con.post(this.usu, 'crear-cuenta', true)
      .subscribe((usuDB) => {
        let usu: Usu = usuDB.data;
        this.usu = usu;
        this.login('EMAIL', usu.usu, usu.pass);
      }, () => this.ui.loadingLogin(false))
  }

  login(metodo: string, usu?: any, pass?: string) {

    this.ui.loadingLogin(true)

    let datos: any;
    switch (metodo) {
      case 'FACEBOOK':
        datos = { ...usu, metodo };

        this.con.postP(datos, 'login', true).then(data => {
          try {
            this.usu = new Usu(data.data);
            this.emp = new Emp(this.usu.emps[0]);
            this.store.dispatch(crearUsuario({ obj: this.usu }));
            this.store.dispatch(cambiarEmpresa({ emp: this.emp })); //en el futuro aca podraimos poner la predeterminada
            this.wSocket.registro(true, this.usu)
            this.irPaginaInicial()
          } catch (e) {
            this.store.dispatch(desactivarLoading());
          }
        }).catch(() => { this.ui.loadingLogin(false) })

        break;
      case 'GOOGLE':

        datos = { ...usu, metodo };

        this.con.postP(datos, 'login', true).then(data => {

          this.usu = new Usu(data.data)
          this.emp = new Emp(this.usu.emps[0]);
          this.store.dispatch(crearUsuario({ obj: this.usu }));
          this.store.dispatch(cambiarEmpresa({ emp: this.emp })); //en el futuro aca podraimos poner la predeterminada

          this.wSocket.registro(true, this.usu)
          this.irPaginaInicial()


        }).catch(() => { this.ui.loadingLogin(false) })

        break;
      case 'EMAIL':
        datos = { usu, pass, metodo }

        this.con.postP(datos, 'login', true).then(data => {

          this.usu = new Usu(data.data);
          this.emp = new Emp(this.usu.emps[0]);
          this.store.dispatch(crearUsuario({ obj: this.usu }));
          this.store.dispatch(cambiarEmpresa({ emp: this.emp })); //en el futuro aca podraimos poner la predeterminada
          this.wSocket.registro(true, this.usu);
          this.irPaginaInicial();

        }).catch(() => { this.ui.loadingLogin(false) })
        break;
      default: break
    }
  }

  loginAutomatico(usu) {

    this.ui.loadingLogin(true);
    this.store.dispatch(activarLoading());
    this.con.postP(usu, 'login-automatico').then(data => {
      this.usu = new Usu(data.data)

      this.emp = this.usu.emps[0];
      this.store.dispatch(crearUsuario({ obj: this.usu }));
      this.store.dispatch(cambiarEmpresa({ emp: this.emp })); //en el futuro aca podraimos poner la predeterminada
      this.wSocket.registro(true, this.usu)
      this.irPaginaInicial()

    }).catch(() => {
      this.store.dispatch(cerrarSesion())
      this.ui.loadingLogin(false);
      this.g.setDato('mensajeSesion', '');
    })
  }

  irPaginaInicial() {

    setTimeout(() => {
      this.ui.loadingLogin(false)
    }, 500);

    if (!this.usu) {
      console.log('Por alguna razon no hay usuario!');
      return;
    }

    this.trk.trackAccion('accion', 'Ir a Pagina Principal')

    if (this.usu.cantidadSesion < 2) {
      this.router.navigate(['asistente-inicial']);
    } else {
      let pagina = 'inicio/dashboard'
      switch (this.usu.config.pantallaInicio) {
        case 'pos':
          pagina = 'inicio/pos'; break;
        case 'dashboard-completo':
          pagina = 'inicio/dashboard'; break;
        case 'dashboard-sin-datos':
          pagina = 'inicio/dashboard-sin-datos'; break;
        default:
          pagina = 'inicio/dashboard'; break;
      }
      this.router.navigate([pagina]);
    }

  }

  cerrarSesion(mensaje = '') {
    this.trk.trackAccion('accion', 'Cierra Sesion');

    this.ui.loadingLogin(false);

    this.socialAuth.authState.subscribe((ok) => {
      if (ok) this.socialAuth.signOut();
    })

    this.usu = null;
    if (mensaje !== '') this.g.setDato('mensajeSesion', mensaje);

    let ruta = window.location.href;
    let url = ruta.split(';')[0];
    let pagina: any = url.split('/')
    pagina = pagina[pagina.length - 1];

    this.ui.loading(false)

    this.wSocket.registro(false)
    if (this.usu?._id) {
      this.store.dispatch(fromFiltros.ResetFiltros());
      this.store.dispatch(cerrarSesion());
    }

    if (!('login, pasarela-stripe, clave, registro, confirmacion-email, tyc, privacy').includes(pagina)) {
      this.router.navigate(['login']);
    }

  }

  /**
   * Para revisar la suscripcion on demand
   */
  revisarSuscripcion() {

    let authToken = this.g.getDato('authToken') || 'xxxx';
    const URL = environment.apiURL + 'oadmin/revisar-suscripcion/';

    let CABECERAS = new HttpHeaders({ 'Content-Type': 'application/json', 'authToken': authToken });

    this._http.get(URL, { headers: CABECERAS })
      .subscribe(
        (data: any) => {
          if (!data.ok) {
            Swal.fire('Suscripción', 'Hay un problema con su suscripción, vuelva a iniciar sesión.', 'error').then(() => this.cerrarSesion())
            return
          }
        },
        err => {
          if (err.status === 402) {
            this.primerMesVencido = true
            this._errorS.manejarError402(err)
          }

        }
      )

  }

  authListener() {

    console.log('authListener: ONLINE');
    this.suscripciones.push(this.store.select('usu').subscribe((usu: Usu) => {
      if (usu && usu._id) {
        this.usu = new Usu(usu);  // si hay un suaurio registrado en memoria,
        // lo carga para validaciones, y despues verifica
        // su validez para sacarlo o no sacarlo.


        this.wSocket.registro(true, usu)
        this.con.getP(`usus/${usu._id}`).then((usuDB) => {

          if (!usuDB.ok || usuDB.data._id !== usu._id) { this.cerrarSesion(); return; }
          this.usu = new Usu(usuDB.data);
          this.con.sGetImg(this.usu.img, 'perfiles').then(img => {
            // this.imgPerfil = '';
            this.imgPerfil = img;

          });

          this.iniciaRevisionEmpresa();

          this.revisarSuscripcion();

        }).catch((e) => {
          console.log(e);
          this.ui.loading(false)
          try {
            if (window.location.href.split('#')[1].split('/')[1] === "inicio") {
              this.cerrarSesion()
            }
          } catch (e) {
            this.cerrarSesion()
          }
        })

      } else {
        //Si no hay user y esta dentro cerramos sesion
        this.ui.loading(false)
        this.usu = null;
        try {
          if (window.location.href.split('#')[1].split('/')[1] === "inicio") {
            this.cerrarSesion()
          }
        } catch (e) {
          this.cerrarSesion()
        }
      }
    }));

    this.suscripciones.push(this.wSocket.listen('emps').subscribe((emp: any) => {
      if (emp._id) {
        this.store.dispatch(cambiarEmpresa({ emp }));
      }
    }))

    this.suscripciones.push(this.socialAuth.authState.subscribe((data) => {
      if (data?.provider === 'GOOGLE') this.login('GOOGLE', data)
    }))

  }

  iniciaRevisionEmpresa() {
    this.suscripciones.push(this.store.select('emp').subscribe((emp: Emp) => {
      if (emp) {
        this.emp = new Emp(emp);
      } else {
        console.log('Se cierra sesion por falta de empresa.');
        this.cerrarSesion('No se enconctró empresa.');
      }
    }));

    // Escucha cambios en la seleccion de susursal
    this.suscripciones.push(this.store.select('suc').subscribe((suc: Sucursal) => {
      if (suc && suc._id) {
        this.suc = new Sucursal(suc);
      } else {
        this.store.dispatch(cambiarSucursal({ obj: new Sucursal('CENTRAL') }));
      }
    }));
    // 	if (this.usu.idSuc && this.usu.idSuc.length > 5 && this.usu.idSuc !== suc._id) { //si tiene suc y esa suc no esta seleccionada en el redux
    // 		//tiene susucrsal restringida, hay que asignarla al auth
    // 		this.suc = this.ds.sucs.filter(s => s._id === this.usu.idSuc)[0]
    // 		this.store.dispatch(CambiarSucursalAction(this.suc))
    // 	}

  }



  ngOnDestroy() {
    this.suscripciones.forEach(s => s.unsubscribe())
  }

}

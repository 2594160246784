import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { FcvService } from '@root/core/facturacion/fcvs.service';
import { activarLoading, desactivarLoading } from '@root/layout/ui.actions';
import { Fcv } from '@root/models/fcvs.model';
import { Fpr } from '@root/models/fprs.model';
import { Respuesta } from '@root/models/respuesta.model';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ConexionService } from '../conexion.service';
import { FprService } from '../facturacion/fpr.service';
import { MxService } from '../facturacion/mx.service';
import { GlobalService } from '../global.service';
import { MensajesService } from '../mensajes.service';

@Injectable({
	providedIn: 'root'
})
export class CfdisService {
	suscripciones: Subscription[] = []

	constructor(
		private conexion: ConexionService,
		private store: Store<AppState>,
		private g: GlobalService,
		private modales: MensajesService,
		private fcvService: FcvService,
		private fprService: FprService,

	) {
		console.log('Cfdis service: online')
	}


	timbrar() {

	}

	async cancelar(type: 'fcv' | 'fpr') {

		if(!type) {
			throw new Error('Para cancelar debe de seleccionar fcv o fpr')
		}
		const cmp: Fcv | Fpr  =  await this.store.select((state: AppState) => state[type]).pipe(take(1)).toPromise()
		if (cmp.baja === 0) {
			Swal.fire('Error', 'Este comprobante sigue activo, para cancelarlo debe primero darlo de baja del sistema.', 'error')
			return
		}
		if (cmp.uuid.length < 5) {
			Swal.fire('Error', 'No e sposible cancelar en SAT un comprobante que no ha sido timbrado.', 'error')
			return
		}

		const resData = await this.modales.cancelarCfdiData(type)

		if(!resData) return

		if(resData instanceof Fcv) this.fcvService.save(resData)
		if(resData instanceof Fpr) this.fprService.save(resData)


		const mensaje = `<b>¿Desea cancelar el CFDi en el SAT?</b><br>
						 <br><b>UUID:</b><br> ${cmp.uuid}
						 <br><b>Motivo:</b><br> ${cmp.motivoCancelacion}
						 ${cmp.facRelUUID ? '<br><b>UUID Sustituto:</b><br>' + cmp.facRelUUID : '' }`

		const res = await this.g.SweetMsg('Cancelación', mensaje, 'question')
		if(!res.value) return
		this.store.dispatch(activarLoading())
		const response: Respuesta<any> = await this.conexion.postP(cmp, 'cfdi40/cancelar', true)
		if(response.ok) {
			Swal.fire('Cancelación', response.message, 'success')
		}

		this.store.dispatch(desactivarLoading())
	}


	async cartaPorte(fcv: Fcv) {

	}

	ngOnInit(): void {

	}

	ngOnDestroy(): void {

	}

}

import { Component, OnInit, Inject } from '@angular/core';
import { Art } from '@models/arts.model';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';import { ConexionService } from '@core/conexion.service';
import { MensajesService } from '@core/mensajes.service';
import { GlobalService } from '../../../core/global.service';

@Component({
  selector: 'app-articulos-popup',
  templateUrl: './articulos-popup.component.html',
  styleUrls: ['./articulos-popup.component.css']
})
export class ArticulosPopupComponent implements OnInit {

  arts: Art[] = []
  art: Art;

  constructor(
    private con: ConexionService,
    private m: MensajesService,
    public g: GlobalService,
    public modal: MatDialogRef<ArticulosPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.con.get('arts/7').subscribe(arts => {
      this.arts = arts.data
    });
  }
  nuevoArticulo() {
    this.m.nuevoArticulo()
      .then((value) => {
        const art = value as Art;
        if (!art) return;
        if (art['_id']) {
          this.seleccionarArticulo(art);
        } else {
          if (art) return;
        }
      })
  }

  regresar() {
    this.modal.close()
  }

  seleccionarArticulo(art: Art) {

    if (!art) { this.modal.close(false); }

    this.art = new Art(art);
    this.modal.close(this.art);

  }

  ngOnInit() { }

}

import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { Usu } from '@models/usu.model';
import { AuthService } from '@auth/auth.service';
import { Subscription } from 'rxjs';
import { ConexionService } from '@core/conexion.service';
import { SidebarService } from '../sidebar-pro/sidebar.service';
import { UiService } from '@core/ui.service';
import * as introjs from 'intro.js';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { cerrarSesion } from '../../auth/auth.actions';
import { Route, Router } from '@angular/router';
import { TrackService } from '@core/track.service';
import { SoporteService } from '@core/soporte.service';
import { MensajesService } from '../../core/mensajes.service';
import { GlobalService } from '../../core/global.service';



@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	providers: [MensajesService]
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('menuToggler') menuToggler: ElementRef;
	suscripciones: Subscription[] = []
	mostrarAlertaTrial = false
	usu: Usu;
	produccion: string
	isSideBarOpen = true;
	swComentarios
	testing = false;
	constructor(
		private store: Store<AppState>,
		private sb: SidebarService,
		public g: GlobalService,
		public auth: AuthService,
		public ui: UiService,
		public mensajes: MensajesService,
		public con: ConexionService,
		public san: DomSanitizer,
		private router: Router,
		private trk: TrackService,
		private _soporte: SoporteService
	) {
		this.produccion = environment.production ? '' : ''
	}

	toggleSideBar() {
		this.sb.setSidebarState(!this.sb.getSidebarState());
	}

	cerrarSesion() {
		this.store.dispatch(cerrarSesion())
	}

	cerrarAlertaTrial() {
		this.mostrarAlertaTrial = false;
		let seccionPrincipal: any = document.querySelector('#seccion-principal')
		seccionPrincipal.classList.add('mt-5');
	}

	comentarios() {
		Swal.fire(<any>{
			title: '¡Hablemos!',
			html: `
        <p>
          Cuéntanos que te parece y si hay algo que podamos mejorar
        </p>
        <textarea id="comentarios-textarea" class="comentarios"></textarea>
        <button id="comentarios-boton" class="btn btn-primary mt-4 btn-lg">
        Enviar
        </button>
        `,
			type: 'info',
			showConfirmButton: false,
			didOpen: () => {
				let comentariosBoton = document.querySelector('#comentarios-boton')
				let comentariosTextarea: any = document.querySelector('#comentarios-textarea')

				comentariosBoton.addEventListener('click', () => {

					this.enviarComentarios(comentariosTextarea.value)

					Swal.close()
				})


			}
		})
	}

	enviarComentarios(comentarios: string) {
		if (comentarios.length < 2) {
			return;
		}
		this.con.postP({ comentarios }, 'support/comentarios', true).then((res) => {

			Swal.fire('¡Bien!', 'Gracias por tomarte el tiempo de escribirnos.', 'success');

		}).catch(console.log)

	}

	sucursales() {
		this.mensajes.seleccionaSucursal();

	}

	soporte() {
		this._soporte.SolicitarSoporte()

	}
	tutorial() {

		if (this.router.url.slice(-6) !== 'inicio' && this.router.url.slice(-9) !== 'dashboard') {
			Swal.fire('¡Ups!', 'Esta página no tiene tutorial disponible por el momento, si tienes dudas contactanos. Estamos para ayudar.', 'success');

			return
		}

		let intro = introjs();
		// intro.start();

		intro.setOptions({
			steps: [
				{
					intro: "¡Bien llegaste! vamos a dar un pequeño paseo por esta pantalla."
				},
				{
					element: "#tuto-sidebar",
					intro:
						"Este es el menu donde encontrás acceso a todos los módulos del sistema.",
					position: "right"
				},
				{
					element: "#tuto-toggle-sidebar",
					intro:
						"Puedes ocultarlo o mostrarlo con este botón.",
					position: "right"
				},
				{
					element: "#tuto-estadistica",
					intro:
						"Aquí aparecerá una gráfica y datos relevantes de tu empresa.",
					position: "right"
				},
				{
					element: "#tuto-ultimos-comprobantes",
					intro:
						"En esta sección aparecerán los últimos comprobantes cargados.",
					position: "left"
				},
			],
			showProgress: true,
			skipLabel: "Saltar",
			doneLabel: "LISTO",
			nextLabel: "Siguiente",
			prevLabel: "Anterior",
			overlayOpacity: 0.7
		});
		intro.start();
	}

	async ngOnInit() {
		this.suscripciones.push(
			this.store.select('ui').subscribe(ui => this.isSideBarOpen = ui.isSideBar.open)
		)

		this.con.getP('system/prod').then(res => {
			// returns true if we are in production
			this.testing = !res.data
		})
	}
	ngAfterViewInit(): void {

		if (this.auth.usu.cantidadSesion === 1) {
			this.tutorial()
			this.trk.trackAccion('accion', 'Viendo Tutorial');
		}

	}

	ngOnDestroy() {
		this.suscripciones.forEach(s => s.unsubscribe);
	}

}

import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { ContentObserver } from '@angular/cdk/observers';
import { Subscription } from 'rxjs';


@Component({
  selector: 'axCollContent',
  templateUrl: './axCollContent.component.html',
  styleUrls: ['./axCollContent.component.css']
})
export class axCollContentComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('axContainer') axContainer: ElementRef;
  @ViewChild('axFade') axFade: ElementRef;
  @Input('minView') minView = null;
  @Input('auto') auto = true;
  @Input('iconUp') iconUp = 'angle-down';
  @Input('iconDown') iconDown = 'angle-up';

  arrowUp = false;
  hideArrow = true;
  hideFade = true;
  started = false

  dataChanges:Subscription = null;

  constructor(
    private el: ElementRef,
    private obs: ContentObserver
  ) { }

  coll() {
    if (this.axContainer.nativeElement.style.maxHeight !== this.minView + 'px') {
      this.hideFade = false;
      this.axContainer.nativeElement.style.maxHeight = this.minView + 'px'
      this.arrowUp = false;

    } else {
      this.hideFade = true;
      this.axContainer.nativeElement.style.maxHeight = this.axContainer.nativeElement.scrollHeight + 'px'
      this.arrowUp = true;
    }
  }

  checkView() {

    if (Number(this.minView) >= Number(this.axContainer.nativeElement.scrollHeight)) {

      setTimeout(( ) => {
        this.hideArrow = true;
        this.hideFade = true;
        this.axContainer.nativeElement.style.maxHeight = this.axContainer.nativeElement.scrollHeight + 'px';
      }, 2 );

    } else {

      setTimeout(( ) => {

        this.hideArrow = false;
        this.hideFade = false;
        this.axContainer.nativeElement.style.maxHeight = this.minView + 'px'
        this.arrowUp = false;

      }, 2 );

    }

  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.checkView();
    this.dataChanges = this.obs.observe(this.axContainer.nativeElement).subscribe((event: MutationRecord[]) => {
      console.log('Cambio Detectado.');
      if ( !this.auto ) return;
      this.checkView();
    });
    this.started = true;
  }

  ngOnDestroy(){
    this.dataChanges.unsubscribe();
  }
}



export class CmpCategoria {

	_id: string = '';
	codigo: string = '';
	categoria: string = '';
	concepto: string = '';
	mdls: number[] = [];
	cmps: any[] = [];
	total: number = 0;

	constructor(obj: any = {}, sinId=false) {
		this._id = obj._id  || this._id
		if(sinId) delete this._id
		this.codigo = obj.codigo || this.codigo
		this.categoria = obj.categoria || this.categoria
		this.concepto = obj.concepto || this.concepto
		this.mdls = obj.mdls || this.mdls
	}

	static predeterminado(){
		return new CmpCategoria()
	}

}

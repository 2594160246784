import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';import { Fcp } from '@models/fcps.model';
import { MensajesService } from '@core/mensajes.service';
import { DatosService } from '@core/datos.service';

@Component({
  selector: 'app-contactos-popup',
  templateUrl: './contactos-popup.component.html',
  styleUrls: ['./contactos-popup.component.css']
})
export class ContactosPopupComponent implements OnInit {

  fcp: Fcp;
  idMdl = 2;
  datos
  constructor(
    public ds: DatosService,
    private m: MensajesService,
    public modal: MatDialogRef<ContactosPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.datos = data
    this.idMdl = this.datos.idMdl;
  }
  nuevoCliente() {
    this.m.nuevoContacto( this.datos.idMdl ).then((value) => {
      const cte = value as Fcp;
      if (!cte) return;
      if (cte['_id']) {
        this.seleccionarCliente(cte);
      } else {
        if (cte) return;
      }
    })
  }

  regresar(){
      this.modal.close()
  }

  seleccionarCliente(fcp) {
    if (!fcp) { this.modal.close(false); }

    this.fcp = new Fcp(fcp);
    this.modal.close(this.fcp);
  }

  ngOnInit() { }

}

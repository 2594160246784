import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { DatosService } from '@core/datos.service';
import { GlobalService } from 'src/app/core/global.service';
import { ConexionService } from '@core/conexion.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AppState } from '../../../../app.reducer';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { UiService } from 'src/app/core/ui.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Sucursal } from '../../../../models/sucursales.model';

@Component({
	selector: 'app-sucursales-edita',
	templateUrl: './sucursales-edita.component.html',
	styleUrls: ['./sucursales-edita.component.scss'],

})
export class SucursalesEditaComponent implements OnInit, OnDestroy {
	@ViewChild('f') formulario: NgForm;

	isModal: boolean = false;
	suscripciones: Subscription[] = [];
	suc: Sucursal;

	cargando: boolean;

	regiones: Object[] = [];
	paises: Object[] = [];
	regsFiss: Object[] = [];
	fons: Object[] = [];

	termino: number

	constructor(
		private g: GlobalService,
		public ds: DatosService,
		private con: ConexionService,
		private snackBar: MatSnackBar,
		public ui: UiService,
		private route: ActivatedRoute,
		//CUANDO SE ABRE COMO DIALOGO
		public modal: MatDialogRef<SucursalesEditaComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		if (data.modal) {
			this.isModal = true;
			this.suc = new Sucursal(data.suc)
			if(this.suc._id==='1'){
				this.g.SweetMsg('¡Ups!', 'La central no se puede modificar, cualquier cambio a la CENTRAL que desee hacer hágalo desde Configuracion -> Mi Empresa', 'warning')
				this.modal.close()
			}
		}

	}
	async guardarNumeracion() {

		if ((this.suc.numeroFactura).toString() === '') this.suc.numeroFactura = 0
		if ((this.suc.numeroTicket).toString() === '') this.suc.numeroTicket = 0
		if ((this.suc.numeroCredito).toString() === '') this.suc.numeroCredito = 0
		if ((this.suc.numeroDebito).toString() === '') this.suc.numeroDebito = 0
		if ((this.suc.numeroPago).toString() === '') this.suc.numeroPago = 0
		if ((this.suc.numeroPedido).toString() === '') this.suc.numeroPedido = 0
		if ((this.suc.numeroAjusteInventario).toString() === '') this.suc.numeroAjusteInventario = 0
		if ((this.suc.numeroPaseInventario).toString() === '') this.suc.numeroPaseInventario = 0
		if ((this.suc.numeroOrdenProduccion).toString() === '') this.suc.numeroOrdenProduccion = 0
		if ((this.suc.numeroPaseFondos).toString() === '') this.suc.numeroPaseFondos = 0


		let respeusta = await this.g.SweetMsg('Numeración', '¿Desea actualizar la numeración?', 'question')

		if (!respeusta.value) return

		this.ui.loading(true);

		let res = null;
		try {

			res = await this.con.postP(this.suc, 'sucursales/numeracion', true)

		} catch (error) {
			this.ui.loading(false);
			return;
		}

		this.ui.loading(false);
		let nvaSuc = res.data
		this.snackBar.open('Numeración Actualizada!', 'OK', { duration: 2000 })

		if (this.isModal) {
			this.modal.close(nvaSuc);
			return;
		}
		this.suc = new Sucursal();
	}

	async guardar() {

		if (!this.g.revisaFormulario(this.formulario)) {
			this.g.SweetMsg('¡Ups!', 'Parece que hay algunos errores, complete los campos marcados en rojo.', 'warning');
			return;
		}


		this.ui.loading(true);

		let res = null
		try {

			res = await this.con.postP(this.suc, 'sucursales', true)

		} catch (error) {
			this.ui.loading(false);
			return;
		}

		this.ui.loading(false);
		let nvaSuc = res.data
		this.snackBar.open('¡Sucursal Guardada!', 'OK', { duration: 2000 })

		if (this.isModal) {
			this.modal.close(nvaSuc);
			return;
		}
		this.suc = new Sucursal();
	}

	async eliminar(suc: Sucursal) {

		suc = this.suc;

		let res = await this.g.SweetMsg('Eliminar', 'Al eliminar la sucursal, todos los movimientos de stock la sucursal pasarán a ser parte de la central ¿Desea eliminar la sucursal?', 'question')

		if (!res.value) return;

		try {

			suc.baja = 1;
			let sucRes = await this.con.postP(suc, `sucursales`, true);
			if (sucRes) this.snackBar.open('¡ Sucursal eliminada correctamente !', 'OK', { duration: 5000 })
			if( this.isModal ) this.modal.close()

		} catch (error) {
			console.log('Error al eliminar sucursal.',error)
		}
	}


	cancelar() {
		if (this.isModal) this.modal.close(true)
	}

	ngOnInit() {
		this.route.data.pipe(take(1)).subscribe((data: any) => {

			if (!this.isModal) this.ui.paginaActual = data.titulo || '';
		})

	}

	ngOnDestroy() {
		this.suscripciones.forEach(suscripcion => {
			suscripcion.unsubscribe();
		});
	}


}

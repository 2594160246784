// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-wrapper {
  height: 100vh;
}
.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-top: 0px;
  transition: padding-left 0.3s ease;
}
@media screen and (min-width: 768px) {
  .page-wrapper .page-content {
    padding-left: 210px;
  }
}
.page-wrapper .page-content .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: #000;
  opacity: 0.5;
  display: none;
}
@media screen and (max-width: 768px) {
  .page-wrapper .page-content .overlay.show {
    display: block;
  }
}
.page-wrapper .sidebar {
  left: 0px;
}
.page-wrapper.toggled .page-content {
  padding-left: 0;
}
.page-wrapper .attribution {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px;
  margin: 5px;
  letter-spacing: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/inicio/inicio.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,qBAAA;EACA,WAAA;EACA,gBAAA;EACA,kCAAA;AACJ;AACI;EANF;IAOI,mBAAA;EAEJ;AACF;AAAI;EACE,eAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;AAEN;AAEQ;EAFF;IAGI,cAAA;EACR;AACF;AAIE;EACE,SAAA;AAFJ;AAOI;EACE,eAAA;AALN;AASE;EACE,eAAA;EACA,SAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AAPJ","sourcesContent":[".page-wrapper {\n  height: 100vh;\n\n  .page-content {\n    display: inline-block;\n    width: 100%;\n    padding-top: 0px;\n    transition: padding-left .3s ease;\n\n    @media screen and (min-width: 768px) {\n      padding-left: 210px;\n    }\n\n    .overlay {\n      position: fixed;\n      top: 0;\n      right: 0;\n      bottom: 0;\n      left: 0;\n      z-index: 998;\n      background: #000;\n      opacity: .5;\n      display: none;\n\n      &.show {\n\n        @media screen and (max-width: 768px) {\n          display: block;\n        }\n      }\n    }\n  }\n\n  .sidebar {\n    left: 0px;\n  }\n\n  &.toggled {\n\n    .page-content {\n      padding-left: 0;\n    }\n  }\n\n  .attribution{\n    position: fixed;\n    bottom: 0;\n    right: 0;\n    padding: 5px;\n    margin: 5px;\n    letter-spacing: 1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit, OnDestroy } from '@angular/core';
import { UiService } from '@core/ui.service';
import { ConexionService } from '@core/conexion.service';
import moment from 'moment';moment.locale('es');
import { GlobalService } from '@core/global.service';
import { AuthService } from '@auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Router } from '@angular/router';
import { Pipeline } from '../models/pipeline.model';
import { CrmDetalle } from '../models/crm-detalle.model';
import { cambiarPipeline } from '../models/pipeline.acciones';
import { Fase } from '../models/fase.model';
import { DatosService } from '@root/core/datos.service';
import { Contacto } from '@root/models/contacto.model';
import { Fcp } from '@root/models/fcps.model';
import { Usu } from '@root/models/usu.model';
import { cambiarTrato } from '../models/trato.acciones';
import { Trato } from '../models/trato.model';
import { CrmService } from '../crm.service';



@Component({
	selector: 'app-crm',
	templateUrl: './crm.component.html',
	styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit, OnDestroy {

	testing = false;
	cargando = false;

	columnasDetalles: string[] = [
		'fecha',
		'agendado',
		'tipo',
		'contacto',
		'status',
	];


	pipes: Pipeline[] = []
	detalles: CrmDetalle[] = []
	constructor(
		public ui: UiService,
		private con: ConexionService,
		private ds: DatosService,
		public g: GlobalService,
		public auth: AuthService,
		private store: Store<AppState>,
		private router: Router,
		private crm: CrmService,
	) {
		if (this.ui.isMobile) this.columnasDetalles = ['mobile'];
		console.log('MODULO CRM: CARGADO...')
		this.buscar()

	}
	verPipe(pipe: Pipeline) {
		this.store.dispatch(cambiarPipeline({pipeline: pipe}))
		this.router.navigate(['inicio/crm/pipeline'])
	}
	async nuevoPipe() {
		let res: any;

		res = await this.g.SweetMsg('Nuevo Pipeline', '¿Creamos nuevo Pipeline?', 'question')
		if (!res.value) return;

		let pipe = new Pipeline()
		pipe.nombre = "Pipeline"
		pipe.fases = [
			new Fase({ fase: 'On Hold' }),
			new Fase({ fase: 'Prospecto Calificado' }),
			new Fase({ fase: 'Contacto Establecido' }),
			new Fase({ fase: 'Presentacion Agendada' }),
			new Fase({ fase: 'Esperando Pago' }),
		]
		try {
			res = await this.con.postP(pipe, 'crm/pipeline-guardar', true)
			if (res.ok) {
				pipe = new Pipeline(res.data)
				this.store.dispatch(cambiarPipeline({pipeline: pipe}))
				this.router.navigate(['inicio/crm/pipeline'])
			}
		} catch (error) {
			console.log(error)
		}

	}

	async verAccion(detalle: CrmDetalle) {

		let res = await  Promise.all([
			this.con.getP('crm/pipelines/' + detalle.idPipe),
			this.con.getP('crm/trato/' + detalle.idTrato)
		])
		this.crm.pipe = new Pipeline(res[0].data)
		this.crm.trato = new Trato(res[1].data)
		this.store.dispatch(cambiarPipeline({pipeline: this.crm.pipe}))
		this.store.dispatch(cambiarTrato({trato: this.crm.trato}))
		this.cargando = true
		setTimeout(() => {
		this.router.navigate(['inicio/crm/trato/', { id: this.crm.trato._id }])
			this.cargando = false
		}, 100);
	}
	async buscar() {
		this.g.cuentaTiempo('dashboard-CRM', 'i')
		this.ui.loading(false); //cancela el loading general
		this.cargando = true;

		let filtrosDetalle = {
			completado: false
		}

		let res = await Promise.all([
			this.con.getP(`crm/pipelines`),
			this.con.postP(filtrosDetalle,`crm/detalles-busqueda`),
		])
		this.cargando = false;

		this.pipes = res[0].data.map(p => {
			p.cantidadTratos = 0
			p.fases.forEach((f) => {
				p.cantidadTratos += f.tratos.length || 0
			})
			return p;
		});

		this.detalles = res[1].data.map((d:CrmDetalle) => {
			d = new CrmDetalle(d);
			d.meta = {}
			d.meta.contacto = this.ds.contactos.filter(c=>c._id === d.idContacto)[0] || new Contacto()
			d.meta.empresa = this.ds.fcps.filter(f=>f._id === d.idEmp)[0] || new Fcp()
			d.meta.usuario = this.ds.usus.filter(u=>u._id === d.idUsuAsignado)[0] || new Usu()
			return d
		});
		this.g.cuentaTiempo('dashboard-CRM', 'f')


	}
	test() {

		this.con.local()

	}
	ngOnInit() {

	}
	ngOnDestroy() {
	}


}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth/auth.guard';
import { Resolver } from '../../resolver';
import { ContactosBusComponent } from './components/contactos-bus/contactos-bus.component';
// import { ContactosComponent } from './components/contactos.component';

const rutas: Routes = [
  {
    path: 'clientes-busqueda',
    component: ContactosBusComponent,
    canActivate: [AuthGuard], resolve: { datosCargados: Resolver },
    data: { titulo: 'Clientes', idMdl: 2 }
  },
  {
    path: 'proveedores-busqueda',
    component: ContactosBusComponent,
    canActivate: [AuthGuard], resolve: { datosCargados: Resolver },
    data: { titulo: 'Proveedores', idMdl: 3 }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(rutas)
  ],
  exports: [
    RouterModule
  ]
})
export class ContactosRoutesModule { }


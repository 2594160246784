import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SidebarService } from './sidebar.service';
import { MenuSystem } from '@models/menu.model';
import { UiService } from '@core/ui.service';
import { AuthService } from '@auth/auth.service';
import { GlobalService } from '@core/global.service';
import Swal from 'sweetalert2';
import versionJSON from '../../../assets/version.json'


@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
	animations: [
		trigger('slide', [
			state('up', style({ height: 0 })),
			state('down', style({ height: '*' })),
			transition('up <=> down', animate(200))
		])
	]
})
export class SidebarComponent implements OnInit {

	// SOLO PARA PRUEBAS, PONEMOS UN VECTOR CON POSICIONES DE MENSAJES
  mensajes = []
  version = versionJSON.version
  build = versionJSON.build

	constructor(
		public sbs: SidebarService,
		public ui: UiService,
		public auth: AuthService,
		public g: GlobalService,
	) {

	}
  verVersion(){
    Swal.fire(<any>{
      title: 'Version', html:`Version Sistema: ${ this.version }<br>Build Sistema: ${ this.build }`, type:'success',
      showCancelButton: true,
      cancelButtonText: 'Ok',
      confirmButtonText: 'Actualizar'
    }).then(res => {
      if( res.value ) {
        location.reload();
      }
    })
  }



	getSideBarState() {
		return this.sbs.getSidebarState();
	}

	toggle(currentMenu:MenuSystem) {

		if( currentMenu.link && this.ui.isMobile ) {
			this.sbs.toggle();
		}
		if ( currentMenu.tipo === 'dropdown' || currentMenu.tipo === 'no-dropdown' ) {
			this.sbs.menus.forEach(menu => {
				if (menu === currentMenu) {
					currentMenu.active = !currentMenu.active;
				} else {
					menu.active = false;
				}
			});
		}
	}

	getState(currentMenu) {

		if (currentMenu.active) {
			return 'down';
		} else {
			return 'up';
		}
	}

	hasBackgroundImage() {
		return this.sbs.hasBackgroundImage;
	}
  ngOnInit() {
    this.sbs.init()
	}

}

import { Region, Pais, RegFis, MetodosLogin } from './interfaces';
import { environment } from 'src/environments/environment';
import { Usu } from './usu.model';
import { Fondo } from './fondo.model';

export interface FcpUsu {
	metodo: MetodosLogin,
	usu: string,
	pass: string,
	activo: boolean,
	token: string,
	tokenFB_GL?: string
}
export interface RecordatorioPago {
	unidad: 'semanas' | 'meses' |  'dias'
	cantidad: number
}


export class Fcp {

	public _id: string = '';
	public idUsu: string = '';
	public idMdl: number = 2;
	public idFcp: string = '';
	public codigo: string = '';
	public nom: string = '';
	public ape: string = '';
	public fcp: string = '';
	public rso: string = '';
	public rfc: string = '';
  idExtranjeroFiscal?: string;

	public email: string = '';
	// IMPUESTOS--------------------------------.
	public retenciones: boolean = false;
	public impuestosExento: boolean = false;
	// -----------------------------------------.
	public regFis: RegFis = environment.pdt.regFis;
	public fon: Fondo = Fondo.predeterminado();
	public cuentaCorriente: boolean = false;
	public ivaIncluido: boolean = false;
	public tel: string = '';
	public usu: FcpUsu = {
		metodo: 'EMAIL',
		usu: '',
		pass: '',
		activo: true,
		token: '',
		tokenFB_GL: '',
	};
	public recordatoriosPagos: RecordatorioPago[] = [];
	public fisDom: string = '';
	public fisDomNro: string = '';
	public fisDomInt: string = '';
	public fisDomCodPos: string = '';
	public fisDomCol: string = '';
	public fisDomCiu: string = '';
	public fisDomMun: string = '';
	public fisDomRegion: Region = environment.pdt.region;
	public fisDomPais: Pais = environment.pdt.pais;
	public envDom: string = '';
	public envDomNro: string = '';
	public envDomInt: string = '';
	public envDomCodPos: string = '';
	public envDomCol: string = '';
	public envDomCiu: string = '';
	public envDomMun: string = '';
	public envDomRegion: Region = environment.pdt.region;
	public envDomPais: Pais = environment.pdt.pais;
	public envDomObservaciones: string = '';
	public baja: number = 0;

	constructor(obj: any = {}) {
		this._id = obj._id || this._id;
		this.idUsu = obj.idUsu || this.idUsu;
		this.idMdl = obj.idMdl || this.idMdl;
		this.idFcp = obj.idFcp || this.idFcp;
		this.codigo = obj.codigo || this.codigo;
		this.nom = obj.nom || this.nom;
		this.ape = obj.ape || this.ape;
		this.fcp = obj.fcp || this.fcp;
		this.rso = obj.rso || this.rso;
		this.rfc = obj.rfc || this.rfc;
		this.idExtranjeroFiscal = obj.idExtranjeroFiscal || this.idExtranjeroFiscal;
		this.email = obj.email || this.email;
		this.retenciones = obj.retenciones || this.retenciones;
		this.impuestosExento = obj.impuestosExento || this.impuestosExento;
		this.regFis = obj.regFis || environment.pdt.regFis;
		this.fon = obj.fon || this.fon;
		this.cuentaCorriente = obj.cuentaCorriente || this.cuentaCorriente;
		this.ivaIncluido = obj.ivaIncluido || this.ivaIncluido;
		this.tel = obj.tel || this.tel;
		this.usu = obj.usu || this.usu;
		this.recordatoriosPagos = obj.recordatoriosPagos || this.recordatoriosPagos;
		this.fisDom = obj.fisDom || this.fisDom;
		this.fisDomNro = obj.fisDomNro || this.fisDomNro;
		this.fisDomInt = obj.fisDomInt || this.fisDomInt;
		this.fisDomCodPos = obj.fisDomCodPos || this.fisDomCodPos;
		this.fisDomCol = obj.fisDomCol || this.fisDomCol;
		this.fisDomCiu = obj.fisDomCiu || this.fisDomCiu;
		this.fisDomMun = obj.fisDomMun || this.fisDomMun;
		this.fisDomRegion = obj.fisDomRegion || this.fisDomRegion;
		this.fisDomPais = obj.fisDomPais || this.fisDomPais;
		this.envDom = obj.envDom || this.envDom;
		this.envDomNro = obj.envDomNro || this.envDomNro;
		this.envDomInt = obj.envDomInt || this.envDomInt;
		this.envDomCodPos = obj.envDomCodPos || this.envDomCodPos;
		this.envDomCol = obj.envDomCol || this.envDomCol;
		this.envDomCiu = obj.envDomCiu || this.envDomCiu;
		this.envDomMun = obj.envDomMun || this.envDomMun;
		this.envDomRegion = obj.envDomRegion || this.envDomRegion;
		this.envDomPais = obj.envDomPais || this.envDomPais;
		this.envDomObservaciones = obj.envDomObservaciones || this.envDomObservaciones;
		this.baja = obj.baja || this.baja;
	}

	public setValoresPreferenciaUsuario(usu: Usu = null) {
		if (!usu || this._id) return;
		this.fon = usu.preferencias.fon || this.fon
		this.fisDomPais = usu.preferencias.pais || this.fisDomPais
		this.fisDomRegion = usu.preferencias.region || this.fisDomRegion
		this.regFis = usu.preferencias.regFis || this.regFis
	}

	public static getDomicilioFiscal(fcp: Fcp) {
		try {
			if (!fcp.fisDom || !fcp.fisDomNro) return 'N/A'

			let domicilio = fcp.fisDom + ' No. ' + fcp.fisDomNro;
			if (fcp.fisDomInt) {
				domicilio += ' Int. ' + fcp.fisDomInt;
			}
			return domicilio;
		} catch (err) {
			console.log(err);
			return 'N/D'
		}
	}



	public static getLocalidadFiscal(fcp: Fcp) {
		try {
			let localidad = fcp.fisDomCiu + ', ' + fcp.fisDomMun;
			if (!fcp.fisDomCiu || !fcp.fisDomMun) localidad = '';

			let region = fcp.fisDomRegion.region ? fcp.fisDomRegion.region.charAt(0).toUpperCase() + fcp.fisDomRegion.region.toLowerCase().slice(1) : ''
			if (region.length < 1) region = '-';

			let pais = fcp.fisDomPais.name ? fcp.fisDomPais.name.charAt(0).toUpperCase() + fcp.fisDomPais.name.toLowerCase().slice(1) : ''
			if (pais.length < 1) pais = '-';


			return `${fcp.fisDomCodPos ? 'C.P. '+ fcp.fisDomCodPos + ', ': '' }${localidad}, ${region}, ${pais}`;
		} catch (err) {
			console.log(err);
			return 'N/D'
		}
	}

	public getDomicilioFiscal() {
		try {
			let fcp = this

			if (!fcp.fisDom || !fcp.fisDomNro) return 'N/A'

			let domicilio = fcp.fisDom + ' No. ' + fcp.fisDomNro;
			if (fcp.fisDomInt) {
				domicilio += ' Int. ' + fcp.fisDomInt;
			}
			return domicilio;
		} catch (err) {
			console.log(err);
			return 'N/D'
		}
	}
	public getLocalidadFiscal() {
		let fcp = this
		try {
			let localidad = fcp.fisDomCiu + (fcp.fisDomMun ? ', '+ fcp.fisDomMun : '');
			if (!fcp.fisDomCiu) localidad = '';

			let region = fcp.fisDomRegion.region ? fcp.fisDomRegion.region.charAt(0).toUpperCase() + fcp.fisDomRegion.region.toLowerCase().slice(1) : ''
			if (region.length < 1) region = '-';

			let pais = fcp.fisDomPais.name ? fcp.fisDomPais.name.charAt(0).toUpperCase() + fcp.fisDomPais.name.toLowerCase().slice(1) : ''
			if (pais.length < 1) pais = '-';


			return localidad + ', ' + region + ', ' + pais;
		} catch (err) {
			console.log(err);
			return 'N/D'
		}
	}
}

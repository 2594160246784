import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { GlobalService } from '@root/core/global.service';
import { SidebarService } from '@root/layout/sidebar-pro/sidebar.service';
import { Subscription } from 'rxjs';
import { Pipeline } from '../../models/pipeline.model';
import { CrmService } from '../../crm.service';
import { Fase } from '../../models/fase.model';
import moment from 'moment';import { SnackBarService } from '@root/core/snackbar.service';
import { cambiarPipeline } from '../../models/pipeline.acciones';
import { ConexionService } from '@root/core/conexion.service';
import { CrmDetalle } from '../../models/crm-detalle.model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-pipeline',
	templateUrl: './pipeline.component.html',
	styleUrls: ['./pipeline.component.scss']
})
export class PipelineComponent implements OnInit, OnDestroy {

	subs: Subscription[] = []
	pipe: Pipeline

	constructor(
		public sb: SidebarService,
		public con: ConexionService,
		public snack: SnackBarService,
		public g: GlobalService,
		public crmS: CrmService,
		private store: Store<AppState>,
		private router: Router,
	) {
		this.subs.push(this.store.select('pipeline').subscribe(pipeline => {
			this.pipe = new Pipeline(pipeline)
			if (pipeline._id) this.buscarEventosVencidos()
		}))
		this.sb.setSidebarState(true);
	}

	agregarFase(i?: number) {
		if (this.pipe.fases.length >= 11) {
			this.snack.open('Por el momento no puede agregar más fases.', 'OK', 3000);
			return;
		}
		if (typeof i === 'undefined') {
			this.pipe.fases.push(new Fase({
				fase: 'Nueva Fase'
			}))
		} else {
			if (i === this.pipe.fases.length) {
				this.pipe.fases.push(new Fase({
					fase: 'Nueva Fase'
				}))
			} else {
				this.pipe.fases.splice(i, 0, new Fase({
					fase: 'Nueva Fase'
				}))
			}
		}
		this.store.dispatch(cambiarPipeline({pipeline: this.pipe}))

	}
	cambiarNombre() {
		this.crmS.editarNombrePipeline()
	}
	async borrarPipe() {
		let res = await this.g.SweetMsg('Borrar Pipeline', '¿Desea eliminar el Pipeline? <br>Perderá toda la información que se encuentre dentro del pipeline (tratos, notas acciones, fases, etc...). <br><br><b> ESTA INFORMACIÓN NO SE PUEDE RECUPERAR </b>', 'question')
		if (!res.value) return

		let res2 = await this.con.postP(this.pipe, 'crm/pipeline-eliminar', true)
		if (res2.ok) {
			this.pipe = new Pipeline()
			this.store.dispatch(cambiarPipeline({pipeline: this.pipe}))
			this.router.navigate(['/inicio/crm/pipelines-buscar'])
		}

	}
	async eliminarFase(i: number) {
		if (this.pipe.fases.length === 1) {
			this.snack.open('Por lo menos debe de existir una fase', 'OK', 3000);
			return;
		}
		let res = await this.g.SweetMsg('Borrar Fase', '¿Desea eliminar esta fase? Los tratos se pasarán a la primera.', 'question')
		if (!res.value) return

		let tratos = this.pipe.fases[i].tratos
		this.pipe.fases.splice(i, 1)
		this.pipe.fases[0].tratos.splice(0, 0, ...tratos)
		this.store.dispatch(cambiarPipeline({pipeline: this.pipe}))

	}
	async buscarEventosVencidos() {
		let res = await this.con.getP('crm/detalles/pipeline/' + this.pipe._id)
		if (res.ok && res.data) {
			this.crmS.crmDetalles = (<CrmDetalle[]>res.data).map(d => new CrmDetalle(d))
		}
		if (this.crmS.crmDetalles) {
			let vencidos = this.crmS.crmDetalles.filter(d => {
				if (
					moment().diff(d.fechaAgenda, 'minutes') > 1 &&
					!d.completado &&
					d.tipo == 'accion'
				) return true
				return false
			})
			if(vencidos){
				vencidos.forEach(v => {
					this.pipe.fases.forEach(f=>{
						f.tratos.forEach(t=>{
							if(t._id === v.idTrato){
								t.detallesVencidos = true
							}
						})
					})
				})
			}
		}
	}
	async ngOnInit() {

	}

	ngOnDestroy(): void {
		this.subs.forEach(s => s.unsubscribe())
	}

}


import { Directive, ElementRef } from '@angular/core';
import { AbstractControl, FormGroup, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

function validaClaves() {
	return (control: AbstractControl, el: ElementRef): any => {
		let camposEvaluar = (el.nativeElement.attributes.ClavesIguales.value)
		camposEvaluar = camposEvaluar.split(',');		
		
	    let isValid = false;
	    if (control && control instanceof FormGroup) {
			const group = control as FormGroup;
			if(group.controls[camposEvaluar[0]] && group.controls[camposEvaluar[1]]){
				isValid = (group.controls[camposEvaluar[0]].value === group.controls[camposEvaluar[1]].value) 
							&& typeof(group.controls[camposEvaluar[0]].value) !== 'undefined';
	       	}
		}
		return isValid ? null : { 'ClavesIguales': 'fallo' };
	}  
}

@Directive({
  selector: '[ClavesIguales]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ClavesIgualesDirective, multi: true }]
})
export class ClavesIgualesDirective implements Validator {
  private valFn: any;

  constructor(private el: ElementRef) {
    this.valFn = validaClaves();
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.valFn(c, this.el);
  }

}

import { Trato } from "./trato.model";

export class Fase {

	_id: string = '';
	fase: string = '';
	tratos: Trato[] = [];

	constructor(obj: any = {}) {
		this._id = obj._id || this._id;
		this.fase = obj.fase || this.fase;
		this.tratos = obj.tratos || this.tratos;
	}

	getTotal(){
		let total = 0
		this.tratos.forEach((t)=>{ total += t.total })
		return total
	}

}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2'
import { NgForm } from '@angular/forms';
import { ChartOptions } from 'chart.js';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { first } from 'rxjs/operators';
import moment from 'moment';import { DatePipe } from '@angular/common';


interface Cmp {
	nombre: string,
	nombreAbr: string
	abr: string,
	idMdl: number,
	ruta: string
}
@Injectable({
	providedIn: 'root'
})
export class GlobalService {

	memoriaCuentaTiempo = {}

	//OPCIONES POR DEFECTO PARA GRAFICAS
	public GraficaOpcionesDefault: any = {
		responsive: true,
		maintainAspectRatio: false,
		scales: { xAxes: [{}], yAxes: [{}] },
		plugins: {
			datalabels: {
				anchor: 'end',
				align: 'end',
			}
		}
	};

	constructor(
		private snackBar: MatSnackBar,
		private http: HttpClient,
		private store: Store<AppState>,
		private datePipe: DatePipe,
	) { }

	cuentaTiempo(nombreEvento: string, inicioFin: 'i' | 'f') {
		if (inicioFin === 'i') {
			this.memoriaCuentaTiempo[nombreEvento] = new Date().getTime()
			return this.memoriaCuentaTiempo[nombreEvento];
		} else {
			let tardo = (new Date().getTime()) - this.memoriaCuentaTiempo[nombreEvento]
			console.log(` ---> ${nombreEvento} tardo: ${tardo} ms`)
			delete this.memoriaCuentaTiempo[nombreEvento]
			return tardo;
		}
	}

	/**
	 * La idea es que se llame desde el HTML donde queremos levantar un mensaje con la información
	 * @param info Strin de la informacion que se queire dar
	 */
	info(info: string) {
		Swal.fire('Información', info, 'info');
	}

	/**
	 * Devuelve objecto sin referencia
	 * @param objeto Objeto a copiar sin referencia
	 */
	deepCopy(objeto: Object) {
		return JSON.parse(JSON.stringify(objeto))
	}

	/**
	 * recibe un string, reemplaza ñ y acentos por sus equivalentes, esto para hacer comparacion de caracteres en busquedas
	 * @param str string que se va a normalizar
	 * @returns string normalizado
	 */
	sacarAcentos(str) {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	}



	/**
	 * Ordena un arreglo por una propiedad dada de forma ascendente o descendente comparandolo como numero
	 * @param arregloParaOrdenar Arreglo a ordenar
	 * @param propiedad Propiedad por la cual se va a ordenar
	 * @param orden si es ascendente o descendente
	 */
	ordenarArreglo(arregloParaOrdenar: any[], propiedad: string, orden: 'ASC' | 'DESC'): any[] {
		let xOrden = 1;
		if (orden === 'ASC') xOrden = -1;

		let propiedades = []
		if (propiedad.indexOf('.') > -1) {
			propiedades = propiedad.split('.')
			switch (propiedades.length) {
				case 2:
					return arregloParaOrdenar.sort((a, b) => (a[propiedades[0]][propiedades[1]] < b[propiedades[0]][propiedades[1]]) ? xOrden : xOrden * -1)
				case 3:
					return arregloParaOrdenar.sort((a, b) => (a[propiedades[0]][propiedades[1]][propiedades[2]] < b[propiedades[0]][propiedades[1]][propiedades[2]]) ? xOrden : xOrden * -1)
				default:
					console.error('La funcion de orden de arreglo puede buscar hasta tres propiedades solamente.')
			}
		}

		return arregloParaOrdenar.sort((a, b) => (a[propiedad] < b[propiedad]) ? xOrden : xOrden * -1)

	}

	/**
	 * Esta funcion es un override del dataAccessor predeterminado de MatSort,
	 * el dataAccessor es el encargado de acceder a los valores de la data del matTable
	 * Cuando accede es case sensitive y no respeta el orden.
	 * Este data accessor aparte de poner todo a lowerCase (solo para el orden, no cambia la data), tambien accede a propiedades internas (nested props)
	 *
	 * Para usarlo this.dataSoruce.sortingDataAccessor = this.g.sortingDataAccessor
	 * Usando nested properties agregar `mat-sort-header="propertie.nested-propertie"` en el th del matTable
	 *
	 */
	sortingDataAccessor(data, sortHeaderId) {
		let value = data[sortHeaderId] ? data[sortHeaderId].toLocaleLowerCase() : '';
		if (sortHeaderId.indexOf('.') > -1) {
			value = sortHeaderId.split('.').reduce((prevObject, currProp) => {
				return prevObject[currProp] || ''
			}, data)
		}
		return value
	}

	/**
	 * Se le pasa el id de un modulo y devuelve el nombre
	 */
	mdl(idMdl?: number | string): Cmp | any {

		let mdls = {
			'2': { ruta: 'clientes', nombreAbr: 'Cliente', nombre: 'Cliente', abr: 'Cte', idMdl: 2 },
			'3': { ruta: 'proveedores', nombreAbr: 'Proveedor', nombre: 'Proveedor', abr: 'Prv', idMdl: 3 },
			'4': { ruta: 'prospectos', nombreAbr: 'Prospecto', nombre: 'Prospecto', abr: 'Lead', idMdl: 4 },
			'70': { ruta: 'ventas-facturas', nombreAbr: 'Factura', nombre: 'Factura', abr: 'Fac', idMdl: 70 },
			'70.1': { ruta: 'ventas-ticket', nombreAbr: 'Ticket', nombre: 'Ticket', abr: 'TIC', idMdl: 70.1 },
			'71': { ruta: 'ventas-notas-credito', nombreAbr: 'N. Credito', nombre: 'Nota de Credito', abr: 'Ncr', idMdl: 71 },
			'72': { ruta: 'nota-debito', nombreAbr: 'N. Debito', nombre: 'Nota de Debito', abr: 'Ndb', idMdl: 72 },
			'73': { ruta: 'ventas-recibos', nombreAbr: 'Recibo', nombre: 'Recibo', abr: 'Rec', idMdl: 73 },
			'74': { ruta: 'ventas-pedidos', nombreAbr: 'Pedido', nombre: 'Pedido', abr: 'Ped', idMdl: 74 },
			'75': { ruta: 'ventas-cotizacion', nombreAbr: 'Cotización', nombre: 'Cotización', abr: 'Cot', idMdl: 75 },
			'80': { ruta: 'compras-facturas', nombreAbr: 'Factura', nombre: 'Factura', abr: 'C-Fac', idMdl: 80 },
			'80.1': { ruta: 'compras-ticket', nombreAbr: 'Ticket', nombre: 'Ticket', abr: 'C-TIC', idMdl: 80.1 },
			'81': { ruta: 'compras-notas-credito', nombreAbr: 'N. Credito', nombre: 'Nota de Credito', abr: 'C-Ncr', idMdl: 81 },
			'82': { ruta: 'com-nota-d', nombreAbr: 'N. Debito', nombre: 'Nota de Debito', abr: 'C-Ndb', idMdl: 82 },
			'83': { ruta: 'compras-pagos', nombreAbr: 'Pago', nombre: 'Pago', abr: 'C-Pag', idMdl: 83 },
			'84': { ruta: 'compras-pedidos', nombreAbr: 'Pedido', nombre: 'Pedido', abr: 'C-Ped', idMdl: 84 },
			'85': { ruta: 'compras-cotizacion', nombreAbr: 'Cotización', nombre: 'Cotización', abr: 'C-Cot', idMdl: 85 },
			'90': { ruta: 'inventario-ajuste', nombreAbr: 'Ajuste Inventario', nombre: 'Ajuste de Inventario', abr: 'A-INV', idMdl: 90 },
			'91': { ruta: 'inventario-pase', nombreAbr: 'Pase Inventario', nombre: 'Pase de Inventario', abr: 'P-INV', idMdl: 91 },
			'150': { ruta: 'produccion', nombreAbr: 'Ord. Produccion', nombre: 'Orden de Producción', abr: 'PROD', idMdl: 150 },
			'250': { ruta: 'pase', nombreAbr: 'Pase Fondos', nombre: 'Pase de Fondos', abr: 'PASE', idMdl: 250 },
		}
		if (idMdl) return mdls[String(idMdl)]

		return mdls;
	}

	/**Scrolea al usuario hasta la parte de arriba de la pagina */
	scrollTop() {
		setTimeout(() => {
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		}, 100);
	}

	/**
	 * Sirve para cortar los caracteres de un texto a una logitud determinada.
	 * Si se debe de cortar, se le pone tres puntos al final
	 * @param str : String a cortar
	 * @param chrs : Cantidad Maxima de caracteres
	 */
	showOnly(str: string, chrs: number): string {
		if (typeof (str) !== 'string') return str

		if (str.length <= chrs) return str;

		return str.substr(0, chrs - 1) + '...';

	}
	/**
	 * Esta funcion garda datos en el localStorage
	 * @param key key del dato que se va a guardar
	 * @param data string que se quiere guardar
	 */
	setDato(key: any, data: string): any {
		window.localStorage.setItem(key, data);
	}

	/**
	 * La funcion va en el input compareWith del mat-select asi -> [compareWith]="funcionComparaSelect_id"
	 */
	funcionComparaSelect_id(optionOne, optionTwo): boolean {
		let val = optionOne._id === optionTwo?._id;
		return val
	}
	/**
	 * La funcion va en el input compareWith del mat-select asi -> [compareWith]="funcionComparaSelectcodigo"
	 */
	funcionComparaSelectCodigo(optionOne, optionTwo): boolean {

		let val = optionOne.codigo === optionTwo?.codigo;
		return val
	}

	fechaRealativa(fec: Date) {
		let dias = moment(fec).diff(moment(), 'days')
		let horas = moment(fec).diff(moment(), 'hours')
		let minutos = moment(fec).diff(moment(), 'minutes')
		let str = moment(fec).calendar(
			null, {
			sameDay: '[Hoy a las] h:mm a',
			nextDay: '[Mañana a las] h:mm a',
			nextWeek: '[El] dddd [que viene las] h:mm a',
			lastDay: '[Ayer a las] h:mm a',
			lastWeek: '[El] dddd [pasado a las] h:mm a',
			sameElse: 'dddd DD-MM-YYYY [a las] h:mm a'
		})

		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

		// const rtf2 = new (<any>Intl).RelativeTimeFormat('es', { numeric: 'auto' });
		// if(minutos > -59) return this.stringTitulo(rtf2.format(minutos, 'minute'))
		// if(horas > -12) return this.stringTitulo(rtf2.format(horas, 'hour'))
		// if(dias > -2) return this.stringTitulo(rtf2.format(dias, 'day')) + ' a las ' + this.datePipe.transform(fec, 'H:mm a')

		// return this.datePipe.transform(fec, 'dd-MM-yyyy H:mm a')
	}

	/**
	 * Toma un string y lo formatea a titulo, le pone Mayuscula a la
	 * primera letra de cada palabra
	 *
	 * @param str string que se va a formatear como titulo
	 */
	stringTitulo(str: string) {
		let strArr: string[] = str.replace(/\s\s/, ' ').split(' ');
		str = '';
		strArr.map((s, i) => {
			s = s.charAt(0).toUpperCase() + s.toLowerCase().slice(1)
			strArr[i] = s;
		});

		return strArr.join(' ');
	}

	/**
	 * Busca esa key en el localStorage
	 * @param key key del dato que se quiere traer
	 */
	getDato(key: any): any {
		return window.localStorage.getItem(key);
	}

	/**
	 * Recibe el formulario lo recorre y muestra errores que hayan sido estipulados en el template
	 * asignando la propiedad touched a los componentes y devuelve si es valido o no (true | false)
	 * @param form Formulario marcado en el template Ejemplo: #nombre="ngForm" name="nombre"
	 */
	revisaFormulario(form: NgForm) {
		if (!form) return true;
		// recorre para mostrar errores
		Object.keys(form.controls).forEach((campo) => {
			let control = form.form.controls[campo];
			control.markAsTouched({ onlySelf: true });
		});
		// regresa asi es valido
		return form.valid;
	}
	resetFormulario(form: NgForm) {
		// recorre para mostrar errores
		if (!form) return false;
		Object.keys(form.controls).forEach((campo) => {
			let control = form.form.controls[campo];
			control.markAsUntouched({ onlySelf: true });
		});
		// regresa asi es valido
		return form.valid;
	}

	configuracionRegional() {
		return { locale: 'es-MX', separadorDecimal: '.' }
	}


	formatoNumero(numero: number, decimales: number = 2, conSeparadorDeMiles = true) {

		numero = this.importeInt(numero, decimales);

		var locale = this.configuracionRegional();

		numero = parseFloat(numero.toString());

		let resultado = numero.toLocaleString(locale.locale, { style: 'decimal', minimumFractionDigits: decimales, maximumFractionDigits: decimales });
		if (!conSeparadorDeMiles) {
			if (locale.separadorDecimal === ',') resultado = resultado.replace(/\./g, '')
			if (locale.separadorDecimal === '.') resultado = resultado.replace(/,/g, '')
		}

		return resultado;

	}



	importeInt(numero, decimales = 2) {
		if (!numero) { numero = 0; }

		numero = numero.toString();
		var separador_decimal = '.';
		for (var i = numero.length - 1; i > 0; i--) {
			if (numero[i] === ',' || numero[i] === '.') {
				separador_decimal = numero[i];
				break;
			}
		}
		switch (separador_decimal) {
			case ',':
				numero = numero.replace(/\./g, '');
				numero = numero.replace(/,/g, '.');
				break;
			case '.':
				numero = numero.replace(/,/g, '');
				break;
		}

		//numero = numero.toLocaleString('es-MX', { style: 'decimal',minimumFractionDigits: decimales,maximumFractionDigits: decimales});
		//numero = numero.replace(',','');
		numero = parseFloat(numero);
		return parseFloat(numero.toFixed(decimales));

	}


	/**
	 * Envia un mensaje de error al usuario a modo de snackBar
	 * tambien registra el error en la base de datos para tener registro del problema
	 */
	muestraError(objErr, mensaje_error = 'Hubo un error') {

		this.snackBar.open(mensaje_error, 'OK', { duration: 5000 });

		let authToken = this.getDato('authToken');
		const URL = environment.apiURL + 'errores/';
		const DATOS = 'datos=' + JSON.stringify({ error: objErr });
		let CABECERAS = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'authToken': authToken });
		this.http.post(URL, DATOS, { headers: CABECERAS });
	}

	/**
	 * Abre un mensaje con animacion, que muestra un mensaje al usuario
	 * @param titulo Titulo del Mensaje
	 * @param mensaje mensaje a mostrar
	 * @param type warning|error|success|info|question
	 */
	SweetMsg(titulo: string, mensaje: string, type: 'success' | 'info' | 'warning' | 'error' | 'question' = 'success', opciones?: { textoOk: string, textoCancela: string }) {
		opciones = opciones ? opciones : { textoOk: 'Si', textoCancela: 'No' }
		if (type === 'question') {
			// return Swal.fire(titulo, mensaje, tipo,)
			return Swal.fire(<any>{
				title: titulo,
				html: mensaje,
				type,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: opciones.textoOk || 'Si',
				cancelButtonText: opciones.textoCancela || 'No'
			})
		} else {
			return Swal.fire(titulo, mensaje, type)
		}

	}

	guardarSelect(objGral: {}, propiedad: string, valor: any) {
		objGral[propiedad] = valor;
	}

	/**
	 * Permite pasarle un id al seleccionar una opcion en un select mediante
	 * el id que se busca en un vector y una vez ubicado el se pasa al objeto
	 * padre (p ej. this.fcv) a su propiedad propGral (P. Ej. metPag, quedando this.fcv[ propGral ] )
	 *
	 * @param valorId valor del id, por ejemplo 123 o D098
	 * @param nombreId nombre del ID, por ejemplo 'id' o 'codigo'
	 * @param arreglo arreglo donde estan todos los elementos
	 * @param objGral Objeto padre del elemento a modificar P.Ej. : this.fcv
	 * @param propGral String del elemento a modificar P .Ej. : 'metPag'
	 */
	cambioSelect(valorId: string | number, nombreId: string, arreglo: any[], objGral: any, propGral: string) {

		arreglo.forEach(e => {
			if (e[nombreId] === valorId) {
				objGral[propGral] = e;
				return;
			}
		});
	}

	buscarStore(estado: any) {
		return new Promise((ok, er) => {
			this.store
				.select(estado)
				.pipe(first())
				.subscribe(fcv => {
					ok(fcv)
				})
		})

	}

	seleccionarSelect(objeto, propiedad, arreglo: any[], valorDefecto = '') {
		let filtro = null
		if (objeto) filtro = arreglo.filter(e => e[propiedad] === objeto[propiedad])[0];
		return filtro ? filtro[propiedad] : valorDefecto;
	}

	/**
	 * Para buscar cuando se debe de seleccionar un valor en un conjunto, ya que si se asigna directo,
	 * la referencia de javascript hace que sean diferentes. ASi que se hace la busqueda manual.
	 * @param array
	 * @param objectoAEncontrar
	 * @param propiedadAComparar
	 */
	filtrar(array: any[], objectoAEncontrar: any, propiedadAComparar: string) {
		if (!array) return null;
		if (!objectoAEncontrar[propiedadAComparar]) {

			console.error('No existe la propiedad en el objecto que se esta buscando')
			if (!environment.production) console.log('busca:', propiedadAComparar, 'en: ', objectoAEncontrar)
			return null

		}
		let filtrado = array.filter((e) => e[propiedadAComparar] === objectoAEncontrar[propiedadAComparar])
		return filtrado[0]

	}

	claseFilaBaja(cmp) {

		if (cmp.baja === 1) return 'anulado'

		return '';
	}


}

import { Pipe, PipeTransform } from '@angular/core';
import escapeStringRegexp from 'escape-string-regexp';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(elArreglo: Array<any>, laPropiedad: string | string[] = [''], busqueda: string = '', laPropiedad2?: string, busqueda2?: string, limite: number = 5000): any {
    try {
      if (!elArreglo) return elArreglo;

      let elArreglo_mod: Array<any> = [];

      if (laPropiedad2 && typeof (busqueda2) === 'string') {

        elArreglo.forEach(subArreglo => {

          const valor = subArreglo[laPropiedad2].toString();
          let busqScaped = escapeStringRegexp(busqueda2.toString().toUpperCase());

          const bus = new RegExp(busqScaped, 'g');

          if (valor.toUpperCase().search(bus) >= 0) {
            elArreglo_mod.push(subArreglo);
          }
        });
        elArreglo = elArreglo_mod;
      }

      if (busqueda === '' || typeof (busqueda) === 'object' || laPropiedad === '') {
        if (limite) {
          return elArreglo.slice(0, limite);
        } else {
          return elArreglo;
        }
      }


      if (typeof (laPropiedad) === 'string') {
        laPropiedad = [laPropiedad]
      }

      elArreglo_mod = [];

      elArreglo.forEach(subArreglo => {

        (<string[]>laPropiedad).forEach(prop => {
          let valor = ''
          if (prop.indexOf('.') > 0) {
            let propArray = prop.split('.')
            if (propArray.length > 2) console.error('El filtro aguanta una profundida de 2 propiedades prop1.prop2')
            valor = subArreglo[propArray[0]][propArray[1]].toString();
          } else {
            valor = subArreglo[prop].toString();
          }

          if (valor) {
            let busqScaped = escapeStringRegexp(busqueda.toString().toUpperCase());

            const bus = new RegExp(busqScaped, 'g');

            if (valor.toUpperCase().search(bus) >= 0) {

              if (!elArreglo_mod.includes(subArreglo)) elArreglo_mod.push(subArreglo);
            }
          }

        });

      });

      if (limite) {
        return elArreglo_mod.slice(0, limite);
      } else {
        return elArreglo_mod;
      }
    } catch (e) {
      console.log('Catch Error Filtros: ', e)
      return elArreglo;
    }
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { ConexionService } from '@root/core/conexion.service';
import { DatosService } from '@root/core/datos.service';
import { SnackBarService } from '@root/core/snackbar.service';
import { Contacto } from '@root/models/contacto.model';
import { Fcp } from '@root/models/fcps.model';
import { Pipeline } from '@root/modulos/crm/models/pipeline.model';
import { CrmDetalle, CrmDetalleTipo } from '@root/modulos/crm/models/crm-detalle.model';
import { Trato } from '@root/modulos/crm/models/trato.model';
import { Subject, Subscription } from 'rxjs';
import { GlobalService } from '@root/core/global.service';
import Swal from 'sweetalert2';
import { CalendarEvent } from 'angular-calendar';
import moment from 'moment';import { Usu } from '@root/models/usu.model';
import { UiService } from '@root/core/ui.service';
import { AuthService } from '@root/auth/auth.service';

@Component({
	selector: 'app-trato-principal',
	templateUrl: './trato-principal.component.html',
	styleUrls: ['./trato-principal.component.scss']
})
export class TratoPrincipalComponent implements OnInit, OnDestroy {

	refrescarCalendario: Subject<any> = new Subject();
	fechaCalendario: Date = new Date();
	eventosCalendario: CalendarEvent[] = [];
	horario: string = moment().endOf('hour').add(1, 'minute').format('HH:mm')
	horarios = []


	sus: Subscription[] = []

	trato: Trato = new Trato()
	tratoOriginal: Trato = new Trato()

	pipe: Pipeline = new Pipeline()

	detalle: CrmDetalle = new CrmDetalle()
	detalles: CrmDetalle[] = []
	detallesImportantes: CrmDetalle[] = []
	detallesCompletos: CrmDetalle[] = []
	detallesPendientes: CrmDetalle[] = []

	empresa: Fcp = new Fcp()
	contacto: Contacto = new Contacto()

	cargandoAccion = false
	cargandoNota = false

	usuarioAsignado = new Usu()
	usuarioNuevo = new Usu()

	filtroTerminado: CrmDetalleTipo | '' = ''
	tab: number = 0
	constructor(
		public ui: UiService,
		public ds: DatosService,
		public auth: AuthService,
		private sb: SnackBarService,
		public g: GlobalService,
		private store: Store<AppState>,
		private con: ConexionService,
	) {
		for (let i = 0; i < 24; i++) {
			this.horarios.push(i + ':00')
			this.horarios.push(i + ':15')
			this.horarios.push(i + ':30')
			this.horarios.push(i + ':45')
		}
		this.sus.push(
			this.store.select('trato').subscribe((trato: Trato) => {
				this.trato = new Trato()
				if (!trato || !trato._id) return
				if (trato._id) this.trato = new Trato(trato)
				if (trato._id) this.tratoOriginal = new Trato(JSON.parse(JSON.stringify(trato)))
				this.empresa = new Fcp(this.ds.fcps.filter(f => f._id === this.trato.idEmp || '')[0] || {})
				this.contacto = new Contacto(this.ds.contactos.filter(c => c._id === this.trato.idContactos[0] || '')[0] || {})
				this.buscarCrmDetalles();

			}),
			this.store.select('pipeline').subscribe((pipe: Pipeline) => {
				if (pipe._id) this.pipe = new Pipeline(pipe)
			})
		)

	}
	cambiarDia(dia) {
		switch (dia) {
			case 'anterior':
				this.fechaCalendario = moment(this.fechaCalendario).add(-1, 'day').toDate()
				break;
			case 'hoy':
				this.fechaCalendario = moment().toDate()
				break;
			case 'siguiente':
				this.fechaCalendario = moment(this.fechaCalendario).add(1, 'day').toDate()
				break;
		}
	}

	cargarEventosActuales() {
		this.eventosCalendario = this.eventosCalendario.filter(e => e.meta === '@nuevo')
		this.detallesPendientes.forEach(d => {
			this.eventosCalendario.push({
				start: moment(d.fechaAgenda).toDate(),
				end: moment(d.fechaAgenda).add(30, 'minutes').toDate(),
				title: d.titulo,
				cssClass: 'calendario-evento-guardado',
				meta: '@anterior'
			})

		})
		this.refrescarCalendario.next('')

	}
	seleccionaHoraDatePicker(hora: string) {
		if (!hora) return;
		hora = moment(hora, 'H:mm').format('HH:mm')
		this.horario = hora


		if (hora.length === 4) hora = '0' + hora

		let horas = hora.toString().substr(0, 2)
		let minutos = hora.toString().substr(3, 2)
		this.detalle.fechaAgenda = moment(this.detalle.fechaAgenda)
			.startOf('day')
			.add(horas, 'hours')
			.add(minutos, 'minutes')
			.toDate()
	}

	cargarEventoNuevo(evento: { date: Date } | null, tipo: 'date' | 'time') {
		let fechaEvento = new Date()
		if (evento) fechaEvento = new Date(evento.date)
		if (!evento) {
			if (tipo === 'date') this.seleccionaHoraDatePicker(this.horario)
			fechaEvento = new Date(moment(this.detalle.fechaAgenda).toDate())
		}

		let indiceEventoActual = -1
		this.eventosCalendario.forEach((e, i) => {
			if (e.meta == '@nuevo') indiceEventoActual = i
		})
		if (indiceEventoActual != -1) {
			this.eventosCalendario.splice(indiceEventoActual, 1)
		}

		this.eventosCalendario.push({
			start: moment(fechaEvento).toDate(),
			end: moment(fechaEvento).add(30, 'minutes').toDate(),
			title: this.detalle.titulo || this.g.stringTitulo(`${this.detalle.accionTipo} ${this.contacto.nom} ${this.contacto.ape}`),
			cssClass: 'calendario-evento-nuevo',
			meta: '@nuevo'
		})
		this.refrescarCalendario.next('')
		this.detalle.fechaAgenda = moment(fechaEvento).toDate()
		this.fechaCalendario = this.detalle.fechaAgenda;
		this.seleccionaHoraDatePicker(moment(fechaEvento).format('HH:mm'))
	}


	marcarDetalleImportante(detalle: CrmDetalle) {
		this.detalle = new CrmDetalle(detalle)
		this.detalle.importante = !this.detalle.importante
		this.guardarCrmDetalle(this.detalle.tipo)
	}
	marcarDetalleIncompleto(detalle: CrmDetalle) {
		this.detalle = new CrmDetalle(detalle)
		this.detalle.completado = false
		this.guardarCrmDetalle(this.detalle.tipo)
	}
	marcarDetalleCompletado(detalle: CrmDetalle) {
		this.detalle = new CrmDetalle(detalle)
		this.detalle.completado = true
		this.detalle.fechaCompletado = new Date()
		this.guardarCrmDetalle(this.detalle.tipo)
	}
	async buscarCrmDetalles() {
		let res = await this.con.getP('crm/detalles/trato/' + this.trato._id, true);
		if (res.ok) {

			this.detallesCompletos = []
			this.detallesImportantes = []
			this.detallesPendientes = []

			this.detalles = (res.data || []).map((d: any) => new CrmDetalle(d))
			this.detalles.forEach((d: CrmDetalle) => {
				if (d.importante) {
					this.detallesImportantes.push(d)
				}
				if (d.completado) {
					this.detallesCompletos.push(d)
				} else {
					this.detallesPendientes.push(d)
				}
			})

			this.detallesImportantes = this.g.ordenarArreglo(this.detallesImportantes, 'fechaModificacion', 'DESC')
			this.detallesCompletos = this.g.ordenarArreglo(this.detallesCompletos, 'fechaModificacion', 'DESC')
			this.detallesPendientes = this.g.ordenarArreglo(this.detallesPendientes, 'fechaAgenda', 'ASC')

			this.cargarEventosActuales()
		}
	}
	cambioTab(tab: MatTabChangeEvent) {
		this.resetAccion()
		switch (tab.index) {
			case 0:
				this.detalle.tipo = 'nota';
				this.cargandoNota = true
				this.cargandoAccion = false

				break;
			case 1:
				this.detalle.tipo = 'accion';
				this.cargandoNota = false
				this.cargandoAccion = true
				setTimeout(() => {
					this.cargarEventosActuales()
					this.cargarEventoNuevo({ date: moment().endOf('hour').add(1, 'minutes').toDate() }, 'time')
				}, 0);
				break;
		}
	}
	resetAccion() {
		this.cargandoNota = false
		this.cargandoAccion = false
		this.detalle = new CrmDetalle()
		this.eventosCalendario = []
		this.refrescarCalendario.next('')
		this.usuarioAsignado = this.auth.usu || this.usuarioNuevo
	}
	async guardarCrmDetalle(tipo: CrmDetalleTipo) {
		if (!tipo) {
			Swal.fire('Falta el tipo de detalle!')
			return
		}
		if (tipo === 'nota' && !this.detalle.detalle) {
			this.sb.open('Escribe algo en la nota.', 'OK', 3000)
			return
		}
		if (tipo === 'accion' && !this.detalle.titulo) {
			this.detalle.titulo = this.g.stringTitulo(`${this.detalle.accionTipo} ${this.contacto.nom} ${this.contacto.ape}`)
		}
		this.detalle.completado = tipo === 'nota' ? true : this.detalle.completado
		this.detalle.tipo = tipo
		this.detalle.idTrato = this.trato._id
		this.detalle.idEmp = this.trato.idEmp
		this.detalle.idContacto = this.contacto._id
		this.detalle.idPipe = this.trato.idPipe
		this.detalle.idUsuAsignado = this.usuarioAsignado._id

		let res = await this.con.postP(this.detalle, 'crm/detalle-guardar', true)

		if (res.ok) {
			this.detalle = new CrmDetalle()
			await this.buscarCrmDetalles()
			this.sb.open('GUARDADO', 'OK', 3000)
		}
		this.resetAccion()
	}
	editarCrmDetalle(detalle: CrmDetalle) {
		this.detalle = new CrmDetalle(detalle);
		this.usuarioAsignado = this.ds.usus.filter(u => u._id === this.detalle.idUsuAsignado)[0] || this.usuarioNuevo
		switch (this.detalle.tipo) {
			case 'nota':
				this.tab = 0
				break;
			case 'accion':
				this.tab = 1
				break;
		}
		this.g.scrollTop()
	}
	async eliminarCrmDetalle(detalle: CrmDetalle) {
		if (!detalle) {
			return
		}
		let res = await this.con.postP(detalle, 'crm/detalle-eliminar', true)
		if (res.ok) {
			this.detalle = new CrmDetalle()
			this.buscarCrmDetalles()
			this.sb.open('Eliminado', 'DESHACER', 3000).onAction().subscribe((a) => {
				delete detalle._id
				this.detalle = new CrmDetalle(detalle)
				this.guardarCrmDetalle(detalle.tipo)
			})
			return
		}
	}

	ngOnInit(): void {
		this.resetAccion()
	}
	ngOnDestroy() {
		this.sus.forEach(s => s.unsubscribe())
	}

}

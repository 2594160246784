import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { EmailTracking } from '@models/email-tracking.model'
import { GlobalService } from '@root/core/global.service';
import { UiService } from '@root/core/ui.service';

@Component({
	selector: 'app-email-tracking',
	templateUrl: './email-tracking.component.html',
	styleUrls: ['./email-tracking.component.css']
})
export class EmailTrackingComponent implements OnInit {

	trackingInfo: EmailTracking = new EmailTracking()

	constructor(
		public ui: UiService,
		public g: GlobalService,
		public modal: MatDialogRef<EmailTrackingComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		this.trackingInfo = data.trackingInfo
	}

	ngOnInit(): void {
	}

}

import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ContactosEditaComponent } from '../../../contactos/components/contactos-edita/contactos-edita.component';
import { UiService } from '@root/core/ui.service';
import Swal from 'sweetalert2';
import { SnackBarService } from '@root/core/snackbar.service';


@Component({
	selector: 'app-lector-codigo-barras',
	templateUrl: './lector-codigo-barras.component.html',
	styleUrls: ['./lector-codigo-barras.component.scss']
})
export class LectorCodigoBarrasComponent implements OnInit, AfterViewInit {

	@ViewChild('codigoBarras')
	codigoBarras: ElementRef
	// @ViewChild('scanner', { static: false })
	// scanner: ZXingScannerComponent;

	cargandoDiv: HTMLDivElement

	availableDevices: MediaDeviceInfo[];
	currentDevice: MediaDeviceInfo = null;

	formatsEnabled: any[] = [4, 5, 7, 11];

	hasDevices: boolean;
	hasPermission: boolean;

	qrResultString: string;

	torchEnabled = false;
	torchAvailable$ = new BehaviorSubject<boolean>(false);
	tryHarder = false;

	overlayCreado = false
	constructor(
		public renderer: Renderer2,
		private ui: UiService,
		private sb: SnackBarService,
		public modal: MatDialogRef<ContactosEditaComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) { }

	clearResult(): void {
		this.qrResultString = null;
	}

	onCamerasFound(devices: MediaDeviceInfo[]): void {
		this.availableDevices = devices;
		this.hasDevices = Boolean(devices && devices.length);

		if (this.hasDevices) {
			this.renderer.addClass(this.cargandoDiv, 'hidden');

		} else {
			Swal.fire('¡Ups!', 'Parece que este dispositivo no cuenta con camaras que sirvan de scanner.').then(() => this.modal.close())
		}

	}

	onCodeResult(resultString: string) {
		this.modal.close(resultString)
		this.qrResultString = resultString;
	}

	onDeviceSelectChange(selected: string) {
		const device = this.availableDevices.find(x => x.deviceId === selected);
		this.currentDevice = device || null;
	}

	onHasPermission(has: boolean) {
		this.hasPermission = has;
		console.log('hasPermission', this.hasPermission);
	}

	onTorchCompatible(isCompatible: boolean): void {
		this.torchAvailable$.next(isCompatible || false);
	}

	toggleTorch(): void {
		if (this.torchEnabled) {
			this.reset();
			return;
		}
		this.torchEnabled = !this.torchEnabled;
	}

	toggleTryHarder(): void {
		this.tryHarder = !this.tryHarder;
	}

	crearOverlay() {
		if (this.overlayCreado) return

		this.overlayCreado = true

		let x1 = 15
		let y1 = 15
		let x2 = 85
		let y2 = 40
		if (this.ui.isMobile) {
			x1 = 8
			y1 = 12
			x2 = 92
			y2 = 35
		}

		//top
		let top = this.renderer.createElement('div');
		this.renderer.addClass(top, 'overlay');
		this.renderer.setStyle(top, 'height', y1 + '%');
		this.renderer.setStyle(top, 'width', '100%');
		this.renderer.setStyle(top, 'top', '0');
		this.renderer.setStyle(top, 'left', '0');
		this.renderer.appendChild(this.codigoBarras.nativeElement, top)

		//bottom
		let bottom = this.renderer.createElement('div');
		this.renderer.addClass(bottom, 'overlay');
		this.renderer.setStyle(bottom, 'height', (100 - y2) + '%');
		this.renderer.setStyle(bottom, 'width', '100%');
		this.renderer.setStyle(bottom, 'top', y2 + '%');
		this.renderer.setStyle(bottom, 'left', '0');
		this.renderer.appendChild(this.codigoBarras.nativeElement, bottom)

		//left
		let left = this.renderer.createElement('div');
		this.renderer.addClass(left, 'overlay');
		this.renderer.setStyle(left, 'height', (y2 - y1) + '%');
		this.renderer.setStyle(left, 'width', x1 + '%');
		this.renderer.setStyle(left, 'top', y1 + '%');
		this.renderer.setStyle(left, 'left', '0');
		this.renderer.appendChild(this.codigoBarras.nativeElement, left)

		//right
		let right = this.renderer.createElement('div');
		this.renderer.addClass(right, 'overlay');
		this.renderer.setStyle(right, 'height', (y2 - y1) + '%');
		this.renderer.setStyle(right, 'width', (100 - x2) + '%');
		this.renderer.setStyle(right, 'top', y1 + '%');
		this.renderer.setStyle(right, 'left', x2 + '%');
		this.renderer.appendChild(this.codigoBarras.nativeElement, right)

		//cuadrado
		let cuadrado = this.renderer.createElement('div');
		this.renderer.addClass(cuadrado, 'cuadrado');
		this.renderer.setStyle(cuadrado, 'height', (y2 - y1) + '%');
		this.renderer.setStyle(cuadrado, 'width', (x2 - x1) + '%');
		this.renderer.setStyle(cuadrado, 'top', y1 + '%');
		this.renderer.setStyle(cuadrado, 'left', x1 + '%');
		this.renderer.appendChild(this.codigoBarras.nativeElement, cuadrado)

		//Instrucciones
		let instrucciones = this.renderer.createElement('div');
		let texto = this.renderer.createText('Encadrar el Codigo de Barras');
		this.renderer.addClass(instrucciones, 'instrucciones');
		this.renderer.appendChild(instrucciones, texto)
		this.renderer.appendChild(cuadrado, instrucciones)


		//Acciones
		/* volver */
		let volverText = this.renderer.createElement('div');
		this.renderer.appendChild(volverText, this.renderer.createText('volver'))
		this.renderer.addClass(volverText, 'volver-texto');
		let volverButton = this.renderer.createElement('div');
		this.renderer.addClass(volverButton, 'fa');
		this.renderer.addClass(volverButton, 'fa-arrow-left');
		this.renderer.addClass(volverButton, 'botones');
		this.renderer.addClass(volverButton, 'boton-volver');
		this.renderer.listen(volverButton, 'click', () => { this.volver() })
		this.renderer.appendChild(this.codigoBarras.nativeElement, volverButton)
		if (!this.ui.isMobile) this.renderer.appendChild(volverButton, volverText)

		/* flash */
		if (this.ui.isMobile) {
			let flashButton = this.renderer.createElement('div');
			this.renderer.addClass(flashButton, 'fas');
			this.renderer.addClass(flashButton, 'fa-bolt');
			this.renderer.addClass(flashButton, 'botones');
			this.renderer.addClass(flashButton, 'boton-flash');
			this.renderer.listen(flashButton, 'click', () => { this.toggleFlash() })
			this.renderer.appendChild(this.codigoBarras.nativeElement, flashButton)
		}

		/* cargando */
		this.cargandoDiv = this.renderer.createElement('div');
		this.renderer.addClass(this.cargandoDiv, 'fa');
		this.renderer.addClass(this.cargandoDiv, 'fa-spinner');
		this.renderer.addClass(this.cargandoDiv, 'fa-spin');
		this.renderer.addClass(this.cargandoDiv, 'cargando');
		this.renderer.appendChild(cuadrado, this.cargandoDiv)

		/* camara */
		// let cameraButton = this.renderer.createElement('div');
		// this.renderer.addClass(cameraButton, 'fas');
		// this.renderer.addClass(cameraButton, 'fa-camera-retro');
		// this.renderer.addClass(cameraButton, 'botones');
		// this.renderer.addClass(cameraButton, 'boton-camara');
		// this.renderer.listen(cameraButton, 'click', () => { this.cambiarCamara() })
		// this.renderer.appendChild(this.codigoBarras.nativeElement, cameraButton)
	}

	toggleFlash() {
		this.toggleTorch()
	}

	cambiarCamara() {
		// DESHABILITADO, NO SE PUEDE CAMBIAR DE CAMARA, HACE COSAS RARAS...
		// RARAS COMO VOS QUE ESTAS LEYENDO
		if (!this.availableDevices || this.availableDevices.length === 1 || this.availableDevices.length === 0) return

		this.availableDevices.forEach((camara, index) => {
			// alert(camara.label + ' - ' + camara.kind + ' - ' + index)
			if (camara.deviceId === this.currentDevice.deviceId) {
				//encontramos en la camara que esta parado pone la que sigue o la primera
				this.currentDevice = this.availableDevices[index + 1] || this.availableDevices[0]
				this.sb.open('Cambio de Cámara', '', 2000)
			}
		})
	}
	volver() {
		this.modal.close()
	}
	reset() {
		this.modal.close('@reset')
	}

	ngOnInit() {
		this.overlayCreado = false
	}

	ngAfterViewInit() {

		setTimeout(() => {
			this.crearOverlay()
		}, 1);

	}
}

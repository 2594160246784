export class trackingData {
	date: Date
	action: 'leido' | 'abierto' | 'enviado'
}
export class EmailTracking {
	_id: string = '';
	idEmp: string = '';
	idUsu: string = '';
	idCmp: string = '';
	email: string = '';
	idMdl: string = '';
	fechaEnvio: Date = new Date()
	tracking: trackingData[] = []

	constructor(obj: any = {}) {
		this._id = obj._id || this._id
		if(!this._id) delete this._id;
		this.idEmp = obj.idEmp || this.idEmp
		this.idUsu = obj.idUsu || this.idUsu
		this.idCmp = obj.idCmp || this.idCmp
		this.email = obj.email || this.email
		this.idMdl = obj.idMdl || this.idMdl
		this.fechaEnvio = obj.fechaEnvio || this.fechaEnvio
		this.tracking = obj.tracking || this.tracking

	}
}

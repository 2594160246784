// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contenedor{
	width:100%;
	height:100%;
}
.miVisor{
	width:100%;
	height:100%;
	border:0px black solid !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/componentes/muestra-pdf/muestra-pdf.component.css"],"names":[],"mappings":"AAAA;CACC,UAAU;CACV,WAAW;AACZ;AACA;CACC,UAAU;CACV,WAAW;CACX,iCAAiC;AAClC","sourcesContent":[".contenedor{\n\twidth:100%;\n\theight:100%;\n}\n.miVisor{\n\twidth:100%;\n\theight:100%;\n\tborder:0px black solid !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

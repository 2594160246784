import { Injectable, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Router } from '@angular/router';
import { AuthService } from './auth.service';
import Swal from 'sweetalert2';
import { usuRoles, usuRutas } from '@root/models/usu.model';
import { acceso } from '@models/usu.model';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { cerrarSesion } from './auth.actions';
import { TrackService } from '../core/track.service';
import { ConexionService } from '@root/core/conexion.service';

/*ESTE LO GENERE CON ng generate guard auth/auth*/
const excepcionesRutas: usuRutas[] = [
	{ ruta: '/inicio/pos', acceso: acceso.permitido, role: usuRoles.USER_ROLE }
]

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

	constructor(
		private _con: ConexionService,
		private auth: AuthService,
		private store: Store<AppState>,
		private trk: TrackService
	) { }

	async canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot, ruta?: string) {
		let rutaDestino = null
		if (ruta) {
			rutaDestino = ruta
		} else {
			rutaDestino = state.url.split(';')[0]
			rutaDestino = rutaDestino.split('?')[0]
		}

		if (!this.auth.usu) {
			this.trk.trackAccion('accion', 'Cierra Sesion - Sin Usuario AuthGuard - ' + rutaDestino);

			this.store.dispatch(cerrarSesion())

		}
		// ACCESOS MASTER ------------------------------------------------------------------------------------------.
		if (this.auth?.usu?.role === usuRoles.MASTER_ROLE &&
			(
				rutaDestino === '/inicio/system/usuarios-activos' ||
				rutaDestino === '/inicio/system/mails' ||
				rutaDestino === '/inicio/system/menus'
			)
		) {
			return true;
		}
		// ---------------------------------------------------------------------------------------------------------.
		// ACCESOS SUCURSALES --------------------------------------------------------------------------------------.
		if (rutaDestino.indexOf('/sucursales') > -1) {
			return this.auth.emp.multisucursal;
		}
		// ---------------------------------------------------------------------------------------------------------.
		const hasPermission = await this.buscarRutaAcceso(rutaDestino)
		if (hasPermission) {
			if (this.auth?.usu?.role < usuRoles.ADMIN_ROLE &&
				(rutaDestino === '/actualizar-plan' || rutaDestino === '/inicio/timbres')
			) {

				this.trk.trackAccion('accion', 'Intento de acceso denegado a :' + rutaDestino + ' solo usu Admin');

				Swal.fire('¡Ups!', 'Solo el usuario administrador de la cuenta puede efectuar compras.', 'error')
				return false;
			}

			this.trk.trackAccion('navegacion', rutaDestino);

			return true;

		} else {

			this.trk.trackAccion('accion', 'Intento de acceso denegado a :' + rutaDestino);
			Swal.fire('¡Ups!', 'No tiene autorización para entrar a este módulo.' + rutaDestino, 'error')
			return false;
		}
	}

	canLoad() {
		return true;
	}

	async buscarRutaAcceso(route: string) {
		try {

			const res = await this._con.postP({ route }, 'auth/user/access-route')
			return res.ok;

		} catch (error) {

			return false;
		}
	}

}

import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { UiService } from '@core/ui.service';
import moment from 'moment';import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PasarelaStripeService } from './pasarela-stripe.service';
import Swal from 'sweetalert2';
import { ConexionService } from '@core/conexion.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '@auth/auth.service';
import { cerrarSesion } from '../../auth/auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { TrackService } from '../../core/track.service';
import { Fcv, FcvDet } from '@root/models/fcvs.model';
import { Fcp } from '@root/models/fcps.model';
import { Art, Impuesto } from '@root/models/arts.model';
import { GlobalService } from '@root/core/global.service';

export const MY_FORMATS = {
	parse: {
		dateInput: 'YYYYMMDD',
	},
	display: {
		dateInput: 'MM / YYYY',//con este se cambia
		monthYearLabel: 'YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'YYYY',
	},
};

declare var Stripe: any;
var stripe = new Stripe(environment.stripeKey);


@Component({
	selector: 'app-pasarela-stripe',
	templateUrl: './pasarela-stripe.component.html',
	styleUrls: ['./pasarela-stripe.component.scss', './stripe.css'],
	providers: [
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	]
})
export class PasarelaStripeComponent implements OnInit, OnDestroy, AfterViewInit {

	element = stripe.elements()
	elements = []
	suscripciones: Subscription[] = [];
	isModal
	fecha: moment.Moment;
	paymentIntentOXXO = null
	nombre: string = !environment.production ? 'Alex Otano' : ''
	apellido: string = !environment.production ? '' : ''
	email: string = !environment.production ? 'alexis.otsa@gmail.com' : ''
	textoBotonPagar: string = ''
	textoPagoRecurrente: string = ''
	metodo: 'oxxo' | 'tdc' | 'transferencia' = 'tdc';
	referencia: string = ''


	constructor(
		public ui: UiService,
		public g: GlobalService,
		public auth: AuthService,
		private router: Router,
		public ss: PasarelaStripeService,
		public modal: MatDialogRef<PasarelaStripeComponent>,
		@Inject(MAT_DIALOG_DATA) data: any,
		private con: ConexionService,
		private store: Store<AppState>,
		private trk: TrackService,
	) {

		this.isModal = data.modal ? true : false;
		this.cambioMetodoPago('tdc')

	}
	eligeAnio(valor) {
		this.ss.pago.ano = moment(valor).format('YYYY');
		this.fecha = moment(valor);
	}
	eligeMes(valor, obj) {
		obj.close();
		this.ss.pago.mes = moment(valor).format('MM');
		this.fecha = moment(valor);
	}

	async pagar() {
		this.trk.trackAccion('accion', 'Realizando Pago - ' + this.metodo, { conceptos: this.ss.pago.conceptos });
		switch (this.metodo) {
			case 'oxxo':
				this.generarPagoOXXO()
				break;
			case 'tdc':
				this.pagarTDC()
				break;
			case 'transferencia':
				await this.g.SweetMsg('Transferencia', `Para realizar el pago por transferencia o depósito puedes realizar el movimiento en cualquier momento.
										<br><br> <b>Cuando lo hagas debes enviar el comprobante a soporte@oadmin.app.<br>
										<i>Es importante que incluyas la referencia en tu transferencia/deposito.</i></b><br><br>
										Enviaremos esta información a ${this.auth.usu.email}`, 'info')

				let datosBancarios = `
				<br>
				<b>Banco:</b> BBVA<br>
				<b>Nombre:</b> Maria del Pilar Santos Salgado<br>
				<b>Nro. Cuenta:</b> 2878070857<br>
				<b>CLABE (Transferencia):</b> 012320028780708576<br>
				<b>Referencia:</b> ${this.referencia}<br>
				<b>Total a Pagar:</b> $ ${ this.g.formatoNumero( this.ss.pago.conceptos[0].precio) }<br>
				`
				await this.con.postP({datosBancarios}, 'api/oadmin/transferencia', true)

				this.store.dispatch(cerrarSesion())

				break;
		}
	}
	async pagarTDC() {
		if (!this.ss.valido()) return

		this.ui.loading(true);

		try {
			let respuestaPago = null
			if (this.elements.length) {
				// si hay elementos de stripe (datos tarjeta de credito)
				let res = await this.stripe(this.elements[0])
				let servicio = '';

				if (this.ss.pago.vendedor.tipo === 'oadmin') {
					if (this.ss.pago.periodo === 'unico') {
						servicio = 'stripe/timbres'; // oadmin productos(timbres)
					} else {
						servicio = 'stripe/suscripcion-oadmin'; // oadmin suscripciones
					}
				} else {
					servicio = 'stripe/pago'; // USUARIO
				}
				this.trk.trackAccion('accion', 'Realizando Pago - ' + servicio);
				respuestaPago = await this.con.postP(this.ss.pago, servicio, true)

			} else {
				// si NO hay elementos de stripe, es por que el costo es cero
				this.trk.trackAccion('accion', 'Realizando Pago - Pago suscripcion gratis en ceros');
				respuestaPago = await this.con.postP(this.ss.pago, 'stripe/suscripcion-oadmin', true)

			}
			this.ui.loading(false);
			this.trk.trackAccion('accion', 'Pago Realizado con Exito');
			Swal.fire('¡Felicidades!', respuestaPago.message, 'success').then(() => {
				this.modal.close(respuestaPago.data);

				if (this.ss.cerrarSesion) {
					this.store.dispatch(cerrarSesion())
					this.auth.cerrarSesion();
				} else {
					this.router.navigate(['/inicio'])
				}

			})
		} catch (error) {
			this.trk.trackAccion('accion', 'Error al Realizar Pago.');
			Swal.fire('Pago no realizado', 'No se pudo procesar el pago, si el problema persiste, envianos un mail a contacto@oadmin.app', 'error')
			this.ui.loading(false);
			console.error(error);
		}


	}

	generaPagoMP() {
		// LO DE MERCADO PAGO QUEDO MEDIAS...
		let pedido = new Fcv({ idMdl: 73 })
		pedido.fcp = new Fcp(this.auth.emp)
		let art = new Art({
			art: this.ss.pago.conceptos[0].descripcion,
			codigo: this.ss.pago.conceptos[0].codigo,
			precio: this.ss.pago.conceptos[0].precio,
			impuestos: [new Impuesto('IMP', { codigo: '002', imp: 'IVA' }, 16.00)]
		})
		pedido.fcvsDets = [new FcvDet(1, art)]
		pedido.calculaTotal();
		this.con.postP({ fcv: pedido }, '/api/mercado-pago/oadmin')

	}
	cambioMetodoPago(metodo: 'oxxo' | 'tdc' | 'transferencia') {
		switch (metodo) {
			case 'oxxo':
				this.textoBotonPagar = 'Generar cupón para pagar en OXXO'
				this.textoPagoRecurrente = ''
				break;
			case 'tdc':
				this.textoBotonPagar = 'Pagar con Tarjeta'
				this.textoPagoRecurrente = 'Pago recurrente ' + this.ss.pago.periodo + '.'
				break;
			case 'transferencia':
				this.textoBotonPagar = 'Pago con Transferencia'
				this.textoPagoRecurrente = ''
				break;
		}
		this.metodo = metodo;
		if (this.ss.pago.importe === 0) {
			this.metodo = 'tdc';
			this.textoBotonPagar = 'Comenzar Gratis Ahora'
		}
	}
	async generarPagoOXXO() {

		if (!this.nombre || !this.email) {
			Swal.fire('Error', 'Debe de completar su nombre y correo.', 'warning')
			return
		}
		this.ui.loading(true)
		let datos = {
			prodOadminCodigo: this.ss.pago.conceptos[0].codigo
		}
		let res = await this.con.postP(datos, 'api/stripe/oxxo')

		console.log(res)
		if (!res.data) {
			this.ui.loading(false)
			Swal.fire('Error', 'Hubo un error al generar el cupón.', 'warning')
			console.log(res);
			return
		}

		let oxxoSecret = res.data.client_secret
		let oxxoData = {
			payment_method: {
				billing_details: {
					name: this.nombre,
					email: this.email,
				},
			},
		}
		try {

			let result = await stripe.confirmOxxoPayment(oxxoSecret, oxxoData) // Stripe.js will open a modal to display the OXXO voucher to your customer
			this.ui.loading(false)
			// This promise resolves when the customer closes the modal
			if (result.error) {
				Swal.fire('OXXO', 'No se pudo generar el cupón para pago en OXXO.', 'error')
				console.log(result.error)
				return
			}
			Swal.fire('¡Listo!', 'Recuerda que tienes hasta 3 días para pagar y OXXO puede demorar hasta 24 horas en confirmar el pago. <br><br>Por las dudas, te hemos <b>enviado un correo</b> con el código para el pago en OXXO.', 'success').then(res => {
				this.router.navigate(['/inicio'])
				this.modal.close()
			})

		} catch (error) {
			this.ui.loading(false)
			Swal.fire('OXXO', 'ERROR: No se pudo generar el cupón para pago en OXXO.', 'error')
			console.log(error)
			return
		}


	}

	ngOnInit() {
		//TODO: SI VIENE DE AFUERA TOMAR OTRA ACCION Y DESPUES VALIDAR

		if (!this.ss.valido()) {
			this.trk.trackAccion('accion', 'Error Pago lo manda a Inicio');
			Swal.fire('¡Ups!', 'Ha habido un error procesando el pago, no se ha realizado ningún cargo. Para ayuda escribenos a contacto@oadmin.app', 'error')
				.then(() => this.router.navigate(['inicio']))
		}

		//crea la referencia
		let split = this.ss.pago.conceptos[0].codigo.split('-')
		split.forEach((s, i) => { this.referencia += i == 1 && split.length == 2 ? s : s.slice(0, 1) })
		this.referencia = (this.referencia + '-' + this.auth.usu.idUsu.slice(-10)).toUpperCase()


	}

	ngAfterViewInit() {
		this.stripeInit()
	}
	stripe(elemento) {
		return new Promise((ok, er) => {
			stripe.createToken(elemento).then((result) => {
				if (result.error) {
					Swal.fire('¡Ups!', result.error.message, 'error');
					this.ui.loading(false);
					return;
				}

				this.ss.pago.token = result.token;
				ok('')

			}).catch(err => {
				this.ui.loading(false);
				er(err);
			})
		})

	}

	stripeInit() {

		// ----------------------------------------------------------------------------------------------------------.
		// -------------------------------------- STRIPE  INICIADOR -------------------------------------------------.
		// ----------------------------------------------------------------------------------------------------------.
		var elementStyles = {
			base: {
				color: '#32325D',
				fontWeight: 500,
				fontSize: '16px',
				fontSmoothing: 'antialiased',

				'::placeholder': {
					color: '#CFD7DF',
				},
				':-webkit-autofill': {
					color: '#e39f48',
				},
			},
			invalid: {
				color: '#E25950',

				'::placeholder': {
					color: '#FFCCA5',
				},
			},
		};

		var elementClasses = {
			focus: 'focused',
			empty: 'empty',
			invalid: 'invalid',
		};

		// 'card' | 'cardNumber' | 'cardExpiry' | 'cardCvc' | 'postalCode' | 'paymentRequestButton' | 'iban' | 'idealBank';
		var cardNumber = this.element.create('cardNumber', { style: elementStyles, classes: elementClasses, });
		cardNumber.mount('#tarjeta-numero');
		this.elements.push(cardNumber);

		var cardExpiry = this.element.create('cardExpiry', { style: elementStyles, classes: elementClasses, });
		cardExpiry.mount('#tarjeta-exp');
		this.elements.push(cardExpiry);

		var cardCvc = this.element.create('cardCvc', { style: elementStyles, classes: elementClasses, });
		cardCvc.mount('#tarjeta-codigo');
		this.elements.push(cardCvc);


		// ----------------------------------------------------------------------------------------------------------.
		// -------------------------------------- STRIPE  INICIADOR -------------------------------------------------.
		// ----------------------------------------------------------------------------------------------------------.
	}


	cerrar() {
		if (this.isModal) {
			this.modal.close();
		}
	}



	ngOnDestroy() {

		this.suscripciones.forEach((suscrpcion: Subscription) => {
			suscrpcion.unsubscribe();
		});
		this.ss.borrarGuardado();
	}


}

@Component({
	selector: 'app-pasarela-stripe',
	templateUrl: './pasarela-stripe.component.html',
	styleUrls: ['./pasarela-stripe.component.scss', './stripe.css'],
	providers: [
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	]
})
export class PasarelaStripeComponentX extends PasarelaStripeComponent { }

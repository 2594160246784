import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { DatosService } from '@core/datos.service';

@Injectable()
export class Resolver implements Resolve<Observable<string>> {
  constructor(
    private ds: DatosService
  ) { }

  resolve() {
    // EL RESOLVER VA A DEVOLVER UN OBSERVABLE Y RECIEN CUANDO ESTE RESUELVA VA A CONTINUAR CARGANDO.
    // EN ESTE CASO LLAMAMOS A BUSCAR DATOS, SI YA ESTAN TODOS LOS DATOS CARGADOS, RESUELVE INMEDIATAMENTE. SI NO ESPERA A QUE ESTE RESUELVA
    return (<Observable<any>>new Observable(s=> {
      this.ds.buscarDatos().then(()=>{
        s.next('este string no importa por que lo que queremos es que esten cargados los datos.. ');
        s.complete()
      }).catch(e=>s.error(e))
    }))
  }
}

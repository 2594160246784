// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boton-social-fb {
  background-color: #39599a;
  color: white;
  text-align: center;
  vertical-align: middle;
}

mat-card.movil {
  margin: 0 !important;
  padding: 4px !important;
}

.contenedor {
  height: auto !important;
  min-height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.contenedor.bg-1 {
  background: url('fondo_registro.798fb988c3ea5403.jpg') no-repeat center center fixed !important;
  background-size: cover !important;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/login/registro/registro.component.scss"],"names":[],"mappings":"AAAA;EACC,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;AACD;;AAEA;EACC,oBAAA;EACA,uBAAA;AACD;;AACA;EACC,uBAAA;EACA,2BAAA;EACA,oBAAA;EACA,qBAAA;AAED;;AACA;EACI,+FAAA;EAIH,iCAAA;AAED","sourcesContent":[".boton-social-fb{\n\tbackground-color: #39599a;\n\tcolor: white;\n\ttext-align: center;\n\tvertical-align: middle;\n}\n\nmat-card.movil{\n\tmargin:0!important;\n\tpadding: 4px!important;\n}\n.contenedor {\n\theight: auto  !important;\n\tmin-height: 100% !important;\n\tmargin: 0 !important;\n\tpadding: 0  !important;\n}\n\n.contenedor.bg-1 {\n    background: url(\"../../../../assets/login/images/auth/fondo_registro.jpg\") no-repeat center center fixed !important;\n\t-webkit-background-size: cover!important;\n\t-moz-background-size: cover!important;\n\t-o-background-size: cover!important;\n\tbackground-size: cover!important;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

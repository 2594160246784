// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  min-height: 47px;
  height: 47px;
}

i.fas {
  font-size: 1.2rem;
}

.separador {
  display: block;
  border-top: 1px rgb(228, 228, 228) solid;
  margin: 0 !important;
  padding: 0 !important;
}

.sucursal {
  border-bottom: 1px rgb(228, 228, 228) solid;
  background-color: white;
  cursor: pointer;
  margin-bottom: 0 !important;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.sucursal .check {
  display: none;
}
.sucursal.actual {
  background-color: #38caff !important;
  color: white !important;
}
.sucursal.actual:hover {
  background-color: #38caff !important;
  color: white !important;
}
.sucursal.actual .check {
  display: block;
  color: white !important;
  right: 0 !important;
  float: right !important;
  text-align: end !important;
  font-size: 2rem !important;
  margin-right: 20px !important;
}
.sucursal.actual .check i.fas {
  font-size: 2rem !important;
}
.sucursal .detalle {
  font-size: 0.85rem;
}
.sucursal:hover {
  background-color: #f9f9f9;
}`, "",{"version":3,"sources":["webpack://./src/app/modulos/sucursales/components/sucursales-selecciona/sucursales-selecciona.component.scss"],"names":[],"mappings":"AAAA;EACC,gBAAA;EACA,YAAA;AACD;;AACA;EACC,iBAAA;AAED;;AACA;EACC,cAAA;EACA,wCAAA;EACA,oBAAA;EACA,qBAAA;AAED;;AAAA;EACC,2CAAA;EACA,uBAAA;EACA,eAAA;EACA,2BAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAGD;AAFC;EACC,aAAA;AAIF;AADC;EACC,oCAAA;EACA,uBAAA;AAGF;AADE;EACC,oCAAA;EACA,uBAAA;AAGH;AAAE;EACC,cAAA;EACA,uBAAA;EACA,mBAAA;EACA,uBAAA;EACA,0BAAA;EACA,0BAAA;EACA,6BAAA;AAEH;AADG;EACC,0BAAA;AAGJ;AAEC;EACC,kBAAA;AAAF;AAGC;EACC,yBAAA;AADF","sourcesContent":["button {\n\tmin-height: 47px;\n\theight: 47px;\n}\ni.fas {\n\tfont-size: 1.2rem;\n}\n\n.separador {\n\tdisplay: block;\n\tborder-top: 1px rgb(228, 228, 228) solid;\n\tmargin: 0 !important;\n\tpadding: 0 !important;\n}\n.sucursal {\n\tborder-bottom: 1px rgb(228, 228, 228) solid;\n\tbackground-color: white;\n\tcursor: pointer;\n\tmargin-bottom: 0 !important;\n\tpadding-left: 10px;\n\tpadding-top: 8px;\n\tpadding-bottom: 8px;\n\t.check {\n\t\tdisplay: none;\n\t}\n\n\t&.actual {\n\t\tbackground-color: #38caff !important;\n\t\tcolor: white !important;\n\n\t\t&:hover {\n\t\t\tbackground-color: #38caff !important;\n\t\t\tcolor: white !important;\n\t\t}\n\n\t\t.check {\n\t\t\tdisplay: block;\n\t\t\tcolor: white !important;\n\t\t\tright: 0 !important;\n\t\t\tfloat: right !important;\n\t\t\ttext-align: end !important;\n\t\t\tfont-size: 2rem !important;\n\t\t\tmargin-right: 20px !important;\n\t\t\ti.fas{\n\t\t\t\tfont-size: 2rem !important;\n\t\t\t}\n\t\t}\n\t}\n\n\t.detalle {\n\t\tfont-size: 0.85rem;\n\t}\n\n\t&:hover {\n\t\tbackground-color: #f9f9f9;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, Inject, AfterViewInit, ElementRef } from '@angular/core';
import { Art, Impuesto, ArtDetalle } from '@models/arts.model';
import { DatosService } from '@core/datos.service';
import { GlobalService } from 'src/app/core/global.service';
import { ConexionService } from '@core/conexion.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { activarLoading, desactivarLoading } from '../../../layout/ui.actions';
import { NgForm } from '@angular/forms';
import { Imp, ProSrv, UniMed } from '@models/interfaces';
import { UiService } from '@core/ui.service';
import { SnackBarService } from '@core/snackbar.service';
import { AuthService } from '../../../auth/auth.service';
import { CodigoBarrasService } from '@root/modulos/codigo-barras/codigo-barras.service';


@Component( {
	selector: 'app-productos-edita',
	templateUrl: './productos-edita.component.html',
	styleUrls: [ './productos-edita.component.scss' ]
} )
export class ProductosEditaComponent implements OnInit, OnDestroy, AfterViewInit {

	suscripciones: Subscription[] = [];
	@ViewChild( 'artCodigoMobile' ) artCodigoMobile: ElementRef;
	@ViewChild( 'f', { static: true } ) formulario: NgForm;
	@ViewChild( 'tabla' ) tabla: MatTable<any>;
	@ViewChild( 'tablaImpuestos' ) tablaImpuestos: MatTable<any>;


	art: Art = new Art()
	artDetalle: ArtDetalle = {
		cantidad: 0,
		art: new Art(),
	}
	impuesto = new Impuesto();
	imagen: File;
	imagen2: File;
	imagen3: File;

	displayedColumnsImpuestos: string[] = [ 'tipo', 'imp', 'tasa', 'acciones' ];
	displayedColumns: string[] = [ 'articulo', 'cantidad', 'acciones' ];


	isModal: boolean = false;
	cargando: boolean;

	unisMeds: UniMed[] = [];
	prosSrvs: ProSrv[] = [];
	//   imps: Imp[] = [];

	constructor(
		private g: GlobalService,
		public ds: DatosService,
		public ui: UiService,
		public auth: AuthService,
		private con: ConexionService,
		private cb: CodigoBarrasService,
		private snackBar: SnackBarService,
		private store: Store<AppState>,
		public modal: MatDialogRef<ProductosEditaComponent>,
		@Inject( MAT_DIALOG_DATA ) data: any
	) {
		if ( data.modal ) {
			this.isModal = true;
			this.art = new Art( data.art )
			if ( this.art.imagen ) this.con.sGetImg( this.art.imagen, 'arts' ).then( imgUrl => { this.art.imagenURL = imgUrl } )
			if ( this.art.imagen2 ) this.con.sGetImg( this.art.imagen2, 'arts' ).then( imgUrl => { this.art.imagenURL2 = imgUrl } )
			if ( this.art.imagen3 ) this.con.sGetImg( this.art.imagen3, 'arts' ).then( imgUrl => { this.art.imagenURL3 = imgUrl } )
		}
	}
	/**
	 * Se le dpasa un archivo y el nombre de la propiedad en donde se va a asignar
	 * @param file Archivo
	 * @param imagen Nombre de la propiedad dentro de articulo
	 */
	cambiarImagen( file: File, imagen: string ) {
		if ( !file ) return this[ imagen ] = null;

		this[ imagen ] = file
	}
	async codigoBarras() {
		try {
			let codigo = await this.cb.abrirScanner()
			if ( codigo ) {
				this.art.codigoBarras = codigo.replace( /^0+/, '' );
			}
		} catch ( error ) {
			console.log( 'Error escaneando codigo de barras', error );
		}

	}

	agregarArt() {

		if ( this.artDetalle.art.art === '' ) {
			this.g.SweetMsg( '¡Ups!', 'Debe de indicar un artículo.', 'error' );
			return;
		}

		if ( this.artDetalle.cantidad <= 0 ) {
			this.g.SweetMsg( '¡Ups!', 'Debe de indicar la cantidad.', 'error' );
			return;
		}

		let encontrado = false
		this.art.detalle.forEach( artDet => {
			if ( artDet.art._id === this.artDetalle.art._id ) encontrado = true;
		} )

		if ( encontrado ) {
			this.g.SweetMsg( '¡Ups!', 'Este artículo ya se encuentra en el listado', 'error' );
			return;
		}

		this.art.detalle.push( this.artDetalle )
		if ( this.tabla ) this.tabla.renderRows();
		this.artDetalle = {
			cantidad: 0.00,
			art: new Art(),
		}


	}

	borrarArticulo( detalle: ArtDetalle ) {

		let detalleTemporal = this.art.detalle.filter( ( artDet ) => {
			return artDet.art._id !== detalle.art._id;
		} )

		this.art.detalle = detalleTemporal;

		if ( this.tabla ) this.tabla.renderRows();


	}

	agregarImpuesto() {

		if ( this.impuesto.imp.codigo === '' ) {
			this.g.SweetMsg( '¡Ups!', 'Debe de indicar un impuesto.', 'error' );
			return;
		}

		if ( Number( this.impuesto.tasa ) <= 0 && Number( this.impuesto.tasa ) >= 0 ) {
			this.g.SweetMsg( '¡Ups!', 'Debe de indicar una tasa porcentual válida.', 'error' );
			return;
		}

		let encontrado = false
		this.art.impuestos.forEach( impuesto => {
			if ( impuesto.imp._id === this.impuesto.imp._id && impuesto.tipo === this.impuesto.tipo ) encontrado = true;
		} )

		if ( encontrado ) {
			this.g.SweetMsg( '¡Ups!', 'Este impuesto ya se encuentra en el listado', 'error' );
			return;
		}

		this.art.impuestos.push( this.impuesto )
		if ( this.tablaImpuestos ) this.tablaImpuestos.renderRows();
		this.impuesto = new Impuesto()


	}

	borrarImpuesto( index ) {
		if ( index < 0 ) return;

		this.art.impuestos.splice( index, 1 );

		if ( this.tablaImpuestos ) this.tablaImpuestos.renderRows();


	}
	guardar() {
		this.cargando = true;
		if ( !this.g.revisaFormulario( this.formulario ) ) {
			this.g.SweetMsg( '¡Ups!', 'Parece que hay algunos errores, complete los campos marcados en rojo.', 'warning' );
			this.cargando = false;
			return
		}

		if ( this.art.impuestos.length > 0 ) {
			let impuestosValidos = true
			this.art.impuestos.forEach( imp => {
				if ( Number( imp.tasa ) === 0 ) impuestosValidos = false
				if ( Number( imp.tasa ) >= 100 ) impuestosValidos = false
				if ( Number( imp.tasa ) < 0 ) impuestosValidos = false
			} )
			if ( !impuestosValidos ) {
				this.g.SweetMsg( '¡Ups!', 'Los impuestos deben de ser un porcentaje válido mayor a cero.', 'warning' );
				this.cargando = false;
				return
			}
		}

		this.store.dispatch( activarLoading() );
		this.art.idMdl = 7;

		let artFormData = new FormData();

		if ( this.art.imagen === 'assets/img/no-img.png' ) this.art.imagen = 'no-img';
		if ( this.art.imagen2 === 'assets/img/no-img.png' ) this.art.imagen2 = 'no-img';
		if ( this.art.imagen3 === 'assets/img/no-img.png' ) this.art.imagen3 = 'no-img';

		if ( this.imagen ) artFormData.append( 'imagen', this.imagen );
		if ( this.imagen2 ) artFormData.append( 'imagen2', this.imagen2 );
		if ( this.imagen3 ) artFormData.append( 'imagen3', this.imagen3 );
		artFormData.append( 'data', JSON.stringify( this.art ) );

		this.ui.loading( true );
		// this.con.post(this.art, 'arts').subscribe(res => {
		this.con.post( artFormData, 'arts', true, 'form-data' ).subscribe( res => {

			this.ui.loading( false );
			let nvoArt = res.data;
			this.snackBar.open( '¡Producto guardado!', 'OK', 5000 );
			this.store.dispatch( desactivarLoading() )

			if ( this.isModal ) {
				this.cargando = false;
				this.modal.close( nvoArt );
				return;
			}

			this.art = new Art();
			if ( this.isModal ) this.modal.close( true )
		} )


	}
	eliminar() {

		this.g.SweetMsg( 'Eliminar', '¿Desea eliminar el registro?', 'question' )
			.then( res => {
				if ( res.value ) {

					this.art.baja = 1;

					let artFormData = new FormData();
					artFormData.append( 'data', JSON.stringify( this.art ) );

					this.con.postP( artFormData, `arts`, true, 'form-data' ).then( ( res ) => {

						if ( this.isModal ) this.modal.close();
						this.snackBar.open( '¡ Articulo eliminado correctamente !', 'DESHACER', 5000 ).onAction().subscribe( () => {

							this.art.baja = 0;

							let artFormData = new FormData();
							artFormData.append( 'data', JSON.stringify( this.art ) );
							this.con.post( artFormData, `arts` ).subscribe( ( res ) => {

								this.snackBar.open( 'Accion deshecha', 'OK', 5000 );

							} )

						} )

					} )
				}
			} )
			.catch( err => {
				this.g.muestraError( err, 'Hubo un error, intente mas tarde' );
			} )
	}
	cancelar() {
		if ( this.isModal ) this.modal.close( true )
	}

	ngAfterViewInit() {
		if ( this.ui.isMobile ) {
			setTimeout( () => {
				this.artCodigoMobile.nativeElement.focus()
			}, 100 );
		}
	}
	ngOnInit() {
		this.suscripciones.push(
			this.store.select( 'ui' ).subscribe( ui => {
				if ( !this.isModal ) this.cargando = ui.isLoading
			} )
		)
	}
	ngOnDestroy() {
		this.suscripciones.forEach( suscripcion => {
			suscripcion.unsubscribe();
		} );
	}

}

@Component( {
	selector: 'app-productos-edita',
	templateUrl: './productos-edita.component.html',
	styleUrls: [ './productos-edita.component.scss' ]
} )
export class ProductosEditaComponentX extends ProductosEditaComponent { }

import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { ConexionService } from './conexion.service';
import { GlobalService } from './global.service';
import { TrackService } from './track.service';

export type pushEstados = 'granted' | 'denied' | 'default' | 'unsupported'

@Injectable({
	providedIn: 'root'
})
export class PushNotificationService {
	estanActivas: Boolean = false
	constructor(
		private swPush: SwPush,
		private trk: TrackService,
		private con: ConexionService,
		private g: GlobalService,
	) {
		this.estanActivas = this._estanActivas()
	}
	//Can't find variable: Notification
	async guardarSuscripcion(suscripcion) {
		console.log('suscripcion', suscripcion)

		let res = await this.con.postP(suscripcion, 'push-server/registrar', true)

		if (!res.ok) return this.trk.trackAccion('accion', 'Push Notification: Error al Guardar')

		this.trk.trackAccion('accion', 'Push Notification: Activadas OK')
		this.estanActivas = this._estanActivas()

	}
	escucharPushNotifications() {
		console.log('Push Service: ACTIVADO');

		this.swPush.notificationClicks.subscribe(N => {
			console.log('Notificacion:', N)
		})
	}

	async pedirSuscripcion() {

		let res = await this.g.SweetMsg('Notificaciones', '¿Desea activar las notificaciones?, esto le permitirá recibir alertas sobre su empresa.', "question")
		if (!res.value) return;

		this.swPush.requestSubscription({
			serverPublicKey: environment.vapidPublicKey
		}).then( async (sub) => {
			console.log('sub > ', sub);
			this.guardarSuscripcion(sub.toJSON())
			this.estanActivas = this._estanActivas()
		}).catch( async (err) => {
			this.estanActivas = this._estanActivas()
			this.trk.trackAccion('accion', 'Push Notification: No se otorgó permiso')
			console.error("No se pudo hacer la suscripción", err)
			this.g.SweetMsg('Notificaciones', 'Parece que las notificaciones estan desactivadas para este dispositivo, deberás activarlas desde las configuraciones de tu nevagador o dispositivo.', "info")

		});
	}

	pushStatus(): pushEstados {
		try {
			return Notification ? Notification.permission : 'unsupported';

		} catch (error) {
			return 'unsupported'
		}
	}
	_estanActivas(): Boolean {
		return this.pushStatus() !== 'granted' ? false : true;
	}

}

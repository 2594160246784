import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SidebarComponent } from './sidebar-pro/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';

import { PipesModule } from '@pipes/pipes.module';

@NgModule({
  declarations: [
    SidebarComponent,
    NavbarComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PipesModule,

    RouterModule,
  ],
  exports: [
    SidebarComponent,
    NavbarComponent,
  ]
})
export class LayoutModule { }

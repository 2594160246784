import { Component, ChangeDetectorRef, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from './app.reducer';

import { WebsocketsService } from '@core/websockets.service'
// import * as $ from 'jquery';
import { UiService } from '@core/ui.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdateService } from '@core/swUpdate.service';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { desactivarLoading } from './layout/ui.actions';

declare var $: any
declare global {
	interface Console {
		stdlog: (payload: any) => void
	}
}

// if (environment.production) {
// 	/**hook de console.log */
// 	console.stdlog = console.log.bind(console);

// 	console.log = function () {
// 		let log: any[];
// 		let logLS = window.localStorage.getItem('oadmin-log');
// 		if (!logLS) {
// 			log = []
// 		} else {
// 			log = JSON.parse(logLS)
// 		}
// 		log.push(Array.from(arguments));
// 		log = log.slice(-100)
// 		window.localStorage.setItem('oadmin-log', JSON.stringify(log))
// 		console.stdlog.apply(console, arguments);
// 	}
// }
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit, AfterViewInit {
	suscripciones: Subscription[] = []
	abierto = true;
	//   mobileQuery: MediaQueryList;
	//   private _mobileQueryListener: () => void;

	constructor(
		public ws: WebsocketsService,
		private auth: AuthService,
		public ui: UiService,
		private store: Store<AppState>,
		private actRoute: ActivatedRoute,
		private router: Router,
		private updates: SwUpdateService,
		private snackbar: MatSnackBar

	) {

		if (!this.ui.isMobile && environment.production) this.freshChat()

		this.updates.updateSW();

		try {
			let datos: string[] | string = window.location.href.split('?')
			if (datos.length > 1) {
				datos = datos.pop().split('|');
				if (datos[0]) window.localStorage.setItem('pagina', datos[0])
				if (datos[1]) window.localStorage.setItem('correo', datos[1])
			}
		} catch (e) { }


	}

	freshChat() {

		return false;

		// function initFreshChat() {
		// 	window['fcWidget'].init({
		// 		token: "e64ee1a8-7778-4706-b0f8-2f15efd7ff87",
		// 		host: "https://wchat.freshchat.com",
		// 		// externalId: this.auth.usu._id.toString() || '',
		// 		// firstName: this.auth.usu.nom || 'no-identificado',
		// 		// lastName: this.auth.usu.ape || 'no-identificado',
		// 		config: {
		// 			content: {
		// 				placeholders: {
		// 					search_field: 'Buscar',
		// 					reply_field: 'Responder',
		// 					csat_reply: 'Agrega tus comentarios aquí'
		// 				},
		// 				actions: {
		// 					csat_yes: 'Si',
		// 					csat_no: 'No',
		// 					push_notify_yes: 'Si',
		// 					push_notify_no: 'No',
		// 					tab_faq: 'Soluciones',
		// 					tab_chat: 'Chat',
		// 					csat_submit: 'Enviar'
		// 				},
		// 				headers: {
		// 					chat: 'Hablemos',
		// 					chat_help: 'Escríbenos si tienes alguna duda.',
		// 					faq: 'Soluciones',
		// 					faq_help: 'Busca en nuestros artículos',
		// 					faq_not_available: 'No se encontraron artículos',
		// 					faq_useful: '¿Este artículo te ayudo?',
		// 					faq_thankyou: 'Gracias por tus comentarios',
		// 					faq_message_us: 'Escríbenos',
		// 					push_notification: '¡No te pierdas de nada! ¿Deseas recibir las útlimas noticias?',
		// 					csat_question: '¿Respopndimos tus dudas?',
		// 					csat_yes_question: '¿Cómo evaluarias la ayuda?',
		// 					csat_no_question: '¿Como podriamos hacerlo mejor?',
		// 					csat_thankyou: 'Gracias por tu respuesta.',
		// 					csat_rate_here: 'Envia tu evaluación',
		// 					channel_response: {
		// 						offline: 'Por el momento no estamos, dejanos un mensaje ',
		// 						online: {
		// 							minutes: {
		// 								one: "Respondiendo en {!time!} minuto ",
		// 								more: "Normalmnete responde en {!time!} minutos"
		// 							},
		// 							hours: {
		// 								one: "Respondiendo en menos de una hora",
		// 								more: "Normalmnete responde en {!time!} horas",
		// 							}
		// 						}
		// 					}
		// 				}
		// 			}
		// 		}
		// 	});

		// }
		// function initialize(i, t) { var e; i.getElementById(t) ? initFreshChat() : ((e = i.createElement("script")).id = t, e.async = !0, e.src = "https://wchat.freshchat.com/js/widget.js", e.onload = initFreshChat, i.head.appendChild(e)) } function initiateCall() { initialize(document, "freshchat-js-sdk") } window.addEventListener ? window.addEventListener("load", initiateCall, !1) : window['attachEvent']("load", initiateCall, !1);
	}

	ngOnInit() {

		//inicializa el escucha de cambios de sesion.
		this.store.dispatch(desactivarLoading());


		this.auth.authListener();

		this.suscripciones.push(
			this.actRoute.queryParams.subscribe((params) => {
				let pagina = params['p'];
				let correo = params['c'];
				if (pagina === 'registro') {
					setTimeout(() => {
						this.router.navigate(['registro', { c: correo }])
					}, 300);
				}
			})
		)

	}
	ngAfterViewInit() {

	}

	ngOnDestroy(): void {
		// this.mobileQuery.removeListener(this._mobileQueryListener);
		this.suscripciones.forEach(s => s.unsubscribe())
	}
}

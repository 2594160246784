import { DataSource } from '@angular/cdk/table';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { ConexionService } from '@root/core/conexion.service';
import { GlobalService } from '@root/core/global.service';
import { UiService } from '@root/core/ui.service';
import { take } from 'rxjs/operators';
import { Fase } from '../../models/fase.model';
import { cambiarPipeline } from '../../models/pipeline.acciones';
import { Pipeline } from '../../models/pipeline.model';

@Component({
	selector: 'app-pipelines-bus',
	templateUrl: './pipelines-bus.component.html',
	styleUrls: ['./pipelines-bus.component.scss']
})
export class PipelinesBusComponent implements OnInit {

	dataSource = new MatTableDataSource([])
	constructor(
		public ui: UiService,
		private route: ActivatedRoute,
		private router: Router,
		private g: GlobalService,
		private con: ConexionService,
		private store: Store<AppState>,
	) { }

	async buscar() {
		try {
			let res = await this.con.getP('crm/pipelines', true)
			if (res.ok) {
				res.data = res.data.map(p => {
					p.cantidadTratos = 0
					p.fases.forEach((f) => {
						p.cantidadTratos += f.tratos.length || 0
					})
					return p;
				});
				this.dataSource = new MatTableDataSource(res.data)
			}
		} catch (error) {

		}
	}
	seleccionaPipelines(pipe: Pipeline) {
		this.store.dispatch(cambiarPipeline({pipeline: pipe}))
		this.router.navigate(['inicio/crm/pipeline'])
	}

	async nuevo() {
		let res: any;

		res = await this.g.SweetMsg('Nuevo Pipeline', '¿Creamos nuevo Pipeline?', 'question')
		if (!res.value) return;

		let pipe = new Pipeline()
		pipe.nombre = "Pipeline"
		pipe.fases = [
			new Fase({ fase: 'On Hold' }),
			new Fase({ fase: 'Prospecto Calificado' }),
			new Fase({ fase: 'Contacto Establecido' }),
			new Fase({ fase: 'Presentacion Agendada' }),
			new Fase({ fase: 'Esperando Pago' }),
		]
		try {
			res = await this.con.postP(pipe, 'crm/pipeline-guardar', true)
			if (res.ok) {
				pipe = new Pipeline(res.data)
				this.store.dispatch(cambiarPipeline({pipeline: pipe}))
				this.router.navigate(['inicio/crm/pipeline'])
			}
		} catch (error) {
			console.log(error)
		}

	}
	ngAfterViewInit() {
		setTimeout(() => {
			this.buscar()
		}, 0);
	}
	ngOnInit() {
		this.route.data.pipe(take(1)).subscribe((data: any) => {

			this.ui.paginaActual = data.titulo || '';
		})
	}

}

import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { DatosService } from '@core/datos.service';
import { GlobalService } from 'src/app/core/global.service';
import { ConexionService } from '@core/conexion.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.reducer';
import { activarLoading } from '../../../layout/ui.actions';
import { UiService } from '@core/ui.service';
import { SnackBarService } from '@core/snackbar.service';
import { AuthService } from '../../../auth/auth.service';
import { CodigoBarrasService } from '@root/modulos/codigo-barras/codigo-barras.service';
import { Fondo } from '@root/models/fondo.model';
import { FondoTipo } from '@root/models/interfaces';
import Swal from 'sweetalert2';


@Component({
	selector: 'app-fondos-edita',
	templateUrl: './fondos-edita.component.html',
	styleUrls: ['./fondos-edita.component.scss']
})
export class FondosEditaComponent implements OnInit, OnDestroy, AfterViewInit {

	suscripciones: Subscription[] = [];
	fondo: Fondo = new Fondo()

	isModal: boolean = false;
	cargando: boolean;
	fondos: FondoTipo[] = []
	constructor(
		public g: GlobalService,
		public ds: DatosService,
		public ui: UiService,
		public auth: AuthService,
		private con: ConexionService,
		private cb: CodigoBarrasService,
		private snackBar: SnackBarService,
		private store: Store<AppState>,
		public modal: MatDialogRef<FondosEditaComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		if (data.modal) {
			this.isModal = true;
			this.fondo = new Fondo(data.fondo)
		}
	}

	guardar() {
		if (!this.fondo.fondo) {
			this.g.SweetMsg('¡Ups!', 'Debe de indicar el nombre de la fondo.', 'warning');
			return
		}
		if (!this.fondo.fondoTipo || !this.fondo.fondoTipo.codigo) {
			this.g.SweetMsg('¡Ups!', 'Debe de indicar el fondo de la fondo (tipo de fondo).', 'warning');
			return
		}


		this.store.dispatch(activarLoading());

		this.ui.loading(true);

		this.con.post(this.fondo, 'fondos', true).subscribe(res => {
			this.ui.loading(false);

			let nvoFondo = new Fondo(res.data);
			this.snackBar.open('Fondo guardada!', 'OK', 5000);

			if (this.isModal) {
				this.modal.close(nvoFondo);
				return;
			}

			this.fondo = new Fondo();
			if (this.isModal) this.modal.close(true)
		})
	}
	async eliminar() {
		let res = await this.g.SweetMsg('Eliminar', `Si elimina el fondo, todos los movimientos que se hayan generado en este fondo serán movidos al fondo <b>${this.fondo.fondoTipo.fon}</b>.<br><br> Esta acción no se puede deshacer ¿desea eliminar el fondo?`, 'question');
		if(!res.value) return

		try {
			let resDB = await this.con.postP(this.fondo, 'fondos/eliminar', true)
			if( resDB.ok ){
				Swal.fire('','Fondo eliminado correctamente', 'success')
			}

		} catch (error) {
			console.log(error)
		}

		this.modal.close()

	}
	cancelar() {
		if (this.isModal) this.modal.close(true)
	}

	ngAfterViewInit() {

	}
	ngOnInit() {
	}
	ngOnDestroy() {
		this.suscripciones.forEach(suscripcion => {
			suscripcion.unsubscribe();
		});
	}

}

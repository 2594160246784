import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { FprDet, Fpr } from '@models/fprs.model';
import { UiService } from './ui.service';
import { ContactosPopupComponent } from '../pop-ups/components/contactos-popup/contactos-popup.component';
import { ArticulosPopupComponent } from '../pop-ups/components/articulos-popup/articulos-popup.component';
import { AgregarArticulosPopupComponent } from '../pop-ups/components/agregarArticulos-popup/agregarArticulos-popup.component';
import { SeleccionarComprobantesPopupComponent } from '../pop-ups/components/seleccionarComprobantes-popup/seleccionarComprobantes-popup.component';
import { AplicarReciboPopupComponent } from '../pop-ups/components/aplicar-recibo-popup/aplicar-recibo-popup.component';

@Injectable({
	providedIn: 'root'
})
export class PopupsService {

	urlAnterior: string;

	constructor(
		public dialog: MatDialog,
		private router: Router,
		private ui: UiService,
	) { }


	contactosPopup(idMdl) {
		let ancho = '100%';
		let alto = '100%';
		// if (!this.ui.isMobile){
		//   ancho = '60%';
		//   alto = '70%';
		// }
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		const dialogo = this.dialog.open(ContactosPopupComponent, {
			width: ancho,
			maxWidth: ancho,
			height: alto,
			panelClass: 'dialogo-popup',
			data: { modal: true, idMdl }
		})

		// Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
		return new Promise((res, rej) => {
			//A esa referencia nos podemos suscribir para esperar respuesta del usuario.
			dialogo.afterClosed().subscribe((result: any) => {
				res(result);
			});
		});
	}
	articulosPopup() {
		let ancho = '100%'
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		const dialogo = this.dialog.open(ArticulosPopupComponent, {
			width: ancho,
			maxWidth: ancho,
			height: '100%',
			panelClass: 'dialogo-popup',
			data: { modal: true }
		})

		// Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
		return new Promise((res, rej) => {
			//A esa referencia nos podemos suscribir para esperar respuesta del usuario.
			dialogo.afterClosed().subscribe((result: any) => {
				res(result);
			});
		});
	}
	agregarArticulosPopup(dets, idMdl = 0) {
		let ancho = '100%'
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		const dialogo = this.dialog.open(AgregarArticulosPopupComponent, {
			width: ancho,
			maxWidth: ancho,
			panelClass: 'dialogo-gris',
			height: '100%',
			data: { modal: true, dets, idMdl }
		})

		// Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
		return new Promise((res, rej) => {
			//A esa referencia nos podemos suscribir para esperar respuesta del usuario.
			dialogo.afterClosed().subscribe((result: any) => {
				res(result);
			});
		});
	}
	seleccionarComprobantesPopup(det) {
		let ancho = '100%'
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		const dialogo = this.dialog.open(SeleccionarComprobantesPopupComponent, {
			width: ancho,
			maxWidth: ancho,
			panelClass: 'dialogo-gris',
			height: '100%',
			data: { modal: true, det }
		})

		// Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
		return new Promise((res, rej) => {
			//A esa referencia nos podemos suscribir para esperar respuesta del usuario.
			dialogo.afterClosed().subscribe((result: any) => {
				res(result);
			});
		});
	}
	aplicarPagoPopup(fd: FprDet, fpr: Fpr) {
		let ancho = '100%'
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		const dialogo = this.dialog.open(AplicarReciboPopupComponent, {
			width: ancho,
			maxWidth: ancho,
			height: '100%',
			closeOnNavigation: true,
			data: { fd, fpr }
		})

		return new Promise((res, rej) => {
			dialogo.afterClosed().subscribe((result: any) => {
				res(result);
			});
		});
	}

}

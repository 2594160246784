import { Injectable } from '@angular/core';
import { DatosService } from '../datos.service';
import Swal from 'sweetalert2';
import { Fcp } from '../../models/fcps.model';
import { ConexionService } from '../conexion.service';
import { Art } from '../../models/arts.model';
import { Fcv, FcvDet } from '../../models/fcvs.model';
import { AuthService } from '@root/auth/auth.service';
import { TrackService } from '@root/core/track.service';
import { GlobalService } from '@root/core/global.service';
import { UiService } from '@root/core/ui.service';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { changeFcv } from '@root/models/fcvs.actions';
import { take } from 'rxjs/operators';
import { Emp } from '@root/models/emps.model';

@Injectable({
	providedIn: 'root'
})
export class EmpService {

	constructor(
		private store: Store<AppState>,
	) { }

	/**
	 * Returns the current state of Emp in the store
	 * @returns Emp
	 */
	get = async (): Promise<Emp> => {
		const emp: Emp = await this.store.select((state: AppState) => state['emp']).pipe(take(1)).toPromise()
		return emp;
	}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ClaveComponent } from './login/clave/clave.component';
import { RegistroComponent } from './login/registro/registro.component';
import { MaterialModule } from '../material.module';
import { FormsModule } from '@angular/forms';
import { ClavesIgualesDirective } from '../validadores/claves-iguales.directive';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ConfirmacionEmailComponent } from './confirmacion-email/confirmacion-email.component';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';


@NgModule({
  declarations: [
    LoginComponent,
    ClaveComponent,
    RegistroComponent,
    ClavesIgualesDirective,
    ConfirmacionEmailComponent,

  ],
  imports: [
    CommonModule,
    GoogleSigninButtonModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,


  ],
  exports: [
    LoginComponent,
    ClaveComponent,
    RegistroComponent,
  ],
  providers: [
    AuthGuard,
    AuthService,

  ]
})
export class AuthModule { }

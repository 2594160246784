// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-mostrar {
	display: none;
}
.contenedor {
	margin-left: 8px
}
.margen-boton-input{
	margin-left: 5px
}
.boton{
	min-width: 120px;
}
.ancho-100{
	width: 90%;
	align-self: center;
	margin-top: 8px;
}
.imagen{
	width: 200px;
	align-self: center;

}

.fa.fa-search{
	font-size: 15px;
    position: relative;
    top: -2px;
}

`, "",{"version":3,"sources":["webpack://./src/app/directivas/components/file-select/file-select.component.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;AACA;CACC;AACD;AACA;CACC;AACD;AACA;CACC,gBAAgB;AACjB;AACA;CACC,UAAU;CACV,kBAAkB;CAClB,eAAe;AAChB;AACA;CACC,YAAY;CACZ,kBAAkB;;AAEnB;;AAEA;CACC,eAAe;IACZ,kBAAkB;IAClB,SAAS;AACb","sourcesContent":[".no-mostrar {\n\tdisplay: none;\n}\n.contenedor {\n\tmargin-left: 8px\n}\n.margen-boton-input{\n\tmargin-left: 5px\n}\n.boton{\n\tmin-width: 120px;\n}\n.ancho-100{\n\twidth: 90%;\n\talign-self: center;\n\tmargin-top: 8px;\n}\n.imagen{\n\twidth: 200px;\n\talign-self: center;\n\n}\n\n.fa.fa-search{\n\tfont-size: 15px;\n    position: relative;\n    top: -2px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

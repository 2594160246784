import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'ceros'
})
export class CerosPipe implements PipeTransform {

  transform(valor: number | string, tamanoTotal: number = 8): string {

	if (valor === '' || typeof(valor) === 'object' || tamanoTotal === 0 || tamanoTotal > 50 ){
        return valor.toString();
    }

	let nvoValor = valor.toString();
	return nvoValor.padStart(tamanoTotal, '0')



  }

}

import { Injectable } from '@angular/core';
import { PagoCC } from '@models/pagoCC.model';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class PasarelaStripeService {

  cerrarSesion = false;
  pago: PagoCC = new PagoCC();

  constructor( ) {
    let ss = window.localStorage.getItem('ss');
    if( ss ) {
      this.pago =  new PagoCC(JSON.parse(atob(ss)));
    }
  }
  valido(): boolean {

    window.localStorage.removeItem('ss');

    if (this.pago.conceptos.length === 0) {
      Swal.fire('¡Ups!', 'No se encontraron conceptos para pagar.', 'warning');
      return false;
    }

    //GUARDA POR REFRESCO
    let data = JSON.stringify(this.pago);
    data = btoa(data);
    window.localStorage.setItem('ss', data);
    return true;
  }

  borrarGuardado(){
    this.pago = new PagoCC();
    window.localStorage.removeItem('ss');
  }


}

import { Injectable, OnDestroy } from '@angular/core';
import { ConexionService } from './conexion.service';
import { Subscription, Subject } from 'rxjs';
import { Imp, UniMed, RegFis, FondoTipo, ForPag, UsoCfdi, Region, Pais, ProSrv } from '@models/interfaces';
import { WebsocketsService } from './websockets.service';
import { Fcp } from '@models/fcps.model';
import { Art } from '@models/arts.model';
import { Usu } from '@models/usu.model';
import { Moneda } from '@models/moneda.model';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { cambiarUsuario } from '../auth/auth.actions';
import { AuthService } from '../auth/auth.service';
import { Sector } from '../models/sectores.model';
import { Rubro } from '../models/rubros.model';
import { Sucursal } from '../models/sucursales.model';
import { cambiarSucursal } from '../models/sucursales.acciones';
import { CurvaAtributos } from '../models/atributo.model';
import { Contacto } from '../models/contacto.model';
import { CmpCategoria } from '@root/models/cmp-categoria.model';
import { Fondo } from '@root/models/fondo.model';
import { AlertasBloqueos } from '@models/alertas-bloqueos.model';

@Injectable({
	providedIn: 'root'
})
export class DatosService implements OnDestroy {
	suscriptions: Subscription[] = []
	datosCargados = false;

	public imps: Imp[] = [];
	public sucs: Sucursal[] = [];
	private sucsObs = new Subject<Sucursal[]>();
	public _sucsObs = this.sucsObs.asObservable();
	public fcps: Fcp[] = [];
	private fcpsObs = new Subject<Fcp[]>();
	public _fcpsObs = this.fcpsObs.asObservable();
	public fondos: Fondo[] = [];
	private fondosObs = new Subject<Fondo[]>();
	public _fondosObs = this.fondosObs.asObservable();
	public contactos: Contacto[] = [];
	private contactosObs = new Subject<Contacto[]>();
	public _contactosObs = this.contactosObs.asObservable();
	public usus: Usu[] = [];
	public cmpCategorias: CmpCategoria[] = [];
	public monedas: Moneda[] = [];
	public alertasBloqueos: AlertasBloqueos = new AlertasBloqueos();
	private alertasBloqueosObs = new Subject<AlertasBloqueos>();
	public _alertasBloqueosObs = this.alertasBloqueosObs.asObservable();
	public pros: Art[] = [];
	private prosObs = new Subject<Art[]>();
	public _prosObs = this.prosObs.asObservable();
	public atributos: CurvaAtributos[] = [];
	private atributosObs = new Subject<CurvaAtributos[]>();
	public _atributosObs = this.atributosObs.asObservable();
	public rubros: Rubro[] = [];
	private rubrosObs = new Subject<Rubro[]>();
	public _rubrosObs = this.rubrosObs.asObservable();
	public subrubros: Rubro[] = [];
	private subrubrosObs = new Subject<Rubro[]>();
	public _subrubrosObs = this.subrubrosObs.asObservable();
	public sectores: Sector[] = [];
	private sectoresObs = new Subject<Sector[]>();
	public _sectoresObs = this.sectoresObs.asObservable();
	public unisMeds: UniMed[] = [];
	public prosSrvs: ProSrv[] = [];
	public regsFiss: RegFis[] = [];
	public fondosTipos: FondoTipo[] = [];
	private fondosTiposObs = new Subject<FondoTipo[]>();
	public _fondosTiposObs = this.fondosTiposObs.asObservable();
	public forsPags: ForPag[] = [];
	public usosCfdis: UsoCfdi[] = [];
	public regiones: Region[] = [];
	public paises: Pais[] = [];

	constructor(
		private con: ConexionService,
		private auth: AuthService,
		private wSockets: WebsocketsService,
		private store: Store<AppState>,
	) {
		console.log('datosService: online')


		this.suscriptions.push(this.store.select('usu').subscribe(usu => {
			if (!usu) {
				this.datosCargados = false;
			}
		}))
	}

	buscarDatos(): Promise<void> {
		return new Promise((ok, er) => {

			let datosPendientes = 0;
			let datosObtenidos = 0;
			if (this.datosCargados) { ok(); return; }
			// IMPUESTOS ------------------------------------------------------------.
			datosPendientes++;
			this.con.getP('data/imps').then((imps) => {
				this.imps = imps.data

				this.wSockets.listen('datos-imps').subscribe(imp => {
					this.imps = this.actualizarAgregar(this.imps, imp, '_id');
				})


				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})


			// CONTACTOS  ------------------------------------------------------------.
			datosPendientes++;
			this.con.getP('crm/contactos').then((contactos) => {
				this.contactos = contactos.data;
				this.contactosObs.next(this.contactos);

				this.wSockets.listen('datos-contactos').subscribe(contacto => {
					this.contactos = this.actualizarAgregar(this.contactos, contacto, '_id', true);
					this.contactosObs.next(this.contactos);
				})
				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})

			// CLIENTES / PROVEEDORES ------------------------------------------------.
			datosPendientes++;
			this.con.getP('fcps/2-3').then((fcps) => {
				this.fcps = fcps.data;
				this.fcpsObs.next(this.fcps);

				this.wSockets.listen('datos-fcps').subscribe(fcp => {
					this.fcps = this.actualizarAgregar(this.fcps, fcp, '_id');
					this.fcpsObs.next(this.fcps);
				})
				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})

			// SUCURSALES  ------------------------------------------------------------.
			datosPendientes++;
			this.con.getP(`sucursales`).then((sucs) => {
				this.sucs = sucs.data;
				this.sucsObs.next(this.sucs);

				if (this.auth.usu.idSuc && this.auth.usu.idSuc.length > 5) {
					//tiene susucrsal restringida, hay que asignarla al auth
					let suc = this.sucs.filter((s) => s._id === this.auth.usu.idSuc)[0]
					this.store.dispatch(cambiarSucursal({obj: suc}))
				}

				this.wSockets.listen('datos-sucursales').subscribe(suc => {
					this.sucs = this.actualizarAgregar(this.sucs, suc, '_id');
					this.sucsObs.next(this.sucs);
				})
				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})

			// CATEGORIAS COMPROBANTES ----------------------------------------------.
			datosPendientes++;
			this.con.getP('data/cmp-categorias').then((cmpCategorias) => {

				this.cmpCategorias = cmpCategorias.data.map(c => new CmpCategoria(c));

				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})
			// USUARIOS -------------------------------------------------------------.
			datosPendientes++;
			this.con.getP('usus').then((usus) => {
				this.usus = usus.data;

				this.wSockets.listen('datos-usus').subscribe((value) => {
          const usu = value as Usu;
					this.usus = this.actualizarAgregar(this.usus, usu, '_id');
					if (usu._id === this.auth.usu._id) {
						console.log('Es el mismo usuario!, se van a cambiar las propiedades.');
						this.store.dispatch(cambiarUsuario({obj: usu}))
					}
				})

				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})

			// ALERTAS BLOQUEOS --------------------------------------------------------.
			datosPendientes++;
			this.con.getP('api/alertas-bloqueos').then((alertasBloqueos) => {
				this.alertasBloqueos = new AlertasBloqueos(alertasBloqueos.data);
				this.alertasBloqueosObs.next(this.alertasBloqueos);

				this.wSockets.listen('alertas-bloqueos').subscribe(alertaBloqueo => {
					console.log('RECIBIO ALERTAS');
					if (alertaBloqueo === '@ELIMINAR') {
						this.alertasBloqueos = new AlertasBloqueos();
						this.alertasBloqueosObs.next(this.alertasBloqueos);
					} else {
						this.alertasBloqueos = new AlertasBloqueos(alertaBloqueo);
						this.alertasBloqueosObs.next(this.alertasBloqueos);
					}

				})
				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}

			})
			// PRODUCTOS ------------------------------------------------------------.
			datosPendientes++;
			this.con.getP('arts/7').then((pros) => {
				this.pros = pros.data;
				this.prosObs.next(this.pros);

				this.wSockets.listen('datos-arts-7').subscribe(pro => {
					if (pro === '@ACTUALIZAR') {
						console.log('Todos los productos actualizados...')
						this.con.getP('arts/7').then((pros) => {
							this.pros = pros.data;
							this.prosObs.next(this.pros);
						})
					} else {
						this.pros = this.actualizarAgregar(this.pros, pro, '_id');
						this.pros = this.pros.filter(p => {
							return p.baja === 0
						})
						this.prosObs.next(this.pros);
					}

				})
				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}

			})
			// ATRIBUTOS ARTICULOS ----------------------------------------------------.
			datosPendientes++;
			this.con.getP('atributos').then((atributos) => {
				this.atributos = atributos.data;
				this.atributosObs.next(this.atributos);

				this.wSockets.listen('datos-atributos').subscribe(rubro => {

					this.atributos = this.actualizarAgregar(this.atributos, rubro, '_id', true);
					this.atributosObs.next(this.atributos);

				})
				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}

			})
			// RUBROS ARTICULOS ------------------------------------------------------.
			datosPendientes++;
			this.con.getP('rubros/7').then((rubros) => {
				this.rubros = rubros.data.filter((r: Rubro) => { return !r.idRubro })
				this.subrubros = rubros.data.filter((r: Rubro) => { return r.idRubro })
				this.rubrosObs.next(this.rubros);
				this.subrubrosObs.next(this.subrubros);

				this.wSockets.listen('datos-rubros-7').subscribe((value) => {
          const rubro: Rubro = value as Rubro;
					if (rubro.idRubro) {
						// subrubro
						this.subrubros = this.actualizarAgregar(this.subrubros, rubro, '_id', true);
						this.subrubrosObs.next(this.subrubros);
					} else {
						//rubro
						this.rubros = this.actualizarAgregar(this.rubros, rubro, '_id', true);
						this.rubrosObs.next(this.rubros);
					}

				})
				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}

			})
			// SECTORES -------------------------------------------------------------.
			datosPendientes++;
			this.con.getP('sectores').then((res: any) => {
				this.sectores = res.data;
				this.sectores.splice(0, 0, Sector.predeterminado())
				this.sectoresObs.next(this.sectores);

				this.wSockets.listen('datos-sectores').subscribe(sectores => {
					this.sectores = this.actualizarAgregar(this.sectores, sectores, '_id', true);
					this.sectoresObs.next(this.sectores);
				})
				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}

			})
			// MONEDAS -------------------------------------------------------------.
			datosPendientes++;
			this.con.getP('tipoCambio').then((monedas) => {
				this.monedas = monedas.data;

				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})
			// UNIDAD DE MEDIDA -----------------------------------------------------.
			datosPendientes++;
			this.con.getP('data/unisMeds').then((unisMeds) => {
				this.unisMeds = unisMeds.data;

				this.wSockets.listen('unis-meds').subscribe(uniMed => {
					this.unisMeds = this.actualizarAgregar(this.unisMeds, uniMed, '_id');
				})

				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})

			// REGIMENES FISCALES --------------------------------------------------.
			datosPendientes++;
			this.con.getP('data/regsfiss').then((regsFiss) => {
				this.regsFiss = regsFiss.data;

				this.wSockets.listen('regs-fiss').subscribe(regFis => {
					this.regsFiss = this.actualizarAgregar(this.regsFiss, regFis, '_id');
				})

				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})

			// FONDOS / CAJAS -------------------------------------------------------.
			datosPendientes++;
			this.con.getP('data/fondos-tipos').then((fondosTipos) => {
				this.fondosTipos = fondosTipos.data;
				this.fondosTiposObs.next(this.fondosTipos);

				datosPendientes++;
				this.con.getP('fondos').then((fondos) => {
					/* usa los fondos para generar fondos + las fondos del usuario */
					this.fondos = fondos.data;
					this.fondosTipos.forEach(f => {
						let fondo: Fondo = {
							_id: f.codigo,
							fondo: f.fon,
							fondoTipo: f
						}
						this.fondos.push(fondo)
					})
					this.fondosObs.next(this.fondos);

					this.wSockets.listen('fondos').subscribe(fondo => {
						if (fondo === '@ACTUALIZAR') {
							this.con.getP('fondos').then((fondos) => {
								console.log('Todos los fondos actualizados...')
								this.fondos = []
								this.fondos = fondos.data;
								this.fondosTipos.forEach(f => {
									let fondo: Fondo = {
										_id: f.codigo,
										fondo: f.fon,
										fondoTipo: f
									}
									this.fondos.push(fondo)
								})
								this.fondosObs.next(this.fondos);
							})
						} else {
							this.fondos = this.actualizarAgregar(this.fondos, fondo, '_id');
							this.fondosObs.next(this.fondos);
						}

					})

					datosObtenidos++;
					if (datosObtenidos === datosPendientes) {
						this.datosCargados = true;
						ok();
					}

				})
				this.wSockets.listen('fondosTipos').subscribe(fon => {
					this.fondosTipos = this.actualizarAgregar(this.fondosTipos, fon, '_id');
					this.fondosTiposObs.next(this.fondosTipos);
				})

				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})
			// FORMAS DE PAGO -------------------------------------------------------.
			datosPendientes++;
			this.con.getP('data/forsPags').then((forsPags:{data:ForPag[]}) => {
				try {

					forsPags.data = forsPags.data.sort((a, b) => {
						var textA = a.codigo.toUpperCase();
						var textB = b.codigo.toUpperCase();
						return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
					})
				} catch (error) {
					console.log(error)
					forsPags.data = []
				}
				this.forsPags = forsPags.data;

				this.wSockets.listen('fors-pags').subscribe(forPag => {
					this.forsPags = this.actualizarAgregar(this.forsPags, forPag, '_id');
				})

				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})
			// USOS DEL CFDI ---------------------------------------------------------.
			datosPendientes++;
			this.con.getP('data/usoscfdis').then((usosCfdis: { data: UsoCfdi[] }) => {
				try {

					usosCfdis.data = usosCfdis.data.sort((a, b) => {
						var textA = a.usoCfdi.toUpperCase();
						var textB = b.usoCfdi.toUpperCase();
						return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
					})
				} catch (error) {
					console.log(error)
					usosCfdis.data = []
				}

				this.usosCfdis = usosCfdis.data;

				this.wSockets.listen('usos-cfdis').subscribe(usoCfdi => {
					this.usosCfdis = this.actualizarAgregar(this.usosCfdis, usoCfdi, '_id');
				})

				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})
			// REGIONES --------------------------------------------------------------.
			datosPendientes++;
			this.con.getP('data/regiones').then((regiones) => {
				this.regiones = regiones.data;

				this.wSockets.listen('regiones').subscribe(region => {
					this.regiones = this.actualizarAgregar(this.regiones, region, '_id');
				})

				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})
			// PAISES -----------------------------------------------------------------.
			datosPendientes++;
			this.con.getP('data/paises').then((paises) => {
				this.paises = paises.data;

				this.wSockets.listen('paises').subscribe(pais => {
					this.paises = this.actualizarAgregar(this.paises, pais, '_id');
				})

				datosObtenidos++;
				if (datosObtenidos === datosPendientes) {
					this.datosCargados = true;
					ok();
				}
			})
		})
	}

	actualizarAgregar(arreglo: any[], obj: any, propiedadCompara: string, filtrarBaja = false) {
		if (!arreglo || !obj || !propiedadCompara) return;

		let flag = false

		arreglo = arreglo.map(d => {

			if (d[propiedadCompara] === obj[propiedadCompara]) {
				flag = true;
				d = obj;
			}
			return d;
		})
		if (!flag) {
			arreglo.push(obj);
		}

		if (filtrarBaja) {
			arreglo = arreglo.filter(e => e.baja === 0)
		}

		return arreglo;
	}

	ngOnDestroy() {
		this.suscriptions.forEach(s => s.unsubscribe())
	}

}

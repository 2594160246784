import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UiService } from './ui.service';
import { MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private sb: MatSnackBar,
    private ui: UiService,
  ) { }

  open( message:string, accion:string='', duracion:number = 4000,  ): MatSnackBarRef<any> {

    let position: 'top' | 'bottom' = 'bottom'

    if ( this.ui.isMobile ) position = 'top';

    let config = {
      duration:duracion,
      verticalPosition: position,
      openFromTemplate:`snack.html`
    }

    return this.sb.open( message, accion, config )
  }

}

import { Injectable } from '@angular/core';
import { ConexionService } from './conexion.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { Emp } from '@models/emps.model';
import { Fpr, FprDet } from '@models/fprs.model';
import { NumerosFormatoPipe } from '@pipes/numerosFormato.pipe';
import { CerosPipe } from '@pipes/ceros.pipe';
import { getMonedaByMonId, numeroALetras } from 'src/assets/js/numerosALetras'
import { take } from 'rxjs/operators';
import { Fcp } from '@models/fcps.model';
import moment from 'moment';
import * as jsPDF from 'jspdf'

@Injectable( {
	providedIn: 'root'
} )
export class PdfRecibo {
	numerosFormato = new NumerosFormatoPipe
	ceros = new CerosPipe
	selloDigitalTest = `l1JACxVsFp7UVUvJMonsrz69nGvAIwZqrBTowJHZVA6QJEA9a0tbKrLoC2mMatrGBwjAA+YT7JRrGyR95YHLkSOcwgisK42UnktiU4cgWYwTWf9/URExxP5RsdJPWSpAoafQoNo7UtmGrcL2YJCQhuw5+z265O9lXFOWzJeEjPg=`;
	selloDigitalSATTest = `iAYPzX9uZncTnGvq98h1octgONCNKJ90+IUP39OYhQ4nT+/DVVwLqY6xNraAY7WG2Y4vLksBDD7NpHs076MZxC49PHCXJsfidmJnjybI3hRrGpA22dPNVvISMks404KsIgqCX56aFd0Ljr+ENnr2b4+q7z2GgqsgMo1+Q1JzGHu6Qf0qctoXeuIJPNa6rQywokFwWU+MPuI01kwBp7ePTSdRlWJ/9tw0sBWaU/PalmvXgfHhjrIsFssFSXwcimDzZw9UAo2/V6v0H4NR3G70J2ACcJUiA7UbGczsPCMWKoVLICHMRgz0HZCkNGYj2+y36BzuN2U9T0DnpQCsyeTQA==`;
	cadenaOriginalTest = `||1.1|FBC362EA-DED7-405C-BA85-5F0004044DC1|2019-02-27T10:51:44|l1JACxVsFp7UVUvJMonsrz69nGvAIwZqrBTowJHZVA6QJEA9a0tbKrLoC2mMatrGBwjAA+YT7JRrGyR95YHLkSOcwgisK42UnktiU4cgWYwTWf9/URExxP5RsdJPWSpAoafQoNo7UtmGrcL2YJCQhuw5+z265O9lXFOWzJeEjPg=|00001000000403442064||`;
	enters = 0

	// imagenesParaAgregar:imagenParaAgregar[] = []
	imagenesCargadas: number = 0
	imagenesPendientes: number = 0
	emp: Emp;
	fpr: Fpr = new Fpr();
	tamLinea = 5;
	linea = 5;

	Y = 0;
	X = 0;
	constructor(
		private con: ConexionService,
		private store: Store<AppState>,

	) { }

	/**
	 * La funcion genera un PDF de Pago  con los datos del pago desde el store, o bien usando datos hardcodeados de prueba.
	 *
	 * @param contenedor : Elemento canvas tipo elementRF (usando notacion Viewchild # ) >>> <iframe fxFlex="100" type="application/pdf" #miVisor class="miVisor"></iframe>
	 * @param demo : Si se va a usar (default true) o no (false) los datos de prueba y no un comprobante real del store
	 * @param blobQR : QR en formato blob string
	 * @param guardar : Si se va a devolver el bloburi (default) o se va a guardar
	 */
	generarPDF( contenedor?: any, demo: boolean = true, blobQR: string = '', salida: 'GUARDAR' | 'VISTA' | 'BLOB' = 'VISTA' ): Promise<any> {

		return new Promise( ( ok, er ) => {


			new Promise( ( res, rej ) => {

				this.store
					.pipe( take( 1 ) )
					.subscribe( store => {
						if ( !demo ) { this.fpr = store.fpr; }
						this.emp = this.fpr?.idEst !== 10 ? this.fpr?.emp : store.emp;
						//PRE-CARGA DE IMAGENES
						let imagenes = {}
						let cantidadImagenes = 3
						this.con.sGetImg( this.emp.logo, 'logos' ).
							then( logoUrl => {
								// codigoQR
								let codigoQR = this.imagenObjetoHTML( blobQR );
								codigoQR.onload = () => {
									imagenes = { ...imagenes, codigoQR };
									if ( Object.keys( imagenes ).length === cantidadImagenes ) { res( imagenes ); }
								}
								// logo
								let logo = this.imagenObjetoHTML( logoUrl );
								logo.onload = () => {
									imagenes = { ...imagenes, logo };
									if ( Object.keys( imagenes ).length === cantidadImagenes ) { res( imagenes ); }
								}
								// formato
								let formatoUrl = 'assets/formatosPDF/p1-gris-rojo.jpg';
								formatoUrl = `assets/formatosPDF/${Number( this.emp.formatoPDF ) || 1}p.jpg`;
								let formato = this.imagenObjetoHTML( formatoUrl );
								formato.onload = () => {
									imagenes = { ...imagenes, formato };
									if ( Object.keys( imagenes ).length === cantidadImagenes ) { res( imagenes ); }
								}
							} )
					} );

			} ).then( ( imagenes: any ) => {
				// SEDECLARAN IMAGENES QUE SE VAN A USAR
				let formato = imagenes.formato;
				let logo = imagenes.logo;
				let codigoQR = imagenes.codigoQR;
				// CONFIGURACION DE PAGINA ------------------------------------------------------.
				let gris = [ 65, 65, 67 ];
				let blanco = [ 255, 255, 255 ];
				let blanco_gris = [ 235, 235, 235 ];
				let separador = [ 240, 240, 240 ];
				let configuracioPagina = {
					orientation: 'portrait',
					unit: 'mm',
					format: 'a4',
					compressPdf: true,
				}
				let doc = new jsPDF( configuracioPagina )
				let hojaAncho = 210;
				let hojaAlto = 297;
				// ------------------------------------------------------------------------------.
				// FORMATO ----------------------------------------------------------------------.
				let ratio = ( <any>formato ).width / ( <any>formato ).height
				let ancho = 210;
				let alto = 89;
				doc.addImage( formato, '', 0, 0, ancho, alto )
				// ------------------------------------------------------------------------------.
				// LOGO -------------------------------------------------------------------------.
				ancho = ( <any>logo ).width;
				alto = ( <any>logo ).height;
				ratio = ( <any>logo ).width / ( <any>logo ).height

				if ( alto >= ancho ) {
					alto = 37;
					ancho = alto * ratio;

				} else {
					ancho = 37;
					alto = ancho / ratio;
				}
				doc.addImage( logo, '', 2, 4, ancho, alto );

				// ------------------------------------------------------------------------------.
				// TIPO DE COMPROBANTE ----------------------------------------------------------.
				this.X = 204
				this.Y = 9
				doc.setFontSize( 14 ); this.tamLinea = 5;
				doc.setTextColor( ...blanco_gris );
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'C. DE PAGO', this.X, this.Y, { align: 'right' } );
				// ------------------------------------------------------------------------------.
				// DATOS CABECERA ---------------------------------------------------------------.
				doc.setFontSize( 10 ); this.tamLinea = 5;
				doc.setTextColor( ...blanco_gris );
				doc.setFont( 'Helvetica', '' );
				this.X = 180
				this.Y = 17
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Folio:', this.X, this.Y, { align: 'right' } );
				doc.setFont( 'Helvetica', '' ); doc.text( `${this.ceros.transform( this.fpr.numero, 10 ) || '0000000001'}`, this.X + 2, this.Y );
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Emisión:', this.X, this.sigLinea(), { align: 'right' } );
				doc.setFont( 'Helvetica', '' ); doc.text( `${moment( this.fpr.fecEmi ).format( 'DD-MM-YYYY' ) || '01-01-2000'}`, this.X + 2, this.Y );
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Pago:', this.X, this.sigLinea(), { align: 'right' } );
				doc.setFont( 'Helvetica', '' ); doc.text( `${moment( this.fpr.fecPag ).format( 'DD-MM-YYYY' ) || '01-01-2000'}`, this.X + 2, this.Y );
				doc.setFontSize( 9 ); this.tamLinea = 4;
				this.X = 148
				this.Y = 35
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'UUID', this.X, this.Y )
				doc.setFontSize( 8 );
				doc.setFont( 'Helvetica', '' ); doc.text( `${this.fpr.uuid || 'PRUEBAPRU-DED7-405C-TEST-TEST0404TEST'}`, this.X, this.sigLinea() )
				// ------------------------------------------------------------------------------.
				// DATOS EMISOR -----------------------------------------------------------------.
				doc.setFontSize( 9 ); this.tamLinea = 4;

				doc.setTextColor.apply(null, (this.emp.formatoPDF == 7 ? gris : blanco_gris));
				doc.setFont( 'Helvetica', '' );
				this.X = 42
				this.Y = 14
				doc.setFont( 'Helvetica', 'bold' ); doc.text( this.emp.emp, this.X, this.Y )
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Razón Social:', this.X, this.sigLinea() );
				doc.setFont( 'Helvetica', '' ); doc.text( this.emp.rso, this.X + 22, this.Y );
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Domicilio:', this.X, this.sigLinea() );
				doc.setFont( 'Helvetica', '' ); doc.text( `${this.emp.fisDom} No. ${this.emp.fisDomNro}${this.emp.fisDomInt ? ' Int. ' + this.emp.fisDomInt : ''}`, this.X + 18, this.Y);
				doc.text(`${this.emp.fisDomCol}, ${this.emp.fisDomMun}, ${this.emp.fisDomRegion.region}, ${this.emp.fisDomPais.name}`, this.X, this.sigLinea());
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'RFC:', this.X, this.sigLinea() );
				doc.setFont( 'Helvetica', '' ); doc.text( this.emp.rfc, this.X + 8, this.Y );
				doc.text( this.emp.regFis.regFis, this.X, this.sigLinea() );
				// ------------------------------------------------------------------------------.
				// DATOS RECEPTOR ---------------------------------------------------------------.
				doc.setFontSize( 9 ); this.tamLinea = 4;
				doc.setTextColor( ...gris );
				doc.setFont( 'Helvetica', '' );
				this.X = 5
				this.Y = 52
				doc.setFont( 'Helvetica', 'bold' ); doc.text( `${!demo ? this.fpr.fcp.fcp : 'Empresa De Prueba'}`, this.X, this.Y )
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Razón Social:', this.X, this.sigLinea() );
				doc.setFont( 'Helvetica', '' ); doc.text( `${!demo ? this.fpr.fcp.rso : 'Empresa Prueba SRL'}`, this.X + 22, this.Y );
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Domicilio:', this.X, this.sigLinea() );
				doc.setFont( 'Helvetica', '' ); doc.text( `${!demo ? Fcp.getDomicilioFiscal( this.fpr.fcp ) : 'Calle con un nombre ext. 670 int D'}`, this.X + 18, this.Y );
				doc.text( `${!demo ? Fcp.getLocalidadFiscal( this.fpr.fcp ) : 'Coloni Barrio, Localidad, Partido, Estado, Pais'}`, this.X, this.sigLinea() );
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'RFC:', this.X, this.sigLinea() );
				doc.setFont( 'Helvetica', '' ); doc.text( `${!demo ? this.fpr.fcp.rfc : 'ASDF880623MF1'}`, this.X + 8, this.Y );
				doc.text( `${!demo ? this.fpr.fcp.regFis.regFis : 'Regimen de Incorporacion Fiscal'}`, this.X, this.sigLinea() );
				// ------------------------------------------------------------------------------.
				// DATOS CABECERA ---------------------------------------------------------------.
				doc.setFontSize( 9 ); this.tamLinea = 4;
				doc.setTextColor( ...gris );
				doc.setFont( 'Helvetica', '' );

				this.X = 146
				this.Y = 55
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Fecha Certificacion', this.X, this.Y, { align: 'right' } );
				doc.setFont( 'Helvetica', '' ); doc.text( moment( this.fpr.fecCer ).format( 'YYYY-MM-DDTHH:mm:ss' ), this.X + 2, this.Y );
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'No. Serie Certificado SAT', this.X, this.sigLinea(), { align: 'right' } );
				doc.setFont( 'Helvetica', '' ); doc.text( `${this.fpr.nroSerSat || 'T00000000000000000'}`, this.X + 2, this.Y );
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'No. Certificado', this.X, this.sigLinea(), { align: 'right' } );
				doc.setFont( 'Helvetica', '' ); doc.text( `${this.fpr.nroSerCsdEmi || 'T00000000000000000'}`, this.X + 2, this.Y );
				// doc.setFont('Helvetica', 'bold'); doc.text('Método de Pago', this.X, this.sigLinea(), { align: 'right' });
				// doc.setFont('Helvetica', ''); doc.text(`${this.fpr.metPag.metPag || 'PPD - Pago en parcialidades o diferido (T)'}`, this.X + 2, this.Y);
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Forma de Pago', this.X, this.sigLinea(), { align: 'right' } );
				doc.setFont( 'Helvetica', '' ); doc.text( `${this.fpr.fon.fondoTipo.codigo || '01 - Efectivo(T)'}`, this.X + 2, this.Y );
				// ------------------------------------------------------------------------------.

				// ------------------------------------------------------------------------------.
				// ----------------------------------- ARTICULOS --------------------------------.
				// ------------------------------------------------------------------------------.
				doc.setFontSize( 8 ); this.tamLinea = 4;
				doc.setTextColor( ...gris );
				doc.setFont( 'Helvetica', '' );
				this.Y = 95
				this.fpr.fprsDets.forEach( ( fd: FprDet ) => {

					this.X = 23
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Parcialidad:', this.X, this.Y, { align: 'right' } );
					doc.setFont( 'Helvetica', '' ); doc.text( `${fd.pagoParcialidad || -1}`, this.X + 2, this.Y );

					this.X = 38
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'UUID:', this.X, this.Y, { align: 'right' } );
					doc.setFont( 'Helvetica', '' ); doc.text( `${fd.fcv.uuid || 'FBC362EA-DED7-405C-TEST5-TESTTEST4DC1'}`, this.X + 2, this.Y );

					this.X = 123
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Importe:', this.X, this.Y, { align: 'right' } );
					doc.setFont( 'Helvetica', '' ); doc.text( `$ ${this.numerosFormato.transform( fd.aplicado, 2 ) || '1,000,000.25'}`, this.X + 2, this.Y );

					this.X = 165
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Saldo Anterior:', this.X, this.Y, { align: 'right' } );
					doc.setFont( 'Helvetica', '' ); doc.text( `$ ${this.numerosFormato.transform( fd.fcv.saldo, 2 ) || '1,000,000.25'}`, this.X + 2, this.Y );

					this.X = 165
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Saldo Insoluto:', this.X, this.Y + 5, { align: 'right' } );
					doc.setFont( 'Helvetica', '' ); doc.text( `$ ${this.numerosFormato.transform( fd.saldo, 2 ) || '1,000,000.25'}`, this.X + 2, this.Y + 5 );

					this.X = 23
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Folio:', this.X, this.Y + 5, { align: 'right' } );
					doc.setFont( 'Helvetica', '' ); doc.text( `${this.ceros.transform( fd.fcv.numero, 8 ) || '000000000'}`, this.X + 2, this.Y + 5 );

					doc.setDrawColor( ...separador );
					doc.line( 4, this.Y + 8, 200, this.Y + 8 ) //LINEA SEPARADORA
					this.Y += 14;
				} )

				// ------------------------------------------------------------------------------.
				// ------------------------------------------------------------------------------.

				// TOTALES ----------------------------------------------------------------------.
				doc.setFontSize( 9 ); this.tamLinea = 4;
				doc.setTextColor( ...gris );
				doc.setFont( 'Helvetica', '' );

				doc.setFontSize( 11 ); this.tamLinea = 6;
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'TOTAL', 145, this.sigLinea(), { align: 'right' } );
				doc.setFont( 'Helvetica', 'bold' ); doc.text( `$ ${this.numerosFormato.transform( this.fpr.total, 2 )}`, 187, this.Y, { align: 'right' } );
				// ------------------------------------------------------------------------------.
				// TOTAL LETRA + MONEDA ---------------------------------------------------------.
				doc.setFontSize( 9 ); this.tamLinea = 4;
				doc.setTextColor( ...gris );
				doc.setFont( 'Helvetica', '' );

				this.X = 8;
				this.Y = 280;
				if ( this.fpr.uuid || demo ) this.Y = 245;

				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'TOTAL LETRA: ', this.X, this.Y );
				doc.setFont( 'Helvetica', '' ); doc.text( numeroALetras( this.fpr.total, getMonedaByMonId(this.fpr.idMon), true ), this.X + 25, this.Y );
				doc.setFont( 'Helvetica', 'bold' ); doc.text( 'MONEDA: ', this.X, this.sigLinea() );
				doc.setFont( 'Helvetica', '' ); doc.text( `${this.fpr ? this.fpr.idMon : 'MXN'} [Cot. ${this.fpr ? this.numerosFormato.transform( this.fpr.monCot, 2 ) : '1.00'}]`, this.X + 25, this.Y );
				// ------------------------------------------------------------------------------.
				// CADEDNAS ---------------------------------------------------------------------.
				if ( this.fpr.uuid || demo ) {
					this.X = 42;
					this.Y = 255;
					this.tamLinea = 3;
					doc.setFontSize( 9 );
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Sello Digital del CFDI', this.X, this.Y );
					doc.setFontSize( 6 );
					doc.setFont( 'Helvetica', '' ); doc.text( this.insertaEnter( this.fpr.selloCfdi ? this.fpr.selloCfdi : this.selloDigitalTest, 130 ), this.X, this.sigLinea() );
					doc.setFontSize( 9 );
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Sello Digital del SAT', this.X, this.sigLinea( this.enters ) );
					doc.setFontSize( 6 );
					doc.setFont( 'Helvetica', '' ); doc.text( this.insertaEnter( this.fpr.selloSat ? String( this.fpr.selloSat ) : this.selloDigitalSATTest, 130 ), this.X, this.sigLinea() );
					doc.setFontSize( 9 );
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'Cadena Orginal del complento de certificacion digital del SAT.', this.X, this.sigLinea( this.enters ) );
					doc.setFontSize( 6 );
					doc.setFont( 'Helvetica', '' ); doc.text( this.insertaEnter( this.fpr.cadOri ? this.fpr.cadOri : this.cadenaOriginalTest, 130 ), this.X, this.sigLinea() );
				}
				// -----------------------------------------------------------------------------.
				// LEYENDA DE FINAL DE PAGINA --------------------------------------------------.
				this.X = 70;
				doc.setFontSize( 8 );
				if ( this.fpr.uuid ) {
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'ESTA ES UNA REPRESETANCIÓN DE UN CFDI', this.X, this.sigLinea( this.enters ) );
				} else {
					doc.setFont( 'Helvetica', 'bold' ); doc.text( 'ESTE DOCUMENTO NO TIENE VALIDEZ FISCAL', this.X, this.sigLinea( this.enters ) );
				}
				// -----------------------------------------------------------------------------.
				// CODIGO DE BARRAS BIDIMENCIONAL (CBB) ----------------------------------------.
				this.X = 7;
				this.Y = 252;
				doc.addImage( codigoQR, '', this.X, this.Y, 33, 33 );
				// ------------------------------------------------------------------------------.
				let dato = '';

				switch ( salida ) {
					case 'GUARDAR':
						doc.save( 'Factura-' + new Date().getDate() + '.pdf' )
						ok( false );
						break;
					case 'VISTA':
						dato = doc.output( 'bloburi' );
						ok( dato );
						break;
					case 'BLOB':
						dato = doc.output( 'blob' );
						ok( dato );
						break;
					default:
						dato = 'Salida no valida';
						break;
				}



			} )
				.catch( e => {
					console.log( 'Aca ta el error: ', e );

					er( e );
				} )
		} )

	}

	imagenObjetoHTML( rutaImg: string ): HTMLElement {
		let obj = document.createElement( "img" )
		obj.src = rutaImg;
		return obj;
	}
	sigLinea( lineas?: number ): number {
		lineas = lineas ? lineas : 1;
		this.Y += ( this.tamLinea * lineas )
		return this.Y;
	}
	insertaEnter( texto: string, cadaCuantosCaracteres: number ): string {

		if ( texto.length < cadaCuantosCaracteres ) return texto

		this.enters = ( texto.match( /\n/g ) || [] ).length + 1; //CUENTA LOS ENTERS QUE YA TENIA..
		let textoConEnters = [];

		let posicion = 0
		let i = 0
		for ( ; i < texto.length; i++ ) {
			let caracter = texto[ i ]
			textoConEnters.push( caracter )
			if ( caracter === '\n' ) posicion = 0;
			if ( posicion === cadaCuantosCaracteres ) {
				textoConEnters.push( '\n' );
				this.enters++;
				posicion = 0
			}
			posicion++
		}
		return textoConEnters.join( '' );

	}
}

import { createAction, props } from '@ngrx/store';

export const cambiarTrato = createAction(
  '[CRM] Cambiar Trato',
  props<{ trato: object }>()
);

export const cambiarPropiedadTrato = createAction(
  '[CRM] Cambiar Propiedad Trato',
  props<{ prop: object }>()
);

export type AccionesValidas = ReturnType<typeof cambiarTrato> | ReturnType<typeof cambiarPropiedadTrato>;

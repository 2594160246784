// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  background-color: #e4e4e4;
  padding: 16px;
}

h6 {
  margin-bottom: 16px;
}

.mercancias .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/carta-porte-data/carta-porte-data.component.scss"],"names":[],"mappings":"AACA;EACC,yBAAA;EACA,aAAA;AAAD;;AAEA;EACC,mBAAA;AACD;;AAEC;EACC,4BAAA;AACF","sourcesContent":["\nsection {\n\tbackground-color: #e4e4e4;\n\tpadding: 16px;\n}\nh6 {\n\tmargin-bottom: 16px;\n}\n.mercancias {\n\t.mat-form-field-wrapper {\n\t\tpadding-bottom: 0 !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { GlobalService } from 'src/app/core/global.service';


@Injectable({
  providedIn: 'root'
})
export class WebsocketsService {

  public conectado = false;
  public registrado = false;

  constructor(
    private socket: Socket,
    private g: GlobalService,
  ) {
    this.checkStatus();
  }

  isLogedIn(){

    let usu = JSON.parse(this.g.getDato('usu'));
    if ( usu ) {
      this.emit('registrar-cliente', usu);
    }
  }

  checkStatus(){

    this.socket.on('connect', () => {
      this.conectado = true;
      this.isLogedIn();
    });

    this.socket.on('disconnect', () => {
      this.conectado = false;
    });
  }

  emit(evento: string, payload?:any, callback?:Function){
    this.socket.emit( evento, payload, callback )
  }

  listen(evento: string){
    return this.socket.fromEvent(evento);
  }

  registro( registrar: boolean, payload?: any ){

    if ( registrar ) {

      if (this.registrado) return;
      this.registrado = true;
      this.emit('registrar-cliente', payload);

    } else {

      if (!this.registrado) return;
      this.registrado = false;
      this.emit('desregistrar-cliente');

    }
  }
}

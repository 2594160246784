export type TipoComunicacion = 'Casa' | 'Trabajo' | 'Otro'

export class ContactoEmail {
	tipo: TipoComunicacion = 'Trabajo'
	email: string = ''
	constructor(email: string, tipo: TipoComunicacion) {
		this.tipo = tipo || this.tipo
		this.email = email || this.email
	}
}
export class ContactoTel {
	tipo: TipoComunicacion = 'Trabajo'
	tel: string = ''
	constructor(tel: string, tipo: TipoComunicacion) {
		this.tipo = tipo || this.tipo
		this.tel = tel || this.tel
	}
}
export class Contacto {

	public _id: string = '';
	public idUsu: string = '';
	public nom: string = '';
	public ape: string = '';
	public idEmp: string = '';
	public email: ContactoEmail[] = [new ContactoEmail('', 'Trabajo')]
	public tel: ContactoTel[] = [new ContactoTel('', 'Trabajo')]
	public baja: number = 0;

	constructor(obj: any = {}) {
		this._id = obj._id || this._id
		this.idUsu = obj.idUsu || this.idUsu
		this.idEmp = obj.idEmp || this.idEmp
		this.nom = obj.nom || this.nom
		this.ape = obj.ape || this.ape
		this.email = obj.email || this.email
		this.tel = obj.tel || this.tel
		this.baja = obj.baja || this.baja
	}

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fila:hover {
  background-color: #f6f6f6;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/selector-general/selector-general.component.scss"],"names":[],"mappings":"AACA;EACC,yBAAA;AAAD","sourcesContent":["\n.fila:hover{\n\tbackground-color: #f6f6f6;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

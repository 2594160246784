import { Component, OnInit,  OnDestroy, AfterViewInit } from '@angular/core';
import { Sucursal } from '@models/sucursales.model';
import { GlobalService } from '@core/global.service';
import { Subscription } from 'rxjs';
import { UiService } from '@core/ui.service';
import { DatosService } from 'src/app/core/datos.service';
import { MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import { AuthService } from '../../../../auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.reducer';
import { cambiarSucursal } from '../../../../models/sucursales.acciones';
import { SnackBarService } from '../../../../core/snackbar.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-sucursales-selecciona',
  templateUrl: './sucursales-selecciona.component.html',
  styleUrls: ['./sucursales-selecciona.component.scss']
})
export class SucursalesSeleccionaComponent implements OnInit, OnDestroy, AfterViewInit {
  suscripciones: Subscription[] = [];
  bus: string = '';
  sucs: Sucursal[] = [];


  constructor(
    public auth: AuthService,
    public ui: UiService,
    public ds: DatosService,
    public g: GlobalService,
    public modal: MatDialogRef<SucursalesSeleccionaComponent>,
    public store: Store<AppState>,
    private snackBar: SnackBarService
  ) { }

  seleccionar(suc: Sucursal) {
    if (this.auth.suc._id === suc._id) return;
    this.store.dispatch(cambiarSucursal({ obj: suc }))
    this.snackBar.open(`Cambio a: ${suc.sucursal}`, 'OK', 3000)
    this.modal.close();
  }

  cerrar() {
    this.modal.close();
  }

  buscar() {
    setTimeout(() => {

      let sucursalesFiltrados: Sucursal[] = this.ds.sucs.filter(s => s.baja === 0)

      if (this.bus) {
        sucursalesFiltrados = sucursalesFiltrados.filter(s => {
          return s.sucursal.toUpperCase().indexOf(this.bus.toUpperCase()) > -1
            || s.codigo.toUpperCase().indexOf(this.bus.toUpperCase()) > -1
            || s.tel.toUpperCase().indexOf(this.bus.toUpperCase()) > -1
            || s.email.toUpperCase().indexOf(this.bus.toUpperCase()) > -1
            || s.fisDom.toUpperCase().indexOf(this.bus.toUpperCase()) > -1
            || s.fisDomCiu.toUpperCase().indexOf(this.bus.toUpperCase()) > -1
            || s.fisDomCodPos.toUpperCase().indexOf(this.bus.toUpperCase()) > -1
        })
      }
      this.sucs = [];
      this.sucs = sucursalesFiltrados;
    });
  }

  ngAfterViewInit() {
    this.buscar()
  }

  ngOnInit() {
    setTimeout(() => {
      this.suscripciones.push(this.ds._sucsObs.subscribe(sucs => {
        // this.sucs = sucs
        this.buscar()
      }))
    });
    if (this.auth.usu.idSuc && this.auth.usu.idSuc.length > 5) {
      Swal.fire('¡Ups!', 'Esta cuenta esta restrigida a una sola sucursal.', 'warning');
      this.modal.close();
    }

  }

  ngOnDestroy() {
    this.suscripciones.forEach(suscripcion => {
      suscripcion.unsubscribe();
    });
  }

}

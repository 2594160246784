import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
// import * as socialAuth from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { UiService } from '@core/ui.service';
import { GlobalService } from '@core/global.service';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as auth_acciones from '../auth.actions';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  suscripciones: Subscription[] = [];
  mensaje = ''
  environment = environment

  usu: string = environment.datosTesting.email;
  pass: string = environment.datosTesting.pass;
  constructor(
    public auth: AuthService,
    private g: GlobalService,
    private store: Store<AppState>,
    public ui: UiService,
    private socialAuth: SocialAuthService,
    private actRoute: ActivatedRoute,
    private router: Router
  ) {
  }

   loginGoogle() {
    // const pepe = await  this.socialAuth.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
    //   console.log({pepe})

      // this.socialAuth.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then(accessToken => console.log({accessToken}));

    // this.socialAuth.signIn( GoogleLoginProvider.PROVIDER_ID )
    //   .then(data => this.auth.login('GOOGLE', data))
    //   .catch(err => {
    //     this.g.SweetMsg('¡Ups!', 'Parece que no se habilito el permiso de Google.', 'error')
    //     console.log('Error - GOOGLE - ', err);
    //   })
  }

  loginFB() {

    this.ui.loadingLogin(true);
    this.socialAuth.signIn(FacebookLoginProvider.PROVIDER_ID).then((data: any) => {
      data.token = data.authToken
      delete data.authTOken
      this.auth.login('FACEBOOK', data)
      this.ui.loadingLogin(false);
    }).catch(err => {
      this.ui.loadingLogin(false);
      this.g.SweetMsg('¡Ups!', 'Parece que no se habilito el permiso de Facebook.', 'error')
      console.log('Error - FACEBOOK - ', err);
    })

  }

  login() {

    this.auth.login('EMAIL', this.usu, this.pass);

  }

  ngOnInit() {

    this.store.select('usu').pipe(take(1)).subscribe(usu => {
      if (usu) {

        this.auth.loginAutomatico(usu)
      } else {
        this.store.dispatch(auth_acciones.cerrarSesion());
      }
    });

    this.mensaje = this.g.getDato('mensajeSesion');

    if (this.mensaje !== '' && this.mensaje !== 'null' && this.mensaje) {

      this.g.SweetMsg('Sesion', this.mensaje, 'error').then(value => this.mensaje = '');

      this.g.setDato('mensajeSesion', '');
    }
    this.suscripciones.push(
      this.actRoute.queryParams.subscribe((params) => {
        let pagina = params['p'];
        let correo = params['c'];
        if (pagina === 'registro') {
          setTimeout(() => {
            this.router.navigate(['registro', { c: correo }])
          }, 300);
        }
      })
    )

  }

  ngOnDestroy(): void {

    this.suscripciones.forEach((suscrpcion: Subscription) => {
      suscrpcion.unsubscribe();
    })

  }






}

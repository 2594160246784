export const c_Exportacion = [
	{ clave: '01', descripcion: 'No aplica' },
	{ clave: '02', descripcion: 'Definitiva' },
	{ clave: '03', descripcion: 'Temporal' },
]

export const c_ObjImp = [
	{ clave: '01', descripcion: 'No objeto de impuesto.' },
	{ clave: '02', descripcion: 'Sí objeto de impuesto.' },
	{ clave: '03', descripcion: 'Sí objeto del impuesto y no obligado al desglose.' },
]

export const c_MetodoPago = [
	{ metPag: 'Pago en una sola exhibición', codigo: 'PUE' },
	{ metPag: 'Pago en parcialidades o diferido', codigo: 'PPD' },
]

import { Pipe, PipeTransform, ViewChild } from '@angular/core';
import escapeStringRegexp from 'escape-string-regexp';

@Pipe({
	name: 'axFilter'
})
export class axFilter implements PipeTransform {

	transform(theArray: Array<any>, firstProperty: string | string[] = [''], searchTerm: string = '', maxItems: number = 50): any {

		try {
			if (!theArray) return theArray;

			let theArray_mod: Array<any> = [];


			if (searchTerm === '' || typeof (searchTerm) === 'object' || firstProperty === '') {
				if (maxItems) {
					return theArray.slice(0, maxItems);
				} else {
					return theArray;
				}
			}


			if (typeof (firstProperty) === 'string') {
				firstProperty = [firstProperty]
			}

			theArray_mod = [];

			theArray.forEach(subArray => {

				(<string[]>firstProperty).forEach(prop => {
					if (!prop) return;
					let theValue = ''
					if (prop.indexOf('.') > 0) {

						let propArray = prop.split('.')

						if (propArray.length > 2) console.error('filter can take up to 2 nested properties. [< ' + propArray.join('.') + ' >] it should be like: prop1.prop2 (max)')
						if (typeof subArray[propArray[0]] === 'undefined') {
							console.error('The ' + propArray[0] + ' property is not part of root object, all objects within the array should have the same structure or at least have the properties you are looking for')
							return;
						}
						if (typeof subArray[propArray[0]][propArray[1]] === 'undefined') {
							console.error('The ' + [propArray[1]] + ' property is not part of '+ [propArray[0]] +' object, all objects within the array should have the same structure or at least have the properties you are looking for')
							return;
						}
						theValue = subArray[propArray[0]][propArray[1]].toString();

					} else {

						if (typeof subArray[prop] === 'undefined') {
							console.error('The ' + prop + ' property is not part of root object, all objects within the array shoudl have the same structure or at least have the properties you are looking for')
							return;
						}
						theValue = subArray[prop].toString();

					}

					if (theValue) {
						let scapedTerm = escapeStringRegexp(searchTerm.toString().toUpperCase());

						const bus = new RegExp(scapedTerm, 'g');

						if (theValue.toUpperCase().search(bus) >= 0) {

							if (!theArray_mod.includes(subArray)) theArray_mod.push(subArray);
						}
					}

				});

				// checks for the final showValue i.e. 'code - name' > '0001 - Computer'
				// thy always are at the end of the array: length-1 and length-2
				let theValue = subArray[firstProperty[firstProperty.length - 1]] + ' - ' + subArray[firstProperty[firstProperty.length - 2]]
				if (theValue) {
					let scapedTerm = escapeStringRegexp(searchTerm.toString().toUpperCase());

					const bus = new RegExp(scapedTerm, 'g');

					if (theValue.toUpperCase().search(bus) >= 0) {

						if (!theArray_mod.includes(subArray)) theArray_mod.push(subArray);
					}
				}

			});

			if (maxItems) {
				return theArray_mod.slice(0, maxItems);
			} else {
				return theArray_mod;
			}
		} catch (e) {
			console.error('Error on axFilter: ', e)
			return theArray;
		}
	}

}

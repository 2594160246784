import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutesModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { InicioComponent } from './componentes/inicio/inicio.component';
import { ActualizarPlanComponent } from './componentes/actualizar-plan/actualizar-plan.component';
import { AsistenteInicialComponent } from './componentes/asistente-inicial/asistente-inicial.component';
import { DatosMxComponentX } from './componentes/datos-paises/datos-mx/datos-mx.component';
import { MuestraPdfComponentX } from './componentes/muestra-pdf/muestra-pdf.component';
import { PasarelaStripeComponentX } from './componentes/pasarela-stripe/pasarela-stripe.component';
import { ProductosEditaComponentX } from './componentes/productos/productos-edita/productos-edita.component';
import { ProductosInfoComponent } from './componentes/productos/productos-info/productos-info.component';
import { MensajeDefaultComponentX } from './core/mensajes/mensaje-default/mensaje-default.component';
import { SucursalesEditaComponent } from './modulos/sucursales/components/sucursales-edita/sucursales-edita.component';
import { SucursalesSeleccionaComponent } from './modulos/sucursales/components/sucursales-selecciona/sucursales-selecciona.component';
import { PrivacyComponent } from './varios/privacy/privacy.component';
import { TycComponent } from './varios/tyc/tyc.component';
import { Resolver } from './resolver';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { MaterialModule } from './material.module';
import { PopUpsModule } from './pop-ups/pop-ups.module';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from './layout/layout.module';
import { AuthModule } from './auth/auth.module';
import { environment } from 'src/environments/environment';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { appReducers } from './app.reducer';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { PushNotificationService } from './core/push-notification.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { momentAdapterFactory } from './modulos/crm/crm.module';
import { EffectsModule } from '@ngrx/effects'
import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { HttpClientModule } from '@angular/common/http';
import { PipesModule } from './pipes/pipes.module';
import { DirectivasModule } from './directivas/directivas.module';
import { ContactosModule } from './modulos/contactos/contactos.module';
import { CoreModule } from './core/core.module';

// Sockets Configuration
const mySocketConfig: SocketIoConfig = { url: environment.socketsURL, options: {} };
// Sentry Configuration
if (environment.production) {
	Sentry.init({
		dsn: "https://6a16a844ef804e9eb8954fe5356bbf48@sentry.io/1793087",
		environment: environment.dev ? "development" : "production",
		integrations: [<any>new TracingIntegrations.BrowserTracing()],

		// To set a uniform sample rate
		tracesSampleRate: 0.5
	});
}
@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    ProductosInfoComponent,

    MensajeDefaultComponentX,
    ProductosEditaComponentX,
    MuestraPdfComponentX,
    PasarelaStripeComponentX,
    DatosMxComponentX,
    SucursalesSeleccionaComponent,
    SucursalesEditaComponent,

    AsistenteInicialComponent,
    ActualizarPlanComponent,

    PrivacyComponent,
    TycComponent,

  ],
  imports: [
    ContactosModule,
		CoreModule,
		DirectivasModule,
		PipesModule,
		HttpClientModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    AuthModule,
    FlexLayoutModule,
    AppRoutesModule,
    BrowserAnimationsModule,
    DragDropModule,
    LayoutModule,
    CdkTreeModule,
    CdkTableModule,
    CdkTreeModule,
    ScrollingModule,
    MaterialModule,
    SocketIoModule.forRoot(mySocketConfig),
    NgxLoadingModule.forRoot({}),
		StoreModule.forRoot(appReducers, {
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false,
			},
		}),

		FlexLayoutModule,
		// ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
		ServiceWorkerModule.register('./custom-service-worker.js', { enabled: environment.production }),
		SocialLoginModule,
		CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
		EffectsModule.forRoot([]),

  ],
  exports: [
    BrowserModule,
    PopUpsModule,
    MaterialModule,
    FlexLayoutModule,
    DragDropModule,
    LayoutModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    ScrollingModule,
  ],
  providers: [
    Resolver,
    { provide: LOCALE_ID, useValue: 'es-Mx' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('881862893720-tg0lj80p2u58aag5vjju4q0a4lsokcfq.apps.googleusercontent.com'),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('330293747665237'),
          }
        ],
      } as SocialAuthServiceConfig,
    },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(
    private push: PushNotificationService
  ) {
    this.push.escucharPushNotifications()
  }
}

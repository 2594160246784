import moment from 'moment';
export type CrmDetalleTipo = 'nota' | 'accion'
export type accionTipo = 'llamada' | 'reunion' | 'seguimiento' | 'entrega' | 'correo'
export type AccionStatusTipo = 'pendiente' | 'vencido' | 'completada'

export class CrmDetalle {

	_id: string = '';
	idUsu: string = '';
	idUsuAsignado: string = '';
	idTrato: string = '';
	idContacto: string = '';
	idEmp: string = '';
	idPipe: string = '';
	titulo: string = '';
	detalle: string = '';
	status: AccionStatusTipo = 'pendiente';
	accionTipo: accionTipo = 'llamada';
	tipo: CrmDetalleTipo = 'accion';
	importante: boolean = false;
	completado: boolean = false;
	fecha: Date = new Date();
	fechaCompletado: Date = new Date();
	fechaModificacion: Date = new Date();
	fechaAgenda: Date = moment().endOf('hour').add(1, 'minute').toDate();
	baja: number = 0

	meta: any

	constructor(obj: any = {}) {
		this._id = obj._id || this._id
		this.idUsu = obj.idUsu || this.idUsu
		this.idUsuAsignado = obj.idUsuAsignado || this.idUsuAsignado
		this.idTrato = obj.idTrato || this.idTrato
		this.idContacto = obj.idContacto || this.idContacto
		this.idEmp = obj.idEmp || this.idEmp
		this.idPipe = obj.idPipe || this.idPipe
		this.titulo = obj.titulo || this.titulo
		this.detalle = obj.detalle || this.detalle
		this.status = obj.status || this.status
		this.accionTipo = obj.accionTipo || this.accionTipo
		this.tipo = obj.tipo || this.tipo
		this.importante = obj.importante || this.importante
		this.completado = obj.completado || this.completado
		this.fecha = obj.fecha || this.fecha
		this.fecha = new Date(this.fecha)
		this.fechaCompletado = obj.fechaCompletado || this.fechaCompletado
		this.fechaCompletado = new Date(this.fechaCompletado)
		this.fechaModificacion = obj.fechaModificacion || this.fechaModificacion
		this.fechaModificacion = new Date(this.fechaModificacion)
		this.fechaAgenda = obj.fechaAgenda || this.fechaAgenda
		this.fechaAgenda = new Date(this.fechaAgenda)
		this.baja = obj.baja || this.baja


		if (this.tipo === 'nota') this.status = 'completada'
		if (this.tipo === 'accion') {
			if(this.completado) this.status = 'completada'
			if(!this.completado) {
				this.status = 'pendiente'
				if(moment().diff(this.fechaAgenda, 'seconds') > 0 ) this.status ='vencido'
			}
		}
	}


}

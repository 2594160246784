import { Fondo } from './fondo.model';

export type Planes =
  'plan-pos-mensual' |
  'plan-starter-mensual' |
  'plan-emprendedor-mensual' |
  'plan-empresa-mensual' |
  'plan-total-mensual' |
  'plan-pos-anual' |
  'plan-starter-anual' |
  'plan-emprendedor-anual' |
  'plan-empresa-anual' |
  'plan-total-anual'

export type MetodosLogin = 'EMAIL' | 'GOOGLE' | 'FACEBOOK';
export enum estado {
  pendiente = 10,
  cerrado = 11,
  anulado = 12,
  abierto = 13
}
export interface ProSrv {
  codigo: string;
  proSrv: string;
}
export type accionTipoType = 'navegacion' | 'accion' | 'envio-informe';

export interface trackingInfo {
  mdl: string
  accionTipo: accionTipoType
  accion: string
  fecha: Date;
  data: any
}

export interface Imp {
  _id?: string;
  codigo: string;
  imp: string;
}

export interface RegFis {
  codigo: string;
  regFis: string;
}

export interface ForPag {
  codigo: string;
  forPag: string;
}
export interface FondoTipo {
  idFon?: number;
  codigoForPag?: string | number;
  idMdl?: string | number;
  codigo?: string;
  fon?: string;
  idMon?: string | number;
  baja?: string | number;
}
export interface UsoCfdi {
  codigo?: string;
  usoCfdi?: string;
}

export interface UniMed {
  codigo: string;
  uniMed: string;
}


export interface Pais {
  name: string;
  code: string;
}

export interface Region {
  region: string;
  country: string;
}

export interface Preferencias {
  pais: Pais,
  region: Region,
  fon: Fondo,
  regFis: RegFis,
}

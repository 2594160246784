import { createAction, props } from '@ngrx/store';

export const crearUsuario = createAction(
  '[Usuario] Crear Usuario',
  props<{ obj: object }>()
);

export const cambiarUsuario = createAction(
  '[Usuario] Cambiar Usuario',
  props<{ obj: object }>()
);

export const cambiarPropiedad = createAction(
  '[Usuario] Cambiar Propiedad',
  props<{ propiedad: string; valor: string }>()
);

export const cerrarSesion = createAction('[Usuario] Cerrar Sesion');

export type AccionesValidas =
  | ReturnType<typeof crearUsuario>
  | ReturnType<typeof cambiarUsuario>
  | ReturnType<typeof cambiarPropiedad>
  | ReturnType<typeof cerrarSesion>;

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Fcv } from '../models/fcvs.model';
import { Fpr } from '../models/fprs.model';
import { Router } from '@angular/router';
import { EmailTrackingComponent } from './../componentes/email-tracking/email-tracking.component';
import { UiService } from './ui.service'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import Swal from 'sweetalert2';
import { ConexionService } from './conexion.service';



@Injectable({
	providedIn: 'root'
})
export class EmailTrackingService {

	constructor(
		private con: ConexionService,
		private router: Router,
		private ui: UiService,
		private dialog: MatDialog,
	) { }

	async showTrackInfo(cmp: Fcv | Fpr) {

		if (!cmp._id) {
			Swal.fire('Error', 'Para ver información de rastreo debe guardar y enviar el comprobante.', 'error')
			return
		}
		let trackingInfo = null
		try {
			trackingInfo = await this.con.getP('api/email-traking/' + cmp._id, true)
		} catch (error) {
			trackingInfo = null
		}
		if (!trackingInfo.data) {
			Swal.fire('Error', 'Este comprobante no tiene informacion de rastreo o no ha sido enviado nunca.', 'error')
			return
		}

		history.pushState({}, "oAdmin", '/#' + this.router.url);
		let ancho = '40%';
		let alto = '60%';
		if (this.ui.isMobile) {
			ancho = '100%';
			alto = '100%';
		}
		this.dialog.open(EmailTrackingComponent, {
			width: ancho,
			maxWidth: ancho,
			height: alto,
			panelClass: 'dialogo-popup',
			closeOnNavigation: true,
			disableClose: false,
			data: { modal: true, trackingInfo:trackingInfo.data }
		})
	}

}

import { Directive, ElementRef, HostListener, Output, Input, EventEmitter, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { GlobalService } from '@core/global.service';
import { UiService } from '@core/ui.service';

@Directive({
	selector: '[formatoNumero]'
})
export class FormatoNumeroDirective implements AfterViewInit {
	cantidadSeparadoresDecimales
	@Input() formatoNumero: number=2;
	@Input() conSeparadorDeMiles: boolean = false;
	@Input() formatoRegional: string='mx';
	private separadorMiles: string;
	private separadorDecimal: string;
	private valorGuardado: string;
	@HostListener('focus', ['$event']) onFocus( e: any ) {
		e.target.value = e.target.value.replace(new RegExp(this.separadorMiles,"g"), '');
		if ( this.ui.isMobile ) {
			this.valorGuardado = e.target.value
			e.target.value = '';
		} else {
			e.target.selectionStart = 0;
			e.target.selectionEnd = e.target.value.length;
		}

	}
	@HostListener('change', ['$event']) onchange(e: any) {
		if (e.target.value === '') {
			e.target.value = '0,00';
			e.target.selectionStart = 0;
			e.target.selectionEnd = e.target.value.length;
		}
	}
	@HostListener('blur', ['$event']) onBlur(e: any) {

		if ( this.ui.isMobile && e.target.value === '') {
			e.target.value = this.valorGuardado;
		}
		e.target.value = this.g.formatoNumero(e.target.value, this.formatoNumero);

	}
	@HostListener('keydown', ['$event']) onKeyPress(e: any) {

		this.cantidadSeparadoresDecimales = (e.target.value.match(/,/g) || []).length;

		switch (e.key) {
			case '0':
			case '1':
			case '2':
			case '3':
			case '4':
			case '5':
			case '6':
			case '7':
			case '8':
			case '9':
				if (this.cantidadSeparadoresDecimales === 0) {
					return true;
				} else {
					let xDec = e.target.value.split(this.separadorDecimal);
					if (
						xDec[1].length >= this.formatoNumero &&
						e.target.value.indexOf(this.separadorDecimal) < e.target.selectionStart
					) {
						//console.log('COMA POS : ',e.target.value.indexOf(this.separadorDecimal));
						//console.log('CURS POS :' , e.target.selectionStart);
						return false;
					}
					return true;
				}

			case this.separadorDecimal:
				if (this.cantidadSeparadoresDecimales === 0) {
					return true;
				}
				return false;

			case this.separadorMiles:
				if (this.cantidadSeparadoresDecimales === 0) {
					let xPos = e.target.selectionEnd;
					e.target.value =
						e.target.value.substr(0, e.target.selectionStart) +
						this.separadorDecimal +
						e.target.value.substr(e.target.selectionStart);
					e.target.selectionStart = xPos + 1;
					e.target.selectionEnd = e.target.selectionStart;
				}
				return false;

			default:
				break;
		}

		switch (e.keyCode) {
			case 37:
			case 38:
			case 39:
			case 40:
			case 9:
				return true;
			case 8:
			case 46:
				return true;
			default:
				break;
		}
		return false;
	}

	REGEX_NUMERO: any
	constructor(
		private el: ElementRef,
		private g: GlobalService,
		private ui: UiService,
	) {
		this.REGEX_NUMERO = /(^(\d+([\.,])?\d*)$)|(^(\d+)$)/gm;
		if ( this.formatoRegional = 'mx') {
			this.separadorDecimal = '.'
			this.separadorMiles = ','
		}

	}

	ngAfterViewInit(){
		this.formatoNumero = this.formatoNumero || 2
		if( isNaN(this.formatoNumero) ) this.formatoNumero = 2;
	}


}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imagen-redonda {
  border-radius: 50%;
}

mat-toolbar {
  height: 57px;
  padding-left: 0;
  position: fixed;
  z-index: 700;
  right: 0;
  padding: 0;
  padding-right: 8px;
  transition: all 0.3s ease;
}
@media screen and (min-width: 768px) {
  mat-toolbar {
    height: 45px;
    padding-right: 18px;
  }
  mat-toolbar.abierto {
    width: calc(100% - 210px) !important;
  }
  mat-toolbar.cerrado {
    width: 100% !important;
  }
}

.nav-bar {
  height: 57px;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .nav-bar {
    height: 45px;
  }
}

.menu-toggler {
  padding: 0;
  top: 0px;
  font-size: 20px;
  margin-right: "16px" !important;
}

.example-fill-remaining-space {
  /* This fills the remaining space, by using flexbox.
     Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.fas {
  color: rgb(92, 92, 92);
}

.perfil-nombre i {
  padding-left: 2px;
}

.boton-perfil {
  padding-right: 0 !important;
  margin-right: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/navbar/navbar.component.scss"],"names":[],"mappings":"AAEA;EACC,kBAAA;AADD;;AAIA;EACC,YAAA;EAEA,eAAA;EACA,eAAA;EACA,YAAA;EACA,QAAA;EACA,UAAA;EACA,kBAAA;EACA,yBAAA;AAFD;AAGC;EAVD;IAYE,YAAA;IACA,mBAAA;EADA;EAGA;IACC,oCAAA;EADD;EAGA;IACC,sBAAA;EADD;AACF;;AAKA;EACC,YAAA;EACA,uBAAA;EACA,eAAA;EACA,MAAA;EACA,WAAA;AAFD;AAGC;EAND;IAOE,YAAA;EAAA;AACF;;AAGA;EACC,UAAA;EACA,QAAA;EACA,eAAA;EACC,+BAAA;AAAF;;AAIA;EACE;mDAAA;EAEA,cAAA;AADF;;AAGA;EACE,sBAAA;AAAF;;AAIC;EACC,iBAAA;AADF;;AAIA;EACC,2BAAA;EACA,0BAAA;AADD","sourcesContent":["$ancho-sidebar:210;\n\n.imagen-redonda {\n\tborder-radius: 50%;\n}\n\nmat-toolbar{\n\theight: 57px;\n\n\tpadding-left:0;\n\tposition: fixed;\n\tz-index: 700;\n\tright: 0;\n\tpadding:0;\n\tpadding-right: 8px;\n\ttransition: all .3s ease;\n\t@media screen and (min-width: 768px) {\n\n\t\theight: 45px;\n\t\tpadding-right: 18px;\n\n\t\t&.abierto{\n\t\t\twidth: calc(100% - 210px)!important;\n\t\t}\n\t\t&.cerrado{\n\t\t\twidth: 100% !important;\n\t\t}\n\t}\n}\n\n.nav-bar {\n\theight: 57px;\n\tbackground-color: white;\n\tposition: fixed;\n\ttop: 0;\n\twidth: 100%;\n\t@media screen and (min-width: 768px) {\n\t\theight: 45px;\n\n\t}\n}\n.menu-toggler {\n\tpadding: 0;\n\ttop: 0px;\n\tfont-size: 20px;\n  margin-right: '16px' !important;\n\t// width: 26px !important;\n\t// min-width: 26px !important;\n}\n.example-fill-remaining-space {\n  /* This fills the remaining space, by using flexbox.\n     Every toolbar row uses a flexbox row layout. */\n  flex: 1 1 auto;\n}\n.fas{\n\t\tcolor: rgb(92, 92, 92)\n\n}\n.perfil-nombre{\n\ti{\n\t\tpadding-left: 2px\n\t}\n}\n.boton-perfil{\n\tpadding-right: 0!important;\n\tmargin-right: 0!important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { GlobalService } from '@core/global.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[focusScroll]'
})
export class FocusScrollDirective {
	isMobile = false;
	@Input('focusScroll')focusScroll=20
	@HostListener('focus', ['$event']) onFocus(e:any) {
		if ( this.isMobile ) {
			setTimeout(() => {
				var elemento = this.el.nativeElement;
				var offset = this.focusScroll || 20;
				//navegadores viejos
				// window.scroll(0, elemento.offsetTop + offset);

				//navegadores NUEVOS

				window.scrollTo({ 	top:  elemento.offsetTop - offset,
									behavior: 'smooth'});
			}, 150 )
			// setTimeout(() => {
			// 	var elemento = this.el.nativeElement;
			// 	var offset = 50;

			// 	// elemento.scrollIntoView({block:'start', behavior:'smooth'});

			// 	var scrolledY = window.scrollY;

			// 	if(scrolledY){

			// 		// window.scroll(0, scrolledY - offset);
			// 		window.scroll(0, elemento.offsetTop + offset);
			// 	}
			// },50)
		}
	}
	@HostListener('click', ['$event']) onclick(e:any) { this.onFocus(e) }
	constructor(
		private el: ElementRef,
		private g: GlobalService,
		private store: Store<AppState>
	) {
		this.store.select('ui').pipe(take(1)).subscribe( ui => this.isMobile = ui.isMobile)
	}
}



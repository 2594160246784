// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tratos {
  min-height: 200px;
  margin: 0 22px 0 0;
  cursor: pointer;
  padding: 0 6px 0 5px;
}

.drop-list.cdk-drop-list-dragging app-trato:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  margin-top: 12px;
  min-height: 85px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}`, "",{"version":3,"sources":["webpack://./src/app/modulos/crm/components/pipeline/fase-columna/fase-columna.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;EAEA,kBAAA;EACA,eAAA;EACA,oBAAA;AAAD;;AAKA;EACE,sDAAA;AAFF;;AAKA;EACE,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;EACA,sDAAA;AAFF","sourcesContent":[".tratos {\n\tmin-height: 200px;\n\t// width: 250px;\n\tmargin: 0 22px 0 0 ;\n\tcursor: pointer;\n\tpadding: 0 6px 0 5px;\n\t// &:hover {\n\t// \tborder: 1px dashed rgb(177, 177, 177);\n\t// }\n}\n.drop-list.cdk-drop-list-dragging app-trato:not(.cdk-drag-placeholder) {\n  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);\n}\n\n.drop-placeholder {\n  background: #ccc;\n  border: dotted 3px #999;\n  margin-top: 12px;\n  min-height: 85px;\n  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

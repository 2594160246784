import { Pipeline } from './pipeline.model';
import * as fromPipelines from './pipeline.acciones';
import { createReducer, on } from '@ngrx/store';

const pipelineLocal = JSON.parse(window.localStorage.getItem('pipeline') || null);
let estadoInicial: Pipeline;

if (pipelineLocal) {
  let xPipeline = new Pipeline({ ...pipelineLocal });
  estadoInicial = xPipeline;
} else {
  estadoInicial = new Pipeline();
}

export const pipelineReducer = createReducer(
  estadoInicial,
  on(fromPipelines.cambiarPipeline, (state, action) => {
    const objFacturaNueva = {
      ...state,
      ...action.pipeline
    };
    const nvoPipeline = new Pipeline(objFacturaNueva);
    Object.assign(nvoPipeline, action.pipeline);

    window.localStorage.setItem('pipeline', JSON.stringify(nvoPipeline));

    return nvoPipeline;
  }),
  on(fromPipelines.cambiarPropiedadPipeline, (state, action) => {
    const objFacturaNueva = {
      ...state,
      ...action.prop
    };
    const nvoPipeline = new Pipeline(objFacturaNueva);

    window.localStorage.setItem('pipeline', JSON.stringify(nvoPipeline));

    return nvoPipeline;
  })
);

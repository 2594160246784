// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendario {
  max-height: 500px;
  overflow: auto;
}

.historico {
  width: 100%;
  height: 50px;
  margin: 12px 8px;
  text-align: center;
}
.historico .opcion {
  margin: 0 8px;
  border-radius: 8px;
  padding: 8px;
  min-width: 100px;
  background-color: #424242;
  color: #ffffff;
  text-align: center;
}
.historico .opcion.activada {
  background-color: #a3a3a3;
}
.historico .opcion:hover {
  cursor: pointer;
  background-color: #6e6e6e;
}`, "",{"version":3,"sources":["webpack://./src/app/modulos/crm/components/trato/trato-principal/trato-principal.component.scss","webpack://./src/assets/css/colores.scss"],"names":[],"mappings":"AAEA;EACC,iBAAA;EACA,cAAA;AADD;;AAGA;EACC,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAAD;AACC;EACC,aAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,yBCQgB;EDPhB,cAAA;EACA,kBAAA;AACF;AAAE;EACC,yBCGc;ADDjB;AACE;EACC,eAAA;EACA,yBCHQ;ADIX","sourcesContent":["@import \"../../../../../../assets/css/colores.scss\";\n\n.calendario{\n\tmax-height: 500px;\n\toverflow: auto;\n}\n.historico{\n\twidth: 100%;\n\theight: 50px;\n\tmargin: 12px 8px;\n\ttext-align: center;\n\t.opcion {\n\t\tmargin: 0 8px;\n\t\tborder-radius: 8px;\n\t\tpadding: 8px;\n\t\tmin-width: 100px;\n\t\tbackground-color: $oa-gris-oscuro;\n\t\tcolor: #ffffff;\n\t\ttext-align: center;\n\t\t&.activada{\n\t\t\tbackground-color: $oa-gris-claro;\n\t\t}\n\n\t\t&:hover{\n\t\t\tcursor: pointer;\n\t\t\tbackground-color: $oa-gris;\n\t\t}\n\n\t}\n}\n\n","\n$oa-principal : #d20a31;\n$oa-principal2 : #6d0015;\n$oa-principal3 : #f06f88;\n\n$oa-secundario : #0a738c;\n$oa-secundario2 : #023b48;\n$oa-secundario3 : #539cad;\n\n$oa-accent : #46c309;\n$oa-accent2 : #216500;\n$oa-accent3 : #90e268;\n\n$oa-accent-secundario : #E1750B;\n$oa-accent-secundario2 : #753A00;\n$oa-accent-secundario3 : #FFBA76;\n\n$oa-background : #023b48;\n\n$oa-blanco-bg : white;\n$oa-blanco : white;\n\n$oa-gris : #6e6e6e;\n$oa-gris-claro : #a3a3a3;\n$oa-gris-oscuro : #424242;\n\n$oa-color-link: #3e92ff;\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();


export class Sector {
	_id?: string = ''
	idUsu: string = ''
	idSuc: string = '1'
	sector: string = ''
	baja: number = 0

	constructor(obj: any = {}) {
		this._id = obj._id || this._id;
		this.idUsu = obj.idUsu || this.idUsu;
		this.idSuc = obj.idSuc || this.idSuc;
		this.sector = obj.sector || this.sector;
		this.baja = obj.baja || this.baja;
	}
	/**
	 * le id predeterminado lo creamos con
	  	function ObjectId () {
			const timestamp = (new Date('1988-06-23 05:30:00').getTime() / 1000 | 0).toString(16);
			const suffix = ('0123456789123456').toString(16).toLowerCase()
			return `${timestamp}${suffix}`;
		}
	*/
	static predeterminado() {
		return new Sector({ _id: '22c0ebb80123456789123456', idSuc: '1', sector: 'Sector Predeterminado' });
	}

}

import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Art } from '../../../models/arts.model';
import { ConexionService } from '../../../core/conexion.service';

@Component({
	selector: 'app-productos-info',
	templateUrl: './productos-info.component.html',
	styleUrls: ['./productos-info.component.scss']
})
export class ProductosInfoComponent implements OnInit {

	isModal: boolean = false
	art: Art = new Art()

	constructor(
		private con: ConexionService,
		public modal: MatDialogRef<ProductosInfoComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		if (data.modal) {
			this.isModal = true;
			this.art = new Art(data.art)
			if (this.art.imagen) this.con.sGetImg(this.art.imagen, 'arts').then(imgUrl => { this.art.imagenURL = imgUrl })
			if (this.art.imagen2) this.con.sGetImg(this.art.imagen2, 'arts').then(imgUrl => { this.art.imagenURL2 = imgUrl })
			if (this.art.imagen3) this.con.sGetImg(this.art.imagen3, 'arts').then(imgUrl => { this.art.imagenURL3 = imgUrl })

			//TODO: Busqueda de stock actual en el sector predeterminado o abrir nueva ventana con el stock de este art en todas las sucursales.
		}
	}

	cerrar(){
		this.modal.close()
	}

	ngOnInit(): void {

	}

}

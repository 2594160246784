import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DatosService } from '@core/datos.service';
import { GlobalService } from 'src/app/core/global.service';
import { Router } from '@angular/router';
import { FprDet, Fpr } from '@models/fprs.model';
import { AplicarReciboPopupComponent } from '../aplicar-recibo-popup/aplicar-recibo-popup.component';
import { UiService } from '../../../core/ui.service';



@Component({
  selector: 'app-seleccionarComprobantes-popup',
  templateUrl: './seleccionarComprobantes-popup.component.html',
  styleUrls: ['./seleccionarComprobantes-popup.component.css']
})
export class SeleccionarComprobantesPopupComponent implements OnInit {

  fpr: Fpr = new Fpr();

  constructor(
    public ui: UiService,
    public ds: DatosService,
    public g: GlobalService,
    private dialog: MatDialog,
    private router: Router,
    public modal: MatDialogRef<SeleccionarComprobantesPopupComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {

    if (data.det) Object.assign(this.fpr, new Fpr({ ...data.det }));

  }

  aplicarPagoPopup(fd: FprDet) {

    this.fpr.saldo += Number(fd.aplicado);
    fd.aplicado = 0
    fd.saldo = fd.fcv.saldo;

    let ancho = '100%'
    history.pushState({}, "oAdmin", '/#' + this.router.url);
    const dialogo = this.dialog.open(AplicarReciboPopupComponent, {
      width: ancho,
      maxWidth: ancho,
      height: '100%',
      closeOnNavigation: true,
      data: { fd, fpr: this.fpr }
    })


    dialogo.afterClosed().subscribe((aplicar: any) => {
      if (!aplicar) return;

      if (aplicar > 0) {
        fd.aplicado = aplicar;
        this.aplicarSaldo(fd);
      } else {
        if (aplicar) return;
        this.g.SweetMsg('¡Ups!', 'Hubo un error al crear nuevo cliente, intente de nuevo.', 'error');
      }
    });

  }


  aplicarSaldo(fd: FprDet) {
    fd.saldo = Number(fd.fcv.saldo.toFixed(6))

    if (Number(fd.aplicado) > Number(fd.saldo)) fd.aplicado = this.g.importeInt(Number(fd.saldo), 6);

    this.fpr.calculaTotal();

    if (Number(this.fpr.saldo) < 0) {
      fd.aplicado = this.g.importeInt(Number(this.fpr.saldo) + Number(fd.aplicado), 2)
      this.fpr.calculaTotal();
    }
    fd.saldo = this.g.importeInt(Number(fd.fcv.saldo.toFixed(6)) - Number(fd.aplicado.toFixed(6)), 2)


  }

  acpetar() {

    this.modal.close( this.fpr.fprsDets );

  }
  cancelar() {

    this.modal.close(null);

  }

  ngOnInit() { }

}


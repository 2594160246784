// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agregar-articulos{
  margin-top:0;
  border-radius: 0;
}

.card-articulo {
  margin: 0 12px 8px 12px ;
}

`, "",{"version":3,"sources":["webpack://./src/app/pop-ups/components/seleccionarComprobantes-popup/seleccionarComprobantes-popup.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".agregar-articulos{\n  margin-top:0;\n  border-radius: 0;\n}\n\n.card-articulo {\n  margin: 0 12px 8px 12px ;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

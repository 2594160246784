import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

import { MomentDateAdapter, MatMomentDateModule } from '@angular/material-moment-adapter';
import localEsMx from '@angular/common/locales/es-MX';
import { MatPaginatorIntlCro } from './i18n/matPaginatorIntlCroClass';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatCardModule } from '@angular/material/card';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
// import { MatButtonModule } from '@angular/material/button';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table'
import { MatIconModule } from '@angular/material/icon'
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
// import { MatDialogRef } from '@angular/material/dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
// import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorIntl, MatPaginator } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
// import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTreeModule } from '@angular/material/tree';
import { LayoutModule } from '@angular/cdk/layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';



export const MY_FORMATS = {
  parse: {
    dateInput: 'DD - MM - YYYY',
  },
  display: {
    dateInput: 'DD - MM - YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD - MM - YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
registerLocaleData(localEsMx, 'es-MX');

@NgModule({
  declarations: [],
  imports: [
    DragDropModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatCheckboxModule,
    MatTableModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    LayoutModule,
    MatSelectModule,
    MatDividerModule,
    MatMenuModule,
    MatStepperModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatTooltipModule,
    MatIconModule,
    MatRippleModule,
    MatBadgeModule,
    MatChipsModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatRadioModule,
    ScrollingModule,
    MatPaginatorModule,
    MatSortModule,
    MatMomentDateModule,
    MatTreeModule,
    MatButtonToggleModule,
  ],
  exports: [
    DragDropModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatSidenavModule,
    MatListModule,
    MatCheckboxModule,
    MatTableModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    LayoutModule,
    MatSelectModule,
    MatDividerModule,
    MatMenuModule,
    MatStepperModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatTooltipModule,
    MatIconModule,
    MatRippleModule,
    MatBadgeModule,
    MatChipsModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatRadioModule,
    ScrollingModule,
    MatPaginatorModule,
    MatPaginator,
    MatSortModule,
    MatMomentDateModule,
    MatTreeModule,
    MatButtonToggleModule,
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class MaterialModule { }

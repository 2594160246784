// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detalle {
  background-color: #539cad;
  color: white;
  margin: 12px 8px;
  min-height: 150px;
  border-radius: 5px;
}
.detalle .detalle-title {
  border-radius: 5px 5px 0 0;
  padding: 4px 8px;
  background-color: #023b48;
  font-weight: 500;
}
.detalle .detalle-title .fas.txt-20 {
  position: relative !important;
  top: 10px !important;
}
.detalle .detalle-title .fas.fa-star {
  position: relative !important;
  top: 0 !important;
  left: 7px !important;
}
.detalle .detalle-body {
  white-space: pre-wrap;
  padding: 8px;
  font-size: 1.15rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modulos/crm/components/trato/trato-principal/detalle-card/detalle-card.component.scss","webpack://./src/assets/css/colores.scss"],"names":[],"mappings":"AAEA;EACC,yBCIiB;EDHjB,YAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AADD;AAGC;EACC,0BAAA;EACA,gBAAA;EACA,yBCNgB;EDOhB,gBAAA;AADF;AAEE;EACC,6BAAA;EACA,oBAAA;AAAH;AAEE;EACC,6BAAA;EACA,iBAAA;EACA,oBAAA;AAAH;AAGC;EACC,qBAAA;EACA,YAAA;EACA,kBAAA;AADF","sourcesContent":["@import \"../../../../../../../assets/css/colores.scss\";\n\n.detalle {\n\tbackground-color: $oa-secundario3;\n\tcolor: white;\n\tmargin: 12px 8px;\n\tmin-height: 150px;\n\tborder-radius: 5px;\n\n\t.detalle-title {\n\t\tborder-radius: 5px 5px 0 0 ;\n\t\tpadding: 4px 8px;\n\t\tbackground-color: $oa-secundario2;\n\t\tfont-weight: 500;\n\t\t.fas.txt-20{\n\t\t\tposition: relative !important;\n\t\t\ttop:10px !important;\n\t\t}\n\t\t.fas.fa-star{\n\t\t\tposition: relative !important;\n\t\t\ttop: 0 !important;\n\t\t\tleft: 7px !important;\n\t\t}\n\t}\n\t.detalle-body {\n\t\twhite-space:pre-wrap;\n\t\tpadding:8px;\n\t\tfont-size: 1.15rem;\n\t}\n}\n","\n$oa-principal : #d20a31;\n$oa-principal2 : #6d0015;\n$oa-principal3 : #f06f88;\n\n$oa-secundario : #0a738c;\n$oa-secundario2 : #023b48;\n$oa-secundario3 : #539cad;\n\n$oa-accent : #46c309;\n$oa-accent2 : #216500;\n$oa-accent3 : #90e268;\n\n$oa-accent-secundario : #E1750B;\n$oa-accent-secundario2 : #753A00;\n$oa-accent-secundario3 : #FFBA76;\n\n$oa-background : #023b48;\n\n$oa-blanco-bg : white;\n$oa-blanco : white;\n\n$oa-gris : #6e6e6e;\n$oa-gris-claro : #a3a3a3;\n$oa-gris-oscuro : #424242;\n\n$oa-color-link: #3e92ff;\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'numerosFormato'
})
export class NumerosFormatoPipe implements PipeTransform {

  transform(valor: number, decimales: number = 2): string {
	if ( typeof(valor) === 'string' ) valor = parseFloat(valor);

    if ( !valor || typeof(valor) !== 'number' ) {
        return valor.toString();
    }

	let n:number = valor;
	let parts = n.toFixed(decimales).split(".");
	let num = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
		(parts[1] ? "." + parts[1] : "");

	return num.toString();

  }

}

import { Component, OnInit } from '@angular/core';
import { PasarelaStripeService } from '../pasarela-stripe/pasarela-stripe.service';
import { AuthService } from '@auth/auth.service';
import { ModalesService } from '@core/modales.service';
import Swal from 'sweetalert2';
import { Planes } from '@models/interfaces';
import { planesMensual, planesAnual } from '@models/planes-oadmin.model';
import { TrackService } from '@core/track.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-actualizar-plan',
	templateUrl: './actualizar-plan.component.html',
	styleUrls: ['./actualizar-plan.component.scss']
})
export class ActualizarPlanComponent implements OnInit {
	precioAnual = false;
	PdfRecibo
	planes = planesMensual
	constructor(
		private ss: PasarelaStripeService,
		private auth: AuthService,
		private modales: ModalesService,
		private trk: TrackService,
		private router: Router,
	) { }


	esDowngrade(planNuevo: Planes) {
		return false; // lo deshabilito por que no me importa ahora que hagan downgrade, que puedan pagar
		planNuevo += this.precioAnual ? '-anual' : '-mensual';

		let planActual: Planes = <Planes>this.auth.usu?.plan;

		if (this.auth.primerMesVencido) {
			//Si vino por que se le vencio el primer mes no lo limita al plan que se quiere cambiar
			return false;
		}
		switch (planActual) {

			// case 'plan-prueba': return false; //este deberiamos de sacarlo
			case 'plan-pos-mensual':
			case 'plan-pos-anual':
				if (
					planNuevo === 'plan-pos-mensual' ||
					planNuevo === 'plan-pos-anual' ||
					planNuevo === 'plan-emprendedor-anual' ||
					planNuevo === 'plan-emprendedor-mensual' ||
					planNuevo === 'plan-empresa-anual' ||
					planNuevo === 'plan-empresa-mensual' ||
					planNuevo === 'plan-total-anual' ||
					planNuevo === 'plan-total-mensual'
				) {
					return false;
				}
				break;
			case 'plan-emprendedor-mensual':
			case 'plan-emprendedor-anual':
				if (
					planNuevo === 'plan-emprendedor-anual' ||
					planNuevo === 'plan-emprendedor-mensual' ||
					planNuevo === 'plan-empresa-anual' ||
					planNuevo === 'plan-empresa-mensual' ||
					planNuevo === 'plan-total-anual' ||
					planNuevo === 'plan-total-mensual'
				) {
					return false;
				}
				break;
			case 'plan-empresa-mensual':
			case 'plan-empresa-anual':
				if (
					planNuevo === 'plan-empresa-anual' ||
					planNuevo === 'plan-empresa-mensual' ||
					planNuevo === 'plan-total-anual' ||
					planNuevo === 'plan-total-mensual'
				) {
					return false;
				}
				break;
			case 'plan-total-mensual':
			case 'plan-total-anual':
				if (
					planNuevo === 'plan-total-anual' ||
					planNuevo === 'plan-total-mensual'
				) {
					return false;
				}
				break;
			case 'plan-starter-anual':
			case 'plan-starter-mensual': return false;

			default: return false;
		}

		return true;
	}
	seleccionarPlan(plan) {
		this.trk.trackAccion('accion', 'Seleccionar Plan - ' + plan);
		if (this.esDowngrade(plan)) {
			Swal.fire('¡Ups!', 'No puedes pasar a un plan menor, para esto debes enviar un mail a contacto@oadmin.app solicitando tu cambio previa autorización.<br> Ante cualquier duda contáctanos.', 'error')
			return
		}
		let xPlan = plan + (this.precioAnual ? '-anual' : '-mensual');
		// if (xPlan === this.auth.usu?.plan && !this.auth.primerMesVencido) {
		// 	Swal.fire('¡Ups!', 'Ya cuentas con ese plan, puedes mejorarlo o cambiar el término de pago.', 'error')
		// 	return
		// }

		let precio = Number(this.planes[plan].precio)
		//ESTOS VALORES SE SOBREESCRIBEN EN BACKEND
		this.ss.pago.comprador.idUsu = this.auth.usu._id;
		this.ss.pago.comprador.email = this.auth.usu.usu;
		this.ss.pago.comprador.nombre = this.auth.usu.nom + ' ' + this.auth.usu.ape;
		this.ss.pago.vendedor.tipo = 'oadmin';

		this.ss.pago.importe = precio;
		this.ss.pago.conceptos = [];
		this.ss.pago.periodo = this.precioAnual ? 'anual' : 'mensual';

		this.ss.pago.conceptos.push({
			codigo: this.planes[plan].codigo,
			descripcion: this.planes[plan].descripcion,
			cantidad: 1,
			precio: precio,
			importe: precio
		})



		if (this.ss.valido()) {
			this.trk.trackAccion('accion', 'Entro a Pantalla  de Pago');
			this.ss.cerrarSesion = true;
			this.modales.pagoStripe().then(() => {

			})

		}

	}
	volver() {
		this.router.navigate(['inicio'])
	}

	cambiarPrecios() {

		if (this.precioAnual) {
			this.planes = planesAnual
		} else {

			this.planes = planesMensual
		}
	}
	ngOnInit() {
		// this.precioAnual = true;
		// this.seleccionarPlan('plan-expert');
	}

}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { take } from 'rxjs/operators';
import { Ui } from '@root/layout/ui.model';

@Directive({
  selector: '[noFocus]'
})
export class NoFocusDirective {
	isMobile = false;
	@Input('noFocus') noFocus = false //SE PONE EN TRUE DESDE EL ELEMENTO PARA FORZAR SIN IMPORTAR SI ESTAMOS EN MOBILE O NO
	// @ViewChildren()elemento: ElementRef
	@HostListener('focus', ['$event']) onFocus(e:any) {

		if( this.isMobile || this.noFocus )this.el.nativeElement.blur();

	}

	constructor(
		private el: ElementRef,
		private store: Store<AppState>
	) {
		this.store.select('ui').pipe(take(1)).subscribe( (ui:Ui) => this.isMobile = ui.isMobile)
	}
}



import { Component, OnInit, Inject } from '@angular/core';
import { Art } from '@models/arts.model';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MensajesService } from '@core/mensajes.service';
import { FcvDet } from '@models/fcvs.model';
import { DatosService } from '@core/datos.service';
import { GlobalService } from 'src/app/core/global.service';
import { Router } from '@angular/router';
import { ArticulosPopupComponent } from '../articulos-popup/articulos-popup.component';
import { UiService } from '../../../core/ui.service';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-agregarArticulos-popup',
	templateUrl: './agregarArticulos-popup.component.html',
	styleUrls: ['./agregarArticulos-popup.component.css']
})
export class AgregarArticulosPopupComponent implements OnInit {

	dets: FcvDet[] = []
	det: FcvDet = new FcvDet(1, new Art());
	idMdl: number = 0

	constructor(
		public ui: UiService,
		private m: MensajesService,
		public ds: DatosService,
		public g: GlobalService,
		private dialog: MatDialog,
		private router: Router,
		private snackBar: MatSnackBar,
		public modal: MatDialogRef<AgregarArticulosPopupComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {

		if (data.dets) this.dets = JSON.parse(JSON.stringify(data.dets));
		if (data.idMdl) this.idMdl = data.idMdl

	}
	nuevoArticulo() {
		this.m.nuevoArticulo().then((value) => {
        const art = value as Art;
			if (!art) return;
			if (art['_id']) {
				this.det = new FcvDet(1, art);
				if (this.idMdl >= 80) {
					this.det.precioManual = this.det.art.costo
				}

			} else {
				if (art) return;
			}
		})
	}

	articulosPopup() {

		let ancho = '100%'
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		const dialogo = this.dialog.open(ArticulosPopupComponent, {
			width: ancho,
			maxWidth: ancho,
			height: '100%',
			panelClass: 'dialogo-popup',
			data: { modal: true }
		})

		dialogo.afterClosed().subscribe((det: any) => {
			if (!det) return
			if (det['_id']) {
				this.acSelArt(det)
			} else {
				if (det) return
				this.g.SweetMsg(
					'¡Ups!',
					'Hubo un error al crear nuevo articulo, intente de nuevo.',
					'error'
				)
			}
		});
	}

	async agregarArticulo() {
		if (this.det.art.art === '') {
			this.snackBar.open('Debe seleccionar un PRODUCTO.', 'OK', {
				duration: 3000,
			})
			return
		}
		if (!this.det.cantidad) {
			this.snackBar.open('Debe asignar una CANTIDAD.', 'OK', {
				duration: 3000,
			})
			return
		}
		if (!this.det.precioManual) {
			this.snackBar.open('Debe asignar un PRECIO.', 'OK', {
				duration: 3000,
			})
			return
		}
		if (this.det.art.curvaAtributos) {

			if (this.det.art.curvaAtributos.atributo1.nombre && !this.det.atributo1) {
				this.snackBar.open(`Indique ${this.det.art.curvaAtributos.atributo1.nombre}.`, 'OK', { duration: 3000, verticalPosition: 'top' })
				return
			}
			if (this.det.art.curvaAtributos.atributo2.nombre && !this.det.atributo2) {
				this.snackBar.open(`Indique ${this.det.art.curvaAtributos.atributo2.nombre}.`, 'OK', { duration: 3000, verticalPosition: 'top' })
				return
			}
			if (this.det.art.curvaAtributos.atributo3.nombre && !this.det.atributo3) {
				this.snackBar.open(`Indique ${this.det.art.curvaAtributos.atributo3.nombre}.`, 'OK', { duration: 3000, verticalPosition: 'top' })
				return
			}
			if (this.det.art.curvaAtributos.atributo4.nombre && !this.det.atributo4) {
				this.snackBar.open(`Indique ${this.det.art.curvaAtributos.atributo4.nombre}.`, 'OK', { duration: 3000, verticalPosition: 'top' })
				return
			}

		}



		if (this.det.descuentoP >= 100) {
			this.snackBar.open('El porcentaje de descuento debe de ser menor al importe.', 'OK', { duration: 3000 })
			return
		}
		this.det.cantidad = Number(Number(this.det.cantidad).toFixed(2))

		this.det.precioOriginal = this.det.precioManual // Para guardar el precio con iva si es que es precio iva incluido

		//para que tenga los
		let det = JSON.parse(JSON.stringify(this.det))

		if (this.idMdl === 70 || this.idMdl === 70.1) {
			let utilidad = FcvDet.calculaUtilidad(det, '%')
			let utilidadMinima = this.ds.alertasBloqueos.alertasBloqueos.utilidadMinima
			if (utilidadMinima.activo && utilidadMinima.onScreen) {
				if (Number(utilidadMinima.parametro) > Number(utilidad)) {
					if (utilidadMinima.bloqueo) {
						let res = await Swal.fire('', 'La venta de este producto no cumple con la utilidad mínima.', 'warning');
						return;
					} else {
						Swal.fire('', 'La venta de este producto no cumple con la utilidad mínima.', 'warning')
					}
				}
			}
		}



		this.det.importe = this.det.cantidad * this.det.precioManual
		this.dets.push(this.det)
		this.det = new FcvDet()

	}
	eliminarArticulo(index: number) {
		let fcvDetGuardado = this.dets[index]
		this.dets.splice(index, 1);
		this.snackBar
			.open('Articulo borrado...', 'DESHACER', { duration: 3000, verticalPosition: 'top' })
			.onAction()
			.subscribe(() => {
				this.dets.push(fcvDetGuardado)
			})
	}

	acSelArt(art: any) {
		if (!art) {
			this.det = new FcvDet(1.0, new Art())
			return
		}

		if (!art['type'] && art._id) {
			this.det = new FcvDet(1.0, art)
			if (this.idMdl >= 80) {
				this.det.precioManual = this.det.art.costo
			}
		} else if (
			art.keyCode === 8 ||
			art.keyCode === 46 ||
			art.keyCode === 17
		) {
			debugger
			this.det = new FcvDet(1.0, new Art())
		}
	}

	acpetar() {

		if (this.det.art._id) this.agregarArticulo();

		this.modal.close(this.dets);

	}
	cancelar() {

		this.modal.close(null);

	}

	ngOnInit() {

		if (this.dets.length === 0) this.articulosPopup()

	}

}

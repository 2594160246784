import { ActionReducerMap } from '@ngrx/store';
import { Usu } from './models/usu.model';
import { Ui } from './layout/ui.model';
import { Fcv } from './models/fcvs.model';
import { Produccion } from './models/produccion.model';
import { Fpr } from './models/fprs.model';
import { Inventario } from './models/inventario.model';
import { Emp } from './models/emps.model';
import { Filtros } from './models/filtros.model';
import { Sucursal } from './models/sucursales.model';
import { Pipeline } from './modulos/crm/models/pipeline.model';
import { Trato } from './modulos/crm/models/trato.model';

import * as fromUsu from './auth/auth.reducer';
import * as fromUi from './layout/ui.reducer';
import { fcvsReducer } from './models/fcvs.reducer';
import * as fromProduccion from './models/produccion.reducer';
import * as fromFprs from './models/fprs.reducer';
import * as fromInventario from './models/inventario.reducer';
import * as fromEmps from './models/emps.reducer';
import * as fromFiltros from './models/filtros.reducer';
import * as fromSucursal from './models/sucursal.reducer';
import * as fromPipeline from './modulos/crm/models/pipeline.reducer';
import * as fromTrato from './modulos/crm/models/trato.reducer';

export interface AppState {
	usu: Usu;
	ui: Ui;
	produccion: Produccion;
	fcv: Fcv;
	fpr: Fpr;
	inventario: Inventario;
	emp: Emp;
	suc: Sucursal;
	filtros: Filtros;
	pipeline: Pipeline;
	trato: Trato;
}

export const appReducers: ActionReducerMap<AppState> = {
	usu: fromUsu.usuReducer,
	ui: fromUi.uiReducer,
	fcv: fcvsReducer,
	produccion: fromProduccion.produccionReducer,
	fpr: fromFprs.fprsReducer,
	inventario: fromInventario.inventariosReducer,
	emp: fromEmps.empsReducer,
	suc: fromSucursal.sucReducer,
	filtros: fromFiltros.filtrosReducer,
	pipeline: fromPipeline.pipelineReducer,
	trato: fromTrato.tratoReducer,
}

import { Component, Inject, OnInit } from '@angular/core';
import { DatosService } from '@root/core/datos.service';
import { Fondo } from '@models/fondo.model';
import { FondoPase } from '../../models/fondo-pase.model';
import { GlobalService } from '@root/core/global.service';
import { UiService } from '@root/core/ui.service';
import Swal from 'sweetalert2';
import { ConexionService } from '@root/core/conexion.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SnackBarService } from '@root/core/snackbar.service';

@Component({
	selector: 'app-fondo-pase',
	templateUrl: './fondo-pase.component.html',
	styleUrls: ['./fondo-pase.component.scss']
})
export class FondoPaseComponent implements OnInit {

	fondos: Fondo[] = []
	pase: FondoPase = new FondoPase()

	constructor(
		public ui: UiService,
		public g: GlobalService,
		public ds: DatosService,
		public sb: SnackBarService,
		public con: ConexionService,
		public modal: MatDialogRef<FondoPaseComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		if (data) {
			this.pase = new FondoPase(data.pase)
		}
	}

	nuevo() {
		this.pase = new FondoPase()
	}

	async guardar() {

		if (!this.pase.fondoDestino || !this.pase.fondoOrigen) {
			Swal.fire('¡Ups!', 'Debe de indicar fondo de origen y destino', 'error')
			return;
		}

		if (!this.pase.total) {
			Swal.fire('¡Ups!', 'Debe indicar el monto a pasar', 'error')
			return;
		}

		try {
			let res = await this.con.postP(this.pase, 'fondos-pase', true)
			if (res.data) this.pase = new FondoPase(res.data)
			this.modal.close()

		} catch (error) {
			console.log(error);
		}

	}

	cancelar() {
		this.modal.close()
	}

	async eliminar() {

		try {

			await this.con.postP(this.pase, 'fondos-pase/eliminar', true)
			this.modal.close()
			this.sb.open('Pase eliminado correctamente', 'OK', 3000)

		} catch (error) {
			console.log(error);
		}
	}

	ngOnInit(): void {
	}

}

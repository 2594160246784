import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConexionService } from './conexion.service';
import { ErrorService } from './errors.service';
import { GlobalService } from './global.service';
import { MensajesService } from './mensajes.service';
import { ModalesService } from './modales.service';
import { PdfFactura } from './pdf-factura.service';
import { PdfPedido } from './pdf-pedido.service';
import { PdfRecibo } from './pdf-recibo.service';
import { PopupsService } from './popups.service';
import { SnackBarService } from './snackbar.service';
import { SwUpdateService } from './swUpdate.service';
import { UiService } from './ui.service';
import { WebsocketsService } from './websockets.service';
import { MxService } from './facturacion/mx.service';
import { TrackService } from './track.service';
import { DatosService } from './datos.service';
import { FiltrosService } from './filtros.service';
import { PushNotificationService } from './push-notification.service';
import { EmailTrackingService } from './email-tracking.service';
import { ClipboardService } from './clipboard.service';
import { CfdisService } from './cfdis/cfdis.service';
import { StoreService } from './store.service';



@NgModule({
	declarations: [],
	imports: [
		CommonModule,
	],
	providers: [
		ClipboardService,
		ConexionService,
		DatosService,
		TrackService,
		ErrorService,
		GlobalService,
		MensajesService,
		ModalesService,
		PdfFactura,
		PdfPedido,
		PdfRecibo,
		PopupsService,
		SnackBarService,
		SwUpdateService,
		UiService,
		WebsocketsService,
		MxService,
		FiltrosService,
		PushNotificationService,
		EmailTrackingService,
		CfdisService
	],

})
export class CoreModule { }

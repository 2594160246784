import { Component, OnInit } from '@angular/core';
import { GlobalService } from '@core/global.service';
import { UiService } from '@core/ui.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { ConexionService } from '@core/conexion.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clave',
  templateUrl: './clave.component.html',
  styleUrls: ['./clave.component.scss']
})
export class ClaveComponent implements OnInit {
	suscripciones: Subscription[] = [];
	email:string
	cargando:boolean

  	constructor(
		private g : GlobalService,
		public ui: UiService,
		private store: Store<AppState>,
		private con: ConexionService,
		private router: Router,
	) { }

	cambiarClave(){
		if ( !this.email ){
			this.g.SweetMsg('¡Ups!', 'Debe de ingresar el email.', 'warning')
			return;
		}
    let ruta ='email-password/'+this.email
    this.email = '';
    this.cargando = true;
		this.con.get(ruta , true)
		.subscribe(
		(d:any) => {
      this.cargando = false;

      this.g.SweetMsg('¡Bien!', 'Se ha enviado un email con las instrucciones para recuperar la contraseña.', 'success')
      this.router.navigate(['/'])
		})
	}

	ngOnInit() {
		this.suscripciones.push(this.store.select('ui').subscribe(ui => {
			this.cargando = ui.isLoading;
		}));
	}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgregarArticulosPopupComponent } from './components/agregarArticulos-popup/agregarArticulos-popup.component';
import { ArticulosPopupComponent } from './components/articulos-popup/articulos-popup.component';
import { ContactosPopupComponent } from './components/contactos-popup/contactos-popup.component';
import { SeleccionarComprobantesPopupComponent } from './components/seleccionarComprobantes-popup/seleccionarComprobantes-popup.component';
import { AplicarReciboPopupComponent } from './components/aplicar-recibo-popup/aplicar-recibo-popup.component'
import { MaterialModule } from '../material.module';
import { PipesModule } from '@pipes/pipes.module';
import { DirectivasModule } from '@directivas/directivas.module';
import { ContactosRoutesModule } from '../modulos/contactos/contactos.routes';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AgregarArticulosPopupComponent,
    ArticulosPopupComponent,
    ContactosPopupComponent,
    SeleccionarComprobantesPopupComponent,
    AplicarReciboPopupComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    DirectivasModule,
    MaterialModule,
    ContactosRoutesModule,
    FlexLayoutModule,
    FormsModule,

  ],
  exports: [
    AgregarArticulosPopupComponent,
    ArticulosPopupComponent,
    ContactosPopupComponent,
    SeleccionarComprobantesPopupComponent,
    AplicarReciboPopupComponent
  ]
})
export class PopUpsModule { }

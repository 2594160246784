import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LectorCodigoBarrasComponent } from './components/lector-codigo-barras/lector-codigo-barras.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UiService } from '../../core/ui.service';

@Injectable({
	providedIn: 'root'
})
export class CodigoBarrasService {
	audio: HTMLAudioElement = new Audio()

	constructor(
		private router: Router,
		private dialog: MatDialog,
		private ui: UiService
	) {
		this.audio.src = "/assets/audio/codebar.mp3";
		this.audio.load();
	}

	beep() {
		try {
			this.audio.play();
		} catch (e) {
			console.log('Error en Codigo de Barra', e)
		}
	}
	abrirScanner(): Promise<string> {
		let ancho = '60%';
		let alto = '90%';
		history.pushState({}, "oAdmin", '/#' + this.router.url);
		if (this.ui.isMobile) {
			ancho = '100%';
			alto = '100%';
		}
		const dialogo = this.dialog.open(LectorCodigoBarrasComponent, {
			width: ancho,
			maxWidth: ancho,
			height: alto,
			panelClass: 'dialogo-popup',
			closeOnNavigation: true,
			disableClose: false,
			data: { modal: true }
		})
		return new Promise((res, rej) => {
			dialogo.afterClosed().subscribe((result: any) => {
				if(result === '@reset'){
					//pide que se reinicie por que el flash anda medio medio
					return this.abrirScanner()
				}
				if (result) this.beep()
				res(result);
			});
		});
	}

}

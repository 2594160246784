import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { ConexionService } from '@root/core/conexion.service';
import { DatosService } from '@root/core/datos.service';
import { GlobalService } from '@root/core/global.service';
import { MensajesService } from '@root/core/mensajes.service';
import { SnackBarService } from '@root/core/snackbar.service';
import { Contacto, ContactoEmail, ContactoTel } from '@root/models/contacto.model';
import { Fcp } from '@root/models/fcps.model';
import { CrmService } from '@root/modulos/crm/crm.service';
import { cambiarPipeline } from '@root/modulos/crm/models/pipeline.acciones';
import { Pipeline } from '@root/modulos/crm/models/pipeline.model';
import { cambiarTrato } from '@root/modulos/crm/models/trato.acciones';
import { Trato, TratoInfo } from '@root/modulos/crm/models/trato.model';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-trato-lateral',
	templateUrl: './trato-lateral.component.html',
	styleUrls: ['./trato-lateral.component.scss']
})
export class TratoLateralComponent implements OnInit {
	@ViewChild('respuesta', { static: true }) respuesta: ElementRef
	sus: Subscription[] = []

	trato: Trato = new Trato();
	tratoOriginal: Trato = new Trato();

	pipe: Pipeline = new Pipeline();
	empresa: Fcp = new Fcp()

	contacto: Contacto = new Contacto()

	tratoInfo: TratoInfo = new TratoInfo();
	editandoTratoInfo = false;
	respondiendoTratoInfo = false;

	hoverEmpresa = false;
	hoverDetalles = false;
	hoverContacto = false;
	constructor(
		private con: ConexionService,
		public ds: DatosService,
		private sb: SnackBarService,
		public crm: CrmService,
		public g: GlobalService,
		private store: Store<AppState>,
		public ms: MensajesService,
		private renderer: Renderer2
	) {
		this.sus.push(
			this.store.select('trato').subscribe((trato: Trato) => {
				if (trato._id) this.trato = new Trato(trato)
				if (trato._id) this.tratoOriginal = new Trato(JSON.parse(JSON.stringify(trato)))
				this.empresa = new Fcp(this.ds.fcps.filter(f => f._id === this.trato.idEmp || '')[0] || {})
				this.contacto = new Contacto(this.ds.contactos.filter(c => c._id === (this.trato.idContactos[0] || ''))[0] || {})
				this.procesaTratoInfo()

			}),
			this.store.select('pipeline').subscribe((pipe: Pipeline) => {
				if (pipe._id) this.pipe = new Pipeline(pipe)
				this.procesaTratoInfo()
			})
		)

	}
	async contactoEdita() {
		let nuevoCotnacto = await this.crm.contactoEdita(this.contacto)
		if (nuevoCotnacto) this.contacto = new Contacto(nuevoCotnacto)
		if(nuevoCotnacto && !nuevoCotnacto._id ){
			//viene con True como parametro, queire decir que lo dieron de baja
			this.trato.idContactos.splice(0,1)
			this.store.dispatch(cambiarTrato({trato: this.trato}));
		}
	}
	editarEmrpesa() {
		this.ms.nuevoContacto(2, this.empresa).then((fcp) => {
      const newFcp = fcp as Fcp;
			if (newFcp && newFcp._id) {
				this.empresa = new Fcp(fcp)
			}
		})
	}
	respondeTratoInfo(tratoInfo: TratoInfo) {
		this.tratoInfo = new TratoInfo(tratoInfo.dato, tratoInfo.valor);
		this.respondiendoTratoInfo = true;
		setTimeout(() => {
			let elemento = this.renderer.selectRootElement('#respuesta')
			elemento.focus()
		}, 0);

	}
	editarTratoInfo(tratoInfo: TratoInfo = new TratoInfo()) {
		this.tratoInfo = new TratoInfo(tratoInfo.dato, tratoInfo.valor)
		this.editandoTratoInfo = true
	}
	procesaTratoInfo() {
		let tratoInfo = JSON.parse(JSON.stringify(this.pipe.tratoInfo))
		tratoInfo.forEach((ti: TratoInfo) => {
			this.trato.detalle.forEach(det => {
				if (ti.dato === det.dato) ti.valor = det.valor
			})
		})
		this.trato.detalle = tratoInfo
	}

	async edicionGuardar(tipo: string, formulario?: NgForm, index?: number) {
		switch (tipo) {
			case 'trato-info-gaurdar':
				if (!this.tratoInfo.dato) {
					this.sb.open('Falta dato', 'OK', 5000)
					return
				}
				if (!this.tratoInfo.valor) {
					this.sb.open('Escriba una respuesta', 'OK', 5000)
					return
				}
				let detalleIndicie = -1
				this.trato.detalle.forEach((det, i) => {
					if (det.dato === this.tratoInfo.dato) detalleIndicie = i
				})
				this.trato.detalle[detalleIndicie].valor = this.tratoInfo.valor;
				this.store.dispatch(cambiarTrato({trato: this.trato}));
				this.tratoInfo = new TratoInfo();
				this.respondiendoTratoInfo = false;
				break;
			case 'trato-info-nuevo':
				if (!this.tratoInfo.dato) {
					this.sb.open('Escribe algún título', 'OK', 5000)
					return
				}
				this.pipe.tratoInfo.push(this.tratoInfo)
				this.store.dispatch(cambiarPipeline({pipeline: this.pipe}))
				this.procesaTratoInfo();
				this.store.dispatch(cambiarTrato({trato: this.trato}));
				this.editandoTratoInfo = false;
				break;
			case 'trato-info-borrar':
				let res = await this.g.SweetMsg('¿Borrar?', 'Este dato se borrara de todos los tratos en los que exista dentro de este pipeline y no podrá recuperarlo.', 'question')
				if (!res.value) return;
				this.pipe.tratoInfo.splice(index, 1)
				this.store.dispatch(cambiarPipeline({pipeline: this.pipe}))
				this.procesaTratoInfo();
				this.store.dispatch(cambiarTrato({trato: this.trato}));
				break;
			case 'contacto':
				this.contactoGuardar()
				formulario.form.markAsPristine()
				break;
		}
	}
	edicionCancelar(tipo: string, formulario?: NgForm) {
		switch (tipo) {
			case 'trato-info-nuevo':
				this.editandoTratoInfo = false;
				break;
			case 'trato-info-gaurdar':
				this.respondiendoTratoInfo = false;
				break;
			case 'contacto':

				formulario.form.markAsPristine()
				break;
		}
	}
	async contactoGuardar() {
		if (!this.contacto.nom) {
			Swal.fire('', 'Escriba el nombre del contacto.', 'info')
			return
		}
		if (!this.contacto.idEmp && this.empresa._id) this.contacto.idEmp = this.empresa._id

		try {

			let res = await this.con.postP(this.contacto, 'crm/contacto', true)

			if (res.ok && res.data._id) {
				this.contacto = new Contacto(res.data)
			} else {
				Swal.fire('', 'Problema al Guardar el Contacto.', 'error')
				return;
			}
		} catch (error) {
			console.log(error);
		}

	}
	agregarEmail() {
		this.contacto.email.push(new ContactoEmail('', 'Trabajo'))
	}
	borrarEmail(i: number) {
		this.contacto.email.splice(i, 1)
	}
	agregarTelefono() {
		this.contacto.tel.push(new ContactoTel('', 'Trabajo'))

	}
	borrarTelefono(i: number) {
		this.contacto.tel.splice(i, 1)
	}
	async selectorGeneral(arreglo, columnas, elemento: 'empresa' | 'contacto') {
		let res = await this.ms.selectorGeneral(arreglo, columnas)
		if (!res) return
		if (elemento === 'contacto') { this.contacto = new Contacto(res); this.trato.idContactos[0] = this.contacto._id }
		if (elemento === 'empresa') { this.empresa = new Fcp(res); this.trato.idEmp = this.empresa._id }

		this.store.dispatch(cambiarTrato({trato: this.trato}));

		this.sb.open(this.g.stringTitulo(elemento) + ' Actualizado Correctamente')
	}

	ngOnInit(): void {

		this.empresa = new Fcp(this.ds.fcps.filter(f => f._id === this.trato.idEmp || '')[0] || {})
		this.contacto = new Contacto(this.ds.contactos.filter(c => c._id === this.trato.idContactos[0] || '')[0] || {})
	}

}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { GlobalService } from '@core/global.service';
import { AuthService } from '@auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { UiService } from '@core/ui.service';
import { DatosService } from '@core/datos.service';
import { ConexionService } from '@core/conexion.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { cambiarEmpresa } from '@root/models/emps.actions';
import {  cambiarUsuario } from '@root/auth/auth.actions';
import { TrackService } from '../../core/track.service';

@Component({
  selector: 'app-asistente-inicial',
  templateUrl: './asistente-inicial.component.html',
  styleUrls: ['./asistente-inicial.component.css']
})
export class AsistenteInicialComponent implements OnInit, AfterViewInit {
  paso: number = 1
  constructor(
    public g: GlobalService,
    public auth: AuthService,
    private route: ActivatedRoute,
    public ui: UiService,
    public ds: DatosService,
    public con: ConexionService,
    public store: Store<AppState>,
    private router: Router,
    private trk:TrackService,
  ) {
    this.ui.loading(true);
    this.ds.buscarDatos().then(()=>{
      this.ui.loading(false);
    })
  }

  guardar() {

    let datos = { usu: this.auth.usu, emp: this.auth.emp };
    this.ui.loading(true);

    this.con.postP(datos, 'usus/asistente-inicial').then(() => {
      this.ui.loading(false);
      this.store.dispatch(cambiarEmpresa({emp: this.auth.emp}));
      this.store.dispatch(cambiarUsuario({obj: this.auth.usu}));

    }).catch((err) => {
      this.g.SweetMsg('¡Ups!', 'Hubo un error al cargar datos. Por favor cierre y vuelva a abrir la app.', 'error')
    });
  }
  paso1() {
    this.trk.trackAccion( 'accion', 'Asistente Inicial - Paso 1' );
    this.paso = 2;
    this.guardar();
  }

  paso2() {
    this.trk.trackAccion( 'accion', 'Asistente Inicial - Paso 2' );
    this.auth.emp.rso = this.auth.emp.emp;
    this.paso = 3;
    this.guardar();
  }

  paso3() {
    this.trk.trackAccion( 'accion', 'Asistente Inicial - Paso 3' );
    this.paso = 4;
    this.guardar();
  }

  paso4() {
    this.trk.trackAccion( 'accion', 'Asistente Inicial - Paso 4' );
    // this.g.SweetMsg('Facturación Electonica', '¿Desea habiliar la facturación electrónica?', 'question').then((res) => {
    //   if (res.value) {
    //     this.con.postP({}, 'emps/habilitar-femex').then(() => {
          this.g.SweetMsg('¡Excelente!', `Recibirás un correo con en ${this.auth.usu.usu} para confirmarlo.`, 'success').then(()=>{
            document.body.style['background-color'] = '#d9d9d9';
            this.router.navigate(['inicio']);
          })
      //   }).catch(e=>{
      //     this.g.SweetMsg('¡Ups!', e?.['error']?.message, 'error')
      //   });
      // }else{
      //   this.ui.loading(true);
      //   this.router.navigate(['inicio']);
      // }

    // })
  }

  ngAfterViewInit() {

    document.body.setAttribute('style', 'background-color:#023b48 !important');
  }

  ngOnInit() {
    this.route.data.pipe(take(1)).subscribe((data: any ) => {
      this.ui.paginaActual = data.titulo || '';
    })
  }

}

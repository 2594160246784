import { Directive, ElementRef, HostListener, Output, Input, EventEmitter, OnInit, AfterViewInit, AfterViewChecked, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { GlobalService } from '@core/global.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { map, take } from 'rxjs/operators';

@Directive({
  selector: '[autoCompletar]'
})
export class autoCompletarDirective implements OnChanges {
	iniciado = false;
	isMobile = false;
	@Input() acPropiedadMuestra:'';
	@Input() ngModelDatos: {};
	@Output() ngModelDatosChange = new EventEmitter();
	@Input() ngModel;
	@Output() ngModelChange = new EventEmitter();
	@Output() acFuncionNuevo = new EventEmitter();

	@HostListener('ngModelChange', ['$event']) onChange(model:any) {
		if( typeof(model) === 'string' || typeof(model) === 'undefined' || !model) return;
		this.el.nativeElement.value = model[this.acPropiedadMuestra];
	}

	@HostListener('blur', ['$event']) onBlur(e:any) {
		if (!this.ngModel) {this.el.nativeElement.value = ''; return;}
		if (!this.ngModel[this.acPropiedadMuestra]) this.el.nativeElement.value = '';
		// if ( this.isMobile ) {
		// 	this.el.nativeElement.style.position = 'relative'
		// 	this.el.nativeElement.style.top = '0'

		// }
	}
	@HostListener( 'keydown', ['$event'] ) onKeyPress(e:KeyboardEvent) {

		if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 17) {

			if ( typeof( this.ngModel ) === 'string' || typeof( this.ngModel ) === 'undefined' || !this.ngModel ) return;

			this.ngModel = ''
			this.ngModelChange.emit( this.ngModel );
		}
	}
	constructor(
		private el: ElementRef,
		private g: GlobalService,
		private store: Store<AppState>
	) {
		this.store.select('ui').pipe(take(1)).subscribe( ui => this.isMobile = ui.isMobile)
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.ngModel) this.el.nativeElement.value = ''

		if( typeof(this.ngModel) === 'string' || typeof(this.ngModel) === 'undefined' || !this.ngModel) return;

		setTimeout(() => {
			this.el.nativeElement.value = this.ngModel[this.acPropiedadMuestra] ;
		}, 50);
	}

}


import { createReducer, on } from '@ngrx/store';
import * as fromProduccion from './produccion.actions';
import { Produccion } from './produccion.model';

const produccionLocal = JSON.parse(window.localStorage.getItem('produccion'));
const estadoInicial: Produccion = produccionLocal ? Object.assign(new Produccion(), produccionLocal) : null;

export const produccionReducer = createReducer(
  estadoInicial,
  on(fromProduccion.cambiarProduccion, (state, { produccion }) => {
    const nvoProduccion = Object.assign(new Produccion(), produccion);
    window.localStorage.setItem('produccion', JSON.stringify(nvoProduccion));
    return nvoProduccion;
  })
);



import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Usu } from '@models/usu.model';
import { AuthService } from '../../auth.service';
import { Subscription } from 'rxjs';
import { UiService } from '@core/ui.service';
import { GoogleLoginProvider, FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { GlobalService } from 'src/app/core/global.service';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss']
})
export class RegistroComponent implements OnInit, OnDestroy {

  @ViewChild('f', {static: true}) f: NgForm
  suscripciones: Subscription[] = [];
  invitacionToken: string
  usu: Usu = new Usu();
  cargando: boolean

  constructor(
    private auth: AuthService,
    public ui: UiService,
    private g: GlobalService,
    private socialAuth: SocialAuthService,
    private actRoute: ActivatedRoute
  ) {
    this.usu.ape = environment.datosTesting.apellido;
    this.usu.nom = environment.datosTesting.nombre;
    this.usu.pass = environment.datosTesting.pass;
    this.usu.email = environment.datosTesting.email;
  }
  crearCuentaGoogle() {
    this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then(data => {
        let datos = { ...data, invitacionToken: '' }
        if (this.invitacionToken) datos.invitacionToken = this.invitacionToken
        this.auth.login('GOOGLE', datos)
      })
  }
  crearCuentaFB() {
    let pepe = this.invitacionToken
    this.socialAuth.signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((data: any) => {

        data.token = data.authToken;
        delete data.authTOken;

        let datos = { ...data, invitacionToken: '' };
        if (this.invitacionToken) datos.invitacionToken = this.invitacionToken;
        this.auth.login('FACEBOOK', datos);
      }).catch(err => {
        this.g.SweetMsg('¡Ups!', 'Parece que no se habilito el permiso de Facebook.', 'error');
        console.log('Error - FACEBOOK - ', err);
      })

  }
  infoCupon(){
    this.g.SweetMsg('Cupones', 'Si tienes algún cupón o código de referido, ingrésalo aquí, si no lo tienes o no lo recuerdas podrás cargarlo después.', 'info');
  }

  crearCuenta() {
    if ( !this.f.valid ){
        this.g.SweetMsg('¡Ups!', 'Complete los todos los campos.', 'error');
        return;
    }
    if (this.invitacionToken) this.usu.invitacionToken = this.invitacionToken;
    this.auth.crearCuentaEmail(this.usu)
  }

  ngOnInit() {
    let referido = this.actRoute.snapshot.paramMap.get("referido")
    if(referido){
      this.usu.referidoPor.codigoReferido = referido
    }
    this.suscripciones.push(
      this.actRoute.paramMap.subscribe((params) => {
        let correo = params.get('c');
        if (correo) {
          this.usu.email = correo
        }

        let invitacionToken = params.get('t');
        if (invitacionToken) {
          this.invitacionToken = invitacionToken
        }
      })
    )
  }

  ngOnDestroy() {
    this.invitacionToken = '';
    this.suscripciones.forEach((suscrpcion: Subscription) => {
      suscrpcion.unsubscribe();
    });

  }


}


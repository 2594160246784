// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-progress-bar {
  z-index: 1200 !important;
  position: absolute;
  top: 0;
  height: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EAEE,wBAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;AAAF","sourcesContent":["mat-progress-bar{\n\n  z-index: 1200 !important;\n  position: absolute;\n  top:0;\n  height: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//MATERIAL
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FileSelectComponent } from './file-select.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
	  FileSelectComponent 
  ],
  imports: [
    CommonModule,
	MatInputModule,
	MatButtonModule,	
	FlexLayoutModule,

  ],
  exports:[
	  FileSelectComponent
  ]
})
export class FileSelectModule { }

import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';import { GlobalService } from '@core/global.service';

@Component({
  selector: 'app-aplicar-recibo-popup',
  templateUrl: './aplicar-recibo-popup.component.html',
  styleUrls: ['./aplicar-recibo-popup.component.css']
})
export class AplicarReciboPopupComponent implements OnInit {
	total
	fpr
	fd


	constructor(
			private g: GlobalService,
			public modal: MatDialogRef<AplicarReciboPopupComponent>,
			@Inject(MAT_DIALOG_DATA) data: any

	) {
		this.fd = data.fd
		this.fpr = data.fpr
	}

	aceptar(){

		if ( this.total > this.fpr.saldo) {
			this.g.SweetMsg('¡Ups!', `No se puede aplicar más que el disponible, puede aplicar hasta: $ ${ this.g.formatoNumero(this.fpr.saldo) }`, 'error')
			return;
		}
		this.modal.close(this.total)
	}
	cancelar(){
		this.modal.close(false)
	}

	ngOnInit() {
	}

}


import { Component, OnInit, Inject } from '@angular/core';
import { Fcv } from '@models/fcvs.model';
import { UsoCfdi, ForPag, FondoTipo } from '@models/interfaces';
import { DatosService } from '@core/datos.service';
import { SelectDirective } from '@directivas/components/select.directive';
import { Fpr } from '@models/fprs.model';
import Swal from 'sweetalert2';
import { GlobalService } from '@core/global.service';
import { UiService } from '../../../core/ui.service';
import { Sector } from '../../../models/sectores.model';
import { SnackBarService } from '../../../core/snackbar.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';import { Fondo } from '@root/models/fondo.model';

@Component({
	selector: 'app-datos-mx',
	templateUrl: './datos-mx.component.html',
	styleUrls: ['./datos-mx.component.css'],
	providers: [SelectDirective],
})
export class DatosMxComponent implements OnInit {

	cmp: Fcv | Fpr = new Fcv();


	isModal: boolean = false;
	cargando: boolean = false

	//   esCuentaCorriente = false;

	//METODO DE PAGO --------------------------------------------.
	metsPags = [
		{ metPag: 'Pago en una sola exhibición', codigo: 'PUE' },
		{ metPag: 'Pago en parcialidades o diferido', codigo: 'PPD' },
	]
	metPagSeleccionado: any = 'PUE'
	//-----------------------------------------------------------.

	//USOS DE CFDIS ---------------------------------------------.
	usosCfdis: UsoCfdi[] = [];
	usoCfdiSeleccionado: any = '';
	//-----------------------------------------------------------.

	//FORMA DE PAGO ---------------------------------------------.
	tipoFondo: string
	fondos: Fondo[] = []
	//-----------------------------------------------------------.


	constructor(
		public ds: DatosService,
		public g: GlobalService,
		private sb: SnackBarService,
		public ui: UiService,
		public modal: MatDialogRef<DatosMxComponent>,
		@Inject(MAT_DIALOG_DATA) data: any,
	) {
		if (data.modal) { this.isModal = true }
		if (data.cmp) {

			if (data.cmp instanceof Fcv) {
				this.cmp = new Fcv(data.cmp)
			} else {
				this.cmp = new Fpr(data.cmp)
			}

			this.metPagSeleccionado = this.g.seleccionarSelect(this.cmp.metPag, 'codigo', this.metsPags, 'PUE');
			this.usoCfdiSeleccionado = this.g.seleccionarSelect(this.cmp.usoCfdi, 'codigo', this.ds.usosCfdis, 'P01');
			this.tipoFondo = this.g.seleccionarSelect(this.cmp.fon.fondoTipo, 'codigo', this.ds.fondosTipos);//FONDOSS
			this.fondos = this.ds.fondos.filter(f=> f.fondoTipo.codigo === this.tipoFondo )

		}
	}

	cambioFon() {
		if (this.tipoFondo !== '99') {
			this.metPagSeleccionado = 'PUE';
			this.g.cambioSelect('PUE', 'codigo', this.metsPags, this.cmp, 'metPag');
		} else {
			this.metPagSeleccionado = 'PPD';
			this.g.cambioSelect('PPD', 'codigo', this.metsPags, this.cmp, 'metPag');
		}
		// this.cmp.metPag = this.metsPags.forEach(mp=> mp.codigo = this.metPagSeleccionado)[0];

		this.fondos = this.ds.fondos.filter(f=> f.fondoTipo.codigo === this.tipoFondo )
		this.cmp.fon =  this.fondos ? this.fondos[0] : null;

	}

	aceptar() {
		if (this.cmp.facRelUUID && this.cmp.facRelUUID.length !== 36  ) {
			Swal.fire('¡Ups!', 'Parece que el UUID relacionado no es válido, debe de contener 36 caracteres (incluidos guines de separación)', 'warning');
			return;
		}

		if (!this.cmp.metPag.metPag) {
			Swal.fire('¡Ups!', 'Seleccione un Método de Pago.', 'warning');
			return;
		}
		if (!this.cmp.fon.fondo) {

			Swal.fire('¡Ups!', 'Seleccione una Forma de Pago.', 'warning');
			return;

		}
		if (!this.cmp.usoCfdi.usoCfdi && (this.cmp instanceof Fcv)) {

			Swal.fire('¡Ups!', 'Seleccione el uso de CFDi.', 'warning');
			return;

		}
		if (this.tipoFondo === '99' && !this.cmp.cuentaCorriente) {
			this.cmp.cuentaCorriente = true
			this.sb.open('Si el FORMA DE PAGO es POR DEFINIR debe ir a CUENTA CORRIENTE.', 'OK', 3000)
		}

		this.modal.close(this.cmp)

	}

	cancelar() {

		this.modal.close(null);

	}


	ngOnInit() {

	}


}
@Component({
	selector: 'app-datos-mx',
	templateUrl: './datos-mx.component.html',
	styleUrls: ['./datos-mx.component.css'],
	providers: [SelectDirective],
})
export class DatosMxComponentX extends DatosMxComponent { }

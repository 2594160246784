import { Injectable } from '@angular/core';
import { DatosService } from '../datos.service';
import Swal from 'sweetalert2';
import { Fcp } from '../../models/fcps.model';
import { ConexionService } from '../conexion.service';
import { Art } from '../../models/arts.model';
import { Fcv, FcvDet } from '../../models/fcvs.model';
import { AuthService } from '@root/auth/auth.service';
import { TrackService } from '@root/core/track.service';
import { GlobalService } from '@root/core/global.service';
import { UiService } from '@root/core/ui.service';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { changeFcv } from '@root/models/fcvs.actions';
import { take } from 'rxjs/operators';
import { EmpService } from '../emp/emp.service';

@Injectable({
	providedIn: 'root'
})
export class FcvService {

	constructor(
		private ui: UiService,
		private auth: AuthService,
		private ds: DatosService,
		private con: ConexionService,
		private trk: TrackService,
		private store: Store<AppState>,
		private g: GlobalService,
		private empService: EmpService,
	) { }

	isValid(fcv: Fcv){
		if (!fcv.fcp._id) {
			Swal.fire('¡Ups!', `Seleccione el ${fcv.idMdl < 80 ? 'cliente' : 'proveedor'}.`, 'warning');
			return false;
		}

		// VALIDACION DE DATOS FISCALES ---------------------------------------.
		if (!fcv.metPag.metPag) {

			Swal.fire('¡Ups!', 'Seleccione un Método de Pago presionando sobre el botón "Editar Datos".', 'warning');
			return;

		}
		if (!fcv.fon.fondo) {

			Swal.fire('¡Ups!', 'Seleccione una Forma de Pago presionando sobre el botón "Editar Datos".', 'warning');
			return;

		}
		if (!fcv.usoCfdi.usoCfdi) {
			Swal.fire('¡Ups!', 'Seleccione el Uso de CFDi presionando sobre el botón "Editar Datos".', 'warning');
			return;
		}
		// --------------------------------------------------------------------.

		if (fcv.fcvsDets.length === 0) {
			this.g.SweetMsg(
				'¡Ups!',
				'Debe de agregar por lo menos un articulo.',
				'warning'
			)
			return false;
		}

		let articuloNoIdentificado = false
		fcv.fcvsDets.forEach((fd) => {
			if (articuloNoIdentificado) return
			if (!fd.art || !fd.art._id || (fd.art._id && fd.art._id.indexOf('no-id') > -1)) {
				articuloNoIdentificado = true
			}
		})
		if (articuloNoIdentificado) {
			this.g.SweetMsg('¡Ups!', 'El comprobante contiene productos no identificados, reemplácelos por productos del sistema.', 'error')
			return false;
		}


		if (fcv.idMdl === 81 || fcv.idMdl === 71) {
			//VALIDACIONES PARA NOTAS DE CREDITO
			if (!fcv.idFac) {
				this.g.SweetMsg(`¡Ups!`, `Las notas de crédito deben de generarse desde una factura.`, `warning`)
				return false;
			}
			if (Math.abs(fcv.facSaldo) < Math.abs(fcv.total)) {
				this.g.SweetMsg(
					`¡Ups!`,
					`El total máximo para esta nota de crédito es de ${fcv.facSaldo}.`,
					`warning`)
				return false;
			}
		}


		if (fcv.idMdl >= 80 && !fcv.numero) {

			let msj = Swal.fire(
				'¡Ups!',
				'Debe de ingresar el número del comprobante.',
				'warning'
			)
			return false;
		}

		return true;
	}

	/**
	 * Saves the current state of Fcv in the store to the DB
	 * @returns
	 */
	async saveToDb() {

		const cmp: Fcv = await this.getFromStore()
		cmp.calculaTotal();

		if (this.auth.suc._id && this.auth.emp.multisucursal) cmp.sector = this.auth.suc.sector

		cmp.idSuc = this.auth.suc && !cmp._id ? this.auth.suc._id : '1';

		//Pasa el precio Manual a numero
		cmp.fcvsDets.forEach(fd => {
			fd.precioManual = Number(Number(fd.precioManual).toFixed(2))
		})

		if (!this.isValid(cmp)) return;

		cmp.emp  =  await this.empService.get()

		cmp.idEst = cmp.idMdl >= 80 ? 11 : cmp.idEst;

		this.trk.trackAccion('accion', 'Guarda ' + this.g.mdl(cmp.idMdl).nombre);

		this.ui.loading(true);
		// this.guardarFcvStore();
		const res = await this.con.postP(cmp, 'fcvs', true)
		this.ui.loading(false);

		if (!res.ok) {
			// fcv = new Fcv({ idMdl: cmp.idMdl, emp: cmp.emp });
			// this.editaContacto = true;
			return;
		}

		const getFcvRes = await this.con.getP(`fcv/${res.data}`) // < -- ?????
		return new Fcv(getFcvRes.data)
	}

	/**
	 * Saves the fcv to the store and optionally saves to the database
	 * @param fcv {Fcv}
	 * @param saveToDB {boolean}
	 */
	async save(fcv: Fcv, saveToDB: boolean = false){
		if(saveToDB) fcv = await this.saveToDb()
		this.store.dispatch(changeFcv({fcv}))
		return fcv

	}

	getFromStore = async (): Promise<Fcv> => {
		const fcv: Fcv = await this.store.select((state: AppState) => state['fcv']).pipe(take(1)).toPromise()
		return fcv;
	}

	buscaRegistraProveedor(emisor: any[], agregaProveedor: boolean) {
		return (async () => {
			let proveedor: any = this.ds.fcps.filter(e => { return e.rfc === emisor['Rfc'] })

			if (proveedor.length === 0) {
				if (!agregaProveedor) {
					Swal.fire('Proveedor', `Proveedor con RFC "${emisor['Rfc']}" no encontrado, registre el RFC en algún proveedor o cree uno nuevo.`, 'question')
					throw new Error('No se encontró el RFC del proveedor.')
				}

				//SE CREA EL PROVEEDOR
				let fcp: Fcp = new Fcp();
				fcp.idMdl = 3;
				fcp.rfc = emisor["Rfc"];
				fcp.codigo = emisor["Rfc"];
				fcp.rso = emisor["Nombre"];
				fcp.fcp = emisor["Nombre"];
				fcp.nom = emisor["Nombre"];

				let regFis = this.ds.regsFiss.filter(rf => rf.codigo === emisor["RegimenFiscal"])
				fcp.regFis = regFis.length > 0 ? regFis[0] : this.ds.regsFiss[0]

				//VALIDA QUE TODO ESTE BIEN
				if (!fcp.rfc) {
					Swal.fire('¡Ups!', 'No se pudo cargar el proveedor, RFC no encontrado en XML. Por favor carguelo manualmente.', 'error');
					throw new Error('No se pudo cargar el proveedor, RFC no encontrado en XML. Por favor carguelo manualmente.')
				}
				if (!fcp.rso) {
					Swal.fire('¡Ups!', 'No se pudo cargar el proveedor, Razón Social no encontrada en XML. Por favor carguelo manualmente.', 'error');
					throw new Error('No se pudo cargar el proveedor, Razón Social no encontrada en XML. Por favor carguelo manualmente.')
				}

				let res = await this.con.postP(fcp, 'fcps');
				return res.data;

				return fcp

			} else {

				return proveedor[0]

			}
		})()
	}

	buscaRegistraArticulo(concepto: any[], agregaArticulo: boolean) {
		return (async () => {
			let articuloXML = concepto["_attributes"]
			let impuestoXML = concepto["cfdi:Impuestos"]['cfdi:Traslados']['cfdi:Traslado']
			let articulos: Art[] = this.ds.pros.filter(e => { return e.art === articuloXML['Descripcion'] })
			let articulo: Art = null

			if (articulos.length === 0) {
				// if (!agregaArticulo) {
				// 	Swal.fire('Artículo', 'No se encontró el artículo.', 'question')
				// 	throw new Error('No se encontró el artículo.')
				// }


				//SE CREA EL ARTICULO
				let art: Art = new Art();
				art._id = 'autoGen' + ((Math.random() * 1000000000).toFixed(0));
				art.idMdl = 7;
				art.codigo = articuloXML['NoIdentificacion'] + '-xml-' + (Math.random() * 1000).toFixed(0);
				art.art = articuloXML['Descripcion'];
				art.precio = Number(articuloXML['ValorUnitario']);
				art.costo = art.precio;
				let uniMed = this.ds.unisMeds.filter((um) => { return (um.codigo === articuloXML['Unidad'] || um.codigo === articuloXML['ClaveUnidad']) })
				let uniMedDefault = this.ds.unisMeds.filter((um) => { return um.codigo.toUpperCase() === 'XUN' })
				art.uniMed = uniMed.length > 0 ? uniMed[0] : uniMedDefault[0]
				art.proSrv = articuloXML['ClaveProdServ'] || '01010101'
				//IMPUESTOS
				if (impuestoXML["_attributes"]) {
					let impuesto = this.ds.imps.filter(i => { return i.codigo === impuestoXML["_attributes"]['Impuesto'] })[0]
					art.impuestos.push({
						tipo: 'IMP',
						imp: impuesto,
						tasa: impuestoXML['_attributes']['TasaOCuota'] ? Number(impuestoXML['_attributes']['TasaOCuota']) * 100 : 0,
						total: impuestoXML['_attributes']['Importe'] ? Number(impuestoXML['_attributes']['Importe']) : 0
					})

				} else {
					//varios impuestos
					Swal.fire('¡Ups!', 'Esta factura contiene artículos con varios impuestos y no es soportado por el sistema, si necesita esta función envie el XML y motivo a soporte@oadmin.app, estamos para ayudar en lo que necesite.', 'error');
					throw new Error('Articulo con varios impuestos.');

				}

				//VALIDA QUE TODO ESTE BIEN
				if (!art.art) {

					Swal.fire('¡Ups!', 'No se pudo cargar el producto, Descripcion no encontrada en XML. Por favor carguelo manualmente.', 'error');
					throw new Error('No se encontro descripcion del articulo');
				}
				if (agregaArticulo) {
					delete art._id
					let artFormData = new FormData();
					artFormData.append('data', JSON.stringify(art));

					let res = await this.con.postP(artFormData, 'arts', true, 'form-data');

					articulo = new Art(res.data);
				}
				articulo = art


			} else {

				articulo = articulos[0];
				articulo.precio = Number(articuloXML['ValorUnitario'])

			}


			let fcvDet = new FcvDet(Number(articuloXML['Cantidad']), articulo)
			let descuentoImporte = Number(articuloXML['Descuento']);
			if (descuentoImporte) {
				//calcula el porcentaje
				let importe = Number(articuloXML['Importe']);
				let descuentoP = descuentoImporte * 100 / importe
				fcvDet.descuentoP = descuentoP;

			}

			return fcvDet

		})()
	}

}

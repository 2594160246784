import { Routes, RouterModule } from '@angular/router';
import { Resolver } from '../../resolver';
import { NgModule } from '@angular/core';
import { CrmComponent } from './components/crm.component';
import { AuthGuard } from '@root/auth/auth.guard';
import { PipelineComponent } from './components/pipeline/pipeline.component';
import { TratoComponent } from './components/trato/trato.component';
import { PipelinesBusComponent } from './components/pipeline/pipelines-bus.component';
import { CrmContactoBusComponent } from './components/crm-contacto/crm-contacto-bus.component';

const rutas: Routes = [
	{
		path: 'dashboard',
		component: CrmComponent,
		canActivate: [AuthGuard], resolve: { datosCargados: Resolver },
		data: { titulo: 'CRM - Dashboard', idMdl: 0 },
	}, {
		path: 'pipelines-buscar',
		component: PipelinesBusComponent,
		canActivate: [AuthGuard], resolve: { datosCargados: Resolver },
		data: { titulo: 'CRM - Pipeline', idMdl: 0 },
	}, {
		path: 'pipeline',
		component: PipelineComponent,
		canActivate: [AuthGuard], resolve: { datosCargados: Resolver },
		data: { titulo: 'CRM - Pipeline', idMdl: 0 },
	}, {
		path: 'contactos-buscar',
		component: CrmContactoBusComponent,
		canActivate: [AuthGuard], resolve: { datosCargados: Resolver },
		data: { titulo: 'CRM - Contactos', idMdl: 0 },
	}, {
		path: 'trato',
		component: TratoComponent,
		canActivate: [AuthGuard], resolve: { datosCargados: Resolver },
		data: { titulo: 'CRM - Trato', idMdl: 0 },
	}, {
		path: '**', canActivate: [AuthGuard], component: CrmComponent, data: { titulo: '', idMdl: 0 },
	},
];


@NgModule({
	imports: [
		RouterModule.forChild(rutas)
	],
	exports: [
		RouterModule
	]
})
export class CrmRoutesModule { }

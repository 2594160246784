import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { AuthService } from '@root/auth/auth.service';
import { ConexionService } from '@root/core/conexion.service';
import { DatosService } from '@root/core/datos.service';
import { SnackBarService } from '@root/core/snackbar.service';
import { Contacto } from '@root/models/contacto.model';
import { Fcp } from '@root/models/fcps.model';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { CrmDetalle } from '../../models/crm-detalle.model';
import { cambiarPropiedadPipeline } from '../../models/pipeline.acciones';
import { Pipeline } from '../../models/pipeline.model';
import { cambiarTrato } from '../../models/trato.acciones';
import { Trato } from '../../models/trato.model';

@Component({
	selector: 'app-trato',
	templateUrl: './trato.component.html',
	styleUrls: ['./trato.component.scss']
})
export class TratoComponent implements OnInit, AfterViewInit, OnDestroy {
	sus: Subscription[] = []
	trato: Trato = new Trato();
	tratoOriginal: Trato = new Trato();
	pipe: Pipeline = new Pipeline()
	empresa: Fcp = new Fcp()
	contacto: Contacto = new Contacto()

	constructor(
		private actRoute: ActivatedRoute,
		private sb: SnackBarService,
		private ds: DatosService,
		private router: Router,
		private con: ConexionService,
		private store: Store<AppState>,
		private auth: AuthService
	) {
		this.sus.push(
			this.actRoute.paramMap.subscribe((params) => {
				let idTrato = params.get('id');
				if(!idTrato) {
					Swal.fire('Error', 'Para entrar a esta ruta es necesario, el ID del trato', 'error')
					this.router.navigate(['/inicio/crm/dashboard'])
				}
				this.buscarTrato(idTrato)

			}),
			this.store.select('trato').subscribe((trato: Trato) => {
				if (trato._id) this.trato = new Trato(trato)
				if (trato._id) this.tratoOriginal = new Trato(trato)
			}),
			this.store.select('pipeline').subscribe((pipe: Pipeline) => {
				if (pipe._id) this.pipe = new Pipeline(pipe)
			})
		)
	}
	async cambiarFase(i: number) {
		this.trato.indexFase = i
		this.trato.index = 0
		await this.guardar()
	}
	async buscarTrato(idTrato: string) {
		try {
			let res = await this.con.getP('crm/trato/' + idTrato, true)
			if (res.ok && res.data && res.data._id) {
				this.trato = new Trato(res.data)
				this.empresa = this.ds.fcps.filter(f => f._id === this.trato.idEmp || '')[0] || new Fcp()
				this.contacto = this.ds.contactos.filter(c => c._id === this.trato.idContactos[0] || '')[0] || new Contacto()
			} else {
				this.trato = new Trato()
				this.store.dispatch(cambiarTrato({trato: this.trato}));
				this.router.navigate(['inicio/crm/dashboard'])
				this.sb.open('No se encontró trato.', 'OK', 2000)
				return
			}

			this.store.dispatch(cambiarTrato({trato: this.trato}));

		} catch (error) {
			console.log(error)

		}
	}
	tratoReabrir() {
		this.trato.status = 'pendiente'
		this.store.dispatch(cambiarTrato({trato: this.trato}))
	}
	tratoGanado() {
		this.trato.status = 'ganado'
		this.trato.fechaGanado = new Date()
		Swal.fire('¡GANADO!', '¡Felicidades!, un trato ganado.', 'success')
		this.store.dispatch(cambiarTrato({trato: this.trato}))
	}
	async tratoPerdido() {
		let motivos: string[] = this.auth.emp['motivosRechazoTrato'] ||
			[
				'Precio elevado',
				'Usan uno de otro proveedor',
				'Buscan opciones que no tenemos',
				'Otro'
			]
		let motivosHTML = ''
		motivos.forEach(m=> {
			motivosHTML += `<option value="${m}">${m}</option>`
		})

		Swal.fire(<any>{
			title: `Trato Perdido`,
			html: `
			<div class="form-group">
          		<label for="medioPago">Motivo</label>
          		<select class="form-control" id="motivo">
					${motivosHTML}
          		</select>
        	</div>
			<div class="txt-16 gris-oscuro">
				Comentario<br>
				<textarea id="comentario" style="width:100%;height:120px;margin:10px 0"></textarea>
			</div>

			<button id="boton-cancelar" class="btn btn-secondary mt-4 btn-lg"> Cancelar </button>
			<button id="boton-aceptar" class="btn btn-primary mt-4 btn-lg"> Aceptar </button>
        `,
			// type: 'question',
			showConfirmButton: false,
			didOpen: () => {
				let BotonAceptar = document.querySelector('#boton-aceptar')
				let BotonCancelar = document.querySelector('#boton-cancelar')
				let comentario: any = document.querySelector('#comentario')
				let motivo: any = document.querySelector('#motivo')

				BotonAceptar.addEventListener('click', () => {
					this.trato.status = 'perdido'
					this.trato.fechaPerdido = new Date()
					let detalle = new CrmDetalle()
					detalle.detalle = `Perdido por: ${motivo}\n\nComentario: ${comentario.value || 'Sin comentarios'}`
					detalle.titulo = 'Trato Perdido'
					detalle.tipo = 'nota'
					detalle.importante = true
					detalle.completado = true
					detalle.idUsu = this.trato.idUsu
					detalle.idUsuAsignado = this.trato.idUsu
					detalle.idTrato = this.trato._id
					detalle.idContacto = this.trato.idContactos[0]
					detalle.idEmp = this.trato.idEmp
					detalle.idPipe = this.trato.idPipe
					this.con.postP(detalle, 'crm/detalle-guardar')
					this.store.dispatch(cambiarTrato({trato: this.trato}))
					Swal.close()
				})
				BotonCancelar.addEventListener('click', () => {
					Swal.close()
				})
			}
		})
	}
	async borrarTrato() {
		this.pipe.fases[this.trato.indexFase].tratos.splice(this.trato.index, 1)
		this.store.dispatch(cambiarPropiedadPipeline({prop: this.pipe}))


		let res = await this.con.postP(this.trato, 'crm/trato-eliminar', true)
		if (res.ok) {
			this.trato = new Trato()
			this.store.dispatch(cambiarTrato({trato: this.trato}))
			this.router.navigate(['/inicio/crm/pipeline'])
		}


	}
	async guardar() {
		try {
			await this.contactoGuardar()
			if (!this.trato.idContactos.includes(this.contacto._id)) this.trato.idContactos.push(this.contacto._id)
			this.trato.idEmp = this.empresa._id
			this.trato.idPipe = this.pipe._id
			this.store.dispatch(cambiarTrato({trato: this.trato}))
		} catch (error) {
			console.log(error);
		}
	}
	async contactoGuardar() {
		if (!this.contacto.nom) {
			Swal.fire('', 'Escriba el nombre del contacto.', 'info')
			return
		}
		if (!this.contacto.idEmp && this.empresa._id) this.contacto.idEmp = this.empresa._id

		try {

			let res = await this.con.postP(this.contacto, 'crm/contacto', true)

			if (res.ok && res.data._id) {
				this.contacto = new Contacto(res.data)
			} else {
				Swal.fire('', 'Problema al Guardar el Contacto.', 'error')
				return;
			}
		} catch (error) {
			console.log(error);
		}

	}

	ngAfterViewInit(): void {

	}
	ngOnInit(): void {

	}
	ngOnDestroy(): void {
		this.sus.forEach(s => s.unsubscribe())
	}

}

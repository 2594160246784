import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.css']
})
export class FileSelectComponent implements OnInit, AfterViewInit {


  @ViewChild('inputFile') inputFile: ElementRef;
  @ViewChild('imagen') imagen: ElementRef;
  @ViewChild('matInputText') matInputText: ElementRef;

  @Input('textoInputLbl') textoInputLbl = 'Cambiar Archivo';
  @Input('textoInputPh') textoInputPh = 'Seleccione Archivo';
  @Input('textoBoton') textoBoton = 'ARCHIVO';
  @Input('disabled') disabled:boolean = false;

  @Input('showInput') showInput:boolean = true;
  // @Input('idFileInput') idFileInput = '';

  @Input('verImagen') verImagen = false;
  @Input('verTexto') verTexto = true;
  @Input('estilo') estilo: string = 'horizontal';
  @Input('extensionesValidas') extensionesValidas: string[] = [];

  @Input('color') color = 'primary';
  @Input('urlImagenPredeterminada') urlImagenPredeterminada = '';

  @Input('archivoNombre') archivoNombre = '';
  @Input('name') name = 'archivo'; //name del input oculto

  // NOTA: aca esta arcvhio con input y archivoChange como output
  //    cuando se sufija la palabra change, angular sabe que ese dato puede ser de entrada o salida
  //    con lo cual se puede escribir [(archivo)]="xxx"
  //    entonces si hay un cambio desde afuera se actualiza este y si se cambia desde aca se actualiza afuera
  //    ojo, esto no limita el uso de (archivoChange)="xxx( $event )"
  @Input('archivo') archivo = null;
  @Output() archivoChange: EventEmitter<Object>;

  constructor() {
    this.archivoChange = new EventEmitter();
  }
  seleccionarArchivo() {
    this.inputFile.nativeElement.value =  null
    this.inputFile.nativeElement.click();
  }
  validaExtensiones(archivos) {
    if (this.extensionesValidas.length === 0) return true
    if (this.extensionesValidas.length === 1 && this.extensionesValidas[0] === '') return true
    for (let i = 0; i < archivos.length; i++) {
      let nombre = archivos[i].name;
      let extension: string = nombre.substring(nombre.lastIndexOf('.'));
      if (!this.extensionesValidas.includes(extension.toUpperCase())) {
        return false;
      }
    }
    return true;
  }
  cambiarArchivo(evento) {
    this.archivo = null;
    if (this.verTexto) this.matInputText.nativeElement.value = '';

    let archivos = evento.target.files
    if (archivos.length === 0) {
      console.log('No hay archivos');
      return;
    }
    if (!this.validaExtensiones(archivos)) {
      console.error('Extension seleccionada invalida');
      return;
    }
    if (archivos.length === 1) {
      this.archivo = archivos[0]
      if (this.verTexto) {
        this.archivoNombre = this.archivo.name;
        this.matInputText.nativeElement.value
      }


      if (this.verImagen) {

        var lector = new FileReader();

        lector.onload = () => {
          this.arreglaOrientacionTamano(lector.result, (imagenCorregida) => {
            this.imagen.nativeElement.src = imagenCorregida;
            this.blob2File(imagenCorregida).then(nvoArchivo => {
              this.archivo = nvoArchivo
              this.archivoChange.emit(nvoArchivo);
            })
          })
          // this.imagen.nativeElement.src = lector.result;
        }
        lector.readAsDataURL(this.archivo);
      } else {
        //ve el archivo directo
        this.archivoChange.emit(this.archivo);
      }
    } else {
      //TODO: VER QUE HACER SI SE RECIBEN MUCHOS ARCHIVOS
    }
  }
  blob2File(dataURL) {
    return new Promise((ok, er) => {
      var blobBin = atob(dataURL.split(',')[1]);
      var array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      var blob = new Blob([new Uint8Array(array)], { type: 'image/png' });
      var file = new File([blob], this.archivo.type);
      ok(file);
    })
  }
  arreglaOrientacionTamano(srcBase64, callback) {
    let img = new Image();
    let srcOrientation = 1;

    img.onload = () => {
      let datos = this.cambiarTamano(img, 250, 'alto');

      let canvas = datos.canvas;
      let ctx = datos.ctx;

      // set proper canvas dimensions before transform & export
      let width = canvas.width;
      let height = canvas.height;
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
        case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
        case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
        case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
        case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
        case 7: ctx.transform(0, -1, -1, 0, height, width); break;
        case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
        default: break;
      }
      ctx.drawImage(img, 0, 0, width, height);
      // export base64
      callback(ctx.canvas.toDataURL());
    }
    this.getOrientacion((orientacion) => {
      srcOrientation = orientacion;
      img.src = srcBase64;
    })


  }
  cambiarTamano(img, tamano, base: 'alto' | 'ancho') {
    let canvas = document.createElement('canvas');
    let height = img.height
    let width = img.width


    // definimos ratio, ancho y alto
    let ratio = 1;
    if ( base = 'alto' ){
      ratio = tamano / height
    } else {
      ratio = tamano / width
    }
    if (ratio < 1 ){
      // si es mas chica no la toca.
      height *= ratio;
      width *= ratio;
    }

    //se lo asignamos al canvas
    canvas.width = width;
    canvas.height = height;

    //obtenemos el contexto
    let ctx = canvas.getContext("2d");

    //dibujamos la imagen
    // ctx.drawImage(img, 0, 0, width, height);

    return { canvas, ctx };

  }

  //  arreglaOrientacionTamano(srcBase64, callback) {
  //   let img = new Image();
  //   let srcOrientation = 1;

  //   img.onload = () => {
  //     let width = img.width;
  //     let height = img.height;

  //     ctx = this.cambiarTamano(ctx, canvas, img, 50)

  //     let canvas = document.createElement('canvas');
  //     let ctx = canvas.getContext("2d");

  //     // set proper canvas dimensions before transform & export
  //     if (4 < srcOrientation && srcOrientation < 9) {
  //       canvas.width = height;
  //       canvas.height = width;
  //     } else {
  //       canvas.width = width;
  //       canvas.height = height;
  //     }

  //     // transform context before drawing image
  //     switch (srcOrientation) {
  //       case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
  //       case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
  //       case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
  //       case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
  //       case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
  //       case 7: ctx.transform(0, -1, -1, 0, height, width); break;
  //       case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
  //       default: break;
  //     }


  //     // draw image
  //     // ctx.drawImage(img, 0, 0);



  //     // export base64
  //     callback(ctx.canvas.toDataURL());
  //   }
  //   this.getOrientacion((orientacion) => {
  //     srcOrientation = orientacion;
  //     img.src = srcBase64;
  //   })


  // }
  // cambiarTamano(canvas, img, tamano) {
  //   canvas.height = canvas.width * (img.height / img.width);

  //   // step 1 - resize to 50%
  //   let oc = document.createElement('canvas');
  //   let octx = oc.getContext('2d');

  //   oc.width = img.width * 0.5;
  //   oc.height = img.height * 0.5;
  //   octx.drawImage(img, 0, 0, oc.width, oc.height);

  //   // step 2
  //   octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5);

  //   // step 3, resize to final size
  //   octx.drawImage(oc, 0, 0, oc.width * 0.5, oc.height * 0.5,
  //     0, 0, canvas.width, canvas.height);

  //   return oc;

  // }
  getOrientacion(callback) {
    let archivo = this.archivo
    var reader = new FileReader();

    reader.onload = (e: any) => {
      var view = new DataView(e.target.result);

      // check for jpeg marker '0xffd8', return if not img
      if (view.getUint16(0, false) != 0xFFD8) return callback(-2);

      var length = view.byteLength,
        offset = 2;

      while (offset < length) {
        var marker = view.getUint16(offset, false);
        offset += 2;

        // check for EXIF marker '0xFFE1'
        if (marker == 0xFFE1) {
          if (view.getUint32(offset += 2, false) != 0x45786966) return callback(-1);
          var little = view.getUint16(offset += 6, false) == 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;
          for (var i = 0; i < tags; i++)
            if (view.getUint16(offset + (i * 12), little) == 0x0112)
              return callback(view.getUint16(offset + (i * 12) + 8, little));
        } else if ((marker & 0xFF00) != 0xFF00) break;
        else offset += view.getUint16(offset, false);
      }
      return callback(-1);
    };
    reader.readAsArrayBuffer(archivo.slice(0, 64 * 1024));
  }

  ngOnInit() {

  }
  ngAfterViewInit() {
    let extensiones = ''
    this.extensionesValidas.forEach((ext) => {
      ext = ext.toUpperCase();
      if (ext.substr(0) !== '.') ext = '.' + ext;
      extensiones += ext + ',';
    })
    extensiones = extensiones.substr(0, extensiones.length - 1)
    this.inputFile.nativeElement.accept = extensiones;
    this.extensionesValidas = extensiones.split(',')

  }
}

import { Fcp } from './fcps.model';
import { Art } from './arts.model';
import { Emp } from './emps.model';
import { Imp, ForPag, UsoCfdi } from './interfaces';
import { Fcv, monType } from './fcvs.model';
import moment from 'moment'
import { Sector } from './sectores.model';
import { Fondo } from './fondo.model';

export class Fpr {

	public _id: string = '';
	public idUsu: string = '';
	public idSuc: string = '1';
	public idMdl: number = 0;
	public idEst: number = 10;
	public facRelUUID: string = '';
	public motivoCancelacion: string = null;
	public est = 'Abierto';
	public emp?: Emp
	public fcp: Fcp = new Fcp();
	public fprsDets: FprDet[] = [];
	public fon: Fondo = Fondo.predeterminado();
	public metPag = { metPag: 'Pago en parcialidades o diferido', codigo: 'PPD' };

	// EXTRAS -----------------------------------
	public ordenCompra: string = ''
	public numeroProveedor: string = ''
	public sector: Sector = Sector.predeterminado();
	// ------------------------------------------

	// FISCAL -----------------------------------
	public fecCer: moment.Moment = moment(0);
	public uuid: string = '';
	public nroSerSat: string;
	public nroSerCsdEmi: string;
	public SelloCfdi: string;
	public SelloSat: number;
	public usoCfdi?: UsoCfdi; //se pone por compatibilidad con fcv
	public cadOri: string;
	public rfcPAC: string;
	public selloCfdi: string;
	public selloSat: string = 'Sin Timbrar';
	public estadoSAT: string;
	public xml: string;
	public xmlAcuseCancelacion: string;
	// ------------------------------------------

	public numero: number = 1;
	public fecEmi: moment.Moment | Date = moment();
	public fecPag: moment.Moment | Date = moment();
	public idMon: monType = 'MXN';
	public monCot: number = 1.00;
	public total: number = 0.00;
	public saldo: number = 0.00;
	public obs: string = '';
	public bajaFec: number = 0;
	public baja: number = 0;
	public cuentaCorriente: boolean = false;

	constructor(obj: any = {}) {
		if(!obj) obj = {}
		this.emp = obj.emp || new Emp();
		this.fcp = obj.fcp || new Fcp();
		this.fprsDets = obj.fprsDets || [];
		this._id = obj._id || '';
		this.fon = obj.fon || this.fon

		// EXTRAS -----------------------------------

		this.ordenCompra = obj.ordenCompra || this.ordenCompra;
		this.numeroProveedor = obj.numeroProveedor || this.numeroProveedor;
		this.sector = obj.sector || this.sector;

		// ------------------------------------------

		// FISCAL -----------------------------------
		this.fecCer = moment(obj.fecCer) || moment(0);
		this.uuid = obj.uuid || '';
		this.nroSerSat = obj.nroSerSat || '';
		this.nroSerCsdEmi = obj.nroSerCsdEmi || '';
		this.SelloCfdi = obj.SelloCfdi || '';
		this.SelloSat = obj.SelloSat || '';
		this.cadOri = obj.cadOri || '';
		this.rfcPAC = obj.rfcPAC || '';
		this.selloCfdi = obj.selloCfdi || '';
		this.selloSat = obj.selloSat || '';
		this.estadoSAT = obj.estadoSAT || 'Sin Timbrar';
		this.xml = obj.xml || '';
		this.xmlAcuseCancelacion = obj.xmlAcuseCancelacion || '';
		// ------------------------------------------
		this.idUsu = obj.idUsu || '';
		this.idSuc = obj.idSuc || this.idSuc;
		this.idMdl = obj.idMdl || 0;
		this.idEst = obj.idEst || 10;
		this.est = String(this.idEst).replace('10', 'Abierto').replace('11', 'Cerrado').replace('12', 'ANULADO')
		this.facRelUUID = obj.facRelUUID || this.facRelUUID;
		this.motivoCancelacion = obj.motivoCancelacion || this.motivoCancelacion;
		this.numero = obj.numero || 0;
		this.fecEmi = moment(obj.fecEmi) || moment();
		this.fecPag = moment(obj.fecPag) || moment();
		this.metPag = obj.metPag || {};

		this.idMon = obj.idMon || 'MXN';
		this.monCot = obj.monCot || 1.00;
		this.total = obj.total || 0.00;
		this.saldo = obj.saldo || 0.00;
		this.obs = obj.obs || '';
		this.bajaFec = obj.bajaFec || 0;
		this.baja = obj.baja || 0;
	}

	calculaTotal() {
		this.total = Number(this.total);
		this.saldo = this.total;

		this.fprsDets.forEach((fd: FprDet) => {
			fd.aplicado = Number(Number(fd.aplicado).toFixed(6))
			fd.saldo = Number(fd.fcv.saldo.toFixed(6))
			fd.saldo = Number((fd.saldo - fd.aplicado).toFixed(6));
			this.saldo = Number((this.saldo - fd.aplicado).toFixed(6));
		})
	}
}

export class FprDet {

	public edita: boolean = false;
	public idFcv: string = '';
	public total: number = 0.00;
	public saldo: number = 0.00;
	public aplicado: number = 0.00;
	public pagoParcialidad: number = 0;
	public fcv?: Fcv = new Fcv();

	constructor(
		fcv: Fcv
	) {
		if (fcv) {
			this.fcv = fcv
			this.idFcv = this.fcv._id
			this.total = this.fcv.total
			this.saldo = this.fcv.saldo
			this.pagoParcialidad = this.fcv.pagoParcialidad;
			this.aplicado = 0.00
		}
	}
}

export const MOTIVOS_CANCELACION = [
	{
		codigo: '01',
		descripcion: 'Comprobante emitido con errores con realación'
	},
	{
		codigo: '02',
		descripcion: 'Comprobante emitido con errores sin realación'
	},
	{
		codigo: '03',
		descripcion: 'No se llevó a cabo la operación'
	},
	{
		codigo: '04',
		descripcion: 'Operación nominativa relacionada en una factura global'
	},
]

import { Injectable } from '@angular/core';
import { ConexionService } from '../conexion.service';
import { Fpr } from '../../models/fprs.model';
import { AuthService } from '@root/auth/auth.service';
import { TrackService } from '@root/core/track.service';
import { GlobalService } from '@root/core/global.service';
import { UiService } from '@root/core/ui.service';
import { AppState } from '@root/app.reducer';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { cambiarPago } from '@root/models/fprs.actions';

@Injectable({
	providedIn: 'root'
})
export class FprService {

	constructor(
		private ui: UiService,
		private auth: AuthService,
		private con: ConexionService,
		private trk: TrackService,
		private g: GlobalService,
		private store: Store<AppState>,
	) { }

	async isValid(fpr: Fpr) {
		if (fpr.idMdl >= 80 && !fpr.numero) {
			this.g.SweetMsg(
				'¡Ups!',
				'Debe de ingresar el número del comprobante.',
				'warning'
			)
			return;
		}
	}
	async saveToDb() {
		const fpr: Fpr = await this.getFromStore()

		if (!this.isValid(fpr)) return;

		fpr.calculaTotal();

		// Saca todas las facturas que no se estan pagando en este recibo
		let fprEnvio = JSON.parse(JSON.stringify(fpr))
		let aplicado = 0;

		this.trk.trackAccion('accion', 'Guarda ' + this.g.mdl(fpr.idMdl).nombre);

		let fprsDetsEnvio = []
		fpr.fprsDets.forEach((fd, i) => {

			if (fd.aplicado) {
				//solo manda los que tengan aplicado
				let xfd = JSON.parse(JSON.stringify(fd));
				aplicado += Number(fd.aplicado.toFixed(6))
				xfd.pagoParcialidad = xfd.fcv.pagoParcialidad + 1
				fprsDetsEnvio.push(xfd)
			}

		});

		fprEnvio.fprsDets = fprsDetsEnvio;

		if (!fprsDetsEnvio.length) {
			this.g.SweetMsg('¡Ups!', `No ha registrado ninguna factura todavia seleccione un ${fpr.idMdl < 80 ? 'cliente' : 'proveedor'},
									 cantidad y forma de pago y luego escriba los montos correspondientes.`,
				'warning');
			return
		}

		//Validaciones
		if (Number(aplicado.toFixed(6)) !== Number(fpr.total)) {
			this.g.SweetMsg(`Cuidado`,
				`Asegurate de aplicar todo el pago a los comprobantes.<br>
							 Quedan  <span class="roboto-medium">$${this.g.formatoNumero(fpr.saldo)}</span> por aplicar`,
				`warning`);
			return;
		}

		try {

			fprEnvio.idEst = 11;
			fprEnvio.idSuc = this.auth.suc && !fprEnvio._id ? this.auth.suc._id : '1';

			this.ui.loading(true);
			const response = await this.con.postP(fprEnvio, 'fprs', true)
			this.ui.loading(false);



			fpr.idEst = fprEnvio.idEst
			fpr.idSuc = fprEnvio.idSuc
			fpr._id = response._id;
			fpr.numero = response.numero;

			return fpr

		} catch (err) {
			this.ui.loading(false);
			console.log('Error', err);
			throw err
		}
	}

	/**
	 * Saves the fcv to the store and optionally saves to the database
	 * @param fpr {Fpr}
	 * @param saveToDB {boolean}
	 */
	async save(fpr: Fpr, saveToDB: boolean = false){
		if(saveToDB) fpr = await this.saveToDb()
		this.store.dispatch(cambiarPago({fpr}))
		return fpr
	}

	getFromStore = async (): Promise<Fpr> => {
		const fpr: Fpr = await this.store.select((state: AppState) => state['fpr']).pipe(take(1)).toPromise()
		return fpr;
	}

}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.movil {
  height: 50px !important;
  line-height: 50px !important;
  margin: 0 !important;
  width: 100% !important;
}

#art_descripcion {
  height: 100px !important;
}

.botonera {
  position: sticky;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/fondos/fondos-edita/fondos-edita.component.scss"],"names":[],"mappings":"AAAA;EACC,uBAAA;EACA,4BAAA;EACA,oBAAA;EACA,sBAAA;AACD;;AAEA;EACC,wBAAA;AACD;;AAEA;EACC,gBAAA;AACD","sourcesContent":["button.movil {\n\theight: 50px !important;\n\tline-height: 50px !important;\n\tmargin: 0 !important;\n\twidth: 100% !important;\n}\n\n#art_descripcion {\n\theight: 100px !important;\n}\n\n.botonera{\n\tposition: sticky;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

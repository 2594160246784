import { AfterContentInit, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { ConexionService } from '@root/core/conexion.service';
import { GlobalService } from '@root/core/global.service';
import { UiService } from '@root/core/ui.service';
import { Fcv } from '@root/models/fcvs.model';
import { Fpr } from '@root/models/fprs.model';
import Swal from 'sweetalert2';
import { cambiarPago } from '@root/models/fprs.actions';

@Component({
	selector: 'app-ver-recibos',
	templateUrl: './ver-recibos.component.html',
	styleUrls: ['./ver-recibos.component.scss']
})
export class VerRecibosComponent implements OnInit, AfterContentInit {

	fcv: Fcv = new Fcv()

	fprs = new MatTableDataSource<Fpr>([])
	displayedColumns = [
		'fecha-emision',
		'fecha-pago',
		'numero',
		'aplicado',
		'saldo',
		'ver',
	]

	constructor(
		private con: ConexionService,
		private store: Store<AppState>,
		private router: Router,
		public g: GlobalService,
		public ui: UiService,
		public modal: MatDialogRef<VerRecibosComponent>,
		@Inject(MAT_DIALOG_DATA) data: { fcv: Fcv }
	) {
		if (data.fcv) {
			this.fcv = new Fcv(data.fcv)
		}
	}

	async verRecibo(fpr: Fpr) {
		try {
			this.ui.loading(true)
			let frpResponse = await this.con.getP('fpr/' + fpr._id)
			this.ui.loading(false)

			if (!frpResponse.ok) {
				Swal.fire('Error', 'Error inesperado al buscar el recibo.', 'error');
				return;
			}
			if (!frpResponse.data || !frpResponse.data._id) {
				Swal.fire('Error', 'No se encontró el recibo.', 'error');
				return;
			}

			this.modal.close(true)

			this.store.dispatch(cambiarPago({fpr: frpResponse.data}))
			let ruta = 'inicio/' + this.g.mdl(fpr.idMdl).ruta
			this.router.navigate([ruta])



		} catch (error) {
			this.ui.loading(false)
			console.log(error)
			Swal.fire('Error', 'Error inesperado al buscar el recibo.', 'error');
			return;
		}

	}
	async buscar() {
		try {
			this.ui.loading(true)
			this.fprs = new MatTableDataSource([])
			const recibosResponse: { ok: boolean, data: Fpr[] } = await this.con.getP('fprs/buscar-fcvs/' + this.fcv._id)

			this.ui.loading(false)
			if (!recibosResponse.ok) return

			if (recibosResponse.data && recibosResponse.data.length) {
				this.fprs = new MatTableDataSource(recibosResponse.data.map(f => {
					return f;
				}))
			}

		} catch (error) {
			this.ui.loading(false)
			console.log(error)
			Swal.fire('Error', 'Error inesperado al buscar los recibos para la factura.', 'error');
			return;
		}
	}
	cancelar() {
		this.modal.close(true)
	}

	ngAfterContentInit() {
		this.buscar()
	}

	ngOnInit(): void { }

}

import { createReducer, on } from '@ngrx/store';
import * as fromUI from './ui.actions';
import { Ui } from './ui.model';

const initialState: Ui = {
  isLoading: false,
  isLoadingLogin: false,
  isMobile: false,
  isSideBar: {
    open: true,
    pined: false
  }
};

if (screen.width <= 700) {
  initialState.isSideBar.open = false;
  initialState.isMobile = true;
}

export const uiReducer = createReducer(
  initialState,
  on(fromUI.activarLoading, (state) => ({
    ...state,
    isLoading: true
  })),
  on(fromUI.desactivarLoading, (state) => ({
    ...state,
    isLoading: false
  })),
  on(fromUI.activarLoadingLogin, (state) => ({
    ...state,
    isLoadingLogin: true
  })),
  on(fromUI.desactivarLoadingLogin, (state) => ({
    ...state,
    isLoadingLogin: false
  })),
  on(fromUI.toggleSidebar, (state, action) => ({
    ...state,
    isSideBar: {
      ...state.isSideBar,
      open: action.estado !== undefined ? action.estado : !state.isSideBar.open
    }
  })),
  on(fromUI.pinSidebar, (state) => ({
    ...state,
    isSideBar: {
      ...state.isSideBar,
      pined: !state.isSideBar.pined
    }
  }))
);

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trato {
  padding: 4px 5px 0 10px;
  width: 100%;
  height: 85px;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 3px 2px #b9b9b9;
  overflow: hidden;
}
.trato .titulo {
  font-size: 0.9rem;
  font-weight: 500;
  color: #474747;
}
.trato .sub-titulo {
  font-size: 0.83rem;
  font-weight: 400;
  color: #555555;
}
.trato .monto {
  font-size: 0.7rem;
  color: #8a8a8a;
}
.trato .icono {
  font-size: 1rem;
  color: #52572a;
}`, "",{"version":3,"sources":["webpack://./src/app/modulos/crm/components/pipeline/trato-card/trato-card.component.scss"],"names":[],"mappings":"AAQA;EAEC,uBAAA;EACA,WAAA;EACA,YAAA;EACG,gBAAA;EACH,oCAAA;EACA,+BAAA;EACA,gBAAA;AARD;AASC;EACC,iBAdY;EAeZ,gBAAA;EACA,cApBa;AAaf;AASC;EACC,kBAlBe;EAmBf,gBAAA;EACA,cAxBgB;AAiBlB;AASC;EACC,iBAtBW;EAuBX,cA3BY;AAoBd;AASC;EACC,eAAA;EACA,cA9BY;AAuBd","sourcesContent":["$titulo-color: #474747;\n$subtitulo-color: #555555;\n$monto-color: #8a8a8a;\n$icono-color: #52572a;\n$titulo-size: .9rem;\n$subtitulo-size: .83rem;\n$monto-size: .70rem;\n\n.trato {\n\t// border: solid 1px rgb(214, 214, 214);\n\tpadding: 4px 5px 0 10px;\n\twidth: 100%;\n\theight: 85px;\n    margin-top: 10px;\n\tbackground-color: rgb(255, 255, 255);\n\tbox-shadow: 0 0 3px 2px #b9b9b9;\n\toverflow: hidden;\n\t.titulo {\n\t\tfont-size: $titulo-size;\n\t\tfont-weight: 500;\n\t\tcolor: $titulo-color;\n\t}\n\t.sub-titulo {\n\t\tfont-size: $subtitulo-size;\n\t\tfont-weight: 400;\n\t\tcolor: $subtitulo-color;\n\t}\n\t.monto {\n\t\tfont-size: $monto-size;\n\t\tcolor: $monto-color;\n\t}\n\t.icono {\n\t\tfont-size: 1rem;\n\t\tcolor: $icono-color;\n\t}\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

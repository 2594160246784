import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';


@Injectable({
	providedIn: 'root'
})
export class ClipboardService {

	constructor(
		private clipboard: Clipboard
	) {

	}

	/**
	 * Reads clipboard and splits the string using first \n the \t to have the rows and the columns of the excel
	 * @param columns columns to report with the values
	 * @returns Object or -1 | Object which properties are the requested columns or -1 if there was an error
	 */
	async leerExcel(columns: string[]): Promise<string[] | -1> {

		try {

			if (window.isSecureContext) {

				var data = await navigator.clipboard.readText()
				let result = this.excel2Object(data, columns)
				return result || -1;

			} else {
				console.error('Clipboard: To read the clipboard you must use secure connection.')
			}
		} catch (error) {
			console.log('Data: >>> ', error);
		}

	}

	excel2Object(data: string, columns: string[]): string[] | -1 {
		if (!data) {
			Swal.fire('Error', `No se encontró data, asegurese de haber copiado desde excel con todo y encabezados.`, 'error')
			return -1;
		}
		if (!columns || columns.length == 0) {
			console.error('Error', `Debe de indicar que columnas se van a importar.`, columns)
			return -1;
		}
		let rows = data.split('\n')
		let headers = rows[0].split('\t')
		let result = []
		for (let ii = 0; ii < columns.length; ii++) {

			let headerName = String(columns[ii])
			let headerPosition = -1;

			// searches for the header position
			for (let i = 0; i < headers.length; i++) {
				if (String(headers[i].replace('\r', '')) == headerName) {
					headerPosition = i
					break;
				}
			}
			if (headerPosition == -1) {
				Swal.fire('Error', `Hace falta la columna ${headerName}, recuerde copiar los encabezados.`, 'error')
				return -1;
			}

			rows.forEach((row, i) => {
				if (!row) return;
				if (i == 0) return;
				if (ii == 0) result.push({})

				let values = row.split('\t');
				result[i - 1][headerName] = values[headerPosition];
			})
		}

		return result

	}


}

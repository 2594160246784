import { Art } from '@models/arts.model';

export type TratoStatusTipo = 'pendiente' |  'ganado' | 'perdido';

export class TratoInfo {
	dato: string;
	valor: string;

	constructor(dato: string='', valor: string = '') {
		this.dato = dato
		this.valor = valor
	}
}

export class Trato {
	_id: string = '';
	idUsu: string = '';
	idPipe: string = '';
	idEmp: string = '';
	fecha: Date = new Date()
	fechaGanado: Date = new Date()
	fechaPerdido: Date = new Date()
	fechaModificacion: Date = new Date()
	indexFase: number = 0;
	index: number = 0;
	titulo: string = '';
	idContactos: string[] = [];
	arts: Art[] = [];
	total: number = 0;
	status: TratoStatusTipo = 'pendiente'
	detallesVencidos: boolean = false
	detalle: TratoInfo[] = []

	constructor(obj: any = {}) {
		this._id = obj._id || this._id
		this.idUsu = obj.idUsu || this.idUsu
		this.idPipe = obj.idPipe || this.idPipe
		this.idEmp = obj.idEmp || this.idEmp
		this.fecha = obj.fecha || this.fecha
		this.fechaGanado = obj.fechaGanado || this.fechaGanado
		this.fechaPerdido = obj.fechaPerdido || this.fechaPerdido
		this.fechaModificacion = obj.fechaModificacion || this.fechaModificacion
		this.indexFase = obj.indexFase || this.indexFase
		this.index = obj.index || this.index
		this.titulo = obj.titulo || this.titulo
		this.idContactos = obj.idContactos || this.idContactos
		this.arts = obj.arts || this.arts
		this.detallesVencidos = obj.detallesVencidos || this.detallesVencidos
		this.total = isNaN(obj.total) ?  Number(this.total) : Number(obj.total);
		this.status = obj.status || this.status
		this.detalle = obj.detalle || this.detalle
	}


}


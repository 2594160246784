import { createReducer, on } from '@ngrx/store';
import * as fromSucs from './sucursales.acciones';
import { Sucursal } from './sucursales.model';

const initialState: Sucursal | null = (() => {
  const sucLocal = JSON.parse(window.localStorage.getItem('suc'));
  if (sucLocal) {
    const xSuc = new Sucursal();
    Object.assign(xSuc, sucLocal);
    return xSuc;
  } else {
    return null;
  }
})();

export const sucReducer = createReducer(
  initialState,
  on(fromSucs.cambiarSucursal, (state, action) => {
    const nvaSuc = JSON.parse(JSON.stringify(action.obj));
    window.localStorage.setItem('suc', JSON.stringify(nvaSuc));
    return nvaSuc;
  })
);

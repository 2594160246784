import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
// import { MensajeDefaultComponentX } from './mensajes/mensaje-default/mensaje-default.component';
// import { ContactosEditaComponent } from '../modulos/contactos/components/contactos-edita/contactos-edita.component';
// import { ProductosEditaComponentX } from '../componentes/productos/productos-edita/productos-edita.component';
// import { MuestraPdfComponentX } from '../componentes/muestra-pdf/muestra-pdf.component';
import { Router } from '@angular/router';
import { PasarelaStripeComponentX } from '../componentes/pasarela-stripe/pasarela-stripe.component';

@Injectable({
  providedIn: 'root'
})
export class ModalesService {

	urlAnterior: string;

  	constructor(
		public dialog: MatDialog,
		private router: Router,
	) { }

  pagoStripe() {

    let ancho = '60%';
		let altura = '95%';
		history.pushState({ }, 'oAdmin', '/#'+this.router.url);

		if (screen.width <= 992) {
      ancho = '100%';
      altura = '100%';
    }
		const dialogo = this.dialog.open(PasarelaStripeComponentX, {
			width: ancho,
			maxWidth: ancho,
			height: altura,
			closeOnNavigation: true,
      disableClose: true,
      panelClass:'dialogo-popup',
			data: { modal:true }
		})

		// Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
		return new Promise((res,rej )=> {
			//A esa referencia nos podemos suscribir para esperar respuesta del usuario.
			dialogo.afterClosed()
			.subscribe((result:any) => {
				res(result);
			});
		});
	}

}

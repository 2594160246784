import { Directive, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { Chart } from "frappe-charts"
import { chartData, chartType } from './frape-charts.types';

// declare var Chart: any; // just to avoid error marked by editor. Optional declaration

@Directive({
  selector: '[frappeChart]'
})
export class FrappeChartsDirective {

  @Input() title: string;
  @Input() data: chartData;
  @Input() type: chartType;
  @Input() height: number;
  @Input() colors: string[];

  @Output() frappe: EventEmitter<any> = new EventEmitter();

  testingData = {
    labels: ["12am-3am", "3am-6pm", "6am-9am", "9am-12am",
      "12pm-3pm", "3pm-6pm", "6pm-9pm", "9am-12am"
    ],
    datasets: [
      {
        name: "Some Data", type: "bar",
        values: [25, 40, 30, 35, 8, 52, 17, -4]
      },
      {
        name: "Another Set", type: "line",
        values: [25, 50, -10, 15, 18, 32, 27, 14]
      }
    ]
  }


  constructor(
    private el: ElementRef
  ) {
    this.type = this.type || 'axis-mixed';
    this.colors = this.colors || ['#0a738c', '#023b48', '#46c309', '#216500'];
    this.height = this.height || 350;

  }

  ngOnChanges() {
    // el objeto data se paasa con las lineOptions
    let options = {
      title: this.title,
      data: this.data,
      height: this.height || 350,
      type: this.type || 'axis-mixed',
      colors: this.colors || ['#0a738c', '#023b48', '#46c309', '#216500'],
      tooltipOptions: {
        formatTooltipX: d => (d + '').toUpperCase(),
        formatTooltipY: d => '$ ' + d,
      },
      lineOptions: this.data.lineOptions ? this.data.lineOptions : {},
    }
    let chart = new Chart(this.el.nativeElement, options)
    this.frappe.emit(chart)

  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { AppState } from '@root/app.reducer';
import { FcvService } from '@root/core/facturacion/fcvs.service';
import { MOTIVOS_CANCELACION } from '@root/constants/cfdi';
import { MotivoCancelacion } from '@root/models/cfdi.types';
import { changeFcv } from '@root/models/fcvs.actions';
import { Fcv } from '@root/models/fcvs.model';
import { cambiarPago } from '@root/models/fprs.actions';
import { Fpr } from '@root/models/fprs.model';
import Swal from 'sweetalert2';
import { FprService } from '@root/core/facturacion/fpr.service';

@Component({
	selector: 'app-cancela-cfdi-data',
	templateUrl: './cancela-cfdi-data.component.html',
	styleUrls: ['./cancela-cfdi-data.component.scss']
})
export class CancelaCfdiDataComponent implements OnInit {

	cmp: Fcv | Fpr = null
	motivosCancelacion: MotivoCancelacion[] = MOTIVOS_CANCELACION

	constructor(
		private modal: MatDialogRef<CancelaCfdiDataComponent>,
		@Inject(MAT_DIALOG_DATA) data: { type: 'fcv' | 'fpr' },
		private store: Store<AppState>,
		private fcvService: FcvService,
		private fprService: FprService,
	) {
		this.getCmp(data.type)
	}

	isValid = () => {
		if (!this.cmp.motivoCancelacion) {
			Swal.fire('Error', 'Debe de indicar el motivo de cancelacion', 'error')
			return false
		}
		if (!this.cmp.facRelUUID && this.cmp.motivoCancelacion === '01') {
			Swal.fire('Error', 'Si el motivo es 01 debe de indicar el comprobante relacionado (UUID)', 'error')
			return false
		}
		if (this.cmp.facRelUUID && !/\b[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-\b[0-9a-fA-F]{12}\b/.test(this.cmp.facRelUUID)) {
			Swal.fire('Error', 'Parece que el UUID está mal escrito, debe de tener la forma "12345678-1234-5678-1234-567812345678"', 'error')
			return false
		}

		return true
	}
	cerrar = () => {
		this.modal.close(null)
	}
	aceptar = (): Fcv | Fpr => {
		if (!this.isValid()) return
		if (this.cmp instanceof Fpr) {
			this.store.dispatch(cambiarPago({fpr: this.cmp}))
		} else {
			this.store.dispatch(changeFcv({ fcv: this.cmp }))
		}
		this.modal.close(this.cmp)
	}

	async getCmp(type: 'fcv' | 'fpr') {

		switch (type) {
			case 'fcv': this.cmp = await this.fcvService.getFromStore(); return;
			case 'fpr': this.cmp = await this.fprService.getFromStore(); return;
			default: this.cmp = null; return;
		}

	}

	ngOnInit(): void {
	}

}

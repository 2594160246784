import { Imp, UniMed, ProSrv, estado } from './interfaces';
import { Rubro } from './rubros.model';
import { Art } from './arts.model';
import { Sector } from './sectores.model';
import { Emp } from './emps.model';

export enum produccionEstados {
  anulado=12,
  nuevo = 19,
  enEsperaStock = 20,
  enProduccion = 21,
  produccionTerminada = 22,

}

export class ProduccionDetalle {
  cantidad: number = 1;
  art: Art = new Art();

  constructor(obj:any = {}){
    this.art = obj.art || this.art;
    this.cantidad = obj.cantidad || this.cantidad;
  }

}

export class Produccion {

  public _id: string = '';
  public idUsu: string = '';
  public idEst: produccionEstados = produccionEstados.nuevo;
  public idMdl: number = 150;
  public emp: Emp = new Emp();
  public numero: number = 0;
  public fechaRegistro:Date = new Date();
  public fechaProduccion:Date = new Date();
  public fechaInicioProduccion:Date = new Date();
  public fechaFinProduccion:Date = new Date();
  public ventaTotal: number = 0;
  public costoTotal: number = 0;
  public detalle: ProduccionDetalle[] = [];
  public detalleFinal: ProduccionDetalle[] = [];
  public materiasPrimas: ProduccionDetalle[] = [];
  public materiasPrimasFinal: ProduccionDetalle[] = [];
  public sector: Sector = Sector.predeterminado();
  public sectorMateriaPrima: Sector = Sector.predeterminado();
  public baja: number = 0;


  constructor(obj: any = {}) {

    this._id = obj._id ? obj._id : this._id;
    this.idUsu = obj.idUsu || this.idUsu;
    this.idEst = obj.idEst || this.idEst;
    this.idMdl = obj.idMdl || this.idMdl;
    this.emp = obj.emp || this.emp;
    this.numero = obj.numero || this.numero;
    this.fechaRegistro = obj.fechaRegistro || this.fechaRegistro;
    this.fechaProduccion = obj.fechaProduccion || this.fechaProduccion;
    this.fechaInicioProduccion = obj.fechaInicioProduccion || this.fechaInicioProduccion;
    this.fechaFinProduccion = obj.fechaFinProduccion || this.fechaFinProduccion;
    this.sector = obj.sector || this.sector;
    this.sectorMateriaPrima = obj.sectorMateriaPrima || this.sectorMateriaPrima;
    this.detalle = obj.detalle || this.detalle;
    this.detalleFinal = obj.detalleFinal || this.detalleFinal;
    this.materiasPrimasFinal = obj.materiasPrimasFinal || this.materiasPrimasFinal;
    this.ventaTotal = obj.ventaTotal || this.ventaTotal;
    this.costoTotal = obj.costoTotal || this.costoTotal;
    this.baja = obj.baja || this.baja;
  }

  /**
   * Recorre los detalles de la produccion y obtiene las materias primas
   */
  obtenerMateriasPrimas(){
    this.materiasPrimas = []
    this.detalle.forEach( det=>{ //articulos a producir

      if( det.art.detalle && det.art.detalle.length > 0 ){

        det.art.detalle.forEach(detDet=>{ //detalle de cada articulo a producir
          let encontrado = false
          this.materiasPrimas.map( mp =>{ //materias primas de la produccion
            if( mp.art._id === detDet.art._id ) {
              mp.cantidad += (Number(detDet.cantidad) * Number(det.cantidad));
              encontrado = true;
            }
          })
          if (!encontrado){
            let nvoDetDet = {... detDet }
            nvoDetDet.cantidad = (Number(detDet.cantidad) * Number(det.cantidad))
            this.materiasPrimas.push(nvoDetDet)
          }
        })

      }
    })
    //FORMATO
    this.materiasPrimas.map( mp =>{

      if(mp.art.cantidadMinima === 0 ) mp.art.cantidadMinima = 1

      let cantidadAPedir = (mp.cantidad / mp.art.cantidadMinima) - Math.trunc(mp.cantidad / mp.art.cantidadMinima)
      if ( cantidadAPedir !== 0 ){
        cantidadAPedir =  Math.trunc(mp.cantidad / mp.art.cantidadMinima) + 1
        cantidadAPedir = cantidadAPedir * mp.art.cantidadMinima;
      }else{
        cantidadAPedir = mp.cantidad;
      }
      mp.cantidad = Number(Number(cantidadAPedir).toFixed(4))
    })
  }
  calculaTotal(){

    this.ventaTotal = 0;
    this.costoTotal = 0;
    this.detalle.forEach( det=>{
      this.ventaTotal += Number(Number(det.art.precio).toFixed(2));
      this.costoTotal += Number(Number(det.art.costo).toFixed(2));
    })

    this.ventaTotal = Number(Number(this.ventaTotal).toFixed(2))
    this.costoTotal = Number(Number(this.costoTotal).toFixed(2))
  }

  estado(){
    switch(this.idEst){
      case produccionEstados.anulado: return 'Anualdo';
      case produccionEstados.nuevo: return 'Nuevo';
      case produccionEstados.enEsperaStock: return 'Esperando Stock';
      case produccionEstados.enProduccion: return 'En Producción';
      case produccionEstados.produccionTerminada: return 'Producción Terminada';
      default: return 'Estado Desconocido';
    }
  }

  modificable(){
    if( this.idEst === produccionEstados.nuevo || this.idEst ===  produccionEstados.enEsperaStock)
      return true

    return false
  }

  estaEn(estado : 'anulado' | 'nuevo' | 'enEsperaStock' | 'enProduccion' | 'produccionTerminada'){

    if( this.idEst === produccionEstados[estado] ) return true;

    return false;

  }


}

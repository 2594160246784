import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MensajeDefaultComponentX } from './mensajes/mensaje-default/mensaje-default.component';
import { ContactosEditaComponent } from '../modulos/contactos/components/contactos-edita/contactos-edita.component';
import { ProductosEditaComponentX } from '../componentes/productos/productos-edita/productos-edita.component';
import { MuestraPdfComponentX } from '../componentes/muestra-pdf/muestra-pdf.component';
import { Router } from '@angular/router';
import { UiService } from './ui.service';
import { Fcp } from '@models/fcps.model';
import { Art } from '@models/arts.model';
import { Fcv } from '@models/fcvs.model';
import { Fpr } from '@models/fprs.model';
import { DatosMxComponentX } from '../componentes/datos-paises/datos-mx/datos-mx.component';
import { Sucursal } from '../models/sucursales.model';
import { SucursalesEditaComponent } from '../modulos/sucursales/components/sucursales-edita/sucursales-edita.component';
import { SucursalesSeleccionaComponent } from '../modulos/sucursales/components/sucursales-selecciona/sucursales-selecciona.component';
import { ProductosInfoComponent } from '../componentes/productos/productos-info/productos-info.component';
import { SelectorGeneralComponent } from '@root/componentes/selector-general/selector-general.component';
import Swal from 'sweetalert2';
import { FondosEditaComponent } from '@root/componentes/fondos/fondos-edita/fondos-edita.component';
import { Fondo } from '@root/models/fondo.model';
import { FondoPase } from '@root/models/fondo-pase.model';
import { FondoPaseComponent } from '@root/componentes/fondo-pase/fondo-pase.component';
import { VerRecibosComponent } from '@root/componentes/ver-recibos/ver-recibos.component';
import { CancelaCfdiDataComponent } from '@root/componentes/cancela-cfdi-data/cancela-cfdi-data/cancela-cfdi-data.component';

@Injectable({
  providedIn: 'root'
})
export class MensajesService {

  urlAnterior: string;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private ui: UiService,
  ) { }

  abrirDialog(titulo, mensaje) {

    //Se crea, configura y anexa informacion al dialogo para abrirlo y se hace referencia al mismo con una constante
    const dialogo = this.dialog.open(MensajeDefaultComponentX, {
      width: '50%',
      data: { titulo: titulo, mensaje: mensaje }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed().subscribe(result => {
        res(result);
      });
    });
  }
  selectorGeneral(arreglo: any[], columnasMostrar: string[]) {
    if (!arreglo || !columnasMostrar) {
      Swal.fire('Error', 'Para hacer la búsqueda, faltan datos.', 'error')
      return
    }
    let ancho = '50%';
    let alto = '70%';
    history.pushState({}, "oAdmin", '/#' + this.router.url);
    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }
    const dialogo = this.dialog.open(SelectorGeneralComponent, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: true,
      data: { modal: true, arreglo, columnasMostrar }
    })

    return new Promise((res, rej) => {

      dialogo.afterClosed().subscribe((result: any) => {
        res(result);
      }, rej);
    });
  }
  datosExtrasMx(cmp: Fcv | Fpr) {
    let ancho = '60%';
    let alto = '90%';
    history.pushState({}, "oAdmin", '/#' + this.router.url);
    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }
    const dialogo = this.dialog.open(DatosMxComponentX, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: false,
      data: { modal: true, cmp }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed().subscribe((result: any) => {
        res(result);
      });
    });
  }
  cancelarCfdiData(type: 'fcv' | 'fpr'): Promise<Fcv | Fpr> {
    let ancho = '60%';
    let alto = '60%';
    history.pushState({}, "oAdmin", '/#' + this.router.url);
    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }
    const dialogo = this.dialog.open(CancelaCfdiDataComponent, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: true,
      data: { type }
    })

    return new Promise((res) => {
      dialogo.afterClosed().subscribe((result: Fcv | Fpr) => {
        res(result);
      });
    });
  }
  nuevoPaseFondo(pase = new FondoPase()) {
    let ancho = '60%';
    let alto = '60%';
    history.pushState({}, "oAdmin", '/#' + this.router.url);
    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }
    const dialogo = this.dialog.open(FondoPaseComponent, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: true,
      data: { modal: true, pase }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed().subscribe((result: any) => {
        res(result);
      });
    });
  }
  verRecibosFactura(fcv = new Fcv()) {

    if (!fcv || !fcv._id) {
      Swal.fire('Comprobante Sin Guardar', 'Este comprobante aun no ha sido guardado.', 'warning')
      return;
    }

    let ancho = '60%';
    let alto = '90%';

    history.pushState({}, "oAdmin", '/#' + this.router.url);

    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }
    const dialogo = this.dialog.open(VerRecibosComponent, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: true,
      data: { modal: true, fcv }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed().subscribe((result: any) => {
        res(result);
      });
    });
  }
  nuevoFondo(fondo = new Fondo()) {

    let ancho = '60%';
    let alto = '90%';

    history.pushState({}, "oAdmin", '/#' + this.router.url);

    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }
    const dialogo = this.dialog.open(FondosEditaComponent, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: true,
      data: { modal: true, fondo }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed().subscribe((result: any) => {
        res(result);
      });
    });
  }
  nuevoContacto(idMdl: number, fcp = new Fcp()) {
    let ancho = '60%';
    let alto = '90%';
    history.pushState({}, "oAdmin", '/#' + this.router.url);
    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }
    const dialogo = this.dialog.open(ContactosEditaComponent, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: true,
      data: { modal: true, fcp, idMdl }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed().subscribe((result: any) => {
        res(result as Fcp);
      });
    });
  }
  nuevaSucursal(suc = new Sucursal()) {
    let ancho = '60%';
    let alto = '90%';
    history.pushState({}, "oAdmin", '/#' + this.router.url);
    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }
    const dialogo = this.dialog.open(SucursalesEditaComponent, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: true,
      data: { modal: true, suc }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed().subscribe((result: any) => {
        res(result as Sucursal);
      });
    });
  }
  seleccionaSucursal() {
    let ancho = '50%';
    let alto = '90%';
    history.pushState({}, "oAdmin", '/#' + this.router.url);
    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }
    const dialogo = this.dialog.open(SucursalesSeleccionaComponent, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: false,
      data: { modal: true }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed().subscribe((result: any) => {
        res(result as Sucursal);
      });
    });
  }
  infoArticulo(art = new Art()) {
    let ancho = '60%';
    let alto = '90%';
    history.pushState({}, "oAdmin", '/#' + this.router.url);
    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }

    const dialogo = this.dialog.open(ProductosInfoComponent, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: true,
      data: { modal: true, art }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed().subscribe((result: any) => {
        res(result as Art);
      });
    });
  }
  nuevoArticulo(art = new Art()) {
    let ancho = '60%';
    let alto = '90%';
    history.pushState({}, "oAdmin", '/#' + this.router.url);
    if (this.ui.isMobile) {
      ancho = '100%';
      alto = '100%';
    }

    const dialogo = this.dialog.open(ProductosEditaComponentX, {
      width: ancho,
      maxWidth: ancho,
      height: alto,
      panelClass: 'dialogo-popup',
      closeOnNavigation: true,
      disableClose: true,
      data: { modal: true, art }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed()
        .subscribe((result: any) => {
          res(result as Art);
        });
    });
  }

  muestraPDF(formato: string, demo: boolean = false, blobQR: string) {
    let ancho = '50%';
    if (this.ui.isMobile) ancho = '95%'
    history.pushState({}, 'oAdmin', '/#' + this.router.url);
    const dialogo = this.dialog.open(MuestraPdfComponentX, {
      width: ancho,
      height: '95%',
      disableClose: true,
      data: { modal: true, formato, demo, blobQR }
    })

    // Encapsulo en una promesa para poder recibir la respuesta en donde lo estoy llamando
    return new Promise((res, rej) => {
      //A esa referencia nos podemos suscribir para esperar respuesta del usuario.
      dialogo.afterClosed().subscribe(result => {
        res(result);
      });
    });
  }
}

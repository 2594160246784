import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ConexionService } from './conexion.service';
import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorService } from './errors.service';
import { TrackService } from './track.service';

@Injectable(
	{ providedIn: 'root' }
)
export class SoporteService implements OnDestroy {
	suscripciones: Subscription[] = []

	constructor(
		private g: GlobalService,
		private _http: HttpClient,
		private trackService: TrackService,

	) { }

	enviarInforme() {
		let logs: any[] = []
		let logsLS = localStorage.getItem('oadmin-log')
		if (logsLS) logs = JSON.parse(logsLS)

		this.trackService.trackAccion('envio-informe', 'informe', logs)

	}
	enviarComentarios(comentarios: string) {
		if (comentarios.length < 2) {
			return;
		}
		let authToken = this.g.getDato('authToken') || 'xxxx';
		const URL = environment.apiURL + 'support/comentarios/';

		let CABECERAS = new HttpHeaders({ 'Content-Type': 'application/json', 'authToken': authToken });

		this._http.post(URL, { comentarios }, { headers: CABECERAS })
			.subscribe(
				(data: any) => {
					if (!data.ok) {
						console.log('Error al enviar comentarios', data)
						return;
					}
					Swal.fire('¡Bien!', 'Gracias por tomarte el tiempo de escribirnos.', 'success');
				},
				err => {
					Swal.fire('Error', 'Hubo un error al enviar tus comentarios, por favor envia un mail a soporte@oadmin.app.', 'error');
					console.log('Error al enviar comentarios', err)
					return;
				}
			)

	}


	SolicitarSoporte() {

		Swal.fire(<any>{
			title: '¡Hablemos!',
			html: `
        <p>
          Cuéntanos que te parece y si hay algo que podamos mejorar. También puedes enviar un mail soporte@oadmin.app.
        </p>
        <textarea id="comentarios-textarea" class="comentarios"></textarea>
        <button id="comentarios-boton" class="btn btn-primary mt-4 btn-lg">
        Enviar
        </button>
        <button id="informe-boton" class="btn btn-primary mt-4 btn-lg">
        Enviar Informe de Error
        </button>
        `,
			type: 'info',
			showConfirmButton: false,
			didOpen: () => {
				let comentariosBoton = document.querySelector('#comentarios-boton')
				let informeBoton = document.querySelector('#informe-boton')
				let comentariosTextarea: any = document.querySelector('#comentarios-textarea')

				comentariosBoton.addEventListener('click', () => {

					this.enviarComentarios(comentariosTextarea.value)

					Swal.close()
				})
				informeBoton.addEventListener('click', () => {

					this.enviarInforme()

					Swal.close()
				})
			}
		})
	}


	ngOnDestroy() {
		this.suscripciones.forEach(s => s.unsubscribe());
	}

}

import { createReducer, on } from '@ngrx/store';
import * as fromEmps from './emps.actions';
import { Emp } from './emps.model';

const empLocal = JSON.parse(window.localStorage.getItem('emp'));
const estadoInicial: Emp | null = empLocal ? Object.assign(new Emp(), empLocal) : null;

export const empsReducer = createReducer(
  estadoInicial,
  on(fromEmps.cambiarEmpresa, (state, action) => {
    const nvoEmp = Object.assign(new Emp(), action.emp);
    window.localStorage.setItem('emp', JSON.stringify(nvoEmp));
    return nvoEmp;
  })
);

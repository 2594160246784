import { monType } from './fcvs.model';
import { Fondo } from './fondo.model';

export class FondoPase {


	_id: string = '';
	idUsu: string = '';
	idMdl: number = 250;
	fondoOrigen: Fondo = null
	fondoDestino: Fondo = null
	numero: number = 0;
	total: number = 0;
	idMon: monType = 'MXN';
	fecha: Date = new Date();
	observacion: string = '';


	constructor(obj: any = {}) {
		if (obj._id) { this._id = obj._id.toString() || ''; }
		this.idUsu = obj.idUsu || this.idUsu;
		this.idMdl = obj.idMdl || this.idMdl;
		this.fondoOrigen = obj.fondoOrigen || this.fondoOrigen;
		this.fondoDestino = obj.fondoDestino || this.fondoDestino;
		this.numero = obj.numero || this.numero;
		this.total = obj.total || this.total;
		this.idMon = obj.idMon || this.idMon;
		this.fecha = obj.fecha || this.fecha;
		this.fecha  = new Date(this.fecha)
		this.observacion = obj.observacion || this.observacion;

		if (!this._id) delete this._id
	}
}

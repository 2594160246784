import { Trato } from './trato.model';
import * as fromTratos from './trato.acciones';
import { createReducer, on } from '@ngrx/store';

const tratoLocal = JSON.parse(window.localStorage.getItem('trato'));
const estadoInicial: Trato = tratoLocal ? new Trato({ ...tratoLocal }) : new Trato();

export const tratoReducer = createReducer(
  estadoInicial,
  on(fromTratos.cambiarTrato, (state, action) => {
    const actual = JSON.parse(JSON.stringify(state));
    const nuevo = {
      ...actual,
      ...action.trato,
    };
    const nvoTrato = new Trato(nuevo);
    Object.assign(nvoTrato, action.trato);

    window.localStorage.setItem('trato', JSON.stringify(nvoTrato));

    return nvoTrato;
  }),
  on(fromTratos.cambiarPropiedadTrato, (state, action) => {
    const actual = JSON.parse(JSON.stringify(state));
    const nuevo = {
      ...actual,
      ...action.prop,
    };
    const nvoTrato = new Trato(nuevo);

    window.localStorage.setItem('trato', JSON.stringify(nvoTrato));

    return nvoTrato;
  })
);

import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
	selector: 'app-selector-general',
	templateUrl: './selector-general.component.html',
	styleUrls: ['./selector-general.component.scss']
})
export class SelectorGeneralComponent implements OnInit {

	arreglo: any[] = []
	columnasMostrar: string[] = []
	isModal:boolean = false
	buscar: string
	constructor(
		public modal: MatDialogRef<SelectorGeneralComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		if (data.modal) {
			this.isModal = data.modal
			this.arreglo = data.arreglo
			this.columnasMostrar = data.columnasMostrar
		}
	}

	cerrar() {
		this.modal.close();
	}
	seleccionar(elemento:any) {
		if (this.isModal) {
			this.modal.close(elemento);
			return;
		}
	}

	ngOnInit(): void {
	}

}

import { Filtros } from './filtros.model';
import * as fromFiltros from './filtros.actions';
import { createReducer, on } from '@ngrx/store';

const filtrosLocal = JSON.parse(window.localStorage.getItem('filtros'));
const estadoInicial: Filtros = filtrosLocal ? new Filtros(filtrosLocal) : new Filtros();

export const filtrosReducer = createReducer(
  estadoInicial,
  on(fromFiltros.cambiarFiltrosComponente, (state, action) => {
    const nvosFiltros: Filtros = {
      ...state,
      componentes: {
        ...state.componentes,
        [action.componente]: action.filtros
      }
    };
    window.localStorage.setItem('filtros', JSON.stringify(nvosFiltros));
    return nvosFiltros;
  }),
  on(fromFiltros.cambiarFcp, (state, action) => {
    const nvosFiltros: Filtros = {
      ...state,
      fcp: action.fcp
    };
    window.localStorage.setItem('filtros', JSON.stringify(nvosFiltros));
    return nvosFiltros;
  }),
  on(fromFiltros.cambiarArt, (state, action) => {
    const nvosFiltros: Filtros = {
      ...state,
      art: action.art
    };
    window.localStorage.setItem('filtros', JSON.stringify(nvosFiltros));
    return nvosFiltros;
  }),
  on(fromFiltros.resetFiltros, () => {
    const nvosFiltros: Filtros = new Filtros();
    window.localStorage.setItem('filtros', JSON.stringify(nvosFiltros));
    return nvosFiltros;
  })
);
